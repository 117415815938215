import { Button } from '@/components/ui/button'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { Filter, Operators } from '@/types/Filters'
import { Quotation } from '@/types/Quotation'
import { Search } from 'lucide-react'

interface SearchButtonProps {
    setFieldFilters: (filters: Filter<Quotation>[]) => void
}

const stateSelector = (state: Store) => ({
    companiesSelected: state.quotationListSlice.state.companiesSelected,
    purchaseRequestsSelected:
        state.quotationListSlice.state.purchaseRequestsSelected,
    suppliersSelected: state.quotationListSlice.state.suppliersSelected,
    statusSelected: state.quotationListSlice.state.statusSelected,
    searchInput: state.quotationListSlice.state.searchInput,
})

const SearchButton = ({ setFieldFilters }: SearchButtonProps) => {
    const {
        searchInput,
        companiesSelected,
        purchaseRequestsSelected,
        suppliersSelected,
        statusSelected,
    } = useBaseStore(stateSelector)

    const onClick = () => {
        const inputFilter: Filter<Quotation> | null = searchInput
            ? {
                  AND: [
                      {
                          OR: [
                              {
                                  field: 'DS_COTACAO',
                                  operator: Operators.like,
                                  value: searchInput,
                              },
                              {
                                  field: 'NM_COTACAO',
                                  operator: Operators.like,
                                  value: searchInput,
                              },
                              {
                                  field: 'NUMERO_DA_COTACAO',
                                  operator: Operators.like,
                                  value: searchInput,
                              },
                          ],
                      },
                  ],
              }
            : null
        const companyFilter: Filter<Quotation> | null =
            companiesSelected.length > 0
                ? {
                      AND: [
                          {
                              OR: companiesSelected.map((company) => ({
                                  field: 'SK_EMPRESA',
                                  operator: Operators.equals,
                                  value: company,
                              })),
                          },
                      ],
                  }
                : null
        const purchaseRequestFilter: Filter<Quotation> | null =
            purchaseRequestsSelected.length > 0
                ? {
                      AND: [
                          {
                              OR: purchaseRequestsSelected.map((requestId) => ({
                                  field: 'SK_SOLICITACAO_DE_COMPRAS',
                                  operator: Operators.equals,
                                  value: requestId,
                              })),
                          },
                      ],
                  }
                : null

        const supplierFilter: Filter<Quotation> | null =
            suppliersSelected.length > 0
                ? {
                      AND: [
                          {
                              OR: suppliersSelected.map((supplier) => ({
                                  field: 'SK_FORNECEDOR',
                                  operator: Operators.equals,
                                  value: supplier,
                              })),
                          },
                      ],
                  }
                : null
        const statusFilter: Filter<Quotation> | null =
            statusSelected.length > 0
                ? {
                      AND: [
                          {
                              OR: statusSelected.map((supplier) => ({
                                  field: 'STATUS',
                                  operator: Operators.like,
                                  value: supplier,
                              })),
                          },
                      ],
                  }
                : null

        setFieldFilters(
            [
                purchaseRequestFilter!,
                companyFilter!,
                supplierFilter!,
                inputFilter!,
                statusFilter!,
            ].filter(Boolean)
        )
    }

    return (
        <Button
            className="gap-2 cursor-pointer"
            onClick={onClick}
            variant="outline"
        >
            <Search size={14} />
            Filtrar
        </Button>
    )
}

export default SearchButton
