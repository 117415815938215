import { Breadcrumb, BreadcrumbList } from '../ui/breadcrumb'
import { Separator } from '../ui/separator'

type NavigationBarProps = {
    navigationList: React.ReactNode
    children?: React.ReactNode
}

const NavigationBar = ({ navigationList, children }: NavigationBarProps) => {
    return (
        <>
            <div className="flex items-center justify-between h-[48px] px-4 py-2">
                <Breadcrumb>
                    <BreadcrumbList>{navigationList}</BreadcrumbList>
                </Breadcrumb>
                {children}
            </div>
            <Separator />
        </>
    )
}

export default NavigationBar
