import { Table } from '@tanstack/react-table'
import { PaymentConditionTable } from '.'
import { utils, writeFile } from 'xlsx'
import { PaymentConditions } from '@/types/PaymentCondition'

const HEADERS: {
    original: keyof PaymentConditions
    header: string
}[] = [
    {
        original: 'SK_CONDICAO_DE_PAGAMENTO',
        header: 'COD CONDICAO DE PAGAMENTO',
    },
    { original: 'DS_CONDICAO_DE_PAGAMENTO', header: 'CONDICAO DE PAGAMENTO' },
]

export const usePaymentConditionSpreadsheet = () => {
    const getTableRows = (
        table: Table<PaymentConditionTable>
    ): Partial<PaymentConditions>[] =>
        table.getRowModel().rows.map((row) => {
            const obj = {} as Record<string, any>

            for (const [key, value] of Object.entries(row.original)) {
                const header = HEADERS.find((h) => h.original === key)?.header

                if (header && value) {
                    obj[header] = value.toString()
                }
            }

            return obj
        })

    const onDownload = (tableInstance: Table<PaymentConditionTable>) => {
        const rows = getTableRows(tableInstance)
        const worksheet = utils.json_to_sheet(rows)
        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Condições de pagamento')

        writeFile(workbook, 'Condições de pagamento.xlsx', {
            compression: true,
        })
    }

    return {
        onDownload,
    }
}
