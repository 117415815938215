import { Table } from '@tanstack/react-table'
import { read, utils, writeFile } from 'xlsx'
import { useToast } from '@/components/ui/use-toast'
import { Supplier } from '@/types/Suppliers'

const BULK_HEADERS: {
    original: keyof Supplier
    header: string
}[] = []

const HEADERS: {
    original: keyof Supplier
    header: string
}[] = [
    {
        original: 'DD_BLOQUEADO',
        header: 'Status',
    },
    {
        original: 'SK_FORNECEDOR',
        header: 'Cod fornecedor',
    },
    {
        original: 'DD_NOME_FANTASIA',
        header: 'Nome fantasia',
    },
    {
        original: 'DD_RAZAO_SOCIAL',
        header: 'Razão social',
    },
    {
        original: 'DS_EMAIL',
        header: 'Email',
    },
    {
        original: 'DD_CNPJ',
        header: 'CNPJ',
    },
    {
        original: 'DD_OBSERVACAO',
        header: 'Observação',
    },
    {
        original: 'DD_ORIGEM_DADO',
        header: 'Origem',
    },
    {
        original: 'DD_UF',
        header: 'UF',
    },
]

export const useSuppliersSpreadsheet = (tableInstance?: Table<Supplier>) => {
    const { toast } = useToast()

    const getTableRows = (table: Table<Supplier>): Partial<Supplier>[] =>
        table.getRowModel().rows.map((row) => {
            const obj = {} as Record<string, any>

            for (const [key, value] of Object.entries(row.original)) {
                const header = HEADERS.find((h) => h.original === key)?.header

                if (header && value) {
                    if ((key as keyof Supplier) === 'DD_BLOQUEADO')
                        obj[header] = value === 1 ? 'Bloqueado' : 'Ativo'
                    else if ((key as keyof Supplier) === 'DS_EMAIL') {
                        obj[header] = value.join(',')
                    } else {
                        obj[header] = value
                    }
                }
            }

            return obj
        })

    const getHeaders = (table: Table<Supplier>): string[] =>
        table
            .getFlatHeaders()
            .map(
                (header) =>
                    BULK_HEADERS.find((h) => header.id === h.original)
                        ?.header || ''
            )
            .filter((header) => Boolean(header))

    const onDownload = () => {
        if (!tableInstance)
            return toast({
                title: 'Erro ao fazer download',
                description:
                    'Houve um erro inesperado ao fazer o download da planilha. Tente novamente ou contate o suporte.',
                variant: 'destructive',
            })

        const rows = getTableRows(tableInstance)
        const worksheet = utils.json_to_sheet(rows)
        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Fornecedores')
        writeFile(workbook, 'Fornecedores.xlsx', {
            compression: true,
        })
    }

    const onDownloadBulkModel = (tableData?: Supplier[]) => {
        const headers = BULK_HEADERS.map((header) => header.header)

        const worksheet =
            tableData && tableData.length > 0
                ? utils.json_to_sheet(
                      tableData.map(() => {
                          const obj = {} as Record<string, any>

                          return obj
                      })
                  )
                : utils.aoa_to_sheet([headers])

        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Fornecedores')

        writeFile(workbook, 'CustoExtra.xlsx', {
            compression: true,
        })
    }

    const onReadData = async (sheetFile: File) => {
        const data = await sheetFile.arrayBuffer()
        const workbook = read(data)
        const sheet = workbook.Sheets[workbook.SheetNames[0]]

        const sheetJson = utils.sheet_to_json<Supplier>(sheet)

        return sheetJson.map((sheetData) => {
            const obj = {} as Record<string, any>

            for (const [key] of Object.entries(sheetData)) {
                const original = BULK_HEADERS.find(
                    (h) => h.header === key
                )?.original

                if (original) {
                }
            }

            return obj
        })
    }

    return {
        onDownloadBulkModel,
        onDownload,
        onReadData,
        getHeaders,
    }
}
