import {
    TypographyLarge,
    TypographyMuted,
    TypographyP,
} from '@/components/ui/typography'
import { QuotationMapSupplierValuesState } from '@/store/quotationMapSlice/type'
import {
    currencyFormat,
    formatCpfOrCnpj,
    percentageFormat,
} from '@/utils/stringFormatter'
import { Variants, motion } from 'framer-motion'
import { Eye } from 'lucide-react'

interface SupplierCardProps extends QuotationMapSupplierValuesState {
    onSelect: (selected: string) => void
}

const SupplierCard = ({
    SK_FORNECEDOR,
    DD_NOME_FANTASIA,
    DD_RAZAO_SOCIAL,
    DD_CNPJ,
    TOTAL_FINAL,
    TOTAL_INICIAL,
    SAVING,
    SAVING_PERCENTUAL,
    // VENCEDOR,
    // FORNECEDOR_DECLINADO,
    // EMPATE,
    onSelect,
}: SupplierCardProps) => {
    const cardVariants: Variants = {
        initial: {
            opacity: 0,
            y: 20,
        },
        animate: {
            opacity: 1,
            y: 0,
        },
        hover: {},
        exit: {
            opacity: 0,
            y: 20,
        },
    }

    return (
        <motion.div
            key={SK_FORNECEDOR}
            className={
                'cursor-pointer overflow-hidden py-4 space-y-2 bg-card border shadow-sm p-4 rounded-md hover:bg-accent'
            }
            layoutId={SK_FORNECEDOR}
            layout
            whileHover="hover"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={cardVariants}
            transition={{
                duration: 0.3,
                ease: 'easeInOut',
            }}
            onClick={() => onSelect(SK_FORNECEDOR)}
        >
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                    <TypographyP asChild>
                        <motion.p className="font-semibold origin-top-left text-primary-600">
                            {DD_NOME_FANTASIA}
                        </motion.p>
                    </TypographyP>
                    <button className="flex items-center justify-center w-6 h-6 border rounded-md">
                        <Eye size={14} className="text-yellow-600" />
                    </button>
                </div>
                {/* <div>
                    {FORNECEDOR_DECLINADO === 1 && (
                        <motion.div className="flex items-center gap-2 origin-top-right">
                            <motion.div>
                                <CircleOff size={14} className="text-red-500" />
                            </motion.div>
                            <TypographyP asChild>
                                <motion.p>Declinado</motion.p>
                            </TypographyP>
                        </motion.div>
                    )}
                    {VENCEDOR ? (
                        <motion.div className="flex items-center gap-2 origin-top-right">
                            <motion.div>
                                <Crown className="text-primary-600" size={18} />
                            </motion.div>
                            <TypographyP asChild>
                                <motion.p>Vencedor</motion.p>
                            </TypographyP>
                        </motion.div>
                    ) : null}
                    {EMPATE ? (
                        <motion.div className="flex items-center gap-2 origin-top-right">
                            <motion.div>
                                <HelpCircle
                                    className="text-amber-500"
                                    size={18}
                                />
                            </motion.div>
                            <TypographyP asChild>
                                <motion.p>Empate</motion.p>
                            </TypographyP>
                        </motion.div>
                    ) : null}
                </div> */}
            </div>
            <div>
                <TypographyMuted asChild>
                    <motion.p className="overflow-hidden whitespace-nowrap text-ellipsis">
                        {DD_RAZAO_SOCIAL}
                    </motion.p>
                </TypographyMuted>
                <TypographyMuted asChild>
                    <motion.p className="overflow-hidden whitespace-nowrap text-ellipsis">
                        {formatCpfOrCnpj(DD_CNPJ)}
                    </motion.p>
                </TypographyMuted>
            </div>
            <div className="flex items-start justify-between py-4">
                <div>
                    <TypographyMuted>Valor total inicial</TypographyMuted>
                    <TypographyLarge>
                        {currencyFormat(TOTAL_INICIAL)}
                    </TypographyLarge>
                </div>
                <div>
                    <TypographyMuted>Valor total final</TypographyMuted>
                    <TypographyLarge>
                        {currencyFormat(TOTAL_FINAL)}
                    </TypographyLarge>
                </div>
                <div>
                    <TypographyMuted>Valor saving</TypographyMuted>
                    <TypographyLarge>{currencyFormat(SAVING)}</TypographyLarge>
                </div>
                <div>
                    <TypographyMuted>Saving </TypographyMuted>
                    <TypographyLarge>
                        {percentageFormat(SAVING_PERCENTUAL)}
                    </TypographyLarge>
                </div>
            </div>
        </motion.div>
    )
}

export default SupplierCard
