import { TypographyH3 } from '@/components/ui/typography'

const QuotationLayoutError = () => {
    return (
        <div className="p-4">
            <TypographyH3 className="font-normal text-center text-destructive">
                Houve um erro ao exibir a cotação
            </TypographyH3>
        </div>
    )
}

export default QuotationLayoutError
