import {
    QuotationMapProduct,
    QuotationMapValues,
} from '@/api/business/cotacoes/type'
import {
    ColumnOrderState,
    Header,
    createColumnHelper,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { currencyFormat, formatCpfOrCnpj } from '@/utils/stringFormatter'
import { useBaseStore } from '@/store'
import { quotationMapStateSelector } from '@/store/quotationMapSlice'
import { cn } from '@/lib/utils'
import { Crown, HelpCircle } from 'lucide-react'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import TableContainer from '@/components/Table/components/TableContainer'
import TableLayout from '@/components/Table/components/TableLayout'
import TableCell from '@/components/Table/components/TableCell'
import TableColumnHeader from '@/components/Table/components/TableColumnHeader'
import { TableContextProvider } from '@/components/Table/contexts/TableContext'
import { format, isValid } from 'date-fns'
import TableBody from '@/components/Table/components/TableBody'
import TableRow from '@/components/Table/components/TableRow'
import TableFooter from '@/components/Table/components/TableFooter'
import TableColumnsVisibility from './components/TableColumnsVisibility'
import { Store } from '@/store/type'

type QuotationMapTableType = QuotationMapValues & QuotationMapProduct

const columnHelper = createColumnHelper<QuotationMapTableType>()

const stateSelector = (state: Store) => ({
    quotationMapTableVisibility:
        state.quotationMapSlice.state.quotationMapTableVisibility,
})

const QuotationMapTable = () => {
    const { quotationMapTableVisibility } = useBaseStore(stateSelector)
    const { quotationMap } = useBaseStore(quotationMapStateSelector)

    const [columnOrderState, setColumnOrderState] = useState<ColumnOrderState>(
        []
    )

    const suppliers = useMemo(
        () => Object.values(quotationMap?.FORNECEDORES.entities || []),
        [quotationMap.FORNECEDORES]
    )

    const products = useMemo(
        () => Object.values(quotationMap?.PRODUTOS.entities || []),
        [quotationMap.PRODUTOS]
    )

    const values = quotationMap?.VALORES || []

    const columnsMemo = useMemo(
        () => [
            columnHelper.accessor('SK_EMPRESA', {
                header: 'Cod Empresa',
                size: 120,
                enableHiding: false,
                cell: ({ getValue, row }) => {
                    const { COR_EMPRESA } = row.original

                    return (
                        <span
                            style={{ color: COR_EMPRESA }}
                            className="overflow-hidden whitespace-nowrap text-ellipsis"
                        >
                            {getValue()}
                        </span>
                    )
                },
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                header: 'Empresa',
                size: 120,
                enableHiding: false,
                cell: ({ getValue, row }) => {
                    const { COR_EMPRESA } = row.original

                    return (
                        <span
                            style={{ color: COR_EMPRESA }}
                            className="overflow-hidden whitespace-nowrap text-ellipsis"
                        >
                            {getValue()}
                        </span>
                    )
                },
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('NK_ITEM_SOLICITACAO_DE_COMPRAS', {
                header: 'Item',
                meta: {
                    enableColumnOrdering: false,
                },
                enableHiding: false,
                size: 80,
            }),
            columnHelper.accessor('NK_SOLICITACAO_DE_COMPRAS', {
                header: 'SC',
                meta: {
                    enableColumnOrdering: false,
                },
                enableHiding: false,
                size: 100,
            }),
            columnHelper.accessor('DS_PRODUTO', {
                header: 'Produto',
                meta: {
                    enableColumnOrdering: false,
                },
                enableHiding: false,
                size: 400,
            }),
            columnHelper.accessor('DD_UM', {
                header: 'UM',
                meta: {
                    enableColumnOrdering: false,
                },
                enableHiding: false,
                size: 80,
            }),
            columnHelper.accessor('QTD_DISPONIVEL', {
                header: 'Qtde. disponível',
                meta: {
                    enableColumnOrdering: false,
                },
                enableHiding: false,
                size: 120,
            }),
            columnHelper.accessor('QTD_SOLICITADA', {
                header: 'Qtde. solicitada',
                enableHiding: false,
                size: 120,
                meta: {
                    enableColumnOrdering: false,
                },
                footer: () => 'Total',
            }),
            columnHelper.group({
                header: 'Fornecedores',
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                    header: {
                        className: 'text-xs p-4 font-bold',
                    },
                },
                columns: [
                    ...suppliers
                        .filter(
                            (supplier) =>
                                quotationMapTableVisibility.suppliers[
                                    supplier.SK_FORNECEDOR
                                ]
                        )
                        .map((supplier) => {
                            return columnHelper.group({
                                id: supplier.SK_FORNECEDOR,
                                header: () => {
                                    return (
                                        <div>
                                            <TypographyP className="text-xs">
                                                {supplier.DD_NOME_FANTASIA}
                                            </TypographyP>
                                            <TypographyMuted className="text-xs">
                                                {supplier.DD_RAZAO_SOCIAL} -{' '}
                                                {formatCpfOrCnpj(
                                                    supplier.DD_CNPJ
                                                )}
                                            </TypographyMuted>
                                        </div>
                                    )
                                },
                                meta: {
                                    enableColumnOrdering: false,
                                    enableMenu: false,
                                    header: {
                                        className: 'text-sm p-4 font-bold',
                                    },
                                },
                                columns: quotationMap?.RODADAS.filter(
                                    (round) =>
                                        quotationMapTableVisibility.rounds[
                                            round.SK_COTACAO_X_RODADA
                                        ]
                                ).map((round) => {
                                    const columnGroup = columnHelper.group({
                                        id: `${round.SK_COTACAO_X_RODADA}${supplier.SK_FORNECEDOR}`,
                                        header: `Rodada ${round.NUMERO_RODADA}`,
                                        meta: {
                                            enableColumnOrdering: false,
                                            enableMenu: false,
                                            header: {
                                                className:
                                                    'text-xs p-4 font-bold',
                                            },
                                        },
                                        columns: [],
                                    })

                                    if (
                                        quotationMapTableVisibility.fields[
                                            'PRECO'
                                        ]
                                    ) {
                                        columnGroup.columns?.push(
                                            columnHelper.display({
                                                id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-net`,
                                                header: 'Valor net',
                                                cell: ({ row }) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row.original

                                                    const value = values?.find(
                                                        (value) =>
                                                            supplier.FORNECEDOR_RODADAS.includes(
                                                                value.SK_FORNECEDOR_X_RODADA
                                                            ) &&
                                                            round.SK_COTACAO_X_RODADA ===
                                                                value.SK_COTACAO_X_RODADA &&
                                                            value.SK_COTACAO_X_PRODUTO ===
                                                                SK_COTACAO_X_PRODUTO
                                                    )

                                                    const price =
                                                        value?.PRECO || 0
                                                    const bestScore =
                                                        value?.MELHOR_SCORE
                                                    const draw = value?.EMPATE

                                                    return (
                                                        <div
                                                            className={cn(
                                                                'relative flex flex-1 p-3 items-center justify-end h-full gap-2',
                                                                draw &&
                                                                    'bg-amber-500',
                                                                bestScore &&
                                                                    'dark:bg-green-500 bg-green-300'
                                                            )}
                                                        >
                                                            {draw ? (
                                                                <div className="text-foreground">
                                                                    <HelpCircle
                                                                        size={
                                                                            14
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : bestScore ? (
                                                                <div className="text-foreground">
                                                                    <Crown
                                                                        size={
                                                                            14
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}

                                                            <TypographyP
                                                                className={cn(
                                                                    'text-right text-xs text-foreground'
                                                                )}
                                                            >
                                                                {currencyFormat(
                                                                    Number(
                                                                        price
                                                                    )
                                                                )}
                                                            </TypographyP>
                                                        </div>
                                                    )
                                                },
                                                meta: {
                                                    enableColumnOrdering: false,
                                                    enableMenu: false,
                                                    header: {
                                                        className:
                                                            'text-xs flex items-center h-auto font-bold',
                                                    },
                                                    cell: {
                                                        className: 'p-0',
                                                    },
                                                },
                                                footer: () => {
                                                    const sum = values
                                                        .filter((value) =>
                                                            supplier.FORNECEDOR_RODADAS.some(
                                                                (id) =>
                                                                    id ===
                                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                                    round.SK_COTACAO_X_RODADA ===
                                                                        value.SK_COTACAO_X_RODADA
                                                            )
                                                        )
                                                        .reduce((acc, curr) => {
                                                            const value =
                                                                Number(
                                                                    curr.PRECO
                                                                ) || 0
                                                            return acc + value
                                                        }, 0)
                                                    return currencyFormat(sum)
                                                },
                                            })
                                        )
                                    }

                                    if (
                                        quotationMapTableVisibility.fields[
                                            'PRECO_TOTAL'
                                        ]
                                    ) {
                                        columnGroup.columns?.push(
                                            columnHelper.display({
                                                id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-total`,
                                                header: 'Valor total',
                                                meta: {
                                                    enableColumnOrdering: false,
                                                    enableMenu: false,
                                                    header: {
                                                        className:
                                                            'text-xs flex items-center h-auto font-bold',
                                                    },
                                                    cell: {
                                                        className: 'p-0',
                                                    },
                                                },
                                                cell: ({ row }) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row.original

                                                    const value = values?.find(
                                                        (value) =>
                                                            supplier.FORNECEDOR_RODADAS.includes(
                                                                value.SK_FORNECEDOR_X_RODADA
                                                            ) &&
                                                            round.SK_COTACAO_X_RODADA ===
                                                                value.SK_COTACAO_X_RODADA &&
                                                            value.SK_COTACAO_X_PRODUTO ===
                                                                SK_COTACAO_X_PRODUTO
                                                    )

                                                    const price =
                                                        value?.PRECO_TOTAL || 0
                                                    const bestScore =
                                                        value?.MELHOR_SCORE
                                                    const draw = value?.EMPATE

                                                    return (
                                                        <div
                                                            className={cn(
                                                                'relative flex flex-1 p-3 items-center justify-end h-full gap-2',
                                                                draw &&
                                                                    'bg-amber-500',
                                                                bestScore &&
                                                                    'dark:bg-green-500 bg-green-300'
                                                            )}
                                                        >
                                                            {draw ? (
                                                                <div className="text-foreground">
                                                                    <HelpCircle
                                                                        size={
                                                                            14
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : bestScore ? (
                                                                <div className="text-foreground">
                                                                    <Crown
                                                                        size={
                                                                            14
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}
                                                            <TypographyP className="flex-1 text-xs text-right">
                                                                {currencyFormat(
                                                                    price
                                                                )}
                                                            </TypographyP>
                                                        </div>
                                                    )
                                                },
                                                footer: () => {
                                                    const sum = values
                                                        .filter((value) =>
                                                            supplier.FORNECEDOR_RODADAS.some(
                                                                (id) =>
                                                                    id ===
                                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                                    round.SK_COTACAO_X_RODADA ===
                                                                        value.SK_COTACAO_X_RODADA
                                                            )
                                                        )
                                                        .reduce((acc, curr) => {
                                                            const value =
                                                                Number(
                                                                    curr.PRECO_TOTAL
                                                                ) || 0
                                                            return acc + value
                                                        }, 0)
                                                    return currencyFormat(sum)
                                                },
                                            })
                                        )
                                    }

                                    if (
                                        quotationMapTableVisibility.fields[
                                            'PRECO_IMPOSTO'
                                        ]
                                    ) {
                                        columnGroup.columns?.push(
                                            columnHelper.display({
                                                id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-taxes`,
                                                header: 'Valor com imposto',
                                                meta: {
                                                    enableColumnOrdering: false,
                                                    enableMenu: false,
                                                    header: {
                                                        className:
                                                            'text-xs flex items-center h-auto font-bold',
                                                    },
                                                    cell: {
                                                        className: 'p-0',
                                                    },
                                                },
                                                cell: ({ row }) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row.original

                                                    const value = values?.find(
                                                        (value) =>
                                                            supplier.FORNECEDOR_RODADAS.includes(
                                                                value.SK_FORNECEDOR_X_RODADA
                                                            ) &&
                                                            round.SK_COTACAO_X_RODADA ===
                                                                value.SK_COTACAO_X_RODADA &&
                                                            value.SK_COTACAO_X_PRODUTO ===
                                                                SK_COTACAO_X_PRODUTO
                                                    )

                                                    const price =
                                                        value?.PRECO_IMPOSTO ||
                                                        0
                                                    const bestScore =
                                                        value?.MELHOR_SCORE
                                                    const draw = value?.EMPATE

                                                    return (
                                                        <div
                                                            className={cn(
                                                                'relative flex flex-1 p-3 items-center justify-end h-full gap-2',
                                                                draw &&
                                                                    'bg-amber-500',
                                                                bestScore &&
                                                                    'dark:bg-green-500 bg-green-300'
                                                            )}
                                                        >
                                                            {draw ? (
                                                                <div className="text-foreground">
                                                                    <HelpCircle
                                                                        size={
                                                                            14
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : bestScore ? (
                                                                <div className="text-foreground">
                                                                    <Crown
                                                                        size={
                                                                            14
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}

                                                            <TypographyP
                                                                className={cn(
                                                                    'text-right text-xs text-foreground'
                                                                )}
                                                            >
                                                                {currencyFormat(
                                                                    Number(
                                                                        price
                                                                    )
                                                                )}
                                                            </TypographyP>
                                                        </div>
                                                    )
                                                },
                                                footer: () => {
                                                    const sum = values
                                                        .filter((value) =>
                                                            supplier.FORNECEDOR_RODADAS.some(
                                                                (id) =>
                                                                    id ===
                                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                                    round.SK_COTACAO_X_RODADA ===
                                                                        value.SK_COTACAO_X_RODADA
                                                            )
                                                        )
                                                        .reduce((acc, curr) => {
                                                            const value =
                                                                Number(
                                                                    curr.PRECO_IMPOSTO
                                                                ) || 0
                                                            return acc + value
                                                        }, 0)
                                                    return currencyFormat(sum)
                                                },
                                            })
                                        )
                                    }

                                    if (
                                        quotationMapTableVisibility.fields[
                                            'PRECO_TOTAL_IMPOSTO'
                                        ]
                                    ) {
                                        columnGroup.columns?.push(
                                            columnHelper.display({
                                                id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-total-taxes`,
                                                header: 'Valor total com imposto',
                                                meta: {
                                                    enableColumnOrdering: false,
                                                    enableMenu: false,
                                                    header: {
                                                        className:
                                                            'text-xs flex items-center h-auto font-bold text-ellipsis overflow-hidden',
                                                    },
                                                    cell: {
                                                        className: 'p-0',
                                                    },
                                                },
                                                cell: ({ row }) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row.original

                                                    const value = values?.find(
                                                        (value) =>
                                                            supplier.FORNECEDOR_RODADAS.includes(
                                                                value.SK_FORNECEDOR_X_RODADA
                                                            ) &&
                                                            round.SK_COTACAO_X_RODADA ===
                                                                value.SK_COTACAO_X_RODADA &&
                                                            value.SK_COTACAO_X_PRODUTO ===
                                                                SK_COTACAO_X_PRODUTO
                                                    )
                                                    const price =
                                                        value?.PRECO_TOTAL_IMPOSTO ||
                                                        0

                                                    const bestScore =
                                                        value?.MELHOR_SCORE
                                                    const draw = value?.EMPATE

                                                    return (
                                                        <div
                                                            className={cn(
                                                                'relative flex flex-1 p-3 items-center justify-end h-full gap-2',
                                                                draw &&
                                                                    'bg-amber-500',
                                                                bestScore &&
                                                                    'dark:bg-green-500 bg-green-300'
                                                            )}
                                                        >
                                                            {draw ? (
                                                                <div className="text-foreground">
                                                                    <HelpCircle
                                                                        size={
                                                                            14
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : bestScore ? (
                                                                <div className="text-foreground">
                                                                    <Crown
                                                                        size={
                                                                            14
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}
                                                            <TypographyP className="flex-1 text-xs text-right">
                                                                {currencyFormat(
                                                                    price
                                                                )}
                                                            </TypographyP>
                                                        </div>
                                                    )
                                                },
                                                footer: () => {
                                                    const sum = values
                                                        .filter((value) =>
                                                            supplier.FORNECEDOR_RODADAS.some(
                                                                (id) =>
                                                                    id ===
                                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                                    round.SK_COTACAO_X_RODADA ===
                                                                        value.SK_COTACAO_X_RODADA
                                                            )
                                                        )
                                                        .reduce((acc, curr) => {
                                                            const value =
                                                                Number(
                                                                    curr.PRECO_TOTAL_IMPOSTO
                                                                ) || 0
                                                            return acc + value
                                                        }, 0)
                                                    return currencyFormat(sum)
                                                },
                                            })
                                        )
                                    }

                                    if (
                                        quotationMapTableVisibility.fields[
                                            'FRETE'
                                        ]
                                    ) {
                                        columnGroup.columns?.push(
                                            columnHelper.display({
                                                id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-frete`,
                                                header: 'Valor com frete',
                                                cell: ({ row }) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row.original

                                                    const value = values?.find(
                                                        (value) =>
                                                            supplier.FORNECEDOR_RODADAS.includes(
                                                                value.SK_FORNECEDOR_X_RODADA
                                                            ) &&
                                                            round.SK_COTACAO_X_RODADA ===
                                                                value.SK_COTACAO_X_RODADA &&
                                                            value.SK_COTACAO_X_PRODUTO ===
                                                                SK_COTACAO_X_PRODUTO
                                                    )

                                                    const price =
                                                        value?.PRECO_COM_FRETE ||
                                                        0
                                                    const bestScore =
                                                        value?.MELHOR_SCORE
                                                    const draw = value?.EMPATE

                                                    return (
                                                        <div
                                                            className={cn(
                                                                'relative flex flex-1 p-3 items-center justify-end h-full gap-2',
                                                                draw &&
                                                                    'bg-amber-500',
                                                                bestScore &&
                                                                    'dark:bg-green-500 bg-green-300'
                                                            )}
                                                        >
                                                            {draw ? (
                                                                <div className="text-foreground">
                                                                    <HelpCircle
                                                                        size={
                                                                            14
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : bestScore ? (
                                                                <div className="text-foreground">
                                                                    <Crown
                                                                        size={
                                                                            14
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}

                                                            <TypographyP
                                                                className={cn(
                                                                    'text-right text-xs text-foreground'
                                                                )}
                                                            >
                                                                {currencyFormat(
                                                                    Number(
                                                                        price
                                                                    )
                                                                )}
                                                            </TypographyP>
                                                        </div>
                                                    )
                                                },
                                                meta: {
                                                    enableColumnOrdering: false,
                                                    enableMenu: false,
                                                    header: {
                                                        className:
                                                            'text-xs flex items-center h-auto font-bold',
                                                    },
                                                    cell: {
                                                        className: 'p-0',
                                                    },
                                                },
                                                footer: () => {
                                                    const sum = values
                                                        .filter((value) =>
                                                            supplier.FORNECEDOR_RODADAS.some(
                                                                (id) =>
                                                                    id ===
                                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                                    round.SK_COTACAO_X_RODADA ===
                                                                        value.SK_COTACAO_X_RODADA
                                                            )
                                                        )
                                                        .reduce((acc, curr) => {
                                                            const value =
                                                                Number(
                                                                    curr.PRECO_COM_FRETE
                                                                ) || 0
                                                            return acc + value
                                                        }, 0)
                                                    return currencyFormat(sum)
                                                },
                                            })
                                        )
                                    }

                                    if (
                                        quotationMapTableVisibility.fields[
                                            'PREVISAO_DE_ENTREGA'
                                        ]
                                    ) {
                                        columnGroup.columns?.push(
                                            columnHelper.display({
                                                id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-date`,
                                                header: 'Previsão de entrega',
                                                meta: {
                                                    enableColumnOrdering: false,
                                                    enableMenu: false,
                                                    header: {
                                                        className:
                                                            'text-xs flex items-center h-auto font-bold',
                                                    },
                                                    cell: {
                                                        className: 'p-0',
                                                    },
                                                },
                                                cell: ({ row }) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row.original

                                                    const value = values?.find(
                                                        (value) =>
                                                            supplier.FORNECEDOR_RODADAS.includes(
                                                                value.SK_FORNECEDOR_X_RODADA
                                                            ) &&
                                                            round.SK_COTACAO_X_RODADA ===
                                                                value.SK_COTACAO_X_RODADA &&
                                                            value.SK_COTACAO_X_PRODUTO ===
                                                                SK_COTACAO_X_PRODUTO
                                                    )
                                                    const date =
                                                        value?.PREVISAO_DE_ENTREGA
                                                    const bestScore =
                                                        value?.MELHOR_SCORE
                                                    const draw = value?.EMPATE

                                                    return (
                                                        <div
                                                            className={cn(
                                                                'relative flex flex-1 p-3 items-center justify-end h-full gap-2',
                                                                draw &&
                                                                    'bg-amber-500',
                                                                bestScore &&
                                                                    'dark:bg-green-500 bg-green-300'
                                                            )}
                                                        >
                                                            {draw ? (
                                                                <div className="text-foreground">
                                                                    <HelpCircle
                                                                        size={
                                                                            14
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : bestScore ? (
                                                                <div className="text-foreground">
                                                                    <Crown
                                                                        size={
                                                                            14
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}
                                                            <TypographyP className="flex-1 text-xs text-right">
                                                                {date
                                                                    ? isValid(
                                                                          new Date(
                                                                              date
                                                                          )
                                                                      )
                                                                        ? format(
                                                                              new Date(
                                                                                  date
                                                                              ),
                                                                              'dd/MM/yyyy'
                                                                          )
                                                                        : '-'
                                                                    : '-'}
                                                            </TypographyP>
                                                        </div>
                                                    )
                                                },
                                            })
                                        )
                                    }

                                    return columnGroup
                                }),
                            })
                        }),
                ],
            }),
        ],
        [quotationMap, quotationMapTableVisibility]
    )

    const dataMemo = useMemo(() => {
        return products.map((product) => {
            return {
                ...product,
                ...values.find(
                    (value) =>
                        value.SK_COTACAO_X_PRODUTO ===
                        product.SK_COTACAO_X_PRODUTO
                )!,
            }
        })
    }, [quotationMap])

    const table = useReactTable({
        data: dataMemo,
        columns: columnsMemo,
        filterFromLeafRows: true,
        columnResizeMode: 'onChange',
        state: {
            columnOrder: columnOrderState,
        },
        meta: {
            layout: 'stretch',
        },
        onColumnOrderChange: setColumnOrderState,
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
    })

    const rows = table.getRowModel().rows
    // const mergedHeaders = getMergeHeaderGroups(table.getHeaderGroups())

    if (!quotationMap) return 'Carregando...'

    return (
        <>
            <div className="flex justify-end p-4">
                <TableColumnsVisibility />
            </div>
            <TableContainer>
                <TableContextProvider table={table}>
                    <TableLayout>
                        {/* thead */}
                        <div className="bg-background">
                            {table.getHeaderGroups().map((headerGroup) => (
                                // tr
                                <div key={headerGroup.id} className="flex">
                                    {headerGroup.headers.map((header) => (
                                        // th
                                        <TableColumnHeader
                                            key={header.id}
                                            header={header}
                                        />
                                    ))}
                                </div>
                            ))}
                        </div>
                        {/* tbody */}
                        <TableBody>
                            {rows.map((row) => {
                                return (
                                    // tr
                                    <TableRow
                                        className="flex border-b"
                                        key={row?.id}
                                    >
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                // td
                                                <TableCell
                                                    key={cell.id}
                                                    cell={cell}
                                                    className="overflow-hidden whitespace-nowrap text-ellipsis"
                                                />
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                        <div>
                            {table.getFooterGroups().map((footerGroup) => (
                                <div key={footerGroup.id} className="flex">
                                    {footerGroup.headers.map(
                                        (header) =>
                                            header.depth === 4 && (
                                                <TableFooter
                                                    key={header.id}
                                                    header={
                                                        header as Header<
                                                            unknown,
                                                            any
                                                        >
                                                    }
                                                />
                                            )
                                    )}
                                </div>
                            ))}
                        </div>
                    </TableLayout>
                </TableContextProvider>
            </TableContainer>
        </>
    )
}

export default QuotationMapTable
