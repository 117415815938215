import { SavingType } from '@/api/business/cotacoes/type'
import Table from '@/components/Table'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { TableData } from '@/components/Table/type'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { useBaseStore } from '@/store'
import { QuotationMapFlatState } from '@/store/quotationMapSlice/type'
import { Store } from '@/store/type'
import {
    currencyFormat,
    formatCpfOrCnpj,
    percentageFormat,
} from '@/utils/stringFormatter'
import { createColumnHelper } from '@tanstack/react-table'
import { format, isValid } from 'date-fns'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'

type PurchaseOrderTableProps = {
    companyId: string
    supplierId: string
    values: QuotationMapFlatState[]
}

type TableType = TableData<QuotationMapFlatState>

const columnHelper = createColumnHelper<TableType>()

const stateSelector =
    (companyId: string, supplierId: string) => (state: Store) => ({
        updatedValues:
            state.quotationMapSlice.state.purchaseOrderDialog.updatedValues.filter(
                (value) =>
                    value.SK_FORNECEDOR === supplierId &&
                    value.SK_EMPRESA === companyId
            ),
        onUpdatePurchaseOrderValue:
            state.quotationMapSlice.actions.onUpdatePurchaseOrderValue,
    })

const PurchaseOrderTable = ({
    companyId,
    supplierId,
    values,
}: PurchaseOrderTableProps) => {
    const { updatedValues, onUpdatePurchaseOrderValue } = useBaseStore(
        stateSelector(companyId, supplierId)
    )

    const data = useMemo(
        () => _.unionBy(updatedValues, values, 'SK_RESPOSTA_FORNECEDOR'),
        [values, updatedValues]
    ) as QuotationMapFlatState[]

    const columns = useMemo(
        () => [
            columnHelper.accessor('SK_EMPRESA', {
                id: 'SK_EMPRESA',
                header: 'Empresa',
                size: 100,
            }),
            columnHelper.accessor('NK_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_SOLICITACAO_DE_COMPRAS',
                header: 'SC',
                size: 100,
            }),
            columnHelper.accessor('NK_ITEM_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_ITEM_SOLICITACAO_DE_COMPRAS',
                header: 'Item',
                size: 100,
            }),
            columnHelper.accessor('DS_PRODUTO', {
                id: 'DS_PRODUTO',
                header: 'Produto',
                size: 400,
            }),
            columnHelper.accessor('DD_UM', {
                id: 'DD_UM',
                header: 'UM',
                size: 100,
            }),
            columnHelper.accessor('CNPJ', {
                id: 'CNPJ',
                header: 'CNPJ',
                size: 120,
                meta: {
                    cell: {
                        formatterFn: ({ value }) => formatCpfOrCnpj(value),
                    },
                },
            }),
            columnHelper.accessor('DD_RAZAO_SOCIAL', {
                id: 'DD_RAZAO_SOCIAL',
                header: 'Fornecedor',
                size: 400,
            }),
            columnHelper.accessor('PRECO', {
                id: 'PRECO',
                header: 'Preço',
                size: 100,
                meta: {
                    cell: {
                        formatterFn: ({ value }) => currencyFormat(value, 4),
                        className: 'justify-end',
                    },
                },
            }),
            columnHelper.accessor('PRECO_IMPOSTO', {
                id: 'PRECO_IMPOSTO',
                header: 'Preço com imposto',
                size: 150,
                meta: {
                    cell: {
                        formatterFn: ({ value }) => currencyFormat(value, 4),
                        className: 'justify-end',
                    },
                },
            }),
            columnHelper.accessor('PRECO_TOTAL', {
                id: 'PRECO_TOTAL',
                header: 'Preço total',
                size: 150,
                meta: {
                    cell: {
                        formatterFn: ({ value }) => currencyFormat(value, 4),
                        className: 'justify-end',
                    },
                },
            }),
            columnHelper.accessor('PRECO_TOTAL_IMPOSTO', {
                id: 'PRECO_TOTAL_IMPOSTO',
                header: 'Preço total com imposto',
                size: 200,
                meta: {
                    cell: {
                        formatterFn: ({ value }) => currencyFormat(value, 4),
                        className: 'justify-end',
                    },
                },
            }),
            columnHelper.accessor('VL_SPENDING', {
                id: 'VL_SPENDING',
                header: 'Valor spending',
                size: 120,
                meta: {
                    cell: {
                        className: 'p-0',
                    },
                    enableColumnOrdering: false,
                    editableColumnType: 'number',
                    enableColumnEditable: true,
                },
            }),
            columnHelper.accessor(
                ({ VL_SAVING }) => currencyFormat(VL_SAVING),
                {
                    id: 'VL_SAVING',
                    header: 'Valor saving',
                    size: 120,
                    meta: {
                        enableColumnOrdering: false,
                        cell: { className: 'p-4 justify-end' },
                    },
                }
            ),
            columnHelper.accessor(
                ({ PERCENTUAL_SAVING }) =>
                    percentageFormat(Number(PERCENTUAL_SAVING) || 0),
                {
                    id: 'PERCENTUAL_SAVING',
                    header: 'Saving (%)',
                    size: 120,
                    meta: {
                        editableColumnType: 'number',
                    },
                }
            ),
            columnHelper.accessor('DD_SAVING', {
                id: 'DD_SAVING',
                header: 'Tipo Saving',
                size: 120,
                cell: ({ getValue, table, row, column }) => {
                    const initialValue = getValue()

                    const [value, setValue] = useState(initialValue)
                    const updateData = table.options.meta?.updateData

                    useEffect(() => {
                        setValue(initialValue)
                    }, [initialValue])

                    return (
                        <Select
                            value={value}
                            onValueChange={(value) => {
                                if (updateData) {
                                    updateData(
                                        row.id,
                                        'DD_SAVING',
                                        value,
                                        column.columnDef
                                    )
                                }
                            }}
                        >
                            <SelectTrigger className="text-xs truncate">
                                <SelectValue placeholder="Selecione um tipo de saving" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value={SavingType.PRECO}>
                                    Preço
                                </SelectItem>
                                <SelectItem value={SavingType.PRECO_MANTIDO}>
                                    Preço mantido
                                </SelectItem>
                                <SelectItem value={SavingType.CUSTO_EVITADO}>
                                    Custo evitado
                                </SelectItem>
                                <SelectItem
                                    value={SavingType.CONDICAO_PAGAMENTO}
                                >
                                    Condição de pagamento
                                </SelectItem>
                                <SelectItem value={SavingType.PERFORMANCE}>
                                    Performance comprador
                                </SelectItem>
                                <SelectItem value={SavingType.NENHUM}>
                                    Nenhum
                                </SelectItem>
                            </SelectContent>
                        </Select>
                    )
                },
                meta: {
                    enableColumnOrdering: false,
                    cell: {
                        className: 'p-0',
                    },
                },
            }),
            columnHelper.accessor('FCP', {
                id: 'FCP',
                header: 'FCP',
            }),
            columnHelper.accessor('ICMS', {
                id: 'ICMS',
                header: 'ICMS',
                meta: {
                    editableColumnType: 'number',
                    enableColumnOrdering: false,
                    enableColumnEditable: true,
                    cell: {
                        className: 'p-0',
                    },
                },
            }),
            columnHelper.accessor('IPI', {
                id: 'IPI',
                header: 'IPI',
            }),
            columnHelper.accessor('PIS', {
                id: 'PIS',
                header: 'PIS',
            }),
            columnHelper.accessor('COFINS', {
                id: 'COFINS',
                header: 'COFINS',
            }),
            columnHelper.accessor('ST', {
                id: 'ST',
                header: 'ST',
            }),
            columnHelper.accessor('FRETE', {
                id: 'FRETE',
                header: 'Frete',
                size: 100,
            }),
            columnHelper.accessor('DS_CONDICAO_DE_PAGAMENTO', {
                id: 'DS_CONDICAO_DE_PAGAMENTO',
                header: 'Condição de pagamento',
                size: 250,
            }),
            columnHelper.accessor('PREVISAO_DE_ENTREGA', {
                id: 'PREVISAO_DE_ENTREGA',
                header: 'Previsão de entrega',
                size: 200,
                meta: {
                    cell: {
                        formatterFn: ({ value }) =>
                            value && isValid(new Date(value))
                                ? format(new Date(value), 'dd/MM/yyyy')
                                : '-',
                    },
                },
            }),
        ],
        []
    )

    return (
        <div className="flex flex-col overflow-hidden">
            <Table<TableType>
                data={data}
                columns={columns}
                getRowId={(row) => row.SK_RESPOSTA_FORNECEDOR}
                meta={{
                    layout: 'stretch',
                    updateData: onUpdatePurchaseOrderValue,
                }}
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultColumn>{getValue() as string}</DefaultColumn>
                    ),
                }}
            />
        </div>
    )
}

export default PurchaseOrderTable
