import { ShippingCompany } from '@/types/ShippingCompany'
import ShippingItem from './components/ShippingItem'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useRef } from 'react'

type ShippingListProps = {
    data: ShippingCompany[]
    selectedId: string | null
    onItemClick: (id: string) => void
}

const ShippingList = ({ data, selectedId, onItemClick }: ShippingListProps) => {
    const parentRef = useRef<HTMLDivElement>(null)

    const count = data.length
    const virtualizer = useVirtualizer({
        count,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 45,
    })

    const items = virtualizer.getVirtualItems()

    return (
        <div
            ref={parentRef}
            className="h-[800px]"
            style={{
                overflowY: 'auto',
                contain: 'strict',
            }}
        >
            <div
                style={{
                    height: virtualizer.getTotalSize(),
                    width: '100%',
                    position: 'relative',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        transform: `translateY(${items[0]?.start ?? 0}px)`,
                    }}
                >
                    {items.map((virtualRow) => {
                        const shipping = data[virtualRow.index]

                        return (
                            <ShippingItem
                                key={virtualRow.key}
                                data-index={virtualRow.index}
                                ref={virtualizer.measureElement}
                                data={shipping}
                                onClick={() =>
                                    onItemClick(shipping.SK_TRANSPORTADORA)
                                }
                                selected={
                                    selectedId === shipping.SK_TRANSPORTADORA
                                }
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default ShippingList
