import { fetchQuotationMap } from '@/api/business/cotacoes'
import { useQuery } from '@tanstack/react-query'

export const quotationMapKeys = {
    all: ['quotationMap'] as const,
    lists: () => [...quotationMapKeys.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...quotationMapKeys.lists(), page, perPage, filters] as const,
    details: () => [...quotationMapKeys.all, 'detail'] as const,
    detail: (params: string) =>
        [...quotationMapKeys.details(), params] as const,
}

interface UseQuotationMapQueryProps {
    id: number
}

export const useQuotationMapQuery = ({ id }: UseQuotationMapQueryProps) =>
    useQuery({
        queryFn: () => fetchQuotationMap({ id }),
        queryKey: quotationMapKeys.detail(id.toString()),
    })
