import ApproveButton from './components/ApproveButton'
import DeclineButton from './components/DeclineButton'
import EndQuotationButton from './components/EndQuotationButton'
import NewRoundButton from './components/NewRoundButton'

const QuotationMapActionBar = () => {
    return (
        <div className="flex items-center gap-2">
            <DeclineButton />
            <ApproveButton />
            <NewRoundButton />
            <EndQuotationButton />
        </div>
    )
}

export default QuotationMapActionBar
