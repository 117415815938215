export type Intervals =
    | 'ATRASO DE 1 À 5 DIAS'
    | 'ATRASO DE 11 À 15 DIAS'
    | 'ATRASO DE 16 À 25 DIAS'
    | 'ATRASO DE 6 À 10 DIAS'
    | 'ATRASO MAIOR QUE 25 DIAS'
    | 'DENTRO DO PRAZO'

export type Priority = 'Normal' | 'Urgente'

export type PurchaseOrderStatus =
    | 'PEDIDO SALVO'
    | 'EM RECEBIMENTO'
    | 'PARCIALMENTE ATENDIDO'
    | 'PC ELIMINADO POR RESÍDUO'
    | 'RECEBIDO'
    | 'EM APROVAÇÃO'
    | 'REJEITADO PELO APROVADOR'
    | 'PENDENTE'

export const purchaseOrderClassNames: Record<PurchaseOrderStatus, string> = {
    'EM APROVAÇÃO': 'text-lime-600',
    'EM RECEBIMENTO': 'text-cyan-500',
    'PARCIALMENTE ATENDIDO': 'text-lime-600',
    'PC ELIMINADO POR RESÍDUO': 'text-red-500',
    'PEDIDO SALVO': 'text-sky-600',
    RECEBIDO: 'text-green-600',
    'REJEITADO PELO APROVADOR': 'text-red-500',
    PENDENTE: 'text-amber-600',
}

export interface PurchaseOrder {
    SK_PEDIDO_DE_COMPRAS: string
    NK_PEDIDO_DE_COMPRAS: string
    NK_ITEM_PEDIDO_DE_COMPRAS: string
    SK_EMPRESA: string
    ABREVIATURA_EMPRESA: string
    CNPJ: string
    SK_FORNECEDOR: string
    NK_FORNECEDOR: string
    DD_RAZAO_SOCIAL: string
    DD_NOME_FANTASIA: string
    DD_STATUS: PurchaseOrderStatus
    DD_UF: string
    SK_CENTRO_DE_CUSTO: string
    CENTRO_DE_CUSTO: string | null
    NK_CENTRO_DE_CUSTO: string | null
    NK_SOLICITACAO_DE_COMPRAS: string | null
    SK_PRODUTO: string
    NK_PRODUTO: string
    DS_PRODUTO: string
    DD_TIPO: string
    DD_UM: string
    DD_NCM: string
    SK_GRUPO_DE_PRODUTOS: string
    NK_GRUPO_DE_PRODUTOS: string
    DS_GRUPO_DE_PRODUTOS: string
    SK_CONTA_ORCAMENTARIA: string | null
    NK_CONTA_ORCAMENTARIA: string | null
    CONTA_ORCAMENTARIA: string | null
    SK_COMPRADOR: string | null
    NK_COMPRADOR: string | null
    NK_CODIGO_USUARIO: string | null
    DD_EMAIL: string | null
    DS_NOME_COMPRADOR: string | null
    TIPO_PEDIDO: string
    DS_COMPRADOR: string
    DS_APROVADOR: string | null
    QTD_PEDIDA: string
    QTD_ENTREGUE: string
    VL_UNITARIO: string
    VL_TOTAL: string
    TIPO_SAVING: string
    VL_SAVING: string
    PERCENTUAL_SAVING: string
    DT_PREVISAO_ENTREGA: string
    DT_EMISSAO: string
    DT_ESTIMATIVA_PAGAMENTO: string
    DD_URGENCIA: Priority
    PRAZO: string
    PRAZO_INTERVALO: Intervals
    DS_OBSERVACAO: string | null
}
