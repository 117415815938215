import { Input } from '@/components/ui/input'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { Search } from 'lucide-react'

const stateSelector = (state: Store) => ({
    searchInput: state.quotationListSlice.state.searchInput,
    onChangeSearchInput: state.quotationListSlice.actions.onChangeSearchInput,
})

const SearchInput = () => {
    const { onChangeSearchInput, searchInput } = useBaseStore(stateSelector)

    return (
        <div className="flex-1">
            <div className="flex items-center gap-2 px-2 border rounded-md shadow-sm border-input">
                <Search size={14} className="text-foreground" />
                <Input
                    className="border-none shadow-none"
                    placeholder="Buscar por número da cotação, nome, descrição..."
                    value={searchInput}
                    onChange={(e) => onChangeSearchInput(e.target.value)}
                />
            </div>
        </div>
    )
}

export default SearchInput
