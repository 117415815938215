import instance from '@/api/business'
import { QuotationDetails, Quotation } from '@/types/Quotation'
import {
    AddProductInQuotationProps,
    AddProductInQuotationResponse,
    AddSuppliersInQuotationProps,
    AddSuppliersInQuotationResponse,
    CreateNewRoundProps,
    CreateQuotationProps,
    FetchPaginatedQuotationsProps,
    FetchPaginatedQuotationsResponse,
    FetchQuotationAPIResponse,
    FetchQuotationMapProps,
    FetchQuotationMapResponse,
    FetchQuotationProductsProps,
    FetchQuotationProductsResponse,
    FetchQuotationSuppliersProps,
    FetchQuotationSuppliersResponse,
    FinalizeRoundProps,
    ChangeRoundDateProps,
    RemoveProductFromQuotationProps,
    RemoveProductFromQuotationResponse,
    RemoveSuppliersFromQuotationProps,
    RemoveSuppliersFromQuotationResponse,
    UpdateQuotationProductProps,
    UpdateQuotationProductResponse,
    GeneratePurchaseOrderProps,
    DeleteQuotationProps,
    FetchSuppliersToQuotationResponse,
    FetchSuppliersToQuotationProps,
    FetchQuotationHeadersProps,
    FetchQuotationHeadersResponse,
    FetchQuotationFiltersResponse,
    UpdateSuppplierValuesProps,
    AddPaymentConditionProps,
    RemovePaymentConditionProps,
    UpdatePaymentConditionPreferenceProps,
    PublishSupplierProps,
    GeneratePurchaseOrderResponse,
    AddShippingCompanyProps,
    RemoveShippingCompanyProps,
    ValidatePurchaseOrderResponse,
    ApproveQuotationMapProps,
    AddItemsToQuotationMapApprovalProps,
    ValidatePurchaseOrderProps,
} from '@/api/business/cotacoes/type'

const BASE_ROUTE = '/cotacoes'

export const fetchQuotations = async () => {
    const response = await instance.post<FetchQuotationAPIResponse[]>(
        BASE_ROUTE
    )

    return response.data
}

export const fetchQuotationHeaders = async ({
    currentPage,
    perPage,
    filters,
}: FetchQuotationHeadersProps) => {
    const response = await instance.post<FetchQuotationHeadersResponse>(
        `${BASE_ROUTE}/cabecalho`,
        {
            POR_PAGINA: perPage,
            PAGINA_ATUAL: currentPage,
            FILTROS: filters || [],
        }
    )

    return response.data
}
export const fetchQuotationFilters = async () => {
    const response = await instance.post<FetchQuotationFiltersResponse>(
        `${BASE_ROUTE}/filtros`
    )

    return response.data
}

export const fetchQuotationById = async (id: number) => {
    const response = await instance.post<QuotationDetails>(`${BASE_ROUTE}/id`, {
        SK_COTACAO: id,
    })

    return response.data
}

export const fetchPaginatedQuotations = async ({
    page = 1,
    perPage = 10,
    filters,
}: FetchPaginatedQuotationsProps) => {
    const response = await instance.post<FetchPaginatedQuotationsResponse>(
        '/cotacoes/paginacao',
        {
            POR_PAGINA: perPage,
            PAGINA_ATUAL: page,
            FILTROS: filters || [],
        }
    )

    return response.data
}

export const createQuotation = async ({
    headers,
    suppliers,
    attachments,
    products,
    purchaseRequests,
    paymentConditions,
}: CreateQuotationProps) => {
    const formData = new FormData()

    for (const [key, value] of Object.entries(headers)) {
        if (value) {
            formData.append(key, value.toString())
        }
    }

    formData.append('FORNECEDORES', JSON.stringify(suppliers || []))
    formData.append(
        'CONDICOES_DE_PAGAMENTO',
        JSON.stringify(paymentConditions || [])
    )

    if (headers.DD_SOLICITACAO_DE_COMPRAS === 0) {
        formData.append('PRODUTOS', JSON.stringify(products || []))
    } else {
        formData.append(
            'SOLICITACOES_DE_COMPRAS',
            JSON.stringify(purchaseRequests || [])
        )
    }

    attachments?.forEach((file) => {
        formData.append('ANEXOS', file)
    })

    const response = await instance.put<{ SK_COTACAO: number }>(
        BASE_ROUTE,
        formData,
        {
            headers: { 'Content-Type': 'multipart/form-data' },
        }
    )

    return response.data
}

export const publishQuotation = async (quotationId: number) => {
    const response = await instance.put(`${BASE_ROUTE}/publicar`, {
        SK_COTACAO: quotationId,
    })

    return response.data
}

export const updateQuotation = async (cotacao: Partial<Quotation>) => {
    const response = await instance.patch(BASE_ROUTE, cotacao)

    return response.data
}

export const createNewRound = async ({
    quotationId,
    suppliers,
    expirationDate,
}: CreateNewRoundProps) => {
    const response = await instance.put(`${BASE_ROUTE}/rodada/nova`, {
        SK_COTACAO: quotationId,
        FORNECEDORES: suppliers,
        DT_EXPIRACAO: expirationDate,
    })

    return response.data
}

export const changeRoundDate = async ({
    quotationId,
    date,
}: ChangeRoundDateProps) => {
    const response = await instance.put(`${BASE_ROUTE}/rodada/alterar-data`, {
        SK_COTACAO: quotationId,
        DT_EXPIRACAO: date,
    })

    return response.data
}

export const finalizeRound = async ({ quotationId }: FinalizeRoundProps) => {
    const response = await instance.put(`${BASE_ROUTE}/rodada/finalizar`, {
        SK_COTACAO: quotationId,
    })

    return response.data
}

export const deleteQuotation = async ({
    id,
    forceDelete,
}: DeleteQuotationProps) => {
    const response = await instance.delete(BASE_ROUTE, {
        data: { SK_COTACAO: id, FORCE_DELETE: forceDelete },
    })

    return response.data
}

export const fetchQuotationMap = async ({ id }: FetchQuotationMapProps) => {
    const response = await instance.post<FetchQuotationMapResponse>(
        `${BASE_ROUTE}/mapa`,
        {
            SK_COTACAO: id,
        }
    )

    return response.data
}

export const approveQuotationMap = async ({
    DD_APROVADO,
    SK_COTACAO,
    DS_OBSERVACAO,
}: ApproveQuotationMapProps) => {
    const response = await instance.put(`${BASE_ROUTE}/mapa/aprovar`, {
        SK_COTACAO,
        DD_APROVADO,
        DS_OBSERVACAO,
    })

    return response.data
}

export const addItemsToQuotationMapApproval = async ({
    ITENS,
    SK_COTACAO,
    DS_APROVACAO,
}: AddItemsToQuotationMapApprovalProps) => {
    const response = await instance.put(
        `${BASE_ROUTE}/mapa/adicionar-itens-aprovacao`,
        {
            SK_COTACAO,
            ITENS,
            DS_APROVACAO,
        }
    )

    return response.data
}

export const generatePurchaseOrder = async ({
    quotations,
}: GeneratePurchaseOrderProps) => {
    const response = await instance.post<GeneratePurchaseOrderResponse[]>(
        `${BASE_ROUTE}/pedido-de-compras`,
        {
            COTACOES: quotations.map((quotation) => ({
                SK_COTACAO_X_PRODUTO: quotation.quotationProductId,
                SK_FORNECEDOR_X_RODADA: quotation.roundSupplierId,
            })),
        }
    )

    return response.data
}

export const validatePurchaseOrder = async ({
    quotations,
}: ValidatePurchaseOrderProps) => {
    const response = await instance.post<ValidatePurchaseOrderResponse[]>(
        `${BASE_ROUTE}/valida-pedido-de-compras`,
        {
            COTACOES: quotations.map((quotation) => ({
                SK_RESPOSTA_FORNECEDOR: quotation.supplierResponseId,
                SK_COTACAO_X_PRODUTO: quotation.quotationProductId,
                SK_FORNECEDOR_X_RODADA: quotation.roundSupplierId,
                DD_SAVING: quotation.savingType,
                VL_SPENDING: quotation.spending,
                VL_SAVING: quotation.saving,
                PERCENTUAL_SAVING: quotation.savingPercentage,
            })),
        }
    )

    return response.data
}

export const sendEmailToFornecedor = async ({
    cotacaoId,
    fornecedorId,
}: {
    cotacaoId: number
    fornecedorId: number
}) => {
    const response = await instance.put(`${BASE_ROUTE}/email`, {
        SK_COTACAO: cotacaoId,
        SK_FORNECEDOR: fornecedorId,
    })

    return response.data
}

export const fetchSuppliersToQuotation = async ({
    products,
    currentPage,
    perPage,
    filters,
}: FetchSuppliersToQuotationProps) => {
    const response = await instance.post<FetchSuppliersToQuotationResponse>(
        `${BASE_ROUTE}/fornecedores/sugestao`,
        {
            PRODUTOS:
                products?.map((product) => ({
                    NK_PRODUTO: product.productNk,
                    SK_EMPRESA: product.companyId,
                })) || [],
            POR_PAGNA: perPage,
            PAGINA_ATUAL: currentPage,
            FILTROS: filters || [],
        }
    )

    return response.data
}

export const updateSupplierValues = async ({
    products,
    quotationId,
    supplierId,
}: UpdateSuppplierValuesProps) => {
    const response = await instance.patch(
        `${BASE_ROUTE}/fornecedores/responder`,
        {
            PRODUTOS: products,
            SK_COTACAO: quotationId,
            SK_FORNECEDOR: supplierId,
        }
    )

    return response.data
}

export const publishSupplier = async ({
    supplierId,
    quotationId,
}: PublishSupplierProps) => {
    const response = await instance.patch(
        `${BASE_ROUTE}/fornecedores/publicar`,
        {
            SK_FORNECEDOR: supplierId,
            SK_COTACAO: quotationId,
        }
    )

    return response.data
}

export const fetchQuotationSuppliers = async ({
    quotationId,
}: FetchQuotationSuppliersProps) => {
    const response = await instance.post<FetchQuotationSuppliersResponse[]>(
        `${BASE_ROUTE}/fornecedores`,
        {
            SK_COTACAO: quotationId,
        }
    )

    return response.data
}

export const addSupplierInQuotation = async ({
    quotationId,
    suppliersIds,
}: AddSuppliersInQuotationProps) => {
    const response = await instance.put<AddSuppliersInQuotationResponse>(
        `${BASE_ROUTE}/fornecedores`,
        {
            SK_COTACAO: quotationId,
            FORNECEDORES: suppliersIds,
        }
    )

    return response.data
}

export const removeSupplierFromQuotation = async ({
    quotationId,
    suppliersIds,
}: RemoveSuppliersFromQuotationProps) => {
    const response =
        await instance.delete<RemoveSuppliersFromQuotationResponse>(
            `${BASE_ROUTE}/fornecedores`,
            {
                data: {
                    SK_COTACAO: quotationId,
                    FORNECEDORES: suppliersIds,
                },
            }
        )

    return response.data
}

export const notifySuppliers = async ({
    quotationId,
}: {
    quotationId: number
}) => {
    const response = await instance.put<{ message: string }>(
        `${BASE_ROUTE}/fornecedores/notificar`,
        {
            SK_COTACAO: quotationId,
        }
    )

    return response.data
}

export const fetchQuotationProducts = async ({
    quotationId,
}: FetchQuotationProductsProps) => {
    const response = await instance.post<FetchQuotationProductsResponse[]>(
        `${BASE_ROUTE}/produtos`,
        {
            SK_COTACAO: quotationId,
        }
    )

    return response.data
}

export const addProductInQuotation = async ({
    quotationId,
    products,
}: AddProductInQuotationProps) => {
    const response = await instance.put<AddProductInQuotationResponse>(
        `${BASE_ROUTE}/produtos`,
        {
            SK_COTACAO: quotationId,
            PRODUTOS: products,
        }
    )

    return response.data
}

export const updateQuotationProduct = async ({
    quotationId,
    products,
}: UpdateQuotationProductProps) => {
    const response = await instance.patch<UpdateQuotationProductResponse>(
        `${BASE_ROUTE}/produtos`,
        {
            SK_COTACAO: quotationId,
            PRODUTOS: products,
        }
    )

    return response.data
}

export const removeProductFromQuotation = async ({
    quotationId,
    products,
}: RemoveProductFromQuotationProps) => {
    const response = await instance.delete<RemoveProductFromQuotationResponse>(
        `${BASE_ROUTE}/produtos`,
        {
            data: {
                SK_COTACAO: quotationId,
                PRODUTOS: products,
            },
        }
    )

    return response.data
}

export const addQuotationToFavorites = async (cotacaoId: number) => {
    const response = await instance.put(`${BASE_ROUTE}/favorito`, {
        SK_COTACAO: cotacaoId,
    })

    return response.data
}

export const removeQuotationFromFavorites = async (cotacaoId: number) => {
    const response = await instance.delete(`${BASE_ROUTE}/favorito`, {
        data: {
            SK_COTACAO: cotacaoId,
        },
    })

    return response.data
}

export const addPaymentCondition = async ({
    paymentConditions,
    quotationId,
}: AddPaymentConditionProps) => {
    const response = await instance.put(
        `${BASE_ROUTE}/condicoes-de-pagamento`,
        {
            CONDICOES_DE_PAGAMENTO: paymentConditions,
            SK_COTACAO: quotationId,
        }
    )

    return response.data
}

export const updatePaymentConditionPreference = async ({
    paymentConditionId,
    preference,
    quotationId,
}: UpdatePaymentConditionPreferenceProps) => {
    const response = await instance.patch(
        `${BASE_ROUTE}/condicoes-de-pagamento`,
        {
            SK_CONDICAO_DE_PAGAMENTO: paymentConditionId,
            SK_COTACAO: quotationId,
            DD_PREFERIDA: preference,
        }
    )

    return response.data
}

export const removePaymentCondition = async ({
    paymentConditions,
    quotationId,
}: RemovePaymentConditionProps) => {
    const response = await instance.delete(
        `${BASE_ROUTE}/condicoes-de-pagamento`,
        {
            data: {
                CONDICOES_DE_PAGAMENTO: paymentConditions,
                SK_COTACAO: quotationId,
            },
        }
    )

    return response.data
}

export const addShippingCompany = async ({
    companyId,
    quotationId,
    shippingCompanyId,
    supplierId,
    freightValue,
}: AddShippingCompanyProps) => {
    const response = await instance.put(`${BASE_ROUTE}/transportadoras`, {
        SK_COTACAO: quotationId,
        SK_TRANSPORTADORA: shippingCompanyId,
        SK_FORNECEDOR: supplierId,
        SK_EMPRESA: companyId,
        VL_FRETE: freightValue,
    })

    return response.data
}

export const removeShippingCompany = async ({
    companyId,
    quotationId,
    supplierId,
}: RemoveShippingCompanyProps) => {
    const response = await instance.delete(`${BASE_ROUTE}/transportadoras`, {
        data: {
            SK_COTACAO: quotationId,
            SK_FORNECEDOR: supplierId,
            SK_EMPRESA: companyId,
        },
    })

    return response.data
}
