import { isAxiosError } from '@/api/business'
import Table from '@/components/Table'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { TableData } from '@/components/Table/type'
import TopMessage from '@/components/TopMessage'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { PaymentConditions } from '@/types/PaymentCondition'
import { createColumnHelper } from '@tanstack/react-table'
import { Star } from 'lucide-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import PaymentConditionsDropdown from './components/PaymentConditionsDropdown'
import PaymentConditionsActions from './components/PaymentConditionsActions'

type PaymentConditionsTable = TableData<PaymentConditions>

const columnHelper = createColumnHelper<PaymentConditionsTable>()

const PaymentConditionsSection = () => {
    const { id } = useParams()
    const {
        data: quotation,
        isFetching,
        isPending,
        isError,
        error,
    } = useQuotationByIdQuery({ id: Number(id) })

    const data = useMemo(
        () => quotation?.CONDICOES_DE_PAGAMENTO || [],
        [quotation]
    )

    const columns = useMemo(
        () => [
            columnHelper.display({
                id: 'ICON',
                header: '',
                cell: ({ row }) => {
                    const { DD_PREFERIDA } = row.original

                    return DD_PREFERIDA === 1 ? (
                        <Star size={14} className="text-yellow-600" />
                    ) : null
                },
                enableColumnFilter: false,
                enableHiding: false,
                size: 80,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DS_CONDICAO_DE_PAGAMENTO', {
                id: 'DS_CONDICAO_DE_PAGAMENTO',
                header: 'Condição de pagamento',
                enableColumnFilter: false,
                enableHiding: false,
                size: 200,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor(
                ({ DD_PREFERIDA }) =>
                    DD_PREFERIDA === 1 ? 'Preferência do comprador' : '',
                {
                    id: 'DD_PREFERIDA',
                    header: 'Observação',
                    enableColumnFilter: false,
                    enableHiding: false,
                    size: 250,
                    enablePinning: false,
                    meta: {
                        enableColumnOrdering: false,
                    },
                }
            ),
            columnHelper.display({
                id: 'ACTIONS',
                header: 'Ações',
                cell: ({ row }) => {
                    return <PaymentConditionsDropdown {...row.original} />
                },
                enableColumnFilter: false,
                size: 80,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
        ],
        []
    )

    return (
        <>
            <div className="py-2">
                <div className="mb-4">
                    <PaymentConditionsActions />
                </div>
                {isFetching && !isPending && (
                    <TopMessage
                        text="Atualizando condições de pagamento..."
                        variant="loading"
                    />
                )}
                {quotation?.CONDICOES_DE_PAGAMENTO?.length === 0 &&
                !isPending ? (
                    <div className="flex items-center text-sm justify-center text-neutral-400 h-[80px]">
                        Cotação sem condição de pagamento
                    </div>
                ) : (
                    <div className="flex flex-col flex-1">
                        <Table<PaymentConditionsTable>
                            data={data}
                            columns={columns}
                            isLoading={isPending}
                            isError={isError}
                            errorMessage={
                                isError && isAxiosError(error)
                                    ? error?.response?.data.message
                                    : 'Erro ao carregar dados'
                            }
                            getRowId={(row) =>
                                row.SK_CONDICAO_DE_PAGAMENTO.toString()
                            }
                            meta={{
                                layout: 'stretch',
                                cell: {
                                    className: 'border-none p-3 border-b',
                                },
                                header: {
                                    className:
                                        'border-r-0 border-t-0 group-first-of-type:border-l-0 border-b py-1.5',
                                },
                            }}
                            enableRowSelection={false}
                            defaultColumn={{
                                cell: ({ getValue }) => (
                                    <DefaultColumn>
                                        {getValue() as string}
                                    </DefaultColumn>
                                ),
                            }}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export default PaymentConditionsSection
