import { fetchCurrencies } from '@/api/business/moedas'
import { useQuery } from '@tanstack/react-query'

export const currenciesKeys = {
    all: ['currencies'] as const,
    lists: () => [...currenciesKeys.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...currenciesKeys.lists(), page, perPage, filters] as const,
    details: () => [...currenciesKeys.all, 'detail'] as const,
    detail: (params: string) => [...currenciesKeys.details(), params] as const,
}

export const useCurrenciesQuery = () => {
    return useQuery({
        queryFn: () => fetchCurrencies(),
        queryKey: currenciesKeys.all,
    })
}
