import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { approveQuotationMap } from '@/api/business/cotacoes'
import {
    quotationKeys,
    useQuotationByIdQuery,
} from '@/queries/useQuotationQuery'
import { useParams } from 'react-router-dom'
import { isAxiosError } from '@/api/business'
import { useToast } from '@/components/ui/use-toast'
import { Label } from '@/components/ui/label'
import { useState } from 'react'
import { TypographyP } from '@/components/ui/typography'
import { Textarea } from '@/components/ui/textarea'
import { quotationMapKeys } from '@/queries/useQuotatioMapQuery'

interface ApproveQuotationMapDialogProps {
    isOpen: boolean
    onClose: () => void
}

const ApproveQuotationMapDialog = ({
    isOpen,
    onClose,
}: ApproveQuotationMapDialogProps) => {
    const { id } = useParams()

    const queryClient = useQueryClient()
    const { toast } = useToast()

    const [observation, setObservation] = useState('')

    const { data } = useQuotationByIdQuery({ id: Number(id) })

    const { mutate, isPending: deleteIsPending } = useMutation({
        mutationFn: approveQuotationMap,
        onSuccess: () => {
            if (id) {
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.all,
                })
                queryClient.invalidateQueries({
                    queryKey: quotationMapKeys.all,
                })
            }

            toast({
                title: 'Cotação Cancelada',
                description: `A cotação ${data?.NUMERO_DA_COTACAO} foi aprovada com sucesso.`,
            })
            onClose()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível aprovar o mapa. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao aprovar mapa',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const onConfirm = () => {
        mutate({
            DD_APROVADO: true,
            SK_COTACAO: Number(id),
            DS_OBSERVACAO: observation,
        })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Aprovar mapa</DialogTitle>
                </DialogHeader>
                <div className="space-y-4">
                    <TypographyP>
                        Realmente deseja aprovar o mapa de cotação?
                    </TypographyP>
                    <div>
                        <Label>Observação:</Label>
                        <Textarea
                            className="resize-none"
                            onChange={(e) => setObservation(e.target.value)}
                        />
                    </div>
                </div>
                <DialogFooter>
                    <Button variant="ghost" onClick={() => onClose()}>
                        Cancelar
                    </Button>
                    <Button onClick={onConfirm} disabled={deleteIsPending}>
                        Confirmar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default ApproveQuotationMapDialog
