import { Button } from '@/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Eye, Paperclip, PlusCircle } from 'lucide-react'
import FileDialog from '../AddFileDialog'
import useDisclosure from '@/hooks/useDisclosure'
import ViewFileDialog from '../ViewFileDialog'

const FileDropdown = () => {
    const { isOpen: isFileDialogOpen, onOpenChange: onFileDialogOpenChange } =
        useDisclosure()
    const {
        isOpen: isViewFileDialogOpen,
        onOpenChange: onViewFileDialogOpenChange,
    } = useDisclosure()

    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger>
                    <Button variant="outline" className="gap-2">
                        <Paperclip size={14} />
                        Anexos
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                    <DropdownMenuItem
                        className="gap-2"
                        onClick={() => onFileDialogOpenChange(true)}
                    >
                        <PlusCircle size={14} className="text-green-600" />
                        Adicionar anexos
                    </DropdownMenuItem>
                    <DropdownMenuItem
                        className="gap-2"
                        onClick={() => onViewFileDialogOpenChange(true)}
                    >
                        <Eye size={14} className="text-blue-500" />
                        Ver anexos
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
            <FileDialog
                isOpen={isFileDialogOpen}
                onOpenChange={onFileDialogOpenChange}
            />
            <ViewFileDialog
                isOpen={isViewFileDialogOpen}
                onOpenChange={onViewFileDialogOpenChange}
            />
        </>
    )
}

export default FileDropdown
