export enum QuotationStatus {
    'TODOS' = 'TODOS',
    'EM ABERTO' = 'EM ABERTO',
    'FINALIZADA' = 'FINALIZADA',
    'RASCUNHO' = 'RASCUNHO',
    'EXCLUIDA' = 'EXCLUIDA',
    'SEM FORNECEDOR' = 'SEM FORNECEDOR',
    'SEM PRODUTO' = 'SEM PRODUTO',
    'FINALIZADA PARCIALMENTE' = 'FINALIZADA PARCIALMENTE',
    'TOTALMENTE ATENDIDA' = 'TOTALMENTE ATENDIDA',
    'CANCELADA' = 'CANCELADA',
    'EM APROVACAO' = 'EM APROVACAO',
    'APROVADA' = 'APROVADA',
    'RECUSADA' = 'RECUSADA',
    'PEDIDO GRAVADO' = 'PEDIDO GRAVADO',
}

export enum QuotationStatusColorScheme {
    'EM ABERTO' = 'border-emerald-500 border text-emerald-500',
    'FINALIZADA' = 'border-sky-500 border text-sky-500',
    'FINALIZADA PARCIALMENTE' = 'border-slate-400 border text-slate-400',
    'RASCUNHO' = 'border-slate-500 border text-slate-500',
    'EXCLUIDA' = 'border-neutral-500 border text-neutral-500',
    'SEM FORNECEDOR' = 'border-yellow-500 border text-yellow-600',
    'SEM PRODUTO' = 'border-yellow-500 border text-yellow-600',
    'TOTALMENTE ATENDIDA' = 'border-green-500 border text-green-500',
    'CANCELADA' = 'border-red-500 border text-red-500',
    'EM APROVACAO' = 'border-amber-500 border text-amber-500',
    'APROVADA' = 'border-blue-500 border text-blue-500',
    'RECUSADA' = 'border-red-500 border text-red-500',
    'PEDIDO GRAVADO' = 'border-sky-500 border text-sky-500',
}
