import Table from '@/components/Table'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { TableData } from '@/components/Table/type'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip'
import { TypographyP } from '@/components/ui/typography'
import { QuotationMapSupplierValuesState } from '@/store/quotationMapSlice/type'
import { formatCpfOrCnpj } from '@/utils/stringFormatter'
import { RowSelectionState, createColumnHelper } from '@tanstack/react-table'
import { Crown } from 'lucide-react'
import { useMemo } from 'react'

type SuppliersTable = TableData<QuotationMapSupplierValuesState>

const columnHelper = createColumnHelper<QuotationMapSupplierValuesState>()

interface RoundSuppliersSectionProps {
    suppliers: QuotationMapSupplierValuesState[]
    selectedSuppliers: RowSelectionState
    onSelectSupplier: (row: RowSelectionState) => void
}

const RoundSuppliersSection = ({
    suppliers,
    onSelectSupplier,
    selectedSuppliers,
}: RoundSuppliersSectionProps) => {
    const data = useMemo(() => suppliers, [suppliers])
    const columns = useMemo(
        () => [
            columnHelper.display({
                id: 'Status',
                header: '',
                cell: ({ row }) => {
                    const {
                        FORNECEDOR_PUBLICADO,
                        FORNECEDOR_DECLINADO,
                        VENCEDOR,
                    } = row.original

                    if (FORNECEDOR_DECLINADO === 1) {
                        return (
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <span className="px-2 py-1 text-red-500 rounded-md bg-red-50 whitespace-nowrap text-ellipsis">
                                            Declinado
                                        </span>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <TypographyP>
                                            Este fornecedor não deseja
                                            participar dessa cotação
                                        </TypographyP>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        )
                    }

                    if (FORNECEDOR_PUBLICADO === 0) {
                        return (
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <span className="px-2 py-1 overflow-hidden rounded-md text-amber-500 bg-amber-50 whitespace-nowrap text-ellipsis">
                                            Pendente
                                        </span>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <TypographyP>
                                            Este fornecedor ainda não terminou
                                            seu processo de cotação
                                        </TypographyP>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        )
                    }

                    if (VENCEDOR) {
                        return (
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <Crown
                                            size={14}
                                            className="text-primary-600"
                                        />
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <TypographyP>
                                            Vencedor da rodada
                                        </TypographyP>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        )
                    }
                },
                size: 120,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
                enableColumnFilter: false,
            }),
            columnHelper.accessor('DD_RAZAO_SOCIAL', {
                header: 'Razão social',
                size: 300,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
                enableColumnFilter: false,
            }),
            columnHelper.accessor('DD_CNPJ', {
                header: 'CNPJ',
                size: 200,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                    cell: {
                        formatterFn: ({ value }) => {
                            return formatCpfOrCnpj(value)
                        },
                    },
                },
                enableColumnFilter: false,
            }),
        ],
        []
    )
    return (
        <div className="flex flex-col gap-2">
            <div className="mb-4">
                <TypographyP className="font-semibold">
                    Fornecedores da rodada atual
                </TypographyP>
            </div>
            <div className="flex-1">
                <Table<SuppliersTable>
                    data={data}
                    columns={columns}
                    getRowId={(row) => row.SK_FORNECEDOR}
                    tableState={{
                        rowSelection: selectedSuppliers,
                    }}
                    onRowSelectionChange={onSelectSupplier}
                    meta={{
                        header: {
                            className: 'border-none h-auto',
                        },
                        row: {
                            className: 'not:last-of-type:border-b',
                        },
                        cell: {
                            className: 'border-none',
                        },
                        layout: 'stretch',
                    }}
                    defaultColumn={{
                        cell: ({ getValue }) => {
                            return (
                                <DefaultColumn>
                                    {getValue() as string}
                                </DefaultColumn>
                            )
                        },
                    }}
                />
            </div>
        </div>
    )
}

export default RoundSuppliersSection
