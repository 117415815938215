import TopMessage from '@/components/TopMessage'
import {
    useQuotationByIdQuery,
    // useQuotationProductsQuery,
    // useQuotationSuppliersQuery,
} from '@/queries/useQuotationQuery'
import { useParams } from 'react-router-dom'

const StatusBar = () => {
    const { id } = useParams()

    const { isError: isQuotationError } = useQuotationByIdQuery({
        id: Number(id),
    })
    // const { isFetching: isSuppliersFetching } = useQuotationSuppliersQuery({
    //     quotationId: Number(id),
    // })

    // const { isFetching: isProductsFetching } = useQuotationProductsQuery({
    //     quotationId: Number(id),

    // })

    return (
        <>
            {isQuotationError && (
                <TopMessage
                    text="Houve um erro ao carregar a cotação"
                    variant="error"
                />
            )}
        </>
    )
}

export default StatusBar
