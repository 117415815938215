import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { Filter } from '@/types/Filters'
import { Quotation } from '@/types/Quotation'
import { Paintbrush } from 'lucide-react'

const stateSelector = (state: Store) => ({
    onResetFilters: state.quotationListSlice.actions.onResetFilters,
})

interface ClearFiltersButtonProps {
    setFieldFilters: (filters: Filter<Quotation>[]) => void
}

const ClearFiltersButton = ({ setFieldFilters }: ClearFiltersButtonProps) => {
    const { onResetFilters } = useBaseStore(stateSelector)

    const onClick = () => {
        onResetFilters()
        setFieldFilters([])
    }

    return (
        <ButtonWithTooltip
            className="flex items-center justify-center cursor-pointer"
            onClick={onClick}
            tooltipContent="Limpar filtros"
            variant="outline"
        >
            <Paintbrush size={14} />
        </ButtonWithTooltip>
    )
}

export default ClearFiltersButton
