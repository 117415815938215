import {
    Priority,
    PurchaseRequestFlat,
} from '@/api/business/solicitacaoDeCompra/type'
import Table from '@/components/Table'
import { Button } from '@/components/ui/button'
import { PurchaseRequestTable } from '@/pages/CotacaoCreatePurchaseRequest/components/SectionPurchaseRequestList'
import { createColumnHelper } from '@tanstack/react-table'
import { AlertTriangle, X } from 'lucide-react'
import { useMemo } from 'react'
import { useBaseStore } from '@/store'
import {
    getSelectedItemsSelector,
    quotationPurchaseRequestActionsSelector,
} from '@/store/quotationPurchaseRequestSlice'
import { format } from 'date-fns'
import { getDateFromId } from '@/utils/date'
import DefaultColumn from '@/components/Table/components/DefaultColumn'

const columnHelper = createColumnHelper<PurchaseRequestFlat>()

const PurchaseRequestItems = () => {
    const items = useBaseStore(getSelectedItemsSelector)

    const { onSelectItem, onDeselectItem } = useBaseStore(
        quotationPurchaseRequestActionsSelector
    )

    const dataMemo = useMemo(() => items, [items])

    const columnsMemo = useMemo(
        () => [
            columnHelper.display({
                id: 'Ações',
                cell: ({ row }) => {
                    const { SK_SOLICITACAO_DE_COMPRAS } = row.original

                    return (
                        <Button
                            className="h-full text-neutral-500 hover:bg-red-100 hover:text-red-500"
                            variant="ghost"
                            size="icon"
                            type="button"
                            onClick={() => {
                                onDeselectItem(SK_SOLICITACAO_DE_COMPRAS)
                            }}
                        >
                            <X size={14} />
                        </Button>
                    )
                },
                size: 80,
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                enableSorting: false,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                    cell: {
                        className: 'justify-center p-0',
                    },
                },
            }),
            columnHelper.accessor('NK_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_SOLICITACAO_DE_COMPRAS',
                header: 'Número SC',
            }),
            columnHelper.accessor('SK_EMPRESA', {
                id: 'SK_EMPRESA',
                header: 'Código empresa',
            }),
            columnHelper.accessor('NK_PRODUTO', {
                id: 'NK_PRODUTO',
                header: 'Código produto',
            }),
            columnHelper.accessor('DS_PRODUTO', {
                id: 'DS_PRODUTO',
                header: 'Produto',
                size: 400,
            }),
            columnHelper.accessor('DD_UM', {
                id: 'DD_UM',
                header: 'UM',
                size: 80,
            }),
            columnHelper.accessor('DS_GRUPO_DE_PRODUTOS', {
                id: 'DS_GRUPO_DE_PRODUTOS',
                header: 'Grupo de produtos',
                size: 160,
            }),
            columnHelper.accessor('QTD_SOLICITADA', {
                id: 'QTD_SOLICITADA',
                header: 'Qtd solicitada',
            }),
            columnHelper.accessor('QTD_PEDIDA', {
                id: 'QTD_PEDIDA',
                header: 'Qtd pedida',
            }),
            columnHelper.accessor('DD_URGENCIA', {
                id: 'DD_URGENCIA',
                header: 'Prioridade',
                cell: ({ getValue }) => {
                    const priority = getValue() as Priority
                    return priority === 'Urgente' ? (
                        <span className="flex items-center gap-1.5">
                            <AlertTriangle
                                size={12}
                                className="text-orange-500"
                            />
                            {priority}
                        </span>
                    ) : (
                        priority
                    )
                },
                size: 110,
            }),
            columnHelper.accessor('DD_SOLICITANTE', {
                id: 'DD_SOLICITANTE',
                header: 'Solicitante',
            }),
            columnHelper.accessor(
                ({ DT_APROVACAO }) =>
                    DT_APROVACAO
                        ? format(getDateFromId(DT_APROVACAO), 'dd/MM/yyyy')
                        : '',
                {
                    id: 'DT_APROVACAO',
                    header: 'Data aprovação',
                    size: 150,
                }
            ),
            columnHelper.accessor(
                ({ DT_EMISSAO }) =>
                    DT_EMISSAO
                        ? format(getDateFromId(DT_EMISSAO), 'dd/MM/yyyy')
                        : '',
                {
                    id: 'DT_EMISSAO',
                    header: 'Data emissão',
                    size: 150,
                }
            ),
            columnHelper.accessor('DD_OBSERVACAO', {
                id: 'DD_OBSERVACAO',
                header: 'Observação',
                size: 400,
            }),
        ],
        [onDeselectItem, onSelectItem]
    )

    return (
        <>
            <Table<PurchaseRequestTable>
                data={dataMemo}
                columns={columnsMemo}
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultColumn>{getValue() as string}</DefaultColumn>
                    ),
                }}
            />
        </>
    )
}

export default PurchaseRequestItems
