import { useBaseStore } from '@/store'
import { getSelectedItemsSelector } from '@/store/quotationPurchaseRequestSlice'
import LayoutDialog from '@/components/LayoutsDialog'
import useDisclosure from '@/hooks/useDisclosure'
import CreateLayoutDialog from '@/components/CreateLayoutDialog'
import { TypographyMuted } from '@/components/ui/typography'

const ActionBar = () => {
    const selectedItems = useBaseStore(getSelectedItemsSelector)

    const {
        isOpen: isCreateLayoutDialogOpen,
        onOpenChange: onCreateLayoutDialogOpenChange,
    } = useDisclosure()
    const {
        isOpen: isLayoutDialogOpen,
        onOpenChange: onLayoutDialogOpenChange,
    } = useDisclosure()

    return (
        <div className="flex items-center gap-3">
            <TypographyMuted>
                {selectedItems.length > 0
                    ? `${selectedItems.length} itens selecionados`
                    : 'Nenhum item selecionado'}
            </TypographyMuted>
            <LayoutDialog
                isOpen={isLayoutDialogOpen}
                onOpenChange={onLayoutDialogOpenChange}
                tableId={'TABELA_SOLICITACOES_DE_COMPRAS'}
                onCreateDialogOpenChange={onCreateLayoutDialogOpenChange}
            />
            <CreateLayoutDialog
                isOpen={isCreateLayoutDialogOpen}
                onOpenChange={onCreateLayoutDialogOpenChange}
                tableId={'TABELA_SOLICITACOES_DE_COMPRAS'}
            />
        </div>
    )
}

export default ActionBar
