import { isAxiosError } from '@/api/business'
import { addItemsToQuotationMapApproval } from '@/api/business/cotacoes'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { TypographyH2, TypographyP } from '@/components/ui/typography'
import { useToast } from '@/components/ui/use-toast'
import { useBaseStore } from '@/store'
import { quotationMapFlatSelector } from '@/store/quotationMapSlice'
import { Store } from '@/store/type'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import PurchaseOrderTable from './components/PurchaseOrderTable'
import { currencyFormat, formatCpfOrCnpj } from '@/utils/stringFormatter'
import { DisclosureCommonProps } from '@/types/Disclosure'
import { Textarea } from '@/components/ui/textarea'
import { Label } from '@/components/ui/label'
import { Separator } from '@/components/ui/separator'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import FileDropdown from './components/FileDropdown'
import { quotationKeys } from '@/queries/useQuotationQuery'
import { quotationMapKeys } from '@/queries/useQuotatioMapQuery'

const stateSelector = (state: Store) => ({
    quotationMap: state.quotationMapSlice.state.quotationMap,
    selectedWinners: state.quotationMapSlice.state.selectedWinners,
})

type ApprovalQuotationDialogProps = DisclosureCommonProps

const ApprovalQuotationDialog = ({
    isOpen,
    onOpenChange,
}: ApprovalQuotationDialogProps) => {
    const { id } = useParams()
    const { toast } = useToast()
    let increment = 0

    const queryClient = useQueryClient()

    const [observation, setObservation] = useState('')

    const {
        quotationMap: { PRODUTOS, VALORES, EMPRESAS },
        selectedWinners,
    } = useBaseStore(stateSelector)

    const flat = useBaseStore(quotationMapFlatSelector)

    const filt = flat.filter(
        (data) =>
            selectedWinners[data.SK_COTACAO_X_PRODUTO] &&
            selectedWinners[data.SK_COTACAO_X_PRODUTO][
                data.SK_FORNECEDOR_X_RODADA
            ]
    )

    const { mutate, isPending } = useMutation({
        mutationFn: addItemsToQuotationMapApproval,
        onSuccess: () => {
            toast({
                title: 'Mapa enviado para aprovação',
                description:
                    'O mapa de cotação foi enviado para a aprovação com sucesso',
            })

            queryClient.invalidateQueries({
                queryKey: quotationKeys.all,
            })

            queryClient.invalidateQueries({
                queryKey: quotationMapKeys.all,
            })

            onOpenChange(false)
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível gerar aprovação. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao gerar aprovação',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const onSubmit = () => {
        const selectedValues = VALORES.filter(
            (value) =>
                selectedWinners[value.SK_COTACAO_X_PRODUTO] &&
                selectedWinners[value.SK_COTACAO_X_PRODUTO][
                    value.SK_FORNECEDOR_X_RODADA
                ]
        )

        if (selectedValues.length !== Object.values(PRODUTOS.entities).length) {
            toast({ title: 'Selecione todos os valores' })

            return
        }

        // mutate({
        //     quotations: selectedValues.map((value) => ({
        //         quotationProductId: value.SK_COTACAO_X_PRODUTO,
        //         roundSupplierId: value.SK_FORNECEDOR_X_RODADA,
        //     })),
        // })
        mutate({
            ITENS: selectedValues,
            SK_COTACAO: Number(id),
            DS_APROVACAO: observation,
        })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            <DialogContent className="flex flex-col h-full max-w-full gap-6">
                <DialogHeader className="flex flex-row items-center justify-between">
                    <div className="space-y-0.5">
                        <DialogTitle>Criar aprovação</DialogTitle>
                        <DialogDescription>
                            A cotação irá gerar os seguintes pedidos de compra:
                        </DialogDescription>
                    </div>
                    <FileDropdown />
                    {/* <FileDialog /> */}
                </DialogHeader>
                <div className="flex-1 space-y-6 overflow-auto">
                    <div className="space-y-4">
                        <div>
                            <Label className="font-semibold text-foreground">
                                Adicionar observação
                            </Label>
                            <Textarea
                                className="resize-none"
                                onChange={(e) => setObservation(e.target.value)}
                                value={observation}
                            />
                        </div>
                        <Separator />
                    </div>
                    {EMPRESAS.map((company) => {
                        const companyPurchaseRequests = filt.filter(
                            (value) => value.SK_EMPRESA === company.SK_EMPRESA
                        )

                        const uniqueSuppliers = [
                            ...new Set(
                                companyPurchaseRequests.map(
                                    (value) => value.SK_FORNECEDOR
                                )
                            ),
                        ].map(
                            (supplierId) =>
                                companyPurchaseRequests.find(
                                    (val) => val.SK_FORNECEDOR === supplierId
                                )!
                        )

                        return (
                            <div key={company.SK_EMPRESA} className="space-y-4">
                                <TypographyH2
                                    className="text-md"
                                    style={{
                                        color: company.COR_EMPRESA,
                                    }}
                                >
                                    {company.ABREVIATURA_EMPRESA} -{' '}
                                    {formatCpfOrCnpj(company.CNPJ)}
                                </TypographyH2>
                                <div className="space-y-4">
                                    {uniqueSuppliers.map((supplier) => {
                                        const values =
                                            companyPurchaseRequests.filter(
                                                (value) =>
                                                    value.SK_FORNECEDOR ===
                                                    supplier.SK_FORNECEDOR
                                            )

                                        const total = values.reduce(
                                            (acc, curr) => {
                                                return (acc +=
                                                    curr.PRECO_TOTAL_IMPOSTO)
                                            },
                                            0
                                        )

                                        increment++
                                        return (
                                            <div
                                                key={supplier.SK_FORNECEDOR}
                                                className="space-y-2"
                                            >
                                                <div>
                                                    <TypographyP>
                                                        {
                                                            supplier.DD_RAZAO_SOCIAL
                                                        }
                                                    </TypographyP>
                                                    <TypographyP className="font-semibold">
                                                        Valor total do pedido:{' '}
                                                        {currencyFormat(
                                                            total,
                                                            4
                                                        )}
                                                    </TypographyP>
                                                </div>
                                                <PurchaseOrderTable
                                                    values={values}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <DialogFooter>
                    <Button onClick={onSubmit} disabled={isPending}>
                        Confirmar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default ApprovalQuotationDialog
