import { useBaseStore } from '@/store'
import { getInitials } from '@/utils/stringFormatter'
import { Avatar, AvatarFallback } from '../ui/avatar'
import ProfileMenu from './components/ProfileMenu'
import { TypographyP } from '../ui/typography'
import { Separator } from '../ui/separator'
import { Store } from '@/store/type'
import { SidebarTrigger } from '../ui/sidebar'
import { Link, useLocation } from 'react-router-dom'
import { SIDENAV_ITEMS } from '@/components/AppSidebar/consts'
import React, { useMemo } from 'react'
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '../ui/breadcrumb'

const stateSelector = (state: Store) => ({
    setSidenavOpen: state.appConfigSlice.actions.setSidenavOpen,
    isSidenavOpen: state.appConfigSlice.state.isSidenavOpen,
    user: state.authSlice.state.user,
})

const Header = () => {
    const { user } = useBaseStore(stateSelector)

    const { pathname } = useLocation()

    const possibleMatches = useMemo(
        () =>
            SIDENAV_ITEMS.flatMap((item) => {
                if (item.subItems) {
                    return [item, ...item.subItems]
                }
                const { subItems, ...rest } = item

                return rest
            }),
        []
    )

    const matches = useMemo(
        () =>
            possibleMatches.filter((possibleMatch) => {
                return pathname
                    .split('/')
                    .map((path) => `/${path}`)
                    .includes(possibleMatch.pathname as string)
            }),
        [pathname]
    )

    return (
        <>
            <header className="flex h-14 shrink-0 items-center px-4 gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
                <SidebarTrigger />
                <Separator orientation="vertical" className="h-4 mr-2" />
                <Breadcrumb>
                    <BreadcrumbList>
                        {matches.length > 0 &&
                            matches.map((match, idx) => {
                                const isLast = idx + 1 === matches.length
                                const isMatch = pathname === match.href

                                return (
                                    <React.Fragment key={match.href}>
                                        <BreadcrumbItem className="hidden md:block">
                                            {isMatch ? (
                                                <BreadcrumbPage>
                                                    {match.label}
                                                </BreadcrumbPage>
                                            ) : (
                                                <BreadcrumbLink asChild>
                                                    <Link
                                                        to={
                                                            match.href as string
                                                        }
                                                    >
                                                        {match.label}
                                                    </Link>
                                                </BreadcrumbLink>
                                            )}
                                        </BreadcrumbItem>
                                        {!isLast && <BreadcrumbSeparator />}
                                    </React.Fragment>
                                )
                            })}
                    </BreadcrumbList>
                </Breadcrumb>
                <div className="h-full p-2 ml-auto">
                    <ProfileMenu
                        className="h-full px-4 rounded-md hover:bg-accent"
                        trigger={
                            <div className="flex items-center h-full gap-2 pointer-events-none">
                                <Avatar className="w-6 h-6 text-xs">
                                    <AvatarFallback className="bg-primary-500 text-gray-50">
                                        {getInitials(user || '')}
                                    </AvatarFallback>
                                </Avatar>
                                <TypographyP className="hidden pointer-events-none sm:block">
                                    {user}
                                </TypographyP>
                            </div>
                        }
                    />
                </div>
            </header>
            <Separator />
        </>
    )
}

export default Header
