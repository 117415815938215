import { isBefore } from 'date-fns'
import { z } from 'zod'
import { attachmentSchema } from './fileSchema'

export const productSchema = z.object({
    SK_PRODUTO: z.string(),
    DS_PRODUTO: z.string(),
    DD_QUANTIDADE: z.string().min(1),
})

export const supplierSchema = z.object({
    SK_FORNECEDOR: z.string(),
    DD_NOME_FANTASIA: z.string(),
    DD_RAZAO_SOCIAL: z.string(),
    DD_UF: z.string().optional(),
    DS_EMAIL: z.string().array().optional().nullable(),
    DD_OBSERVACAO: z.string().optional().nullable(),
    DD_CNPJ: z.string().optional().nullable(),
})

export const purchaseRequestSchema = z.object({
    SK_SOLICITACAO_DE_COMPRAS: z.string(),
    NK_SOLICITACAO_DE_COMPRAS: z.string(),
    SK_EMPRESA: z.string(),
    ABREVIATURA_EMPRESA: z.string(),
    SK_PRODUTO: z.string(),
    DS_PRODUTO: z.string(),
    NK_PRODUTO: z.string(),
    DD_UM: z.string(),
    DD_NCM: z.string(),
    DD_BLOQUEADO: z.number(),
    NK_GRUPO_DE_PRODUTOS: z.string(),
    DS_GRUPO_DE_PRODUTOS: z.string(),
    SK_CENTRO_DE_CUSTO: z.string(),
    NK_CENTRO_DE_CUSTO: z.string(),
    CENTRO_DE_CUSTO: z.string(),
    SK_CONTA_ORCAMENTARIA: z.string(),
    NK_CONTA_ORCAMENTARIA: z.string(),
    CONTA_ORCAMENTARIA: z.string(),
    SK_COMPRADOR: z.string(),
    NK_COMPRADOR: z.string(),
    NK_CODIGO_USUARIO: z.string(),
    DS_NOME_COMPRADOR: z.string(),
    NK_ITEM_SOLICITACAO_DE_COMPRAS: z.string(),
    DD_STATUS: z.string(),
    DD_URGENCIA: z.string(),
    QTD_PEDIDA: z.string(),
    QTD_SOLICITADA: z.string(),
    DD_SOLICITANTE: z.string(),
    DD_APROVADOR: z.string().nullable(),
    DD_OBSERVACAO: z.string(),
    DT_EMISSAO: z.number(),
    DT_NECESSIDADE: z.number(),
    DT_APROVACAO: z.number(),
    HR_APROVACAO: z.string(),
})

export const paymentConditionSchema = z.object({
    SK_CONDICAO_DE_PAGAMENTO: z.string(),
    NK_CONDICAO_DE_PAGAMENTO: z.string(),
    DD_PREFERIDA: z.literal(0).or(z.literal(1)),
    DD_COTACAO: z.literal(0).or(z.literal(1)),
    DS_CONDICAO_DE_PAGAMENTO: z.string(),
    DD_REGRA: z.string(),
    DD_TIPO: z.string(),
    DD_REGRA_INICIO_CALCULO: z.string(),
})

export const createQuotationSchema = z.object({
    quotation: z.string().optional(),
    description: z.string().optional(),
    expirationDate: z.string(),
    attachments: z.instanceof(File).array().optional(),
    products: productSchema.array().min(0),
    suppliers: supplierSchema.array().min(0),
    paymentConditions: paymentConditionSchema
        .array()
        .min(1, 'Condição de pagamento obrigatória'),
})

export const createQuotationValidationSchema = createQuotationSchema.refine(
    ({ expirationDate }) =>
        expirationDate && isBefore(new Date(), new Date(expirationDate)),
    {
        message: 'Data de expiração não pode ser anterior a data atual',
        path: ['expirationDate'],
    }
)

export const publishSchema = z.object({
    quotation: z.string().optional().nullable(),
    description: z.string().optional().nullable(),
    expirationDate: z.string().min(1, 'Campo obrigatório'),
    attachments: attachmentSchema.array().optional(),
    products: productSchema
        .array()
        .min(1, 'Cotação deve conter no mínimo 1 produto'),
    suppliers: supplierSchema
        .array()
        .min(1, 'Cotação deve conter no mínimo 1 fornecedor'),
})

export const publishValidationSchema = publishSchema.refine(
    ({ expirationDate }) =>
        expirationDate && isBefore(new Date(), new Date(expirationDate)),
    {
        message: 'Data de expiração não pode ser anterior a data atual',
        path: ['expirationDate'],
    }
)

export const createPurchaseRequestQuotationSchema = z
    .object({
        purchaseRequests: purchaseRequestSchema
            .pick({ SK_SOLICITACAO_DE_COMPRAS: true })
            .array()
            .min(1),
    })
    .merge(createQuotationSchema)
    .omit({ products: true })

export const publishPurchaseRequestQuotationSchema = z
    .object({
        purchaseRequests: purchaseRequestSchema
            .array()
            .min(1, 'Condição de pagamento obrigatória'),
    })
    .merge(publishSchema)
    .omit({ products: true })

export const editQuotationSchema = z
    .object({})
    .merge(createQuotationSchema)
    .omit({
        products: true,
        suppliers: true,
        attachments: true,
        expirationDate: true,
        paymentConditions: true,
    })
