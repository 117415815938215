import { useToast } from '@/components/ui/use-toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isAxiosError } from '@/api/business'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Checkbox } from '@/components/ui/checkbox'
import { addAvaliacao } from '@/api/business/avaliacoes'
import { avaliacoesKeys } from '@/queries/useAvaliacoesQuery'

interface AddDialogProps {
    isOpen: boolean
    onClose: () => void
}

const AddDialog = ({ isOpen, onClose }: AddDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const schema = z.object({
        description: z.string().min(1, 'Campo obrigatório'),
        default: z.boolean().optional(),
    })

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            description: '',
            default: false,
        },
    })

    const { mutate, isPending: mutateLoading } = useMutation({
        mutationFn: addAvaliacao,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: avaliacoesKeys.lists() })
            onClose()
            form.reset()
            toast({
                title: 'Avaliação adicionado com sucesso',
            })
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível criar avaliação. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao criar avaliação',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const onSubmit = (data: z.infer<typeof schema>) => {
        mutate({
            DESCRICAO_AVALIACAO: data.description,
            AVALIACAO_PADRAO: data?.default ? 1 : 0,
        })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Adicionar avaliação</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-4"
                    >
                        <FormField
                            name="description"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Descrição</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="default"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem className="flex items-center gap-2 space-y-0">
                                    <FormControl>
                                        <Checkbox
                                            checked={field.value}
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <FormLabel>Avaliação padrão</FormLabel>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <DialogFooter>
                            <Button
                                type="button"
                                onClick={onClose}
                                variant="ghost"
                            >
                                Cancelar
                            </Button>
                            <Button type="submit" disabled={mutateLoading}>
                                Confirmar
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default AddDialog
