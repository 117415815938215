import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { useMemo, useState } from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import { useApprovalAttachmentsQuery } from '@/queries/useAttachmentsQuery'
import { useParams } from 'react-router-dom'
import { Download, File, Loader2 } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { useMutation } from '@tanstack/react-query'
import { downloadFile } from '@/api/business/arquivos'
import { isAxiosError } from '@/api/business'
import { toast } from 'sonner'
import { getFileSize } from '@/utils/file'
import { Attachment } from '@/types/Attachments'

type ApprovalAttachmentsTableType = TableData<Attachment>

const columnHelper = createColumnHelper<Attachment>()

const ApprovalAttachmentsTable = () => {
    const { id } = useParams()

    const { data: attachments = [] } = useApprovalAttachmentsQuery({
        quotationId: Number(id),
    })

    const { mutate } = useMutation({
        mutationFn: downloadFile,
        onError: (err) => {
            let errorMessage =
                'Não foi possível baixar o arquivo. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast('Erro ao baixar o arquivo', {
                description: errorMessage,
            })
        },
    })

    const columns = useMemo(
        () => [
            columnHelper.accessor('ARQUIVO', {
                id: 'ARQUIVO',
                header: 'Arquivo',
                cell: ({ getValue }) => (
                    <div className="flex items-center gap-2">
                        <File size={14} />
                        {getValue()}
                    </div>
                ),
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                size: 600,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('EXTENSAO_DO_ARQUIVO', {
                id: 'EXTENSAO_DO_ARQUIVO',
                header: 'Extensão',
                size: 120,
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor(
                ({ TAMANHO_DO_ARQUIVO }) => getFileSize(TAMANHO_DO_ARQUIVO),
                {
                    id: 'TAMANHO_DO_ARQUIVO',
                    header: 'Tamanho',
                    size: 200,
                    enableColumnFilter: false,
                    enableHiding: false,
                    enablePinning: false,
                    meta: {
                        enableColumnOrdering: false,
                    },
                }
            ),
            columnHelper.display({
                id: 'actions',
                cell: ({ row }) => {
                    const [started, setStarted] = useState(false)

                    return (
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => {
                                setStarted(true)
                                mutate({
                                    fileKey: row.original.CHAVE_DO_ARQUIVO,
                                    fileName: row.original.ARQUIVO,
                                    cb: (e) => {
                                        if (e.progress === 1) {
                                            setStarted(false)
                                        }
                                    },
                                })
                            }}
                        >
                            {started ? (
                                <Loader2 className="text-sky-500 animate-spin" />
                            ) : (
                                <Download className="text-sky-500" />
                            )}
                        </Button>
                    )
                },
                size: 100,
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
        ],
        []
    )

    return (
        <Table<ApprovalAttachmentsTableType>
            data={attachments}
            columns={columns}
            meta={{
                layout: 'stretch',
            }}
        />
    )
}

export default ApprovalAttachmentsTable
