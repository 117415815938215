import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { cn } from '@/lib/utils'
import { AlertTriangle, Pencil } from 'lucide-react'
import { useMemo, useState } from 'react'
import Lottie from 'lottie-react'
import Success from '@/assets/lottie/Success.json'
import useDisclosure from '@/hooks/useDisclosure'
import AddEmailDialog from '@/pages/DadosMestre/DadosMestreFornecedores/components/AddEmailDialog'
import { Supplier } from '@/types/Suppliers'
import { useQuotationSuppliersQuery } from '@/queries/useQuotationQuery'
import { useParams } from 'react-router-dom'
import TopMessage from '../TopMessage'
import { TypographyMuted, TypographyP } from '../ui/typography'

interface SupplierWarningDialogProps {
    isOpen: boolean
    suppliersIds: string[]
    onClose: () => void
}

const SupplierWarningDialog = ({
    isOpen,
    suppliersIds,
    onClose,
}: SupplierWarningDialogProps) => {
    const { id } = useParams()

    const { data, isFetching } = useQuotationSuppliersQuery({
        quotationId: Number(id),
    })
    const [selectedSupplier, setSelectedSupplier] = useState<Supplier>()

    const {
        isOpen: isAddSupplierEmailDialogOpen,
        onClose: onAddSupplierEmailDialogClose,
        onOpen: onAddSupplierEmailDialogOpen,
    } = useDisclosure()

    const suppliers = useMemo(
        () =>
            data
                ? suppliersIds.map((id) =>
                      data.find((supplier) => supplier.SK_FORNECEDOR === id)
                  )
                : [],
        [data, suppliersIds]
    )

    return (
        <>
            <Dialog open={isOpen} onOpenChange={onClose}>
                <DialogContent className="max-w-2xl">
                    <DialogHeader>
                        <DialogTitle>Fornecedores sem email</DialogTitle>
                        <DialogDescription>
                            Os seguintes fornecedores não possuem email valido
                            para cotação. Informe os emails prosseguir com a
                            publicação.
                        </DialogDescription>
                    </DialogHeader>
                    <div className="space-y-2">
                        {isFetching && (
                            <TopMessage
                                text="Atualizando..."
                                variant="loading"
                            />
                        )}
                        {suppliers.length === 0 ? (
                            <div>
                                <Lottie animationData={Success} loop={false} />
                            </div>
                        ) : (
                            suppliers.map(
                                (supplier) =>
                                    supplier && (
                                        <div
                                            key={supplier.SK_FORNECEDOR}
                                            className="flex items-center justify-between"
                                        >
                                            <div className="flex items-center gap-2">
                                                <AlertTriangle
                                                    className="text-amber-600"
                                                    size={16}
                                                />
                                                <div className="px-2">
                                                    <div className="flex items-center gap-2">
                                                        <TypographyP>
                                                            {
                                                                supplier.DD_RAZAO_SOCIAL
                                                            }
                                                        </TypographyP>
                                                        <span
                                                            className={cn(
                                                                'text-xs p-1 rounded-lg',
                                                                supplier.DD_ORIGEM_DADO ===
                                                                    'MANUAL'
                                                                    ? 'bg-green-50 text-green-500'
                                                                    : 'bg-blue-50 text-cyan-600'
                                                            )}
                                                        >
                                                            {
                                                                supplier.DD_ORIGEM_DADO
                                                            }
                                                        </span>
                                                    </div>
                                                    <TypographyMuted>
                                                        {supplier.DS_EMAIL}
                                                    </TypographyMuted>
                                                </div>
                                            </div>
                                            <div>
                                                <Button
                                                    variant="ghost"
                                                    onClick={() => {
                                                        setSelectedSupplier(
                                                            supplier
                                                        )
                                                        onAddSupplierEmailDialogOpen()
                                                    }}
                                                >
                                                    <Pencil size={14} />
                                                </Button>
                                            </div>
                                        </div>
                                    )
                            )
                        )}
                    </div>
                    <DialogFooter>
                        <Button
                            onClick={() => {
                                onClose()
                            }}
                        >
                            Fechar
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
            {selectedSupplier && (
                <AddEmailDialog
                    isOpen={isAddSupplierEmailDialogOpen}
                    onClose={onAddSupplierEmailDialogClose}
                    supplier={selectedSupplier}
                />
            )}
        </>
    )
}

export default SupplierWarningDialog
