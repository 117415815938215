import { Button } from '@/components/ui/button'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { Separator } from '@/components/ui/separator'
import { cn } from '@/lib/utils'
import { createColumnHelper } from '@tanstack/react-table'
import { Paintbrush, Plus, X } from 'lucide-react'
import { useMemo } from 'react'
import { FieldError } from 'react-hook-form'
import { z } from 'zod'
import { supplierSchema } from '../../../../schemas/quotationSchema'
import { TypographyH2, TypographyMuted } from '@/components/ui/typography'
import DefaultColumn from '@/components/Table/components/DefaultColumn'

export type SupplierSchema = z.infer<typeof supplierSchema>

interface SuppliersSectionProps {
    suppliers: SupplierSchema[]
    error?: FieldError
    onSuppliersDialogOpen: () => void
    onClearAll: () => void
    onRemoveSupplier: (supplier: string | number) => void
}

export type SuppliersTable = TableData<SupplierSchema>

const columnHelper = createColumnHelper<SupplierSchema>()

const SuppliersSection = ({
    suppliers,
    error,
    onSuppliersDialogOpen,
    onClearAll,
    onRemoveSupplier,
}: SuppliersSectionProps) => {
    const data = useMemo(() => suppliers, [suppliers])
    const columns = useMemo(
        () => [
            columnHelper.display({
                id: 'Ações',
                cell: ({ row }) => {
                    return (
                        <Button
                            className="w-6 h-6 p-0 hover:bg-red-100 hover:text-red-500"
                            type="button"
                            size="icon"
                            variant="ghost"
                            onClick={() =>
                                onRemoveSupplier(row.original.SK_FORNECEDOR)
                            }
                        >
                            <X size={14} />
                        </Button>
                    )
                },
                meta: {
                    cell: {
                        className: 'justify-center p-0',
                    },
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
                size: 50,
            }),
            columnHelper.accessor('SK_FORNECEDOR', {
                id: 'sk_fornecedor',
                header: 'Cod fornecedor',
                size: 80,
                enableColumnFilter: false,
                enableHiding: false,
            }),
            columnHelper.accessor('DD_NOME_FANTASIA', {
                id: 'DD_NOME_FANTASIA',
                header: 'Nome fantasia',
                size: 200,
                enableColumnFilter: false,
                enableHiding: false,
            }),
            columnHelper.accessor('DD_RAZAO_SOCIAL', {
                id: 'DD_RAZAO_SOCIAL',
                header: 'Razão social',
                size: 200,
                enableColumnFilter: false,
                enableHiding: false,
            }),
            columnHelper.accessor('DD_UF', {
                id: 'DD_UF',
                header: 'UF',
                size: 100,
                enableColumnFilter: false,
                enableHiding: false,
            }),
            columnHelper.accessor('DD_CNPJ', {
                id: 'DD_CNPJ',
                header: 'CNPJ',
                size: 200,
                enableColumnFilter: false,
                enableHiding: false,
            }),
            columnHelper.accessor('DS_EMAIL', {
                id: 'DS_EMAIL',
                header: 'Email',
                size: 200,
                enableColumnFilter: false,
                enableHiding: false,
            }),
        ],
        [onRemoveSupplier]
    )

    return (
        <div
            className={cn(
                'flex flex-1 flex-col w-full max-h-[500px] space-y-2 overflow-hidden'
            )}
        >
            <div className="mb-4 space-y-1.5 w-full">
                <div className="flex items-center justify-between flex-1 max-w-full">
                    <div>
                        <TypographyH2 className="border-none">
                            Fornecedores
                        </TypographyH2>
                        <span className="text-sm text-red-500">
                            {error?.message}
                        </span>
                    </div>
                    <div className="flex items-center">
                        <ButtonWithTooltip
                            type="button"
                            className="w-10 rounded-md"
                            variant="ghost"
                            onClick={onSuppliersDialogOpen}
                            size="icon"
                            tooltipContent="Adicionar fornecedor"
                        >
                            <Plus size={14} />
                        </ButtonWithTooltip>
                        <ButtonWithTooltip
                            type="button"
                            className="w-10 rounded-md"
                            onClick={onClearAll}
                            variant="ghost"
                            size="icon"
                            tooltipContent="Remover tudo"
                        >
                            <Paintbrush size={14} />
                        </ButtonWithTooltip>
                    </div>
                </div>
                <Separator className={cn(error && 'bg-red-300')} />
            </div>
            {suppliers.length > 0 ? (
                <Table<SuppliersTable>
                    data={data}
                    columns={columns}
                    getRowId={(row) => row.SK_FORNECEDOR.toString()}
                    meta={{
                        layout: 'stretch',
                        header: {
                            className: 'py-2',
                        },
                    }}
                    defaultColumn={{
                        cell: ({ getValue }) => (
                            <DefaultColumn>
                                {getValue() as string}
                            </DefaultColumn>
                        ),
                    }}
                />
            ) : (
                <div className="flex items-center text-sm justify-center text-neutral-400 h-[100px]">
                    <TypographyMuted>
                        Nenhum fornecedor selecionado
                    </TypographyMuted>
                </div>
            )}
        </div>
    )
}

export default SuppliersSection
