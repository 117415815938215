import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { TypographyLarge } from '@/components/ui/typography'
import { ChevronDown } from 'lucide-react'
import useDisclosure from '@/hooks/useDisclosure'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useToast } from '@/components/ui/use-toast'
import { quotationKeys } from '@/queries/useQuotationQuery'
import { useParams } from 'react-router-dom'
import { RowSelectionState } from '@tanstack/react-table'
import { isAxiosError } from '@/api/business'
import PurchaseRequestItemsDialog from '@/components/PurchaseRequestItemsDialog'
import AddProductsButton from './components/AddProductsButton'
import { addProductInQuotation } from '@/api/business/cotacoes'

const ProductsActions = () => {
    const { id } = useParams()
    const queryClient = useQueryClient()

    const {
        isOpen: isPurchaseRequestItemsDialogOpen,
        onOpen: onPurchaseRequestItemsDialogOpen,
        onClose: onPurchaseRequestItemsDialogClose,
    } = useDisclosure()

    const [selectedPurchaseRequestItems, onSelectPurchaseRequestItems] =
        useState<RowSelectionState>({})

    const { toast } = useToast()

    const { mutate: mutateAddProduct, isPending: isAddProductPending } =
        useMutation({
            mutationFn: addProductInQuotation,
            onSuccess: () => {
                if (id) {
                    queryClient.invalidateQueries({
                        queryKey: quotationKeys.detail(id),
                    })
                    queryClient.invalidateQueries({
                        queryKey: quotationKeys.detailProducts(id),
                    })
                }

                toast({
                    title: 'Produtos adicionados',
                    description: `Produtos adicionados com sucesso.`,
                })
                onSelectPurchaseRequestItems({})
                onPurchaseRequestItemsDialogClose()
            },
            onError: (err) => {
                let errorMessage =
                    'Não foi possível adicionar o produto. Tente novamente mais tarde.'

                if (isAxiosError(err)) {
                    errorMessage = err.response?.data.message || errorMessage
                }

                toast({
                    title: 'Erro ao adicionar produto',
                    description: errorMessage,
                    variant: 'destructive',
                })
            },
        })

    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger className="flex items-center gap-2 rounded-md hover:bg-accent">
                    <TypographyLarge>Produtos</TypographyLarge>
                    <ChevronDown size={18} />
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                    <AddProductsButton
                        onClick={onPurchaseRequestItemsDialogOpen}
                    />
                </DropdownMenuContent>
            </DropdownMenu>
            <PurchaseRequestItemsDialog
                isOpen={isPurchaseRequestItemsDialogOpen}
                rowSelection={{
                    onSelectPurchaseRequestItems,
                    selectedRows: selectedPurchaseRequestItems,
                }}
                dialogState={isAddProductPending ? 'loading' : undefined}
                onClose={onPurchaseRequestItemsDialogClose}
                onCloseWithAction={() => {
                    mutateAddProduct({
                        quotationId: Number(id),
                        usePurchaseRequest: true,
                        products: Object.keys(selectedPurchaseRequestItems).map(
                            (id) => ({
                                SK_SOLICITACAO_DE_COMPRAS: id,
                            })
                        ),
                    })
                }}
            />
        </>
    )
}

export default ProductsActions
