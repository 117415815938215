import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { useBaseStore } from '@/store'
import { getSelectedPaymentConditionSelector } from '@/store/quotationMapSlice'
import { useParams } from 'react-router-dom'

const PaymentConditionSection = () => {
    const { id } = useParams()
    const { data } = useQuotationByIdQuery({ id: Number(id) })
    const selectedPaymentConditionId = useBaseStore(
        getSelectedPaymentConditionSelector
    )

    const selectedPaymentCondition = selectedPaymentConditionId?.manual
        ? selectedPaymentConditionId?.id
        : data?.CONDICOES_DE_PAGAMENTO.find(
              (condition) =>
                  condition.SK_CONDICAO_DE_PAGAMENTO ===
                  selectedPaymentConditionId?.id
          )?.DS_CONDICAO_DE_PAGAMENTO

    return (
        <div>
            <TypographyMuted>Condição de pagamento</TypographyMuted>
            <div className="px-4 py-2 border rounded-md">
                <TypographyP>{selectedPaymentCondition || '-'}</TypographyP>
            </div>
        </div>
    )
}

export default PaymentConditionSection
