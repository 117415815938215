import { GeneratePurchaseOrderError } from '@/api/business/cotacoes/type'
import Table from '@/components/Table'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { TableData } from '@/components/Table/type'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog'
import { TypographyH2, TypographyP } from '@/components/ui/typography'
import { cn } from '@/lib/utils'
import { createColumnHelper } from '@tanstack/react-table'
import { CheckCircle, Copy } from 'lucide-react'
import { useMemo, useState } from 'react'

type ErrorTableProps = {
    data: GeneratePurchaseOrderError[]
}

type ErrorTableData = TableData<GeneratePurchaseOrderError>

const columnHelper = createColumnHelper<GeneratePurchaseOrderError>()

const ErrorTable = ({ data }: ErrorTableProps) => {
    const columns = useMemo(
        () => [
            columnHelper.accessor('NK_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_SOLICITACAO_DE_COMPRAS',
                header: 'SC',
            }),
            columnHelper.accessor('NK_ITEM_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_ITEM_SOLICITACAO_DE_COMPRAS',
                header: 'Item',
            }),
            columnHelper.accessor('DS_PRODUTO', {
                id: 'DS_PRODUTO',
                header: 'Produto',
            }),
            columnHelper.accessor('ERRO', {
                id: 'ERRO',
                header: 'Erro',
                cell: ({ getValue }) => {
                    const [hasCopied, setHasCopied] = useState(false)

                    const onClick = () => {
                        navigator.clipboard.writeText(getValue() as string)
                        setHasCopied(true)
                        setTimeout(() => {
                            setHasCopied(false)
                        }, 2000)
                    }

                    return (
                        getValue() && (
                            <Dialog>
                                <DialogTrigger>{getValue()}</DialogTrigger>
                                <DialogContent className="max-h-full max-w-[680px] overflow-auto">
                                    <DialogHeader>
                                        <DialogTitle>Erro</DialogTitle>
                                    </DialogHeader>
                                    <div>
                                        {getValue()
                                            .split(';')
                                            .map((error, idx) => {
                                                const invalidLine = error
                                                    .toLowerCase()
                                                    .includes('invalido')
                                                return (
                                                    <TypographyP
                                                        key={idx}
                                                        className={cn(
                                                            invalidLine &&
                                                                'text-red-500'
                                                        )}
                                                    >
                                                        {error}
                                                    </TypographyP>
                                                )
                                            })}
                                    </div>
                                    <Button
                                        variant="outline"
                                        className="w-full gap-2"
                                        onClick={onClick}
                                    >
                                        {hasCopied ? (
                                            <CheckCircle
                                                size={14}
                                                className="text-emerald-500"
                                            />
                                        ) : (
                                            <Copy size={14} />
                                        )}
                                        Copiar mensagem
                                    </Button>
                                </DialogContent>
                            </Dialog>
                        )
                    )
                },
                meta: {
                    cell: {
                        className: 'text-red-500',
                    },
                },
            }),
        ],
        []
    )

    return (
        <div className="space-y-4">
            <TypographyH2>Pedidos com erro</TypographyH2>
            <Table<ErrorTableData>
                data={data}
                columns={columns}
                meta={{
                    layout: 'stretch',
                }}
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultColumn>{getValue() as string}</DefaultColumn>
                    ),
                }}
            />
        </div>
    )
}

export default ErrorTable
