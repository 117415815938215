import { Button } from '@/components/ui/button'
import { TypographyH1 } from '@/components/ui/typography'
import { cn } from '@/lib/utils'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { Filter, Operators } from '@/types/Filters'
import { Quotation, QuotationHeader } from '@/types/Quotation'
import { RefreshCcw } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'

interface HeaderProps {
    data?: Quotation[] | QuotationHeader[]
    refetch: () => void
    setButtonFilters: (
        filters: Filter<Pick<Quotation, 'SK_COTACAO' | 'SK_USUARIO'>>[]
    ) => void
}

const stateSelector = (state: Store) => ({
    userId: state.authSlice.state.userId,
    a: state.authSlice.state,
})

const Header = ({ data, setButtonFilters, refetch }: HeaderProps) => {
    const { userId } = useBaseStore(stateSelector)

    const buttons = useMemo(
        () => [
            {
                label: 'Minha cotações',
                id: 'mine',
                onClick: () =>
                    setButtonFilters([
                        {
                            AND: [
                                {
                                    field: 'SK_USUARIO',
                                    operator: Operators.equals,
                                    value: userId,
                                },
                            ],
                        },
                    ]),
            },
            {
                label: 'Todas',
                id: 'all',
                onClick: () => setButtonFilters([]),
            },
        ],
        [data]
    )
    const [activeButton, setActiveButton] = useState(buttons[0].id)

    useEffect(() => {
        buttons.find((button) => button.id === activeButton)?.onClick()
    }, [data])

    return (
        <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
                <TypographyH1 className="text-xl font-semibold">
                    Cotações
                </TypographyH1>
                <Button onClick={() => refetch()} variant="ghost" size="icon">
                    <RefreshCcw size={16} />
                </Button>
                <div className="flex items-center gap-2 border-neutral-200">
                    {buttons.map((button) => (
                        <Button
                            key={button.id}
                            id={button.id}
                            className={cn(
                                'px-4 py-3 rounded-full shadow-sm border bg-background text-foreground',
                                activeButton === button.id &&
                                    'bg-primary-200 text-primary-700 hover:bg-primary-200 hover:text-primary-700 dark:bg-primary-500 dark:text-primary-foreground'
                            )}
                            variant="ghost"
                            onClick={() => {
                                setActiveButton(button.id)
                                button.onClick()
                            }}
                        >
                            {button.label}
                        </Button>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Header
