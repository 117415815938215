import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Row } from '@tanstack/react-table'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/components/ui/use-toast'
import { isAxiosError } from '@/api/business'
import { AvaliacoesTable } from '../..'
import { updateAvaliacao } from '@/api/business/avaliacoes'
import { avaliacoesKeys } from '@/queries/useAvaliacoesQuery'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { Input } from '@/components/ui/input'
import { Checkbox } from '@/components/ui/checkbox'
import { Button } from '@/components/ui/button'

interface EditDialogProps {
    row: Row<AvaliacoesTable>
    isOpen: boolean
    onClose: () => void
}

const EditDialog = ({ row, isOpen, onClose }: EditDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const schema = z.object({
        description: z.string().min(1, { message: 'Campo obrigatório' }),
        default: z.boolean().optional(),
    })

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            description: row.original.DESCRICAO_AVALIACAO,
            default: row.original.AVALIACAO_PADRAO === 1 ? true : false,
        },
    })

    useEffect(() => {
        form.reset({
            description: row.original.DESCRICAO_AVALIACAO,
            default: row.original.AVALIACAO_PADRAO === 1 ? true : false,
        })
    }, [row.original])

    const { mutate, isPending: mutateLoading } = useMutation({
        mutationFn: updateAvaliacao,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: avaliacoesKeys.lists() })
            toast({
                title: 'Criado com sucesso',
            })
            onClose()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível editar a avaliação. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao editar avaliação',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const onSubmit = (data: z.infer<typeof schema>) => {
        mutate({
            DESCRICAO_AVALIACAO: data.description,
            AVALIACAO_PADRAO: data.default ? 1 : 0,
        })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Editar avaliação</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-4"
                    >
                        <FormField
                            name="description"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Descrição</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="default"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem className="flex items-center gap-2 space-y-0">
                                    <FormControl>
                                        <Checkbox
                                            checked={field.value}
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <FormLabel>Avaliação padrão</FormLabel>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <DialogFooter>
                            <Button
                                type="button"
                                onClick={onClose}
                                variant="ghost"
                            >
                                Cancelar
                            </Button>
                            <Button type="submit" disabled={mutateLoading}>
                                Confirmar
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default EditDialog
