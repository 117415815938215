import { fetchSupplierAttachments } from '@/api/business/arquivos'
import { FetchSupplierAttachmentsProps } from '@/api/business/arquivos/type'
import { useQuery } from '@tanstack/react-query'

export const supplierAttachmentsKeys = {
    all: ['supplierAttachments'] as const,
    lists: () => [...supplierAttachmentsKeys.all, 'list'] as const,
    list: (params: { quotationId: string; supplierId: string }) =>
        [...supplierAttachmentsKeys.lists(), params] as const,
    details: () => [...supplierAttachmentsKeys.all, 'detail'] as const,
    detail: (params) => [...supplierAttachmentsKeys.details(), params] as const,
    filters: () => [...supplierAttachmentsKeys.all, 'filters'] as const,
}

interface UseQuotationSupplierAttachmentsQueryProps
    extends FetchSupplierAttachmentsProps {
    enabled: boolean
}

export const useQuotationSupplierAttachmentsQuery = ({
    quotationId,
    supplierId,
    enabled,
}: UseQuotationSupplierAttachmentsQueryProps) => {
    return useQuery({
        queryFn: () => fetchSupplierAttachments({ quotationId, supplierId }),
        queryKey: supplierAttachmentsKeys.list({
            quotationId: quotationId.toString(),
            supplierId,
        }),
        enabled,
    })
}
