import { isAxiosError } from '@/api/business'
import { updateSupplierEmail } from '@/api/business/fornecedores'
import { UpdateSupplierEmailProps } from '@/api/business/fornecedores/type'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import {
    Form,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import { quotationKeys } from '@/queries/useQuotationQuery'
import { suppliersKeys } from '@/queries/useSuppliersQuery'
import { validationEmailSchema } from '@/schemas/emailSchema'
import { Supplier } from '@/types/Suppliers'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Plus, X } from 'lucide-react'
import { useFieldArray, useForm } from 'react-hook-form'
import { z } from 'zod'

interface AddEmailDialogProps {
    isOpen: boolean
    supplier: Supplier
    onClose: () => void
    onSuccess?:
        | ((
              data: any,
              variables: UpdateSupplierEmailProps,
              context: unknown
          ) => unknown)
        | undefined
}

const emailSchema = z.object({
    emails: z
        .object({
            email: validationEmailSchema,
        })
        .array(),
})

type EmailSchema = z.infer<typeof emailSchema>

const AddEmailDialog = ({
    isOpen,
    supplier,
    onSuccess,
    onClose,
}: AddEmailDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()
    const form = useForm<EmailSchema>({
        resolver: zodResolver(emailSchema),
        defaultValues: {
            emails: [
                {
                    email: '',
                },
            ],
        },
    })

    const { fields, append, remove } = useFieldArray<EmailSchema>({
        control: form.control,
        name: 'emails',
    })

    const { mutate, isPending } = useMutation({
        mutationFn: updateSupplierEmail,
        onSuccess: onSuccess
            ? onSuccess
            : () => {
                  queryClient.invalidateQueries({
                      queryKey: quotationKeys.all,
                  })
                  queryClient.invalidateQueries({
                      queryKey: suppliersKeys.lists(),
                  })

                  form.reset()
                  onClose()
              },
        onError: (err) => {
            let errorMessage =
                'Não foi possível atualizar email. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao atualizar',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const onSubmit = (data: EmailSchema) => {
        mutate({
            SK_FORNECEDOR: supplier.SK_FORNECEDOR,
            DS_EMAIL: data.emails.map((email) => email.email),
        })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Adicionar novo email</DialogTitle>
                    <DialogDescription></DialogDescription>
                </DialogHeader>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-4"
                    >
                        <ul className="space-y-2">
                            {fields.map((field, idx) => (
                                <li key={field.id}>
                                    <FormField
                                        control={form.control}
                                        name={`emails.${idx}.email`}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Email</FormLabel>
                                                <div className="flex items-center gap-2">
                                                    <Input {...field} />
                                                    {idx !== 0 && (
                                                        <button
                                                            className="p-2 hover:bg-neutral-100"
                                                            type="button"
                                                            onClick={() =>
                                                                remove(idx)
                                                            }
                                                        >
                                                            <X size={14} />
                                                        </button>
                                                    )}
                                                </div>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </li>
                            ))}
                        </ul>
                        <DialogFooter className="sm:justify-between">
                            <Button
                                type="button"
                                className="flex items-center gap-1 text-sm"
                                variant="ghost"
                                onClick={() =>
                                    append({
                                        email: '',
                                    })
                                }
                            >
                                Adicionar <Plus size={14} />
                            </Button>
                            <Button disabled={isPending}>Salvar</Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default AddEmailDialog
