import { fetchPurchaseOrder } from '@/api/business/pedidosDeCompra'
import { FetchPurchaseOrderProps } from '@/api/business/pedidosDeCompra/type'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const purchaseOrderKeys = {
    all: ['purchaseOrder'] as const,
    lists: () => [...purchaseOrderKeys.all, 'list'] as const,
    list: (params: { perPage: number; currentPage: number; filters?: any }) =>
        [...purchaseOrderKeys.lists(), params] as const,
    details: () => [...purchaseOrderKeys.all, 'detail'] as const,
    detail: (params) => [...purchaseOrderKeys.details(), params] as const,
    filters: () => [...purchaseOrderKeys.all, 'filters'] as const,
}

interface UseQuotationPurchaseOrderListQueryProps
    extends FetchPurchaseOrderProps {
    enabled: boolean
}

export const useQuotationPurchaseOrderListQuery = ({
    currentPage,
    perPage,
    filters,
    enabled,
}: UseQuotationPurchaseOrderListQueryProps) => {
    return useQuery({
        queryFn: () => fetchPurchaseOrder({ currentPage, perPage, filters }),
        queryKey: purchaseOrderKeys.list({
            currentPage,
            perPage,
            filters,
        }),
        placeholderData: keepPreviousData,
        enabled,
    })
}

// export const usePurchaseOrderFiltersQuery = () => {
//     return useQuery({
//         queryFn: fetchPurchaseOrderFilters,
//         queryKey: purchaseOrderKeys.filters(),
//     })
// }
