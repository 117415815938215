import { z } from 'zod'

export const imagesExtensionsSchema = z.union([
    z.literal('png'),
    z.literal('jpeg'),
    z.literal('jpg'),
])

export const videosExtensionsSchema = z.literal('mp4')

export const fileExtensionsSchema = z.union([
    z.literal('doc'),
    z.literal('docx'),
    z.literal('pdf'),
    z.literal('txt'),
    z.literal('xls'),
    z.literal('xlsx'),
    z.literal('csv'),
    z.literal('ppt'),
    z.literal('pptx'),
])

export const allExtensionsSchema = z.union([
    imagesExtensionsSchema,
    videosExtensionsSchema,
    fileExtensionsSchema,
])

export const attachmentSchema = z.object({
    SK_COTACAO_X_ARQUIVOS: z.number(),
    ARQUIVO: z.string(),
    EXTENSAO_DO_ARQUIVO: allExtensionsSchema,
    CHAVE_DO_ARQUIVO: z.string(),
    TAMANHO_DO_ARQUIVO: z.number(),
})
