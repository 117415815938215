import { Button } from '@/components/ui/button'
import useDisclosure from '@/hooks/useDisclosure'
import { cn } from '@/lib/utils'
import { useQuotationMapQuery } from '@/queries/useQuotatioMapQuery'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { PlusCircle } from 'lucide-react'
import { useParams } from 'react-router-dom'

const ApproveButton = () => {
    const { onOpen } = useDisclosure()
    const { id } = useParams()

    const { data } = useQuotationByIdQuery({ id: Number(id) })
    const { isLoading, isError } = useQuotationMapQuery({ id: Number(id) })

    const disabled = isLoading || isError || data?.DD_EM_APROVACAO !== 1

    return (
        data?.DD_EM_APROVACAO === 1 && (
            <Button onClick={onOpen} disabled={disabled} variant="outline">
                <PlusCircle
                    size={14}
                    className={cn(
                        disabled ? 'text-neutral-500' : 'text-secondary-500'
                    )}
                />
                <span>Aprovar</span>
            </Button>
        )
    )
}

export default ApproveButton
