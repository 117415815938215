import { Skeleton } from '@/components/ui/skeleton'

interface ItemSkeletonProps {
    total?: number
}

const ItemSkeleton = ({ total = 2 }: ItemSkeletonProps) => {
    const quantity = Array.from({ length: total }, (_, i) => i + 1)

    return quantity.map((item) => (
        <div
            key={item}
            className="p-4 space-y-2 border rounded-md cursor-pointer hover:bg-neutral-100"
        >
            <div className="space-y-2">
                <Skeleton className="w-[180px] h-5" />
                <Skeleton className="w-[240px] h-5" />
            </div>
            <div className="flex items-center gap-2">
                <Skeleton className="w-[80px] h-4" />
                <Skeleton className="w-[80px] h-4" />
                <Skeleton className="w-[80px] h-4" />
            </div>
            <div className="grid grid-cols-[repeat(auto-fit,minmax(220px,1fr))]">
                <div className="flex items-start gap-2">
                    <div className="p-1">
                        <Skeleton className="w-6 h-6" />
                    </div>
                    <div className="space-y-2">
                        <Skeleton className="w-[150px] h-4" />
                        <Skeleton className="w-[200px] h-4" />
                    </div>
                </div>
                <div className="flex items-start gap-2">
                    <div className="p-1">
                        <Skeleton className="w-6 h-6" />
                    </div>
                    <div className="space-y-2">
                        <Skeleton className="w-[150px] h-4" />
                        <Skeleton className="w-[200px] h-4" />
                    </div>
                </div>
            </div>
        </div>
    ))
}
export default ItemSkeleton
