import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Toaster } from './components/ui/toaster'
import { Toaster as SonnerToaster } from './components/ui/sonner'
import i18next from 'i18next'
import { z } from 'zod'
import { zodI18nMap } from 'zod-i18n-map'
import translation from 'zod-i18n-map/locales/pt/zod.json'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { setDefaultOptions } from 'date-fns'
import { ptBR } from 'date-fns/locale'

i18next.init({
    lng: 'es',
    resources: {
        es: { zod: translation },
    },
})

z.setErrorMap(zodI18nMap)

setDefaultOptions({ locale: ptBR })

// const CACHE_TIME = 30 * 1000
const STALE_TIME = 60 * 30 * 1000

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: STALE_TIME,
        },
    },
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <QueryClientProvider client={queryClient}>
        <DndProvider backend={HTML5Backend}>
            <App />
        </DndProvider>
        <Toaster />
        <SonnerToaster />
        <ReactQueryDevtools />
    </QueryClientProvider>
)
