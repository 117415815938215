import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { usePaymentConditionsPaginatedQuery } from '@/queries/usePaymentConditionsQuery'
import { PaymentConditions } from '@/types/PaymentCondition'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import DadosMestreHeader from '../components/DadosMestreHeader'
import EditDialog from './components/EditDialog'
import DadosMestreHeaderActions from '../components/DadosMestreHeaderActions'
import { Filter, Operators } from '@/types/Filters'
import TableRowActions from '@/components/Table/components/TableRowActions'
import { usePaymentConditionSpreadsheet } from './usePaymentConditionSpreadsheet'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { useTable } from '@/hooks/useTable'

export type PaymentConditionTable = TableData<PaymentConditions>

const columnHelper = createColumnHelper<PaymentConditions>()

const DadosMestreCondicoesDePagamento = () => {
    const {
        table,
        selectedRow,
        selectedRows,
        isEditDialogOpen,
        pageIndex,
        pageSize,
        columnFilters,
        onPaginationChange,
        onColumnFiltersChange,
        onClickEdit,
        onClickMultiDelete,
        onEditDialogClose,
        setSelectedRows,
        setTable,
    } = useTable<PaymentConditions>()

    const [fieldFilters, setFieldFilters] = useState<
        Filter<PaymentConditions>[]
    >([])

    const { onDownload } = usePaymentConditionSpreadsheet()

    const { data, isFetching, isPending, refetch } =
        usePaymentConditionsPaginatedQuery({
            currentPage: pageIndex,
            perPage: pageSize,
            filters: fieldFilters,
            enabled: true,
        })

    const memoData = useMemo(() => (data ? data.DADOS : []), [data])
    const memoColumns = useMemo(
        () => [
            columnHelper.accessor('NK_CONDICAO_DE_PAGAMENTO', {
                id: 'SK_CONDICAO_DE_PAGAMENTO',
                header: 'Cod condição de pagamento',
                size: 200,
            }),
            columnHelper.accessor('DS_CONDICAO_DE_PAGAMENTO', {
                id: 'DS_CONDICAO_DE_PAGAMENTO',
                header: 'Condição de pagamento',
                size: 400,
            }),
            columnHelper.accessor(
                ({ DD_COTACAO }) => (DD_COTACAO === 1 ? 'Sim' : 'Não'),
                {
                    id: 'DD_COTACAO',
                    header: 'Cotação',
                    size: 130,
                }
            ),
            columnHelper.display({
                id: 'Ações',
                cell: ({ row }) => {
                    return (
                        <TableRowActions onClickEdit={() => onClickEdit(row)} />
                    )
                },
                size: 130,
            }),
        ],
        []
    )

    return (
        <div className="w-full h-full p-4 overflow-hidden">
            <Table<PaymentConditionTable>
                data={memoData}
                columns={memoColumns}
                getRowId={(row) => row.SK_CONDICAO_DE_PAGAMENTO.toString()}
                isLoading={isPending}
                isFetching={isFetching}
                getTableInstance={(table) => setTable(table)}
                onRowSelectionChange={setSelectedRows}
                tableState={{
                    columnFilters,
                    pagination: {
                        pageIndex,
                        pageSize,
                    },
                }}
                onPaginationChange={onPaginationChange}
                onColumnFiltersChange={(columnFilter) => {
                    if (columnFilter.length > 0) {
                        const filters: Filter<PaymentConditions>[] = [
                            {
                                AND: columnFilter.map((filter) => {
                                    if (
                                        (filter.id as keyof PaymentConditions) ===
                                        'DD_COTACAO'
                                    ) {
                                        return {
                                            field: filter.id as keyof PaymentConditions,
                                            operator: Operators.equals,
                                            value:
                                                filter.value === 'Sim' ? 1 : 0,
                                        }
                                    }
                                    return {
                                        field: filter.id as keyof PaymentConditions,
                                        operator: Operators.like,
                                        value: filter.value as string,
                                    }
                                }),
                            },
                        ]

                        setFieldFilters(filters)
                    } else {
                        setFieldFilters([])
                    }
                    onColumnFiltersChange(columnFilter)
                }}
                pagination={{
                    pageSize,
                    totalPages: data?.TOTAL_DE_PAGINAS || 0,
                    totalItems: data?.TOTAL || 0,
                }}
                tableHeader={
                    <DadosMestreHeader
                        title="Condições de pagamento"
                        onDelete={onClickMultiDelete}
                        selectedRows={
                            table
                                ?.getFilteredRowModel()
                                .flatRows.filter((row) => selectedRows[row.id])
                                .length
                        }
                    />
                }
                tableActions={
                    <DadosMestreHeaderActions
                        onRefech={refetch}
                        tableId="DADOS_MESTRE_CONDICOES_DE_PAGAMENTO"
                        onDownload={() => table && onDownload(table)}
                        layout
                    />
                }
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultColumn>{getValue() as string}</DefaultColumn>
                    ),
                }}
            />
            {selectedRow && (
                <EditDialog
                    row={selectedRow}
                    isOpen={isEditDialogOpen}
                    onClose={onEditDialogClose}
                />
            )}
        </div>
    )
}

export default DadosMestreCondicoesDePagamento
