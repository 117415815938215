import instance from '@/api/business'
import {
    AddFileProps,
    DownloadFileProps,
    FetchApprovalAttachmentsProps,
    FetchApprovalAttachmentsResponse,
    FetchSupplierAttachmentsProps,
    FetchSupplierAttachmentsResponse,
    RemoveFileProps,
} from './type'

const BASE_ROUTE = '/arquivos'

export const fetchFileById = async () => {
    const response = await instance.post(`${BASE_ROUTE}/id`)

    return response.data
}

export const addFile = async ({
    quotationId,
    files,
    isApproavalFile,
}: AddFileProps) => {
    const formData = new FormData()

    formData.append('SK_COTACAO', quotationId.toString())
    formData.append('DD_APROVACAO', isApproavalFile ? '1' : '0')

    Object.values(files).forEach((file) => {
        formData.append('ANEXOS', file)
    })

    const response = await instance.put(BASE_ROUTE, formData)

    return response.data
}

export const fetchSupplierAttachments = async ({
    quotationId,
    supplierId,
}: FetchSupplierAttachmentsProps) => {
    const response = await instance.post<FetchSupplierAttachmentsResponse>(
        `/cotacoes/arquivos/fornecedor`,
        {
            SK_COTACAO: quotationId,
            SK_FORNECEDOR: supplierId,
        }
    )

    return response.data
}

export const fetchApprovalAttachments = async ({
    quotationId,
}: FetchApprovalAttachmentsProps) => {
    const response = await instance.post<FetchApprovalAttachmentsResponse>(
        `/arquivos/aprovacao`,
        {
            SK_COTACAO: quotationId,
        }
    )

    return response.data
}

export const removeFile = async ({ files, quotationId }: RemoveFileProps) => {
    const response = await instance.delete(BASE_ROUTE, {
        data: {
            SK_COTACAO: quotationId,
            ARQUIVOS: files,
        },
    })

    return response.data
}

export const downloadFile = async ({
    fileKey,
    fileName,
    cb,
}: DownloadFileProps) => {
    const response = await instance.post(
        `${BASE_ROUTE}/download`,
        {
            CHAVE_DO_ARQUIVO: fileKey,
        },
        {
            responseType: 'blob',
            onDownloadProgress: cb,
        }
    )

    if (response.status === 200) {
        const a = document.createElement('a')
        const url = window.URL.createObjectURL(new Blob([response.data]))
        a.href = url
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(url)
    }

    return response.data
}
