import {
    QuotationMapProduct,
    QuotationMapValues,
} from '@/api/business/cotacoes/type'
import {
    ColumnOrderState,
    Header,
    createColumnHelper,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { currencyFormat, formatCpfOrCnpj } from '@/utils/stringFormatter'
import { useBaseStore } from '@/store'
import { quotationMapStateSelector } from '@/store/quotationMapSlice'
import { cn } from '@/lib/utils'
import {
    CheckCircle,
    Clock,
    Crown,
    Eye,
    HelpCircle,
    XCircle,
} from 'lucide-react'
import {
    TypographyH2,
    TypographyMuted,
    TypographyP,
} from '@/components/ui/typography'
import TableContainer from '@/components/Table/components/TableContainer'
import TableLayout from '@/components/Table/components/TableLayout'
import TableCell from '@/components/Table/components/TableCell'
import TableColumnHeader from '@/components/Table/components/TableColumnHeader'
import { TableContextProvider } from '@/components/Table/contexts/TableContext'
import { format, isValid } from 'date-fns'
import TableBody from '@/components/Table/components/TableBody'
import TableRow from '@/components/Table/components/TableRow'
import TableFooter from '@/components/Table/components/TableFooter'
import TableColumnsVisibility from './components/TableColumnsVisibility'
import { Store } from '@/store/type'
import { Button } from '@/components/ui/button'
import { useNavigate } from 'react-router-dom'
import ApprovalDialog from '../ApprovalDialog'

type QuotationMapTableType = QuotationMapValues & QuotationMapProduct

const columnHelper = createColumnHelper<QuotationMapTableType>()

const stateSelector = (state: Store) => ({
    quotationMapTableVisibility:
        state.quotationMapSlice.state.quotationMapTableVisibility,
})

const QuotationMapTable = () => {
    const navigate = useNavigate()
    const { quotationMapTableVisibility } = useBaseStore(stateSelector)
    const { quotationMap } = useBaseStore(quotationMapStateSelector)

    const [columnOrderState, setColumnOrderState] = useState<ColumnOrderState>(
        []
    )

    const suppliers = useMemo(
        () => Object.values(quotationMap?.FORNECEDORES.entities || []),
        [quotationMap.FORNECEDORES]
    )

    const products = useMemo(
        () => Object.values(quotationMap?.PRODUTOS.entities || []),
        [quotationMap.PRODUTOS]
    )

    const values = quotationMap?.VALORES || []

    const columnsMemo = useMemo(
        () => [
            columnHelper.accessor('SK_EMPRESA', {
                header: 'Cod Empresa',
                size: 80,
                enableHiding: false,
                cell: ({ getValue, row }) => {
                    const { COR_EMPRESA } = row.original

                    return (
                        <span
                            style={{ color: COR_EMPRESA }}
                            className="truncate"
                        >
                            {getValue()}
                        </span>
                    )
                },
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                header: 'Empresa',
                size: 150,
                enableHiding: false,
                cell: ({ getValue, row }) => {
                    const { COR_EMPRESA } = row.original

                    return (
                        <span
                            style={{ color: COR_EMPRESA }}
                            className="truncate"
                        >
                            {getValue()}
                        </span>
                    )
                },
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('NK_ITEM_SOLICITACAO_DE_COMPRAS', {
                header: 'Item',
                meta: {
                    enableColumnOrdering: false,
                },
                enableHiding: false,
                size: 80,
            }),
            columnHelper.accessor('NK_SOLICITACAO_DE_COMPRAS', {
                header: 'SC',
                meta: {
                    enableColumnOrdering: false,
                },
                enableHiding: false,
                size: 100,
            }),
            columnHelper.accessor('DS_PRODUTO', {
                header: 'Produto',
                meta: {
                    enableColumnOrdering: false,
                },
                enableHiding: false,
                size: 400,
            }),
            columnHelper.accessor('DD_UM', {
                header: 'UM',
                meta: {
                    enableColumnOrdering: false,
                },
                enableHiding: false,
                size: 80,
            }),
            columnHelper.accessor('ULTIMO_PRECO', {
                header: 'Último preço',
                meta: {
                    enableColumnOrdering: false,
                },
                enableHiding: false,
                size: 80,
            }),
            columnHelper.accessor('QTD_DISPONIVEL', {
                header: 'Qtde. disponível',
                meta: {
                    enableColumnOrdering: false,
                },
                enableHiding: false,
                size: 120,
            }),
            columnHelper.accessor('QTD_SOLICITADA', {
                header: 'Qtde. solicitada',
                enableHiding: false,
                size: 120,
                meta: {
                    enableColumnOrdering: false,
                },
                footer: () => 'Total',
            }),
            columnHelper.group({
                header: 'Fornecedores',
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                    header: {
                        className: 'text-xs p-4 font-bold',
                    },
                },
                columns: [
                    ...suppliers
                        .filter(
                            (supplier) =>
                                quotationMapTableVisibility.suppliers[
                                    supplier.SK_FORNECEDOR
                                ]
                        )
                        .map((supplier) => {
                            return columnHelper.group({
                                id: supplier.SK_FORNECEDOR,
                                header: () => {
                                    const declined =
                                        supplier.FORNECEDOR_DECLINADO === 1
                                    const finished =
                                        supplier.FORNECEDOR_PUBLICADO === 1

                                    return (
                                        <div className="flex gap-2 overflow-hidden">
                                            <Button
                                                variant="outline"
                                                size="icon"
                                                onClick={() =>
                                                    navigate(
                                                        `mapa/fornecedor/${supplier.SK_FORNECEDOR}`
                                                    )
                                                }
                                            >
                                                <Eye className="text-primary-600" />
                                            </Button>
                                            <div className="flex-1 overflow-hidden">
                                                <TypographyP className="text-xs truncate">
                                                    {supplier.DD_NOME_FANTASIA}
                                                </TypographyP>
                                                <TypographyMuted className="text-xs truncate">
                                                    {supplier.DD_RAZAO_SOCIAL} -{' '}
                                                    {formatCpfOrCnpj(
                                                        supplier.DD_CNPJ
                                                    )}
                                                </TypographyMuted>
                                                <TypographyP
                                                    className={cn(
                                                        'flex items-center gap-1 text-xs text-amber-500',
                                                        finished &&
                                                            'text-green-500',
                                                        declined &&
                                                            'text-red-500'
                                                    )}
                                                >
                                                    {declined ? (
                                                        <>
                                                            <XCircle
                                                                size={14}
                                                            />
                                                            Declinado
                                                        </>
                                                    ) : finished ? (
                                                        <>
                                                            <CheckCircle
                                                                size={14}
                                                            />
                                                            Finalizado
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Clock size={14} />
                                                            Pendente
                                                        </>
                                                    )}
                                                </TypographyP>
                                            </div>
                                        </div>
                                    )
                                },
                                meta: {
                                    enableColumnOrdering: false,
                                    enableMenu: false,
                                    header: {
                                        className: 'text-sm p-4 font-bold',
                                    },
                                },
                                columns: quotationMap?.RODADAS.filter(
                                    (round) =>
                                        quotationMapTableVisibility.rounds[
                                            round.SK_COTACAO_X_RODADA
                                        ]
                                ).map((round) => {
                                    const columnGroup = columnHelper.group({
                                        id: `${round.SK_COTACAO_X_RODADA}${supplier.SK_FORNECEDOR}`,
                                        header: `Rodada ${round.NUMERO_RODADA}`,
                                        meta: {
                                            enableColumnOrdering: false,
                                            enableMenu: false,
                                            header: {
                                                className:
                                                    'text-xs p-4 font-bold',
                                            },
                                        },
                                        columns: [],
                                    })

                                    if (
                                        quotationMapTableVisibility.fields[
                                            'PRECO'
                                        ]
                                    ) {
                                        columnGroup.columns?.push(
                                            columnHelper.display({
                                                id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-net`,
                                                header: 'Valor net',
                                                cell: ({ row }) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row.original

                                                    const value = values?.find(
                                                        (value) =>
                                                            supplier.FORNECEDOR_RODADAS.includes(
                                                                value.SK_FORNECEDOR_X_RODADA
                                                            ) &&
                                                            round.SK_COTACAO_X_RODADA ===
                                                                value.SK_COTACAO_X_RODADA &&
                                                            value.SK_COTACAO_X_PRODUTO ===
                                                                SK_COTACAO_X_PRODUTO
                                                    )

                                                    const price =
                                                        value?.PRECO || 0

                                                    const inApproval =
                                                        value?.DD_EM_APROVACAO
                                                    const approved =
                                                        value?.DD_APROVADO_POR_APROVADOR
                                                    const declined =
                                                        value?.DD_RECUSADO_POR_APROVADOR

                                                    return (
                                                        <div
                                                            className={cn(
                                                                'relative flex flex-1 p-3 items-center justify-end h-full gap-2',
                                                                inApproval &&
                                                                    'bg-amber-400',
                                                                approved &&
                                                                    'dark:bg-green-500 bg-green-300',
                                                                declined &&
                                                                    'dark:bg-red-500 bg-red-300'
                                                            )}
                                                        >
                                                            <TypographyP
                                                                className={cn(
                                                                    'text-right text-xs text-foreground'
                                                                )}
                                                            >
                                                                {currencyFormat(
                                                                    Number(
                                                                        price
                                                                    )
                                                                )}
                                                            </TypographyP>
                                                        </div>
                                                    )
                                                },
                                                meta: {
                                                    enableColumnOrdering: false,
                                                    enableMenu: false,
                                                    header: {
                                                        className:
                                                            'text-xs flex items-center h-auto font-bold',
                                                    },
                                                    cell: {
                                                        className: 'p-0',
                                                    },
                                                },
                                                footer: () => {
                                                    const sum = values
                                                        .filter((value) =>
                                                            supplier.FORNECEDOR_RODADAS.some(
                                                                (id) =>
                                                                    id ===
                                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                                    round.SK_COTACAO_X_RODADA ===
                                                                        value.SK_COTACAO_X_RODADA
                                                            )
                                                        )
                                                        .reduce((acc, curr) => {
                                                            const value =
                                                                Number(
                                                                    curr.PRECO
                                                                ) || 0
                                                            return acc + value
                                                        }, 0)
                                                    return currencyFormat(sum)
                                                },
                                            })
                                        )
                                    }

                                    if (
                                        quotationMapTableVisibility.fields[
                                            'PRECO_TOTAL'
                                        ]
                                    ) {
                                        columnGroup.columns?.push(
                                            columnHelper.display({
                                                id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-total`,
                                                header: 'Valor total',
                                                meta: {
                                                    enableColumnOrdering: false,
                                                    enableMenu: false,
                                                    header: {
                                                        className:
                                                            'text-xs flex items-center h-auto font-bold',
                                                    },
                                                    cell: {
                                                        className: 'p-0',
                                                    },
                                                },
                                                cell: ({ row }) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row.original

                                                    const value = values?.find(
                                                        (value) =>
                                                            supplier.FORNECEDOR_RODADAS.includes(
                                                                value.SK_FORNECEDOR_X_RODADA
                                                            ) &&
                                                            round.SK_COTACAO_X_RODADA ===
                                                                value.SK_COTACAO_X_RODADA &&
                                                            value.SK_COTACAO_X_PRODUTO ===
                                                                SK_COTACAO_X_PRODUTO
                                                    )

                                                    const price =
                                                        value?.PRECO_TOTAL || 0
                                                    const inApproval =
                                                        value?.DD_EM_APROVACAO
                                                    const approved =
                                                        value?.DD_APROVADO_POR_APROVADOR
                                                    const declined =
                                                        value?.DD_RECUSADO_POR_APROVADOR

                                                    return (
                                                        <div
                                                            className={cn(
                                                                'relative flex flex-1 p-3 items-center justify-end h-full gap-2',
                                                                inApproval &&
                                                                    'bg-amber-400',
                                                                approved &&
                                                                    'dark:bg-green-500 bg-green-300',
                                                                declined &&
                                                                    'dark:bg-red-500 bg-red-300'
                                                            )}
                                                        >
                                                            <TypographyP className="flex-1 text-xs text-right">
                                                                {currencyFormat(
                                                                    price
                                                                )}
                                                            </TypographyP>
                                                        </div>
                                                    )
                                                },
                                                footer: () => {
                                                    const sum = values
                                                        .filter((value) =>
                                                            supplier.FORNECEDOR_RODADAS.some(
                                                                (id) =>
                                                                    id ===
                                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                                    round.SK_COTACAO_X_RODADA ===
                                                                        value.SK_COTACAO_X_RODADA
                                                            )
                                                        )
                                                        .reduce((acc, curr) => {
                                                            const value =
                                                                Number(
                                                                    curr.PRECO_TOTAL
                                                                ) || 0
                                                            return acc + value
                                                        }, 0)
                                                    return currencyFormat(sum)
                                                },
                                            })
                                        )
                                    }

                                    if (
                                        quotationMapTableVisibility.fields[
                                            'PRECO_IMPOSTO'
                                        ]
                                    ) {
                                        columnGroup.columns?.push(
                                            columnHelper.display({
                                                id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-taxes`,
                                                header: 'Valor com imposto',
                                                meta: {
                                                    enableColumnOrdering: false,
                                                    enableMenu: false,
                                                    header: {
                                                        className:
                                                            'text-xs flex items-center h-auto font-bold',
                                                    },
                                                    cell: {
                                                        className: 'p-0',
                                                    },
                                                },
                                                cell: ({ row }) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row.original

                                                    const value = values?.find(
                                                        (value) =>
                                                            supplier.FORNECEDOR_RODADAS.includes(
                                                                value.SK_FORNECEDOR_X_RODADA
                                                            ) &&
                                                            round.SK_COTACAO_X_RODADA ===
                                                                value.SK_COTACAO_X_RODADA &&
                                                            value.SK_COTACAO_X_PRODUTO ===
                                                                SK_COTACAO_X_PRODUTO
                                                    )

                                                    const price =
                                                        value?.PRECO_IMPOSTO ||
                                                        0
                                                    const inApproval =
                                                        value?.DD_EM_APROVACAO
                                                    const approved =
                                                        value?.DD_APROVADO_POR_APROVADOR
                                                    const declined =
                                                        value?.DD_RECUSADO_POR_APROVADOR

                                                    return (
                                                        <div
                                                            className={cn(
                                                                'relative flex flex-1 p-3 items-center justify-end h-full gap-2',
                                                                inApproval &&
                                                                    'bg-amber-400',
                                                                approved &&
                                                                    'dark:bg-green-500 bg-green-300',
                                                                declined &&
                                                                    'dark:bg-red-500 bg-red-300'
                                                            )}
                                                        >
                                                            <TypographyP
                                                                className={cn(
                                                                    'text-right text-xs text-foreground'
                                                                )}
                                                            >
                                                                {currencyFormat(
                                                                    Number(
                                                                        price
                                                                    )
                                                                )}
                                                            </TypographyP>
                                                        </div>
                                                    )
                                                },
                                                footer: () => {
                                                    const sum = values
                                                        .filter((value) =>
                                                            supplier.FORNECEDOR_RODADAS.some(
                                                                (id) =>
                                                                    id ===
                                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                                    round.SK_COTACAO_X_RODADA ===
                                                                        value.SK_COTACAO_X_RODADA
                                                            )
                                                        )
                                                        .reduce((acc, curr) => {
                                                            const value =
                                                                Number(
                                                                    curr.PRECO_IMPOSTO
                                                                ) || 0
                                                            return acc + value
                                                        }, 0)
                                                    return currencyFormat(sum)
                                                },
                                            })
                                        )
                                    }

                                    if (
                                        quotationMapTableVisibility.fields[
                                            'PRECO_TOTAL_IMPOSTO'
                                        ]
                                    ) {
                                        columnGroup.columns?.push(
                                            columnHelper.display({
                                                id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-total-taxes`,
                                                header: 'Valor total com imposto',
                                                meta: {
                                                    enableColumnOrdering: false,
                                                    enableMenu: false,
                                                    header: {
                                                        className:
                                                            'text-xs flex items-center h-auto font-bold truncate',
                                                    },
                                                    cell: {
                                                        className: 'p-0',
                                                    },
                                                },
                                                cell: ({ row }) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row.original

                                                    const value = values?.find(
                                                        (value) =>
                                                            supplier.FORNECEDOR_RODADAS.includes(
                                                                value.SK_FORNECEDOR_X_RODADA
                                                            ) &&
                                                            round.SK_COTACAO_X_RODADA ===
                                                                value.SK_COTACAO_X_RODADA &&
                                                            value.SK_COTACAO_X_PRODUTO ===
                                                                SK_COTACAO_X_PRODUTO
                                                    )
                                                    const price =
                                                        value?.PRECO_TOTAL_IMPOSTO ||
                                                        0

                                                    const inApproval =
                                                        value?.DD_EM_APROVACAO
                                                    const approved =
                                                        value?.DD_APROVADO_POR_APROVADOR
                                                    const declined =
                                                        value?.DD_RECUSADO_POR_APROVADOR

                                                    return (
                                                        <div
                                                            className={cn(
                                                                'relative flex flex-1 p-3 items-center justify-end h-full gap-2',
                                                                inApproval &&
                                                                    'bg-amber-400',
                                                                approved &&
                                                                    'dark:bg-green-500 bg-green-300',
                                                                declined &&
                                                                    'dark:bg-red-500 bg-red-300'
                                                            )}
                                                        >
                                                            <TypographyP className="flex-1 text-xs text-right">
                                                                {currencyFormat(
                                                                    price
                                                                )}
                                                            </TypographyP>
                                                        </div>
                                                    )
                                                },
                                                footer: () => {
                                                    const sum = values
                                                        .filter((value) =>
                                                            supplier.FORNECEDOR_RODADAS.some(
                                                                (id) =>
                                                                    id ===
                                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                                    round.SK_COTACAO_X_RODADA ===
                                                                        value.SK_COTACAO_X_RODADA
                                                            )
                                                        )
                                                        .reduce((acc, curr) => {
                                                            const value =
                                                                Number(
                                                                    curr.PRECO_TOTAL_IMPOSTO
                                                                ) || 0
                                                            return acc + value
                                                        }, 0)
                                                    return currencyFormat(sum)
                                                },
                                            })
                                        )
                                    }

                                    if (
                                        quotationMapTableVisibility.fields[
                                            'FRETE'
                                        ]
                                    ) {
                                        columnGroup.columns?.push(
                                            columnHelper.display({
                                                id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-frete`,
                                                header: 'Valor com frete',
                                                cell: ({ row }) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row.original

                                                    const value = values?.find(
                                                        (value) =>
                                                            supplier.FORNECEDOR_RODADAS.includes(
                                                                value.SK_FORNECEDOR_X_RODADA
                                                            ) &&
                                                            round.SK_COTACAO_X_RODADA ===
                                                                value.SK_COTACAO_X_RODADA &&
                                                            value.SK_COTACAO_X_PRODUTO ===
                                                                SK_COTACAO_X_PRODUTO
                                                    )

                                                    const price =
                                                        value?.PRECO_COM_FRETE ||
                                                        0
                                                    const bestScore =
                                                        value?.MELHOR_SCORE
                                                    const draw = value?.EMPATE

                                                    return (
                                                        <div
                                                            className={cn(
                                                                'relative flex flex-1 p-3 items-center justify-end h-full gap-2',
                                                                draw &&
                                                                    'bg-amber-400',
                                                                bestScore &&
                                                                    'dark:bg-green-500 bg-green-300'
                                                            )}
                                                        >
                                                            {draw ? (
                                                                <div className="text-foreground">
                                                                    <HelpCircle
                                                                        size={
                                                                            14
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : bestScore ? (
                                                                <div className="text-foreground">
                                                                    <Crown
                                                                        size={
                                                                            14
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}

                                                            <TypographyP
                                                                className={cn(
                                                                    'text-right text-xs text-foreground'
                                                                )}
                                                            >
                                                                {currencyFormat(
                                                                    Number(
                                                                        price
                                                                    )
                                                                )}
                                                            </TypographyP>
                                                        </div>
                                                    )
                                                },
                                                meta: {
                                                    enableColumnOrdering: false,
                                                    enableMenu: false,
                                                    header: {
                                                        className:
                                                            'text-xs flex items-center h-auto font-bold',
                                                    },
                                                    cell: {
                                                        className: 'p-0',
                                                    },
                                                },
                                                footer: () => {
                                                    const sum = values
                                                        .filter((value) =>
                                                            supplier.FORNECEDOR_RODADAS.some(
                                                                (id) =>
                                                                    id ===
                                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                                    round.SK_COTACAO_X_RODADA ===
                                                                        value.SK_COTACAO_X_RODADA
                                                            )
                                                        )
                                                        .reduce((acc, curr) => {
                                                            const value =
                                                                Number(
                                                                    curr.PRECO_COM_FRETE
                                                                ) || 0
                                                            return acc + value
                                                        }, 0)
                                                    return currencyFormat(sum)
                                                },
                                            })
                                        )
                                    }

                                    if (
                                        quotationMapTableVisibility.fields[
                                            'PREVISAO_DE_ENTREGA'
                                        ]
                                    ) {
                                        columnGroup.columns?.push(
                                            columnHelper.display({
                                                id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-date`,
                                                header: 'Previsão de entrega',
                                                meta: {
                                                    enableColumnOrdering: false,
                                                    enableMenu: false,
                                                    header: {
                                                        className:
                                                            'text-xs flex items-center h-auto font-bold',
                                                    },
                                                    cell: {
                                                        className: 'p-0',
                                                    },
                                                },
                                                cell: ({ row }) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row.original

                                                    const value = values?.find(
                                                        (value) =>
                                                            supplier.FORNECEDOR_RODADAS.includes(
                                                                value.SK_FORNECEDOR_X_RODADA
                                                            ) &&
                                                            round.SK_COTACAO_X_RODADA ===
                                                                value.SK_COTACAO_X_RODADA &&
                                                            value.SK_COTACAO_X_PRODUTO ===
                                                                SK_COTACAO_X_PRODUTO
                                                    )
                                                    const date =
                                                        value?.PREVISAO_DE_ENTREGA

                                                    const inApproval =
                                                        value?.DD_EM_APROVACAO
                                                    const approved =
                                                        value?.DD_APROVADO_POR_APROVADOR
                                                    const declined =
                                                        value?.DD_RECUSADO_POR_APROVADOR

                                                    return (
                                                        <div
                                                            className={cn(
                                                                'relative flex flex-1 p-3 items-center justify-end h-full gap-2',
                                                                inApproval &&
                                                                    'bg-amber-400',
                                                                approved &&
                                                                    'dark:bg-green-500 bg-green-300',
                                                                declined &&
                                                                    'dark:bg-red-500 bg-red-300'
                                                            )}
                                                        >
                                                            <TypographyP className="flex-1 text-xs text-right">
                                                                {date
                                                                    ? isValid(
                                                                          new Date(
                                                                              date
                                                                          )
                                                                      )
                                                                        ? format(
                                                                              new Date(
                                                                                  date
                                                                              ),
                                                                              'dd/MM/yyyy'
                                                                          )
                                                                        : '-'
                                                                    : '-'}
                                                            </TypographyP>
                                                        </div>
                                                    )
                                                },
                                            })
                                        )
                                    }

                                    columnGroup.columns?.push(
                                        columnHelper.display({
                                            id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-saving`,
                                            header: 'Saving baseline',
                                            cell: ({ row }) => {
                                                const { SK_COTACAO_X_PRODUTO } =
                                                    row.original

                                                const value = values?.find(
                                                    (value) =>
                                                        supplier.FORNECEDOR_RODADAS.includes(
                                                            value.SK_FORNECEDOR_X_RODADA
                                                        ) &&
                                                        round.SK_COTACAO_X_RODADA ===
                                                            value.SK_COTACAO_X_RODADA &&
                                                        value.SK_COTACAO_X_PRODUTO ===
                                                            SK_COTACAO_X_PRODUTO
                                                )

                                                const price =
                                                    value?.SAVING_BASELINE || 0

                                                const inApproval =
                                                    value?.DD_EM_APROVACAO
                                                const approved =
                                                    value?.DD_APROVADO_POR_APROVADOR
                                                const declined =
                                                    value?.DD_RECUSADO_POR_APROVADOR

                                                return (
                                                    <div
                                                        className={cn(
                                                            'relative flex flex-1 p-3 items-center justify-end h-full gap-2',
                                                            inApproval &&
                                                                'bg-amber-400',
                                                            approved &&
                                                                'dark:bg-green-500 bg-green-300',
                                                            declined &&
                                                                'dark:bg-red-500 bg-red-300'
                                                        )}
                                                    >
                                                        <TypographyP
                                                            className={cn(
                                                                'text-right text-xs text-foreground'
                                                            )}
                                                        >
                                                            {currencyFormat(
                                                                Number(price)
                                                            )}
                                                        </TypographyP>
                                                    </div>
                                                )
                                            },
                                            meta: {
                                                enableColumnOrdering: false,
                                                enableMenu: false,
                                                header: {
                                                    className:
                                                        'text-xs flex items-center h-auto font-bold',
                                                },
                                                cell: {
                                                    className: 'p-0',
                                                },
                                            },
                                            footer: () => {
                                                const sum = values
                                                    .filter((value) =>
                                                        supplier.FORNECEDOR_RODADAS.some(
                                                            (id) =>
                                                                id ===
                                                                    value.SK_FORNECEDOR_X_RODADA &&
                                                                round.SK_COTACAO_X_RODADA ===
                                                                    value.SK_COTACAO_X_RODADA
                                                        )
                                                    )
                                                    .reduce((acc, curr) => {
                                                        const value =
                                                            Number(
                                                                curr.PRECO
                                                            ) || 0
                                                        return acc + value
                                                    }, 0)
                                                return currencyFormat(sum)
                                            },
                                        })
                                    )

                                    return columnGroup
                                }),
                            })
                        }),
                ],
            }),
        ],
        [quotationMap, quotationMapTableVisibility]
    )

    const dataMemo = useMemo(() => {
        return products.map((product) => {
            return {
                ...product,
                ...values.find(
                    (value) =>
                        value.SK_COTACAO_X_PRODUTO ===
                        product.SK_COTACAO_X_PRODUTO
                )!,
            }
        })
    }, [quotationMap])

    const table = useReactTable({
        data: dataMemo,
        columns: columnsMemo,
        filterFromLeafRows: true,
        columnResizeMode: 'onChange',
        state: {
            columnOrder: columnOrderState,
        },
        meta: {
            layout: 'stretch',
        },
        onColumnOrderChange: setColumnOrderState,
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
    })

    const rows = table.getRowModel().rows
    // const mergedHeaders = getMergeHeaderGroups(table.getHeaderGroups())

    if (!quotationMap) return 'Carregando...'

    return (
        <>
            <div className="flex items-center justify-between">
                <TypographyH2>Mapa</TypographyH2>
                <div className="flex items-center gap-2">
                    <ApprovalDialog />
                    <TableColumnsVisibility />
                </div>
            </div>
            <TableContainer>
                <TableContextProvider table={table}>
                    <TableLayout>
                        {/* thead */}
                        <div className="bg-background">
                            {table.getHeaderGroups().map((headerGroup) => (
                                // tr
                                <div key={headerGroup.id} className="flex">
                                    {headerGroup.headers.map((header) => (
                                        // th
                                        <TableColumnHeader
                                            key={header.id}
                                            header={header}
                                        />
                                    ))}
                                </div>
                            ))}
                        </div>
                        {/* tbody */}
                        <TableBody>
                            {rows.map((row) => {
                                return (
                                    // tr
                                    <TableRow
                                        className="flex border-b"
                                        key={row?.id}
                                    >
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                // td
                                                <TableCell
                                                    key={cell.id}
                                                    cell={cell}
                                                    className="truncate"
                                                />
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                        <div>
                            {table.getFooterGroups().map((footerGroup) => (
                                <div key={footerGroup.id} className="flex">
                                    {footerGroup.headers.map(
                                        (header) =>
                                            header.depth === 4 && (
                                                <TableFooter
                                                    key={header.id}
                                                    header={
                                                        header as Header<
                                                            unknown,
                                                            any
                                                        >
                                                    }
                                                />
                                            )
                                    )}
                                </div>
                            ))}
                        </div>
                    </TableLayout>
                </TableContextProvider>
            </TableContainer>
        </>
    )
}

export default QuotationMapTable
