import { Checkbox } from '@/components/ui/checkbox'
import { cn } from '@/lib/utils'
import { PaymentConditions } from '@/types/PaymentCondition'
import { Star, X } from 'lucide-react'

interface PaymentConditionItemProps {
    paymentCondition: PaymentConditions
    checked: boolean
    onSelect: (paymentCondition: PaymentConditions) => void
    onChangePreference: (id: string) => void
    onRemove?: () => void
}

const PaymentConditionItem = ({
    paymentCondition,
    checked,
    onSelect,
    onChangePreference,
    onRemove,
}: PaymentConditionItemProps) => {
    return (
        <li className="px-4 py-2 hover:bg-neutral-100">
            <div className="flex items-center">
                <label
                    className="flex items-start flex-1 gap-6"
                    htmlFor={paymentCondition.SK_CONDICAO_DE_PAGAMENTO}
                >
                    <div className="self-center">
                        {onRemove ? (
                            <button type="button" onClick={onRemove}>
                                <X size={14} className="text-red-500" />
                            </button>
                        ) : (
                            <Checkbox
                                id={paymentCondition.SK_CONDICAO_DE_PAGAMENTO}
                                onCheckedChange={() => {
                                    onSelect(paymentCondition)
                                }}
                                checked={checked}
                            />
                        )}
                    </div>
                    <div>
                        <p className="text-sm text-neutral-400">Código</p>
                        <p>{paymentCondition.SK_CONDICAO_DE_PAGAMENTO}</p>
                    </div>
                    <div>
                        <p className="text-sm text-neutral-400">Descrição</p>
                        <p>{paymentCondition.DS_CONDICAO_DE_PAGAMENTO}</p>
                    </div>
                </label>

                {checked && (
                    <div
                        className={cn(
                            'flex flex-col items-center self-center gap-1 ml-auto cursor-pointer'
                        )}
                        onClick={() =>
                            onChangePreference(
                                paymentCondition.SK_CONDICAO_DE_PAGAMENTO
                            )
                        }
                    >
                        <p
                            className={cn(
                                'text-xs text-center ',
                                paymentCondition.DD_PREFERIDA === 1
                                    ? 'text-yellow-500 '
                                    : 'text-neutral-400'
                            )}
                        >
                            Preferência
                        </p>
                        <Star
                            size={14}
                            className={cn(
                                paymentCondition.DD_PREFERIDA === 1
                                    ? 'text-yellow-500 '
                                    : 'text-neutral-400'
                            )}
                        />
                    </div>
                )}
            </div>
        </li>
    )
}

export default PaymentConditionItem
