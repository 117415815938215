import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { useTable } from '@/hooks/useTable'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { Attachment } from '@/types/Attachments'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import RemoveSupplierDialog from './components/RemoveAttachmentsDialog'
import useDisclosure from '@/hooks/useDisclosure'
import { quotationDetailStateSelector } from '@/store/quotationDetailSlice'
import { useBaseStore } from '@/store'
import { isAxiosError } from '@/api/business'
import TopMessage from '@/components/TopMessage'
import AttachmentsActions from './components/AttachmentsActions'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { TypographyMuted } from '@/components/ui/typography'
import { Button } from '@/components/ui/button'
import { Download, File, Loader2 } from 'lucide-react'
import { useMutation } from '@tanstack/react-query'
import { downloadFile } from '@/api/business/arquivos'
import { toast } from 'sonner'
import { getFileSize } from '@/utils/file'

type AttachmentsTable = TableData<Attachment>

const columnHelper = createColumnHelper<Attachment>()

const AttachmentsSection = () => {
    const { id } = useParams()
    const { selectedRows, setSelectedRows } = useTable()
    const { selectedRoundId } = useBaseStore(quotationDetailStateSelector)

    const {
        data: quotation,
        isPending,
        isFetching,
        isError,
        error,
    } = useQuotationByIdQuery({
        id: Number(id),
    })

    const {
        isOpen: isRemoveSupplierDialogOpen,
        onOpen: onRemoveSupplierDialogOpen,
        onClose: onRemoveSupplierDialogClose,
    } = useDisclosure()

    const data = useMemo(() => quotation?.ANEXOS || [], [quotation])

    const { mutate } = useMutation({
        mutationFn: downloadFile,
        onError: (err) => {
            let errorMessage =
                'Não foi possível baixar o arquivo. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast('Erro ao baixar o arquivo', {
                description: errorMessage,
            })
        },
    })

    const selectedAttachments = useMemo(
        () =>
            data.filter((attachment) =>
                Object.keys(selectedRows).includes(
                    attachment.SK_COTACAO_X_ARQUIVOS.toString()
                )
            ),
        [data, selectedRows, selectedRoundId]
    )

    const columns = useMemo(
        () => [
            columnHelper.accessor('ARQUIVO', {
                id: 'ARQUIVO',
                header: 'Arquivo',
                cell: ({ getValue }) => (
                    <div className="flex items-center gap-2">
                        <File size={14} />
                        {getValue()}
                    </div>
                ),
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                size: 600,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('EXTENSAO_DO_ARQUIVO', {
                id: 'EXTENSAO_DO_ARQUIVO',
                header: 'Extensão',
                size: 120,
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor(
                ({ TAMANHO_DO_ARQUIVO }) => getFileSize(TAMANHO_DO_ARQUIVO),
                {
                    id: 'TAMANHO_DO_ARQUIVO',
                    header: 'Tamanho',
                    size: 200,
                    enableColumnFilter: false,
                    enableHiding: false,
                    enablePinning: false,
                    meta: {
                        enableColumnOrdering: false,
                    },
                }
            ),
            columnHelper.display({
                id: 'actions',
                cell: ({ row }) => {
                    const [started, setStarted] = useState(false)

                    return (
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => {
                                setStarted(true)
                                mutate({
                                    fileKey: row.original.CHAVE_DO_ARQUIVO,
                                    fileName: row.original.ARQUIVO,
                                    cb: (e) => {
                                        if (e.progress === 1) {
                                            setStarted(false)
                                        }
                                    },
                                })
                            }}
                        >
                            {started ? (
                                <Loader2 className="text-sky-500 animate-spin" />
                            ) : (
                                <Download className="text-sky-500" />
                            )}
                        </Button>
                    )
                },
                size: 100,
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
        ],
        []
    )

    return (
        <>
            <div className="py-2">
                <div className="mb-4">
                    <AttachmentsActions />
                    {selectedAttachments.length > 0 && (
                        <div className="flex items-center gap-4">
                            <TypographyMuted>
                                {selectedAttachments.length} arquivos
                                selecionados
                            </TypographyMuted>
                            <div className="flex items-center gap-4">
                                <button
                                    className="text-sm text-muted-foreground hover:underline"
                                    onClick={onRemoveSupplierDialogOpen}
                                >
                                    Remover selecionados
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                {isFetching && !isPending && (
                    <TopMessage
                        text="Atualizando arquivos..."
                        variant="loading"
                    />
                )}
                {data?.length === 0 && !isPending ? (
                    <div className="flex items-center justify-center h-[100px]">
                        <TypographyMuted>Cotação sem arquivo</TypographyMuted>
                    </div>
                ) : (
                    <div className="flex flex-col flex-1">
                        <Table<AttachmentsTable>
                            data={data}
                            columns={columns}
                            isLoading={isPending}
                            isError={isError}
                            errorMessage={
                                isError && isAxiosError(error)
                                    ? error?.response?.data.message
                                    : 'Erro ao carregar dados'
                            }
                            getRowId={(row) =>
                                row.SK_COTACAO_X_ARQUIVOS.toString()
                            }
                            tableState={{ rowSelection: selectedRows }}
                            onRowSelectionChange={setSelectedRows}
                            enableRowSelection
                            meta={{
                                layout: 'stretch',
                                cell: {
                                    className: 'border-none py-4 border-b',
                                },
                                header: {
                                    className:
                                        'border-r-0 border-t-0 group-first-of-type:border-l-0 border-b py-1.5',
                                },
                            }}
                            defaultColumn={{
                                cell: ({ getValue }) => (
                                    <DefaultColumn>
                                        {getValue() as string}
                                    </DefaultColumn>
                                ),
                            }}
                        />
                    </div>
                )}
            </div>
            <RemoveSupplierDialog
                isOpen={isRemoveSupplierDialogOpen}
                attachments={selectedAttachments}
                onClose={onRemoveSupplierDialogClose}
                onSelectRows={setSelectedRows}
            />
        </>
    )
}

export default AttachmentsSection
