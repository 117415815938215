import { Skeleton } from '@/components/ui/skeleton'

interface PaymentConditionItemSkeletonProps {
    quantity: number
}

const PaymentConditionItemSkeleton = ({
    quantity,
}: PaymentConditionItemSkeletonProps) => {
    return (
        <ul>
            {Array.from({ length: quantity }).map((_, idx) => (
                <li
                    className="flex items-center gap-4 px-4 py-2 hover:bg-neutral-100"
                    key={idx}
                >
                    <div>
                        <Skeleton className="w-5 h-5" />
                    </div>
                    <div className="w-[60px] h-full space-y-1">
                        <Skeleton className="w-[70%] h-4" />
                        <Skeleton className="w-full h-6" />
                    </div>
                    <div className="w-[150px] h-full space-y-1">
                        <Skeleton className="w-[70%] h-4" />
                        <Skeleton className="w-full h-5" />
                    </div>
                </li>
            ))}
        </ul>
    )
}

export default PaymentConditionItemSkeleton
