import useDisclosure from '@/hooks/useDisclosure'
import { cn } from '@/lib/utils'
import { CheckCircle } from 'lucide-react'
import { useQuotationMapQuery } from '@/queries/useQuotatioMapQuery'
import { useNavigate, useParams } from 'react-router-dom'
import EndQuotationResponseDialog from '@/pages/QuotationEnd/components/EndQuotationResponseDialog'
import { Button } from '@/components/ui/button'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'

const EndQuotationButton = () => {
    const {
        isOpen: isEndQuotationResponseDialogOpen,
        onClose: onEndQuotationResponseDialogClose,
    } = useDisclosure()
    const { id } = useParams()
    const navigate = useNavigate()

    const { isLoading, isError } = useQuotationMapQuery({ id: Number(id) })
    const { data } = useQuotationByIdQuery({ id: Number(id) })

    const disabled =
        isLoading ||
        isError ||
        data?.DD_COTACAO_FINALIZADA === 1 ||
        data?.DD_CANCELADA === 1

    const onClick = () => navigate('finalizar-cotacao')

    return (
        <>
            <Button onClick={onClick} disabled={disabled} variant="outline">
                <CheckCircle
                    size={14}
                    className={cn(
                        disabled ? 'text-neutral-500' : 'text-green-500'
                    )}
                />
                Finalizar cotação
            </Button>
            <EndQuotationResponseDialog
                isOpen={isEndQuotationResponseDialogOpen}
                onOpenChange={onEndQuotationResponseDialogClose}
            />
        </>
    )
}

export default EndQuotationButton
