import {
    Menubar,
    MenubarContent,
    MenubarMenu,
    MenubarRadioGroup,
    MenubarRadioItem,
    MenubarSeparator,
    MenubarSub,
    MenubarSubContent,
    MenubarSubTrigger,
    MenubarTrigger,
} from '@/components/ui/menubar'
import { TypographyMuted } from '@/components/ui/typography'
import PublishItem from './components/PublishItem'
import DeleteItem from './components/DeleteButton'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DeleteQuotationDialog from '../Dialogs/DeleteQuotationDialog'
import PublishDialog from '../Dialogs/PublishDialog'
import EditItem from './components/EditItem'
import EditDetailsDialog from '../Dialogs/EditDetailsDialog'
import ChangeDateDialog from '../Dialogs/ChangeDateDialog'
import ChangeDateItem from './components/ChangeDateItem'
import FinishRoundItem from './components/FinishRoundItem'
import FinishRoundDialog from '../Dialogs/FinishRoundDialog'
import FinishQuotation from './components/FinishQuotationItem'
import NewRoundItem from './components/NewRoundtem'
import DeclineQuotationMapItem from './components/DeclineQuotationMapItem'
import DeclineQuotationMapDialog from '../Dialogs/DeclineQuotationMapDialog'
import ApproveQuotationMapDialog from '../Dialogs/ApproveQuotationMapDialog'
import ApproveQuotationMapItem from './components/ApproveQuotationMapItem'
import { useBaseStore } from '@/store'
import {
    quotationDetailActionsSelector,
    quotationDetailStateSelector,
} from '@/store/quotationDetailSlice'
import NewRoundDialog from '../Dialogs/NewRoundDialog'

type ModalIds =
    | 'delete'
    | 'publish'
    | 'change-expiration-date'
    | 'finish-round'
    | 'edit'
    | 'finish-quotation'
    | 'new-round'
    | 'decline-quotation-map'
    | 'approve-quotation-map'

type ModalState = {
    modalId: ModalIds | null
    isOpen: boolean
}

const ActionsMenuBar = () => {
    const navigate = useNavigate()

    const { rounds, selectedRoundId } = useBaseStore(
        quotationDetailStateSelector
    )

    const { onSelectRound } = useBaseStore(quotationDetailActionsSelector)

    const [modal, setModal] = useState<ModalState>({
        modalId: null,
        isOpen: false,
    })

    const onOpenModal = (modalId: ModalIds) => {
        setModal({ modalId, isOpen: true })
    }

    const onCloseModal = () => {
        setModal({ modalId: null, isOpen: false })
    }

    return (
        <>
            <div className="flex items-center gap-2">
                <TypographyMuted>Ações: </TypographyMuted>
                <Menubar className="w-fit">
                    <MenubarMenu>
                        <MenubarTrigger>Cotação</MenubarTrigger>
                        <MenubarContent align="end">
                            <PublishItem
                                onClick={() => onOpenModal('publish')}
                            />
                            <EditItem onClick={() => onOpenModal('edit')} />
                            <FinishQuotation
                                onClick={() =>
                                    navigate('mapa/finalizar-cotacao')
                                }
                            />
                            <MenubarSeparator />
                            <DeleteItem onClick={() => onOpenModal('delete')} />
                        </MenubarContent>
                    </MenubarMenu>
                    <MenubarMenu>
                        <MenubarTrigger>Rodadas</MenubarTrigger>
                        <MenubarContent align="end">
                            <NewRoundItem
                                onClick={() => onOpenModal('new-round')}
                            />
                            <ChangeDateItem
                                onClick={() =>
                                    onOpenModal('change-expiration-date')
                                }
                            />
                            <FinishRoundItem
                                onClick={() => onOpenModal('finish-round')}
                            />
                            <MenubarSub>
                                <MenubarSubTrigger>Rodadas</MenubarSubTrigger>
                                <MenubarSubContent>
                                    <MenubarRadioGroup
                                        value={selectedRoundId?.toString()}
                                    >
                                        {rounds.map((round) => {
                                            return (
                                                <MenubarRadioItem
                                                    value={round.roundId.toString()}
                                                    key={round.roundId}
                                                    onClick={() =>
                                                        onSelectRound(
                                                            round.roundId
                                                        )
                                                    }
                                                >
                                                    {round.round}ª rodada
                                                </MenubarRadioItem>
                                            )
                                        })}
                                    </MenubarRadioGroup>
                                </MenubarSubContent>
                            </MenubarSub>
                        </MenubarContent>
                    </MenubarMenu>
                    <MenubarMenu>
                        <MenubarTrigger>Mapa</MenubarTrigger>
                        <MenubarContent align="end">
                            <MenubarSub>
                                <MenubarSubTrigger>Aprovação</MenubarSubTrigger>
                                <MenubarSubContent>
                                    <ApproveQuotationMapItem
                                        onClick={() =>
                                            onOpenModal('approve-quotation-map')
                                        }
                                    />
                                    <DeclineQuotationMapItem
                                        onClick={() =>
                                            onOpenModal('decline-quotation-map')
                                        }
                                    />
                                </MenubarSubContent>
                            </MenubarSub>
                        </MenubarContent>
                    </MenubarMenu>
                </Menubar>
            </div>
            {modal.modalId === 'delete' && (
                <DeleteQuotationDialog
                    isOpen={modal.isOpen}
                    onClose={onCloseModal}
                />
            )}
            {modal.modalId === 'publish' && (
                <PublishDialog isOpen={modal.isOpen} onClose={onCloseModal} />
            )}
            {modal.modalId === 'edit' && (
                <EditDetailsDialog
                    isOpen={modal.isOpen}
                    onClose={onCloseModal}
                />
            )}
            {modal.modalId === 'change-expiration-date' && (
                <ChangeDateDialog
                    isOpen={modal.isOpen}
                    onClose={onCloseModal}
                />
            )}
            {modal.modalId === 'finish-round' && (
                <FinishRoundDialog
                    isOpen={modal.isOpen}
                    onClose={onCloseModal}
                />
            )}
            {modal.modalId === 'decline-quotation-map' && (
                <DeclineQuotationMapDialog
                    isOpen={modal.isOpen}
                    onClose={onCloseModal}
                />
            )}
            {modal.modalId === 'approve-quotation-map' && (
                <ApproveQuotationMapDialog
                    isOpen={modal.isOpen}
                    onClose={onCloseModal}
                />
            )}
            {modal.modalId === 'new-round' && (
                <NewRoundDialog isOpen={modal.isOpen} onClose={onCloseModal} />
            )}
        </>
    )
}

export default ActionsMenuBar
