import { isAxiosError } from '@/api/business'
import { downloadFile } from '@/api/business/arquivos'
import { Button } from '@/components/ui/button'
import File from '@/components/File'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover'
import { useToast } from '@/components/ui/use-toast'
import useDisclosure from '@/hooks/useDisclosure'
import { useQuotationSupplierAttachmentsQuery } from '@/queries/useSupplierAttachmentsQuery'
import { useMutation } from '@tanstack/react-query'
import { Paperclip } from 'lucide-react'

interface AttachmentsPopoverProps {
    enabled: boolean
    quotationId: number
    supplierId: string
}

const AttachmentsPopover = ({
    enabled,
    quotationId,
    supplierId,
}: AttachmentsPopoverProps) => {
    const { isOpen, onOpen, onOpenChange } = useDisclosure()
    const { toast } = useToast()

    const { mutate: mutateDownload } = useMutation({
        mutationFn: downloadFile,
        onError: (err) => {
            let errorMessage =
                'Não foi possível faer download do arquivo. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao deletar cotação',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const { data, isPending, isError } = useQuotationSupplierAttachmentsQuery({
        enabled,
        quotationId,
        supplierId,
    })

    return (
        <Popover open={isOpen} onOpenChange={onOpenChange}>
            <PopoverTrigger asChild>
                <Button
                    onClick={onOpen}
                    disabled={isPending || isError}
                    variant="outline"
                    className="gap-2"
                >
                    <Paperclip size={14} />
                    <span>{data?.length}</span>
                    <span>
                        anexo
                        {data && (data?.length > 1 || data.length == 0)
                            ? 's'
                            : null}
                    </span>
                </Button>
            </PopoverTrigger>
            <PopoverContent align="start" className="rounded-lg p-2 w-[300px]">
                <div className="p-2">
                    <div className="flex items-center justify-between mb-4">
                        <p className="text-sm text-neutral-700">Anexos</p>
                    </div>
                    <div className="space-y-1.5">
                        {data && data?.length > 0 ? (
                            data?.map((file) => (
                                <File
                                    key={file.SK_COTACAO_X_ARQUIVOS}
                                    type="attachment"
                                    file={file}
                                    onDownload={() =>
                                        mutateDownload({
                                            fileKey: file.CHAVE_DO_ARQUIVO,
                                            fileName: file.ARQUIVO,
                                            cb: (e) => console.log(e),
                                        })
                                    }
                                />
                            ))
                        ) : (
                            <div className="p-2 border rounded-md">
                                <p className="text-sm text-center">
                                    Nenhum arquivo anexado na cotação
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    )
}

export default AttachmentsPopover
