import { isAxiosError } from '@/api/business'
import { SuppliersToQuotation } from '@/api/business/cotacoes/type'
import { PurchaseRequestFlat } from '@/api/business/solicitacaoDeCompra/type'
import { Button } from '@/components/ui/button'
import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogDescription,
} from '@/components/ui/dialog'
import { useTable } from '@/hooks/useTable'
import { useSuppliersToQuotationQuery } from '@/queries/useQuotationQuery'
import { Filter, Operators } from '@/types/Filters'
import { RowSelectionState, createColumnHelper } from '@tanstack/react-table'
import { SparkleIcon } from 'lucide-react'
import { useMemo, useState } from 'react'
import DefaultColumn from '../Table/components/DefaultColumn'

interface SuppliersToQuotationProps {
    isOpen: boolean
    dialogState?: 'loading' | 'disabled'
    rowSelection: {
        selectedRows: RowSelectionState
        onSelectSuppliers: (
            selectedRows: RowSelectionState,
            suppliers: SuppliersToQuotation[]
        ) => void
    }
    items?: Pick<PurchaseRequestFlat, 'SK_EMPRESA' | 'NK_PRODUTO'>[]
    onClose: () => void
    onCloseWithAction?: () => void
}

type SuppliersTable = TableData<SuppliersToQuotation>

const columnHelper = createColumnHelper<SuppliersToQuotation>()

const SuppliersToQuotationDialog = ({
    isOpen,
    rowSelection: { selectedRows, onSelectSuppliers },
    items = [],
    dialogState,
    onClose,
    onCloseWithAction,
}: SuppliersToQuotationProps) => {
    const [fieldFilters, setFieldFilters] = useState<
        Filter<SuppliersToQuotation>[]
    >([])

    const {
        pageIndex,
        pageSize,
        columnFilters,
        onColumnFiltersChange,
        onPaginationChange,
    } = useTable<SuppliersToQuotation>()

    const { data, isPending, isFetching, isError, error } =
        useSuppliersToQuotationQuery({
            currentPage: pageIndex,
            perPage: pageSize,
            filters: fieldFilters,
            enabled: isOpen,
            products: items.map((item) => ({
                companyId: item.SK_EMPRESA,
                productNk: item.NK_PRODUTO,
            })),
        })

    const dataMemo = useMemo(() => data?.DADOS || [], [data])

    const columnsMemo = useMemo(
        () => [
            columnHelper.display({
                id: 'Status',
                cell: ({ row }) => {
                    const { DD_BLOQUEADO, DD_SUGESTAO } = row.original
                    return DD_BLOQUEADO === 1 ? (
                        <span className="px-2 text-xs text-red-500 rounded-md bg-red-50">
                            Bloqueado
                        </span>
                    ) : DD_SUGESTAO ? (
                        <span className="flex items-center gap-1 px-2 text-xs text-yellow-600 bg-yellow-100 rounded-md">
                            <SparkleIcon size={14} />
                            Sugestão
                        </span>
                    ) : null
                },
                size: 100,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('SK_FORNECEDOR', {
                header: 'Cod fornecedor',
                size: 100,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DD_LOJA', {
                header: 'Loja',
                size: 80,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DD_NOME_FANTASIA', {
                header: 'Nome fantasia',
                size: 150,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DD_RAZAO_SOCIAL', {
                header: 'Razão social',
                size: 400,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DD_UF', {
                header: 'UF',
                size: 100,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DS_EMAIL', {
                header: 'Email',
                size: 150,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DD_CNPJ', {
                header: 'CNPJ',
                size: 150,
                meta: {
                    enableColumnOrdering: false,
                },
            }),

            columnHelper.accessor('DD_ORIGEM_DADO', {
                header: 'Origem',
                meta: {
                    enableColumnOrdering: false,
                },
            }),
        ],
        []
    )

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="flex flex-col max-w-[1200px] h-3/4 max-h-[900px]">
                <DialogHeader>
                    <DialogTitle>Fornecedores</DialogTitle>
                    <DialogDescription>
                        Selecione os fornecedores para serem adicionados na
                        cotação
                    </DialogDescription>
                </DialogHeader>
                <div className="flex-1 overflow-hidden">
                    <Table<SuppliersTable>
                        columns={columnsMemo}
                        data={dataMemo}
                        isLoading={isPending}
                        isError={isError}
                        tableState={{
                            rowSelection: selectedRows,
                            columnFilters,
                            pagination: {
                                pageIndex,
                                pageSize,
                            },
                        }}
                        errorMessage={
                            isError && isAxiosError(error)
                                ? error.response?.data.message
                                : undefined
                        }
                        getRowId={(row) => row.SK_FORNECEDOR.toString()}
                        isFetching={isFetching}
                        meta={{
                            layout: 'stretch',
                        }}
                        enableRowSelection={(row) =>
                            row.original.DD_BLOQUEADO !== 1
                        }
                        pagination={{
                            pageSize,
                            totalItems: data?.TOTAL || 0,
                            totalPages: data?.TOTAL_DE_PAGINAS || 0,
                        }}
                        onColumnFiltersChange={(columnFilterState) => {
                            if (columnFilterState) {
                                setFieldFilters([
                                    {
                                        AND: columnFilterState.map((field) => {
                                            const fieldId =
                                                field.id as keyof SuppliersToQuotation
                                            return {
                                                field: fieldId,
                                                operator: Operators.like,
                                                value: field.value as string,
                                            }
                                        }),
                                    },
                                ])
                            } else {
                                setFieldFilters([])
                            }
                            onColumnFiltersChange(columnFilterState)
                        }}
                        onPaginationChange={onPaginationChange}
                        onRowSelectionChange={(rowSelection) =>
                            onSelectSuppliers(
                                rowSelection,
                                dataMemo.filter((supplier) =>
                                    Object.keys(rowSelection).includes(
                                        supplier.SK_FORNECEDOR.toString()
                                    )
                                )
                            )
                        }
                        defaultColumn={{
                            cell: ({ getValue }) => (
                                <DefaultColumn>
                                    {getValue() as string}
                                </DefaultColumn>
                            ),
                        }}
                    />
                </div>
                <DialogFooter>
                    <Button
                        disabled={
                            dialogState === 'disabled' ||
                            dialogState === 'loading'
                        }
                        onClick={
                            onCloseWithAction ? onCloseWithAction : onClose
                        }
                    >
                        Confirmar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default SuppliersToQuotationDialog
