import { GeneratePurchaseOrderSucess } from '@/api/business/cotacoes/type'
import Table from '@/components/Table'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { TableData } from '@/components/Table/type'
import { TypographyH2 } from '@/components/ui/typography'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'

type SucessTableProps = {
    data: GeneratePurchaseOrderSucess[]
}

type SuccessTableData = TableData<GeneratePurchaseOrderSucess>

const columnHelper = createColumnHelper<GeneratePurchaseOrderSucess>()

const SuccessTable = ({ data }: SucessTableProps) => {
    const columns = useMemo(
        () => [
            columnHelper.accessor('SK_EMPRESA', {
                id: 'SK_EMPRESA',
                header: 'Empresa',
            }),
            columnHelper.accessor('NK_PEDIDO', {
                id: 'NK_PEDIDO',
                header: 'PC',
            }),
            columnHelper.accessor('NK_ITEM_PEDIDO_DE_COMPRAS', {
                id: 'NK_ITEM_PEDIDO_DE_COMPRAS',
                header: 'Item PC',
            }),
            columnHelper.accessor('NK_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_SOLICITACAO_DE_COMPRAS',
                header: 'SC',
            }),
            columnHelper.accessor('NK_ITEM_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_ITEM_SOLICITACAO_DE_COMPRAS',
                header: 'Item SC',
            }),
            columnHelper.accessor('DS_PRODUTO', {
                id: 'DS_PRODUTO',
                header: 'Produto',
            }),
            columnHelper.accessor('OBSERVACAO', {
                id: 'OBSERVACAO',
                header: 'Observação',
            }),
        ],
        []
    )

    return (
        <div className="space-y-4">
            <TypographyH2>Pedidos gerados</TypographyH2>
            <Table<SuccessTableData>
                data={data}
                columns={columns}
                meta={{
                    layout: 'stretch',
                }}
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultColumn>{getValue() as string}</DefaultColumn>
                    ),
                }}
            />
        </div>
    )
}

export default SuccessTable
