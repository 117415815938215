import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { usePaginatedProductsQuery } from '@/queries/useProductsQuery'
import { Product } from '@/types/Products'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import DadosMestreHeader from '../components/DadosMestreHeader'
import DadosMestreHeaderActions from '../components/DadosMestreHeaderActions'
import { Filter, Operators } from '@/types/Filters'
import { useProductSpreadsheet } from './useProductSpreadsheet'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { useTable } from '@/hooks/useTable'
import { currencyFormat } from '@/utils/stringFormatter'

export type ProductTable = TableData<Product>

const columnHelper = createColumnHelper<Product>()

const DadosMestreProdutos = () => {
    const {
        table,
        selectedRows,
        pageIndex,
        pageSize,
        columnFilters,
        onPaginationChange,
        onColumnFiltersChange,
        onClickMultiDelete,
        setTable,
    } = useTable<Product>()

    const { onDownload } = useProductSpreadsheet()
    const [fieldFilters, setFieldFilters] = useState<Filter<Product>[]>([])

    const { data, isFetching, isPending, refetch } = usePaginatedProductsQuery({
        currentPage: pageIndex,
        perPage: pageSize,
        filters: fieldFilters,
        enabled: true,
    })

    const memoData = useMemo(() => (data ? data.DADOS : []), [data])
    const memoColumns = useMemo(
        () => [
            columnHelper.accessor('NK_PRODUTO', {
                id: 'NK_PRODUTO',
                header: 'Cod produto',
                size: 100,
            }),
            columnHelper.accessor('NK_GRUPO_DE_EMPRESA', {
                id: 'NK_GRUPO_DE_EMPRESA',
                header: 'Cod grupo de empresa',
                size: 100,
            }),
            columnHelper.accessor('DS_PRODUTO', {
                id: 'DS_PRODUTO',
                header: 'Produto',
                size: 300,
            }),
            columnHelper.accessor('DS_GRUPO_DE_PRODUTOS', {
                id: 'DS_GRUPO_DE_PRODUTOS',
                header: 'Grupo de Produto',
                size: 200,
            }),
            columnHelper.accessor('DD_UM', {
                id: 'DD_UM',
                header: 'UM',
                size: 100,
            }),
            columnHelper.accessor(
                ({ ULTIMO_PRECO }) => currencyFormat(Number(ULTIMO_PRECO) || 0),
                {
                    id: 'ULTIMO_PRECO',
                    header: 'Último preço',
                    size: 130,
                }
            ),
            columnHelper.accessor('DD_NCM', {
                id: 'DD_NCM',
                header: 'NCM',
                size: 100,
            }),
        ],
        []
    )

    return (
        <div className="w-full h-full p-4 overflow-hidden">
            <Table<ProductTable>
                data={memoData}
                columns={memoColumns}
                getRowId={(row) => row.SK_PRODUTO.toString()}
                isLoading={isPending}
                isFetching={isFetching}
                tableState={{
                    columnFilters,
                    pagination: {
                        pageIndex,
                        pageSize,
                    },
                }}
                getTableInstance={(table) => setTable(table)}
                onPaginationChange={onPaginationChange}
                onColumnFiltersChange={(columnFilter) => {
                    if (columnFilter.length > 0) {
                        const filters: Filter<Product>[] = [
                            {
                                AND: columnFilter.map((filter) => {
                                    return {
                                        field: filter.id as keyof Product,
                                        operator: Operators.like,
                                        value: filter.value as string,
                                    }
                                }),
                            },
                        ]
                        setFieldFilters(filters)
                    } else {
                        setFieldFilters([])
                    }
                    onColumnFiltersChange(columnFilter)
                }}
                pagination={{
                    pageSize,
                    totalPages: data?.TOTAL_DE_PAGINAS || 0,
                    totalItems: data?.TOTAL || 0,
                }}
                tableHeader={
                    <DadosMestreHeader
                        title={`Produtos`}
                        onDelete={onClickMultiDelete}
                        selectedRows={
                            table
                                ?.getFilteredRowModel()
                                .flatRows.filter((row) => selectedRows[row.id])
                                .length
                        }
                    />
                }
                tableActions={
                    <DadosMestreHeaderActions
                        onDownload={() => table && onDownload(table)}
                        onRefech={refetch}
                        layout
                        tableId="DADOS_MESTRE_PRODUTOS"
                    />
                }
                meta={{
                    layout: 'stretch',
                }}
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultColumn>{getValue() as string}</DefaultColumn>
                    ),
                }}
            />
        </div>
    )
}

export default DadosMestreProdutos
