import { fetchApprovalAttachments } from '@/api/business/arquivos'
import { useQuery } from '@tanstack/react-query'

export const attachmentsKeys = {
    all: ['attachments'] as const,
    list: (quotationId: number) =>
        [...attachmentsKeys.all, quotationId, 'approval'] as const,
}

type UseApprovalAttachmentsQueryProps = {
    quotationId: number
}

export const useApprovalAttachmentsQuery = ({
    quotationId,
}: UseApprovalAttachmentsQueryProps) => {
    return useQuery({
        queryFn: () => fetchApprovalAttachments({ quotationId }),
        queryKey: attachmentsKeys.list(quotationId),
    })
}
