import Table from '@/components/Table'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { TableData } from '@/components/Table/type'
import { publishSchema } from '@/schemas/quotationSchema'
import { QuotationProduct } from '@/types/Products'
import { QuotationSupplier } from '@/types/Suppliers'
import { createColumnHelper } from '@tanstack/react-table'
import { AlertCircleIcon } from 'lucide-react'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { z } from 'zod'

interface PublishDialogTableProps {
    products: QuotationProduct[]
    suppliers: QuotationSupplier[]
}

type PublishTableData = Partial<QuotationProduct & QuotationSupplier>
type PublishTable = TableData<PublishTableData>

const columnHelper = createColumnHelper<PublishTableData>()

const PublishDialogTable = ({
    products,
    suppliers,
}: PublishDialogTableProps) => {
    const form = useFormContext<z.infer<typeof publishSchema>>()
    const data: PublishTableData[] = useMemo(
        () =>
            suppliers.length > 0
                ? suppliers.flatMap((supplier) => {
                      return products.length > 0
                          ? products.map((product) => {
                                return {
                                    ...product,
                                    ...supplier,
                                }
                            })
                          : supplier
                  })
                : products.length > 0
                ? products.flatMap((product) => {
                      return suppliers.length > 0
                          ? suppliers.map((supplier) => {
                                return {
                                    ...product,
                                    ...supplier,
                                }
                            })
                          : product
                  })
                : [],
        [products, suppliers]
    )

    const columns = useMemo(
        () => [
            columnHelper.accessor('NK_SOLICITACAO_DE_COMPRAS', {
                header: 'SC',
                size: 80,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
                enableColumnFilter: false,
            }),
            columnHelper.accessor('NK_PRODUTO', {
                header: 'Cod produto',
                size: 150,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
                enableColumnFilter: false,
            }),
            columnHelper.accessor('DS_PRODUTO', {
                header: 'Produto',
                size: 400,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
                enableColumnFilter: false,
            }),
            columnHelper.accessor('SK_FORNECEDOR', {
                header: 'Cod fornecedor',
                size: 150,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
                enableColumnFilter: false,
            }),
            columnHelper.accessor('DD_RAZAO_SOCIAL', {
                header: 'Razão Social',
                size: 200,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
                enableColumnFilter: false,
            }),
            columnHelper.accessor('DD_NOME_FANTASIA', {
                header: 'Nome fantasia',
                size: 200,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
                enableColumnFilter: false,
            }),
            columnHelper.accessor('DS_EMAIL', {
                header: 'Email',
                size: 200,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
                enableColumnFilter: false,
            }),
        ],
        []
    )

    return (
        <>
            <div>
                {suppliers.length === 0 && (
                    <div className="flex items-center gap-2 text-amber-600">
                        <AlertCircleIcon size={14} />
                        <p> Nenhum fornecedor selecionado</p>
                    </div>
                )}
                {products.length === 0 && (
                    <div className="flex items-center gap-2 text-amber-600">
                        <AlertCircleIcon size={14} />
                        <p> Nenhum produto selecionado</p>
                    </div>
                )}
                {form.formState.errors.suppliers?.message && (
                    <p className="text-sm text-red-500">
                        {form.formState.errors.suppliers.message}
                    </p>
                )}
                {form.formState.errors.products?.message && (
                    <p className="text-sm text-red-500">
                        {form.formState.errors.products.message}
                    </p>
                )}
            </div>
            <Table<PublishTable>
                data={data}
                columns={columns}
                meta={{
                    header: {
                        className: 'p-4',
                    },
                }}
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultColumn>{getValue() as string}</DefaultColumn>
                    ),
                }}
            />
        </>
    )
}

export default PublishDialogTable
