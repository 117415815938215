import instance from '@/api/business'
import { ShippingCompany } from '@/types/ShippingCompany'

const BASE_URL = '/transportadoras'

export const fetchShippingCompanies = async () => {
    const response = await instance.post<ShippingCompany[]>(BASE_URL)

    return response.data
}
