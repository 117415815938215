import { Label } from '@/components/ui/label'
import CompaniesSelect from '@/pages/SupplierMap/components/EditMode/components/CompaniesSelect'
import CurrencySelect from '@/pages/SupplierMap/components/EditMode/components/CurrencySelect'
import FreightSelect from '@/pages/SupplierMap/components/EditMode/components/FreightSelect'
import PaymentConditionCombobox from '@/pages/SupplierMap/components/EditMode/components/PaymentConditionCombobox'
import ShippingDialog from '../../../ShippingDialog'

const TableHeader = () => {
    return (
        <div className="flex items-end gap-4">
            <CompaniesSelect />
            <div className="space-y-1.5">
                <Label>Selecionar condição de pagamento</Label>
                <PaymentConditionCombobox />
            </div>
            <CurrencySelect />
            <ShippingDialog />
            <FreightSelect />
        </div>
    )
}

export default TableHeader
