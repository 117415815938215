import { Link } from 'react-router-dom'
import SectionPurchaseRequestFilters from './components/SectionPurchaseRequestFilters'
import SectionPurchaseRequestList from './components/SectionPurchaseRequestList'
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import { getSelectedItemsSelector } from '@/store/quotationPurchaseRequestSlice'
import { useBaseStore } from '@/store'
import { cn } from '@/lib/utils'
import { ChevronsRight } from 'lucide-react'
import { Separator } from '@/components/ui/separator'

const CotacaoCreatePurchaseRequest = () => {
    const selectedItems = useBaseStore(getSelectedItemsSelector)

    return (
        <div className="flex flex-col h-full overflow-auto">
            <div className="flex items-center justify-between px-4 py-2">
                <Breadcrumb>
                    <BreadcrumbList>
                        <BreadcrumbItem>
                            <BreadcrumbLink asChild>
                                <Link
                                    className="hover:underline"
                                    to="/cotacoes"
                                >
                                    Cotações
                                </Link>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbPage>
                                Solicitações de compra
                            </BreadcrumbPage>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>
                <Link
                    to="gerar"
                    className={cn(
                        'border flex items-center gap-2 p-2 text-sm rounded-lg',
                        selectedItems.length === 0
                            ? 'bg-neutral-100 text-neutral-500 cursor-not-allowed'
                            : 'hover:bg-slate-100 border-slate-200 hover:text-slate-600'
                    )}
                    onClick={(e) => {
                        selectedItems.length === 0
                            ? e.preventDefault()
                            : undefined
                    }}
                >
                    <ChevronsRight
                        size={16}
                        className={
                            selectedItems.length === 0
                                ? 'text-neutral-500'
                                : 'text-sky-600'
                        }
                    />
                    Gerar cotação
                </Link>
            </div>
            <Separator />
            <SectionPurchaseRequestFilters />
            <div className="flex flex-col flex-1 h-screen p-4">
                <SectionPurchaseRequestList />
            </div>
        </div>
    )
}

export default CotacaoCreatePurchaseRequest
