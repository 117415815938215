import { RowSelectionState } from '@tanstack/react-table'
import { ImmerStateCreator, Store } from '../type'
import {
    PurchaseOrderDialogState,
    QuotationMapFlatState,
    QuotationMapSlice,
    QuotationMapState,
    QuotationMapSupplierNormalizedState,
    QuotationMapTableVisibility,
    QuotationMapValuesState,
    SupplierDialogState,
} from './type'
import { isAfter, isValid } from 'date-fns'
import { QuotationMapValues } from '@/api/business/cotacoes/type'

const purchaseOrderDialogInitialState: PurchaseOrderDialogState = {
    updatedValues: [],
}
const supplierDialogInitialState: SupplierDialogState = {
    selectedCompanyId: '',
    supplierId: '',
    selectedCurrencyId: {},
    selectedFreights: {},
    selectedPaymentConditionId: {},
    supplierValues: [],
    updatedValues: [],
}

const quotationMapTableVisibilityState: QuotationMapTableVisibility = {
    fields: {
        PRECO: true,
        PRECO_TOTAL: true,
        PRECO_IMPOSTO: true,
        PRECO_TOTAL_IMPOSTO: true,
        // PRECO_COM_FRETE: false,
        // PRECO_TOTAL_COM_FRETE: false,
        PREVISAO_DE_ENTREGA: true,
    } as Record<keyof QuotationMapValuesState, boolean>,
    rounds: {},
    suppliers: {},
}

const initialState: QuotationMapState = {
    quotationMap: {
        EMPRESAS: [],
        FORNECEDORES: {
            entities: {},
            ids: [],
        },
        PRODUTOS: {
            entities: {},
            ids: [],
        },
        RODADAS: [],
        VALORES: [],
        flatState: [],
    },
    ranking: {},
    selectedWinners: {},
    selectedNewRoundWinners: {},
    supplierDialog: supplierDialogInitialState,
    purchaseOrderResponse: [],
    purchaseOrderValidateResponse: [],
    quotationMapTableVisibility: quotationMapTableVisibilityState,
    purchaseOrderDialog: purchaseOrderDialogInitialState,
}

interface Totals {
    id: string | number
    companyId: string
    total: number
}

const calculateTotals = (
    products: QuotationMapValues[],
    fieldKey: keyof QuotationMapFlatState,
    companyKey: keyof QuotationMapFlatState
) => {
    return products.reduce((acc, product) => {
        const field = product[fieldKey] as string | number
        const companyId = product[companyKey] as string
        const hasField = acc.find(
            (item) => item.id === field && item.companyId === companyId
        )

        if (hasField) {
            return acc.map((item) =>
                item.id === field && item.companyId === companyId
                    ? {
                          ...item,
                          total: item.total + 1,
                          companyId: item.companyId,
                      }
                    : item
            )
        }

        return [...acc, { id: field, companyId, total: 1 }]
    }, [] as Totals[])
}
const findMaxTotal = (totals: Totals[]) => {
    const max = Math.max(...totals.map((item) => item.total))
    return totals.find((item) => item.total === max)!
}

export const useQuotationMapSlice: ImmerStateCreator<QuotationMapSlice> = (
    set
) => ({
    state: initialState,
    actions: {
        onSetData: (data, quotation) => {
            set((state) => {
                const products = Object.values(data.PRODUTOS.entities)
                const values = data.VALORES

                const normalizeMinMax = (
                    value: number,
                    maxValue: number,
                    _: number
                ) => 1 - value / maxValue

                const productValuesByRound = data.RODADAS.flatMap((round) => {
                    const roundProductsValues = values.filter((value) =>
                        round.FORNECEDOR_RODADAS.includes(
                            value.SK_FORNECEDOR_X_RODADA
                        )
                    )

                    return products.flatMap((product) => {
                        const productValues = roundProductsValues.filter(
                            (value) =>
                                value.SK_COTACAO_X_PRODUTO ===
                                product.SK_COTACAO_X_PRODUTO
                        )

                        const prices = productValues.map(
                            (value) => value.PRECO_IMPOSTO || 0
                        )

                        const deliveryDates = productValues
                            .filter((value) => value.PREVISAO_DE_ENTREGA)
                            .map((value) =>
                                new Date(
                                    value.PREVISAO_DE_ENTREGA as string
                                ).getTime()
                            )

                        const maxRoundPrice = Math.max(...prices)

                        const maxRoundDeliveryDate =
                            deliveryDates.length > 0
                                ? Math.max(...deliveryDates)
                                : new Date().getTime()

                        const minRoundPrice = Math.min(...prices)

                        const minRoundDeliveryDate =
                            deliveryDates.length > 0
                                ? Math.min(...deliveryDates)
                                : new Date().getTime()

                        const scores = productValues.map((value) => {
                            // const weight = 0.5

                            if (
                                !value.PRECO_IMPOSTO &&
                                !value.PREVISAO_DE_ENTREGA
                            ) {
                                return {
                                    ...value,
                                    PRODUTO_SCORE: 0,
                                }
                            }

                            const normalizedPrice = normalizeMinMax(
                                value.PRECO_IMPOSTO || 0,
                                maxRoundPrice,
                                minRoundPrice
                            )

                            const normalizedDeliveryDate =
                                normalizeMinMax(
                                    value.PREVISAO_DE_ENTREGA &&
                                        isValid(
                                            new Date(value.PREVISAO_DE_ENTREGA)
                                        )
                                        ? new Date(
                                              value.PREVISAO_DE_ENTREGA
                                          ).getTime()
                                        : new Date().getTime(),
                                    maxRoundDeliveryDate,
                                    minRoundDeliveryDate
                                ) || 0

                            return {
                                ...value,
                                PRODUTO_SCORE:
                                    (normalizedPrice + normalizedDeliveryDate) /
                                    2,
                            }
                        })

                        const bestScore = Math.max(
                            ...scores.map((value) => value.PRODUTO_SCORE)
                        )

                        return scores.map((value) => {
                            const isWinner =
                                value.PRODUTO_SCORE === bestScore &&
                                value.PRODUTO_SCORE !== 0 &&
                                value.DD_RECUSADO !== 1

                            const isDraw =
                                scores
                                    .map((score) => score.PRODUTO_SCORE)
                                    .filter(
                                        (score) =>
                                            score === value.PRODUTO_SCORE &&
                                            score !== 0
                                    ).length > 1

                            return {
                                ...value,
                                MELHOR_SCORE: isWinner && !isDraw,
                                EMPATE: isDraw,
                            }
                        })
                    })
                })

                const suppliersIds = [
                    ...new Set(
                        productValuesByRound.map(
                            (value) => value.SK_FORNECEDOR_X_RODADA
                        )
                    ),
                ]

                const scoresBySuppliers = suppliersIds.reduce((acc, curr) => {
                    const values = productValuesByRound.filter(
                        (value) =>
                            value.SK_FORNECEDOR_X_RODADA === curr &&
                            value.MELHOR_SCORE
                    )

                    return {
                        ...acc,
                        [curr]: values.length,
                    }
                }, {})

                const suppliersTotals: QuotationMapSupplierNormalizedState = {
                    entities: Object.values(data.FORNECEDORES.entities).reduce(
                        (acc, curr) => {
                            const supplier = curr
                            const supplierRounds = data.RODADAS.filter(
                                (round) =>
                                    round.FORNECEDOR_RODADAS.some((round) =>
                                        supplier.FORNECEDOR_RODADAS.includes(
                                            round
                                        )
                                    )
                            )

                            const rounds = supplierRounds.map(
                                (round) => round.NUMERO_RODADA
                            )

                            const minRound = Math.min(...rounds)
                            const maxRound = Math.max(...rounds)

                            const firstRound = data.RODADAS.find(
                                (round) => round.NUMERO_RODADA === minRound
                            )

                            const lastRound = data.RODADAS.find(
                                (round) => round.NUMERO_RODADA === maxRound
                            )

                            const values = data.VALORES.filter((value) =>
                                supplier.FORNECEDOR_RODADAS.some(
                                    (id) => id === value.SK_FORNECEDOR_X_RODADA
                                )
                            )

                            const supplierKey =
                                supplier.FORNECEDOR_RODADAS.find(
                                    (id) => id in scoresBySuppliers
                                )!

                            const maxScore = Math.max(
                                ...Object.values(scoresBySuppliers as number)
                            )

                            const initialTotal = values
                                .filter((value) =>
                                    firstRound?.FORNECEDOR_RODADAS.some(
                                        (id) =>
                                            id === value.SK_FORNECEDOR_X_RODADA
                                    )
                                )
                                .reduce((acc, value) => {
                                    return (
                                        acc +
                                        (Number(value.PRECO_TOTAL_IMPOSTO) || 0)
                                    )
                                }, 0)

                            const finalTotal = values
                                .filter((value) =>
                                    lastRound?.FORNECEDOR_RODADAS.some(
                                        (id) =>
                                            id === value.SK_FORNECEDOR_X_RODADA
                                    )
                                )
                                .reduce((acc, value) => {
                                    return (
                                        acc +
                                        (Number(value.PRECO_TOTAL_IMPOSTO) || 0)
                                    )
                                }, 0)

                            const saving = initialTotal - finalTotal

                            const isWinner =
                                scoresBySuppliers[supplierKey] === maxScore &&
                                supplier.FORNECEDOR_DECLINADO !== 1 &&
                                supplier.FORNECEDOR_PUBLICADO === 1

                            const isDraw = Object.entries(
                                scoresBySuppliers
                            ).some(
                                ([id, score]) =>
                                    id != supplierKey.toString() &&
                                    score === scoresBySuppliers[supplierKey]
                            )

                            return {
                                ...acc,
                                [curr.SK_FORNECEDOR]: {
                                    ...curr,
                                    TOTAL_INICIAL: initialTotal,
                                    TOTAL_FINAL: finalTotal,
                                    SAVING: saving,
                                    SAVING_PERCENTUAL:
                                        saving === 0
                                            ? 0
                                            : saving / initialTotal,
                                    VENCEDOR:
                                        isWinner &&
                                        !isDraw &&
                                        supplier.FORNECEDOR_DECLINADO !== 1,
                                    EMPATE:
                                        isDraw &&
                                        supplier.FORNECEDOR_DECLINADO !== 1,
                                    SCORE: 0,
                                },
                            }
                        },
                        {}
                    ),
                    ids: data.FORNECEDORES.ids,
                }

                state.quotationMapSlice.state.quotationMap.FORNECEDORES =
                    suppliersTotals

                state.quotationMapSlice.state.quotationMap.EMPRESAS =
                    data.EMPRESAS

                state.quotationMapSlice.state.quotationMap.PRODUTOS =
                    data.PRODUTOS

                state.quotationMapSlice.state.quotationMap.RODADAS =
                    data.RODADAS

                state.quotationMapSlice.state.quotationMap.VALORES =
                    productValuesByRound

                let winnersRowSelection = productValuesByRound
                    .filter((value) => {
                        const rounds = data.RODADAS.map(
                            (round) => round.NUMERO_RODADA
                        )

                        const maxRound = Math.max(...rounds)
                        const lastRound = data.RODADAS.find(
                            (round) => round.NUMERO_RODADA === maxRound
                        )

                        return (
                            value.SK_COTACAO_X_RODADA ===
                            lastRound?.SK_COTACAO_X_RODADA
                        )
                    })
                    .reduce((acc, curr) => {
                        return {
                            ...acc,
                            [curr.SK_COTACAO_X_PRODUTO]: {
                                ...acc[curr.SK_COTACAO_X_PRODUTO],
                                [curr.SK_FORNECEDOR_X_RODADA]:
                                    curr.MELHOR_SCORE,
                            },
                        }
                    }, {} as Record<number, RowSelectionState>)

                if (
                    quotation.DD_EM_APROVACAO === 1 ||
                    quotation.DD_MAPA_RECUSADO === 1 ||
                    quotation.DD_MAPA_APROVADO === 1 ||
                    quotation.DD_FINALIZADA === 1
                ) {
                    winnersRowSelection = productValuesByRound
                        .filter((value) => {
                            const rounds = data.RODADAS.map(
                                (round) => round.NUMERO_RODADA
                            )

                            const maxRound = Math.max(...rounds)
                            const lastRound = data.RODADAS.find(
                                (round) => round.NUMERO_RODADA === maxRound
                            )

                            return (
                                value.SK_COTACAO_X_RODADA ===
                                    lastRound?.SK_COTACAO_X_RODADA &&
                                (value.DD_EM_APROVACAO === 1 ||
                                    value.DD_APROVADO_POR_APROVADOR === 1 ||
                                    value.DD_RECUSADO_POR_APROVADOR === 1)
                            )
                        })
                        .reduce((acc, curr) => {
                            return {
                                ...acc,
                                [curr.SK_COTACAO_X_PRODUTO]: {
                                    ...acc[curr.SK_COTACAO_X_PRODUTO],
                                    [curr.SK_FORNECEDOR_X_RODADA]: true,
                                },
                            }
                        }, {} as Record<number, RowSelectionState>)
                }

                const roundWinnersSelector = Object.values(
                    suppliersTotals.entities
                ).reduce((acc, curr) => {
                    return {
                        ...acc,
                        [curr.SK_FORNECEDOR]: curr.VENCEDOR,
                    }
                }, {} as RowSelectionState)

                state.quotationMapSlice.state.selectedWinners =
                    winnersRowSelection

                state.quotationMapSlice.state.selectedNewRoundWinners =
                    roundWinnersSelector

                state.quotationMapSlice.state.quotationMapTableVisibility.suppliers =
                    Object.values(data.FORNECEDORES.entities).reduce(
                        (acc, curr) => {
                            const found =
                                state.quotationMapSlice.state
                                    .quotationMapTableVisibility.suppliers[
                                    curr.SK_FORNECEDOR
                                ]

                            if (found !== undefined) {
                                return {
                                    ...acc,
                                    [curr.SK_FORNECEDOR]: found,
                                }
                            }

                            return {
                                ...acc,
                                [curr.SK_FORNECEDOR]: true,
                            }
                        },
                        {} as Record<string, boolean>
                    )

                state.quotationMapSlice.state.quotationMapTableVisibility.rounds =
                    data.RODADAS.reduce((acc, curr) => {
                        const found =
                            state.quotationMapSlice.state
                                .quotationMapTableVisibility.suppliers[
                                curr.SK_COTACAO_X_RODADA
                            ]

                        if (found !== undefined) {
                            return {
                                ...acc,
                                [curr.SK_COTACAO_X_RODADA]: found,
                            }
                        }

                        return {
                            ...acc,
                            [curr.SK_COTACAO_X_RODADA]: true,
                        }
                    }, {} as Record<string, boolean>)

                state.quotationMapSlice.state.purchaseOrderDialog.updatedValues =
                    []

                state.quotationMapSlice.state.quotationMap.flatState =
                    data.VALORES.map((value) => {
                        const product = Object.values(
                            data.PRODUTOS.entities
                        ).find(
                            (product) =>
                                product.SK_COTACAO_X_PRODUTO ===
                                value.SK_COTACAO_X_PRODUTO
                        )!

                        const supplier = Object.values(
                            data.FORNECEDORES.entities
                        ).find((supplier) =>
                            supplier.FORNECEDOR_RODADAS.some(
                                (round) =>
                                    round === value.SK_FORNECEDOR_X_RODADA
                            )
                        )!

                        const round = data.RODADAS.find(
                            (round) =>
                                round.SK_COTACAO_X_RODADA ===
                                value.SK_COTACAO_X_RODADA
                        )

                        const company = data.EMPRESAS.find(
                            (company) =>
                                company.SK_EMPRESA === product.SK_EMPRESA
                        )!

                        return {
                            ...value,
                            ...product,
                            ...supplier,
                            ...round,
                            SK_EMPRESA: company.SK_EMPRESA,
                            DESCRICAO_EMPRESA: company.DESCRICAO_EMPRESA,
                            ABREVIATURA_EMPRESA: company.ABREVIATURA_EMPRESA,
                            CNPJ: company.CNPJ,
                            TOTAL_INICIAL: 0,
                            TOTAL_FINAL: 0,
                            SAVING: 0,
                            SAVING_PERCENTUAL: 0,
                            VENCEDOR: false,
                            EMPATE: false,
                            MELHOR_SCORE: false,
                            SCORE: 0,
                            PRODUTO_SCORE: 0,
                        }
                    })
            })
        },
        onSelectFinalSuppliers: (productId, rowSelection) => {
            set((state) => {
                state.quotationMapSlice.state.selectedWinners[productId] =
                    rowSelection
            })
        },
        onSelectAllFinalSupplier: (supplierId) => {
            set((state) => {
                const quotationMap = state.quotationMapSlice.state.quotationMap

                const supplier = Object.values(
                    quotationMap.FORNECEDORES.entities
                ).find((supplier) => supplier.SK_FORNECEDOR === supplierId)

                const values = quotationMap.VALORES.filter(
                    (value) =>
                        supplier?.FORNECEDOR_RODADAS.includes(
                            value.SK_FORNECEDOR_X_RODADA
                        ) &&
                        value.SK_COTACAO_X_RODADA ===
                            state.quotationDetailSlice.state.lastRoundId &&
                        value.DD_RECUSADO !== 1
                )

                const rowSelection = values.reduce((acc, curr) => {
                    return {
                        ...acc,
                        [curr.SK_COTACAO_X_PRODUTO]: {
                            [curr.SK_FORNECEDOR_X_RODADA]: true,
                        },
                    }
                }, {} as Record<number, RowSelectionState>)

                state.quotationMapSlice.state.selectedWinners = rowSelection
            })
        },
        onSelectNewRoundSuppliers: (rowSelection) => {
            set((state) => {
                state.quotationMapSlice.state.selectedNewRoundWinners =
                    rowSelection
            })
        },
        onSelectCompany: (companyId) => {
            set((state) => {
                state.quotationMapSlice.state.supplierDialog.selectedCompanyId =
                    companyId
            })
        },
        onSelectSupplierToUpdate: (supplierId) => {
            set((state) => {
                state.quotationMapSlice.state.supplierDialog.supplierId =
                    supplierId

                const quotationMap = state.quotationMapSlice.state.quotationMap

                const supplier = Object.values(
                    quotationMap.FORNECEDORES.entities
                ).find((supplier) => supplier.SK_FORNECEDOR === supplierId)!

                const lastRoundId = state.quotationDetailSlice.state.lastRoundId

                const supplierValues: QuotationMapFlatState[] =
                    quotationMap.VALORES.filter(
                        (value) =>
                            supplier?.FORNECEDOR_RODADAS.includes(
                                value.SK_FORNECEDOR_X_RODADA
                            ) && value.SK_COTACAO_X_RODADA === lastRoundId
                    ).map((value) => {
                        const product = Object.values(
                            quotationMap.PRODUTOS.entities
                        ).find(
                            (product) =>
                                product.SK_COTACAO_X_PRODUTO ===
                                value.SK_COTACAO_X_PRODUTO
                        )!

                        const company = quotationMap.EMPRESAS.find(
                            (company) =>
                                company.SK_EMPRESA === product?.SK_EMPRESA
                        )!
                        const round = quotationMap.RODADAS.find(
                            (round) =>
                                round.SK_COTACAO_X_RODADA ===
                                value.SK_COTACAO_X_RODADA
                        )!

                        return {
                            ...value,
                            ...product,
                            ...company,
                            ...round,
                            ...supplier,
                        }
                    })

                const companyIds = [
                    ...new Set(
                        supplierValues.map((supplier) => supplier.SK_EMPRESA!)
                    ),
                ]

                const productsCurrency = supplierValues.filter(
                    (product) => product.SK_MOEDA
                )

                const productsPaymentCondition = supplierValues.filter(
                    (product) => product.SK_CONDICAO_DE_PAGAMENTO
                )

                const productsManualPaymentCondition = supplierValues.filter(
                    (product) => product.DS_CONDICAO_DE_PAGAMENTO_MANUAL
                )

                const productsFreight = supplierValues.filter(
                    (product) => product.FRETE
                )

                state.quotationMapSlice.state.supplierDialog.selectedCompanyId =
                    companyIds[0]

                state.quotationMapSlice.state.supplierDialog.supplierValues =
                    supplierValues

                if (productsCurrency.length > 0) {
                    const totals = calculateTotals(
                        productsCurrency,
                        'SK_MOEDA',
                        'SK_EMPRESA'
                    )

                    state.quotationMapSlice.state.supplierDialog.selectedCurrencyId =
                        companyIds.reduce((acc, curr) => {
                            const selected = findMaxTotal(
                                totals.filter(
                                    (total) => total.companyId === curr
                                )
                            )

                            if (!selected) {
                                return {
                                    ...acc,
                                    [curr]: null,
                                }
                            }

                            return {
                                ...acc,
                                [curr]: selected.id as number,
                            }
                        }, {} as Record<string, number | null>)
                } else {
                    state.quotationMapSlice.state.supplierDialog.selectedCurrencyId =
                        {}
                }

                if (
                    productsPaymentCondition.length > 0 ||
                    productsManualPaymentCondition.length > 0
                ) {
                    const isManual =
                        productsPaymentCondition.length === 0 &&
                        productsManualPaymentCondition.length > 0

                    const totals = calculateTotals(
                        isManual
                            ? productsManualPaymentCondition
                            : productsPaymentCondition,
                        isManual
                            ? 'DS_CONDICAO_DE_PAGAMENTO_MANUAL'
                            : 'SK_CONDICAO_DE_PAGAMENTO',
                        'SK_EMPRESA'
                    )

                    state.quotationMapSlice.state.supplierDialog.selectedPaymentConditionId =
                        companyIds.reduce(
                            (acc, curr) => {
                                const selected = findMaxTotal(
                                    totals.filter(
                                        (total) => total.companyId === curr
                                    )
                                )

                                if (!selected) {
                                    return {
                                        ...acc,
                                        [curr]: {
                                            id: null,
                                            manual: isManual,
                                        },
                                    }
                                }

                                return {
                                    ...acc,
                                    [curr]: {
                                        id: selected.id as string,
                                        manual: isManual,
                                    },
                                }
                            },
                            {} as Record<
                                string,
                                {
                                    id: string | null
                                    manual: boolean
                                }
                            >
                        )
                } else {
                    state.quotationMapSlice.state.supplierDialog.selectedPaymentConditionId =
                        {}
                }

                if (productsFreight.length > 0) {
                    const totals = calculateTotals(
                        productsFreight,
                        'FRETE',
                        'SK_EMPRESA'
                    )

                    state.quotationMapSlice.state.supplierDialog.selectedFreights =
                        companyIds.reduce((acc, curr) => {
                            const selected = findMaxTotal(
                                totals.filter(
                                    (total) => total.companyId === curr
                                )
                            )

                            if (!selected) {
                                return {
                                    ...acc,
                                    [curr]: null,
                                }
                            }

                            return {
                                ...acc,
                                [curr]: selected.id as string,
                            }
                        }, {} as Record<string, string | null>)
                } else {
                    state.quotationMapSlice.state.supplierDialog.selectedFreights =
                        {}
                }
                // state.quotationMapSlice.state.supplierDialog.selectedPaymentConditionId =
                //     supplierCompanies.reduce((acc, curr) => {
                //         const company = quotationMap.EMPRESAS.find(
                //             (company) => company.SK_EMPRESA === curr
                //         )!

                //         return {
                //             ...acc,
                //             [curr]: company.FORMA_PAGAMENTO,
                //         }
                //     }, {} as RowSelectionState)

                // find((supplier) =>
                //     supplier.FORNECEDOR_RODADAS.some(
                //         (round) => round === supplierId
                //     )
                // )!
            })
        },
        onUpdatePurchaseOrderValue: (id, columnId, value) => {
            set((state) => {
                const prevUpdatedValues =
                    state.quotationMapSlice.state.purchaseOrderDialog
                        .updatedValues
                const prevValues =
                    state.quotationMapSlice.state.quotationMap.flatState

                const prevValue = prevValues.find(
                    (val) => val.SK_RESPOSTA_FORNECEDOR === id
                )

                if (!prevValue) return

                const found = prevUpdatedValues.find(
                    (val) => val.SK_RESPOSTA_FORNECEDOR === id
                )

                const updateValues = (target: typeof found) => {
                    if (!target) return

                    const price = Number(target['PRECO']) || 0
                    const quantity = Number(target['QTD_SOLICITADA']) || 0
                    const spending = Number(target['VL_SPENDING']) || 0

                    const total = price * quantity

                    target['VL_SAVING'] = Number(total - spending) || 0
                    target['PERCENTUAL_SAVING'] = (
                        (target['VL_SAVING'] / total) *
                        100
                    ).toString()
                }

                if (found) {
                    //@ts-ignore
                    found[columnId] = value

                    updateValues(found)
                } else {
                    const newValue = {
                        ...prevValue,
                        [columnId]: value,
                    }

                    prevUpdatedValues.push(newValue)
                    updateValues(newValue)
                }
            })
        },
        onSelectPaymentCondition: (paymentConditionId, manual = false) => {
            set((state) => {
                const prevState = state.quotationMapSlice.state.supplierDialog

                if (prevState.selectedCompanyId) {
                    prevState.selectedPaymentConditionId[
                        prevState.selectedCompanyId
                    ] = {
                        id: paymentConditionId,
                        manual,
                    }
                }

                const prevUpdatedValues =
                    state.quotationMapSlice.state.supplierDialog.updatedValues

                const prevValues =
                    state.quotationMapSlice.state.supplierDialog.supplierValues.filter(
                        (supplier) =>
                            supplier.SK_EMPRESA === prevState.selectedCompanyId
                    )

                prevValues.forEach((value) => {
                    const found = prevUpdatedValues.find(
                        (val) =>
                            val.SK_RESPOSTA_FORNECEDOR ===
                            value.SK_RESPOSTA_FORNECEDOR
                    )

                    if (found) {
                        if (manual) {
                            found.DS_CONDICAO_DE_PAGAMENTO_MANUAL =
                                paymentConditionId
                        } else {
                            found.SK_CONDICAO_DE_PAGAMENTO = paymentConditionId
                        }
                    } else {
                        prevUpdatedValues.push({
                            SK_RESPOSTA_FORNECEDOR:
                                value.SK_RESPOSTA_FORNECEDOR,
                            SK_COTACAO_X_PRODUTO: value.SK_COTACAO_X_PRODUTO,
                            SK_EMPRESA: value.SK_EMPRESA,
                            SK_COTACAO_X_RODADA: value.SK_COTACAO_X_RODADA,
                            SK_FORNECEDOR: value.SK_FORNECEDOR,
                            SK_FORNECEDOR_X_RODADA:
                                value.SK_FORNECEDOR_X_RODADA,
                            SK_CONDICAO_DE_PAGAMENTO: !manual
                                ? paymentConditionId
                                : null,
                            DS_CONDICAO_DE_PAGAMENTO_MANUAL: manual
                                ? paymentConditionId
                                : null,
                        })
                    }
                })
            })
        },
        onSelectCurrency: (currencyId) => {
            set((state) => {
                const prevState = state.quotationMapSlice.state.supplierDialog

                if (prevState.selectedCompanyId) {
                    prevState.selectedCurrencyId[prevState.selectedCompanyId] =
                        currencyId
                }
                const prevUpdatedValues =
                    state.quotationMapSlice.state.supplierDialog.updatedValues

                const prevValues =
                    state.quotationMapSlice.state.supplierDialog.supplierValues.filter(
                        (supplier) =>
                            supplier.SK_EMPRESA === prevState.selectedCompanyId
                    )

                prevValues.forEach((value) => {
                    const found = prevUpdatedValues.find(
                        (val) =>
                            val.SK_RESPOSTA_FORNECEDOR ===
                            value.SK_RESPOSTA_FORNECEDOR
                    )

                    if (found) {
                        found.SK_MOEDA = currencyId
                    } else {
                        prevUpdatedValues.push({
                            SK_RESPOSTA_FORNECEDOR:
                                value.SK_RESPOSTA_FORNECEDOR,
                            SK_COTACAO_X_PRODUTO: value.SK_COTACAO_X_PRODUTO,
                            SK_EMPRESA: value.SK_EMPRESA,
                            SK_COTACAO_X_RODADA: value.SK_COTACAO_X_RODADA,
                            SK_FORNECEDOR: value.SK_FORNECEDOR,
                            SK_FORNECEDOR_X_RODADA:
                                value.SK_FORNECEDOR_X_RODADA,
                            SK_MOEDA: currencyId,
                        })
                    }
                })
            })
        },
        onSelectFreight: (freight) => {
            set((state) => {
                const prevState = state.quotationMapSlice.state.supplierDialog

                if (prevState.selectedCompanyId) {
                    prevState.selectedFreights[prevState.selectedCompanyId] =
                        freight
                }

                const prevUpdatedValues =
                    state.quotationMapSlice.state.supplierDialog.updatedValues

                const prevValues =
                    state.quotationMapSlice.state.supplierDialog.supplierValues.filter(
                        (supplier) =>
                            supplier.SK_EMPRESA === prevState.selectedCompanyId
                    )

                prevValues.forEach((value) => {
                    const found = prevUpdatedValues.find(
                        (val) =>
                            val.SK_RESPOSTA_FORNECEDOR ===
                            value.SK_RESPOSTA_FORNECEDOR
                    )

                    if (found) {
                        found.FRETE = freight
                    } else {
                        prevUpdatedValues.push({
                            SK_RESPOSTA_FORNECEDOR:
                                value.SK_RESPOSTA_FORNECEDOR,
                            SK_COTACAO_X_PRODUTO: value.SK_COTACAO_X_PRODUTO,
                            SK_EMPRESA: value.SK_EMPRESA,
                            SK_COTACAO_X_RODADA: value.SK_COTACAO_X_RODADA,
                            SK_FORNECEDOR: value.SK_FORNECEDOR,
                            SK_FORNECEDOR_X_RODADA:
                                value.SK_FORNECEDOR_X_RODADA,
                            FRETE: freight,
                        })
                    }
                })
            })
        },
        onUpdateSupplierValue: (id, columnId, value) => {
            set((state) => {
                console.log(id, columnId, value)
                const prevUpdatedValues =
                    state.quotationMapSlice.state.supplierDialog.updatedValues
                const prevValues =
                    state.quotationMapSlice.state.supplierDialog.supplierValues

                const prevValue = prevValues.find(
                    (val) => val.SK_RESPOSTA_FORNECEDOR === id
                )

                if (!prevValue) return

                const found = prevUpdatedValues.find(
                    (val) => val.SK_RESPOSTA_FORNECEDOR === id
                )

                const updateValues = (target: typeof found) => {
                    if (!target) return

                    const price = Number(target['PRECO']) || 0
                    const quantity = Number(target['QTD_SOLICITADA']) || 0
                    const spending = Number(target['VL_SPENDING']) || 0

                    const total = price * quantity

                    target['VL_SAVING'] = Number(total - spending) || 0
                    target['PERCENTUAL_SAVING'] = (
                        (target['VL_SAVING'] / total) *
                        100
                    ).toString()
                }

                if (found) {
                    found[columnId] = value

                    updateValues(found)
                } else {
                    const newValue = {
                        ...prevValue,
                        [columnId]: value,
                    }

                    prevUpdatedValues.push(newValue)
                    updateValues(newValue)
                }
            })
        },
        onBulkUpdateSupplierValue: (data) => {
            set((state) => {
                const prevUpdatedValues =
                    state.quotationMapSlice.state.supplierDialog.updatedValues

                data.forEach((value) => {
                    const foundIdx = prevUpdatedValues.findIndex(
                        (updated) =>
                            updated.SK_RESPOSTA_FORNECEDOR ===
                            value.SK_RESPOSTA_FORNECEDOR
                    )

                    const isValidDate =
                        isValid(value.PREVISAO_DE_ENTREGA) &&
                        isAfter(
                            value.PREVISAO_DE_ENTREGA as unknown as Date,
                            new Date()
                        )

                    if (foundIdx !== -1) {
                        prevUpdatedValues[foundIdx] = {
                            ...prevUpdatedValues[foundIdx],
                            PRECO: value.PRECO,
                            QTD_DISPONIVEL: value.QTD_DISPONIVEL,
                            FCP: value.FCP,
                            ICMS: value.ICMS,
                            IPI: value.IPI,
                            PIS: value.PIS,
                            COFINS: value.COFINS,
                            ST: value.ST,
                            PREVISAO_DE_ENTREGA: isValidDate
                                ? value.PREVISAO_DE_ENTREGA
                                : '',
                            OBSERVACAO: value.OBSERVACAO,
                        }
                    } else {
                        const prevValue =
                            state.quotationMapSlice.state.supplierDialog.supplierValues.find(
                                (supplier) =>
                                    supplier.SK_RESPOSTA_FORNECEDOR ===
                                    value.SK_RESPOSTA_FORNECEDOR
                            )

                        if (prevValue) {
                            prevUpdatedValues.push({
                                ...prevValue,
                                PRECO: value.PRECO,
                                QTD_DISPONIVEL: value.QTD_DISPONIVEL,
                                FCP: value.FCP,
                                ICMS: value.ICMS,
                                IPI: value.IPI,
                                PIS: value.PIS,
                                COFINS: value.COFINS,
                                ST: value.ST,
                                PREVISAO_DE_ENTREGA: isValidDate
                                    ? value.PREVISAO_DE_ENTREGA
                                    : '',
                                OBSERVACAO: value.OBSERVACAO,
                            })
                        }
                    }
                })
            })
        },
        onResetUpdatedValues: () => {
            set((state) => {
                const prevState = state.quotationMapSlice.state.supplierDialog

                prevState.updatedValues.forEach((updatedValue) => {
                    const id = updatedValue.SK_RESPOSTA_FORNECEDOR

                    if (id) {
                        prevState.supplierValues = prevState.supplierValues.map(
                            (supplierValue) => {
                                if (
                                    supplierValue.SK_RESPOSTA_FORNECEDOR === id
                                ) {
                                    return {
                                        ...supplierValue,
                                        ...updatedValue,
                                    }
                                } else {
                                    return supplierValue
                                }
                            }
                        )

                        prevState.updatedValues = []
                    }
                })
            })
        },
        onResetSupplierDialog: () => {
            set((state) => {
                state.quotationMapSlice.state.supplierDialog =
                    supplierDialogInitialState
            })
        },
        setPurchaseOrderResponse: (purchaseOrderResponse) => {
            set((state) => {
                state.quotationMapSlice.state.purchaseOrderResponse =
                    purchaseOrderResponse
            })
        },
        setPurchaseOrderValidateResponse: (response) => {
            set((state) => {
                state.quotationMapSlice.state.purchaseOrderValidateResponse =
                    response
            })
        },
        onUpdateSupplierShippingCompany: ({ id, name }, freight) => {
            set((state) => {
                const prevState = state.quotationMapSlice.state.supplierDialog

                prevState.supplierValues.forEach((value) => {
                    if (value.SK_EMPRESA === prevState.selectedCompanyId) {
                        value.SK_TRANSPORTADORA = id
                        value.NM_TRANSPORTADORA = name
                        value.VL_FRETE = freight.toString()
                    }
                })
            })
        },
        onRemoveSupplierShippingCompany: () => {
            set((state) => {
                const prevState = state.quotationMapSlice.state.supplierDialog

                prevState.supplierValues.forEach((value) => {
                    if (value.SK_EMPRESA === prevState.selectedCompanyId) {
                        value.SK_TRANSPORTADORA = null
                        value.NM_TRANSPORTADORA = null
                        value.VL_FRETE = null
                    }
                })
            })
        },
        onChangeQuotationMapColumnVisibity: (type, id, value) => {
            set((state) => {
                state.quotationMapSlice.state.quotationMapTableVisibility[type][
                    id
                ] = value
            })
        },
        onReset: () => {
            set((state) => {
                state.quotationMapSlice.state = initialState
            })
        },
    },
})

export const quotationMapStateSelector = (state: Store) =>
    state.quotationMapSlice.state

export const quotationMapActionsSelector = (state: Store) =>
    state.quotationMapSlice.actions

export const quotationMapFlatSelector = (
    state: Store
): QuotationMapFlatState[] => {
    const quotationMap = state.quotationMapSlice.state.quotationMap

    if (!quotationMap) return []

    return quotationMap.VALORES.map((value) => {
        const product = Object.values(quotationMap.PRODUTOS.entities).find(
            (product) =>
                product.SK_COTACAO_X_PRODUTO === value.SK_COTACAO_X_PRODUTO
        )!

        const supplier = Object.values(quotationMap.FORNECEDORES.entities).find(
            (supplier) =>
                supplier.FORNECEDOR_RODADAS.some(
                    (round) => round === value.SK_FORNECEDOR_X_RODADA
                )
        )!

        const round = quotationMap.RODADAS.find(
            (round) => round.SK_COTACAO_X_RODADA === value.SK_COTACAO_X_RODADA
        )

        const company = quotationMap.EMPRESAS.find(
            (company) => company.SK_EMPRESA === product.SK_EMPRESA
        )!

        return {
            ...value,
            ...product,
            ...supplier,
            ...round,
            SK_EMPRESA: company.SK_EMPRESA,
            DESCRICAO_EMPRESA: company.DESCRICAO_EMPRESA,
            ABREVIATURA_EMPRESA: company.ABREVIATURA_EMPRESA,
            CNPJ: company.CNPJ,
        }
    })
}

export const supplierValuesByIdSelector =
    (id: string) =>
    (state: Store): QuotationMapFlatState[] => {
        const quotationMap = state.quotationMapSlice.state.quotationMap

        if (!quotationMap) return []

        return quotationMap.VALORES.filter(
            (value) =>
                value.SK_COTACAO_X_RODADA ===
                state.quotationDetailSlice.state.selectedRoundId
        )
            .map((value) => {
                const product = Object.values(
                    quotationMap.PRODUTOS.entities
                ).find(
                    (product) =>
                        product.SK_COTACAO_X_PRODUTO ===
                        value.SK_COTACAO_X_PRODUTO
                )!

                const supplier = Object.values(
                    quotationMap.FORNECEDORES.entities
                ).find((supplier) =>
                    supplier.FORNECEDOR_RODADAS.some(
                        (round) => round === value.SK_FORNECEDOR_X_RODADA
                    )
                )!

                const company = quotationMap.EMPRESAS.find(
                    (company) => company.SK_EMPRESA === product.SK_EMPRESA
                )!

                return {
                    ...value,
                    ...product,
                    ...supplier,
                    SK_EMPRESA: company.SK_EMPRESA,
                    DESCRICAO_EMPRESA: company.DESCRICAO_EMPRESA,
                    ABREVIATURA_EMPRESA: company.ABREVIATURA_EMPRESA,
                    CNPJ: company.CNPJ,
                }
            })
            .filter((supplier) => supplier.SK_FORNECEDOR === id)
    }

export const getUpdatedSupplierValuesSelector =
    (id: string) =>
    (state: Store): QuotationMapFlatState[] => {
        const quotationMap = state.quotationMapSlice.state.supplierDialog

        if (!quotationMap) return []

        return quotationMap.supplierValues
            .filter(
                (value) =>
                    value.SK_COTACAO_X_RODADA ===
                    state.quotationDetailSlice.state.selectedRoundId
            )
            .map((value) => {
                const found = quotationMap.updatedValues.find(
                    (val) =>
                        val.SK_RESPOSTA_FORNECEDOR ===
                        value.SK_RESPOSTA_FORNECEDOR
                )

                if (found) {
                    const newValue = {}

                    for (const [key, v] of Object.entries(found)) {
                        newValue[key] = v
                    }

                    return {
                        ...value,
                        ...newValue,
                    }
                }

                return value
            })
            .filter((supplier) => supplier.SK_FORNECEDOR === id)
    }

export const supplierByIdSelector = (id: string) => (state: Store) => {
    const quotationMap = state.quotationMapSlice.state.quotationMap

    if (!quotationMap) return null

    return quotationMap.FORNECEDORES.entities[id]
}

export const supplierByRoundSelector = (id: string) => (state: Store) => {
    const quotationMap = state.quotationMapSlice.state.quotationMap

    if (!quotationMap) return null

    return quotationMap.FORNECEDORES.entities[id]
}

export const getRoundsSelector = (state: Store) =>
    state.quotationMapSlice.state.quotationMap?.RODADAS || []

export const lastRoundSuppliersSelector = (state: Store) => {
    const suppliers = Object.values(
        state.quotationMapSlice.state.quotationMap.FORNECEDORES.entities
    )

    const maxRound = Math.max(
        ...state.quotationMapSlice.state.quotationMap.RODADAS.map(
            (round) => round.NUMERO_RODADA
        )
    )

    const lastRound = state.quotationMapSlice.state.quotationMap.RODADAS.find(
        (round) => round.NUMERO_RODADA === maxRound
    )!

    return suppliers.filter((supplier) =>
        supplier.FORNECEDOR_RODADAS.some((round) =>
            lastRound?.FORNECEDOR_RODADAS.includes(round)
        )
    )
}

export const suppliersByRoundSelector = (state: Store) => {
    const quotationMap = state.quotationMapSlice.state.quotationMap

    const suppliers = Object.values(quotationMap.FORNECEDORES.entities)

    const selectedRound = state.quotationDetailSlice.state.selectedRoundId

    return selectedRound
        ? suppliers.filter((supplier) =>
              supplier.FORNECEDOR_COTACAO_RODADAS.includes(selectedRound)
          )
        : []
}

export const getSelectedCurrencySelector = (state: Store) => {
    const currencyId =
        state.quotationMapSlice.state.supplierDialog.selectedCurrencyId
    const companyId =
        state.quotationMapSlice.state.supplierDialog.selectedCompanyId

    if (currencyId && companyId) {
        if (currencyId[companyId]) {
            return currencyId[companyId]
        }

        return null
    }

    return null
}

export const getSelectedPaymentConditionSelector = (state: Store) => {
    const paymentConditionId =
        state.quotationMapSlice.state.supplierDialog.selectedPaymentConditionId
    const companyId =
        state.quotationMapSlice.state.supplierDialog.selectedCompanyId

    if (paymentConditionId && companyId) {
        if (paymentConditionId[companyId]) {
            return paymentConditionId[companyId]
        }

        return null
    }

    return null
}

export const getSelectedFreightSelector = (state: Store) => {
    const freight =
        state.quotationMapSlice.state.supplierDialog.selectedFreights
    const companyId =
        state.quotationMapSlice.state.supplierDialog.selectedCompanyId

    if (freight && companyId) {
        if (freight[companyId]) {
            return freight[companyId]
        }

        return null
    }

    return null
}

export const getSelectedShippingCompanySelector = (state: Store) => {
    const companyId =
        state.quotationMapSlice.state.supplierDialog.selectedCompanyId
    const supplierValues =
        state.quotationMapSlice.state.supplierDialog.supplierValues

    const values = supplierValues.filter(
        (value) => value.SK_EMPRESA === companyId
    )

    const value = values[0]

    return {
        SK_TRANSPORTADORA: value?.SK_TRANSPORTADORA,
        NM_TRANSPORTADORA: value?.NM_TRANSPORTADORA,
        VL_FRETE: value?.VL_FRETE,
    }
}
