import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/components/ui/use-toast'
import { isAxiosError } from '@/api/business'
import { updateSupplier } from '@/api/business/fornecedores'
import { suppliersKeys } from '@/queries/useSuppliersQuery'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { Supplier } from '@/types/Suppliers'
import { UpdateSupplierProps } from '@/api/business/fornecedores/type'

interface EditDialogProps {
    supplier: Supplier
    isOpen: boolean
    onClose: () => void
    onSuccess?:
        | ((
              data: any,
              variables: UpdateSupplierProps,
              context: unknown
          ) => unknown)
        | undefined
}

const EditDialog = ({
    supplier,
    isOpen,
    onSuccess,
    onClose,
}: EditDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const schema = z.object({
        supplierName: z.string().min(1, 'Informe o fornecedor'),
        supplier: z.string().min(1, 'Informe o fornecedor'),
        store: z.string().optional(),
        cnpj: z.string().optional(),
        email: z.string().email().array(),
        observation: z.string().optional(),
    })

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            supplier: supplier.DD_RAZAO_SOCIAL,
            supplierName: supplier.DD_NOME_FANTASIA,
            store: supplier.DD_LOJA,
            cnpj: supplier.DD_CNPJ,
            email: supplier.DS_EMAIL,
            observation: supplier.DD_OBSERVACAO,
        },
    })

    useEffect(() => {
        form.reset({
            supplier: supplier.DD_RAZAO_SOCIAL,
            supplierName: supplier.DD_NOME_FANTASIA,
            store: supplier.DD_LOJA,
            cnpj: supplier.DD_CNPJ,
            email: supplier.DS_EMAIL,
            observation: supplier.DD_OBSERVACAO,
        })
    }, [supplier])

    const { mutate, isPending: mutateLoading } = useMutation({
        mutationFn: updateSupplier,
        onSuccess: onSuccess
            ? onSuccess
            : () => {
                  queryClient.invalidateQueries({
                      queryKey: suppliersKeys.lists(),
                  })
                  onClose()
                  toast({
                      title: 'Fornecedor editado com sucesso',
                  })
              },
        onError: (err) => {
            let errorMessage =
                'Não foi possível editar fornecedor. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao editar cotação',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const onSubmit = (data: z.infer<typeof schema>) => {
        mutate({
            SK_FORNECEDOR: supplier.SK_FORNECEDOR,
            DD_RAZAO_SOCIAL: data.supplier,
            DD_NOME_FANTASIA: data.supplierName,
            DD_LOJA: data.store,
            DD_CNPJ: data.cnpj,
            DS_EMAIL: data.email,
            DD_OBSERVACAO: data.observation,
        })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Editar fornecedor</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-2"
                    >
                        <FormField
                            name="supplier"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Razão social</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="supplierName"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Nome fantasia</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="store"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Loja</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="cnpj"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>CNPJ</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="email"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="observation"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Observação</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <DialogFooter>
                            <Button
                                type="button"
                                onClick={onClose}
                                variant="ghost"
                            >
                                Cancelar
                            </Button>
                            <Button type="submit" disabled={mutateLoading}>
                                Confirmar
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default EditDialog
