import { PurchaseOrder } from '@/types/PurchaseOrder'
import instance from '..'
import { FetchPurchaseOrderProps } from './type'
import { PaginationAPIResponse } from '@/types/Pagination'

const BASE_URL = '/pedidos-de-compras'

export const fetchPurchaseOrder = async ({
    currentPage,
    perPage,
    filters,
}: FetchPurchaseOrderProps) => {
    const response = await instance.post<PaginationAPIResponse<PurchaseOrder>>(
        `${BASE_URL}`,
        {
            POR_PAGINA: perPage,
            PAGINA_ATUAL: currentPage,
            FILTROS: filters || [],
        }
    )

    return response.data
}
