import Table from '@/components/Table'
import TableRowActions from '@/components/Table/components/TableRowActions'
import { createColumnHelper } from '@tanstack/react-table'
import { ReactNode, useMemo } from 'react'
import DeleteDialog from './components/DeleteDialog'
import EditDialog from './components/EditDialog'
import AddDialog from './components/AddDialog'
import { isEqual, isToday } from 'date-fns'
import DadosMestreHeader from '../components/DadosMestreHeader'
import DadosMestreHeaderActions from '../components/DadosMestreHeaderActions'
import { TableData } from '@/components/Table/type'
import { Avaliacoes } from '@/types/Avaliacoes'
import { useAvaliacoesQuery } from '@/queries/useAvaliacoesQuery'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { useTable } from '@/hooks/useTable'

export type AvaliacoesTable = TableData<Avaliacoes>

const columnHelper = createColumnHelper<Avaliacoes>()

const DadosMestreAvaliacoes = () => {
    const { data, isPending, isFetching, refetch } = useAvaliacoesQuery()

    const {
        table,
        isAddDialogOpen,
        isDeleteDialogOpen,
        isEditDialogOpen,
        selectedRow,
        selectedRows,
        onAddDialogClose,
        onClickAdd,
        onClickDelete,
        onClickEdit,
        onClickMultiDelete,
        onDeleteDialogClose,
        onEditDialogClose,
        setSelectedRows,
        setTable,
    } = useTable<Avaliacoes>()

    // const { onDownload } = useCurrencySpreadsheet()

    const memoData = useMemo(() => data || [], [data])

    const memoColumns = useMemo(
        () => [
            columnHelper.accessor('DESCRICAO_AVALIACAO', {
                id: 'avaliacao',
                header: 'Avaliação',
                size: 200,
            }),
            columnHelper.accessor('AVALIACAO_PADRAO', {
                id: 'avaliacao_padrao',
                header: 'Padrão',
                cell: ({ getValue }) => (getValue() === 1 ? 'Sim' : null),
                size: 100,
            }),
            columnHelper.display({
                id: 'Ações',
                cell: ({ row }) => {
                    return (
                        <TableRowActions
                            onClickDelete={() => onClickDelete(row)}
                            onClickEdit={() => onClickEdit(row)}
                        />
                    )
                },
                size: 100,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.display({
                id: 'Tipo',
                cell: ({ row }) => {
                    const { CREATED_AT, UPDATED_AT } = row.original
                    let Badge: ReactNode | null = null
                    const createdAt = new Date(CREATED_AT)
                    const updatedAt = new Date(UPDATED_AT)

                    if (isEqual(createdAt, updatedAt)) {
                        if (isToday(createdAt)) {
                            Badge = (
                                <span className="px-2 py-1 text-xs rounded-md text-emerald-500 ">
                                    Novo *
                                </span>
                            )
                        }
                    } else {
                        Badge = (
                            <span className="px-2 py-1 text-xs rounded-md text-zinc-400">
                                Editado
                            </span>
                        )
                    }

                    return Badge
                },
                meta: {
                    cell: {
                        className: 'flex items-center justify-center',
                    },
                },
                size: 50,
                enableColumnFilter: false,
                enableSorting: false,
            }),
        ],

        []
    )

    return (
        <div className="w-full h-full p-4 overflow-hidden">
            <Table<AvaliacoesTable>
                data={memoData}
                columns={memoColumns}
                getRowId={(row) => row.SK_AVALIACAO.toString()}
                isLoading={isPending}
                isFetching={isFetching}
                getTableInstance={(table) => setTable(table)}
                onRowSelectionChange={setSelectedRows}
                tableHeader={
                    <DadosMestreHeader
                        title={`Avaliações ${data ? `(${data.length})` : ''}`}
                        onDelete={onClickMultiDelete}
                        selectedRows={
                            table
                                ?.getFilteredRowModel()
                                .flatRows.filter((row) => selectedRows[row.id])
                                .length
                        }
                    />
                }
                tableActions={
                    <DadosMestreHeaderActions
                        tableId="DADOS_MESTRE_AVALIACOES"
                        onAdd={onClickAdd}
                        onRefech={refetch}
                        layout
                    />
                }
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultColumn>{getValue() as string}</DefaultColumn>
                    ),
                }}
            />
            {isDeleteDialogOpen && (
                <DeleteDialog
                    ids={
                        selectedRow
                            ? [selectedRow.original.SK_AVALIACAO]
                            : table
                            ? table
                                  .getFilteredRowModel()
                                  .flatRows.filter(
                                      (row) => selectedRows[row.id]
                                  )
                                  .map((row) => row.original.SK_AVALIACAO)
                            : []
                    }
                    isOpen={isDeleteDialogOpen}
                    onClose={onDeleteDialogClose}
                />
            )}

            {selectedRow && (
                <EditDialog
                    row={selectedRow}
                    isOpen={isEditDialogOpen}
                    onClose={onEditDialogClose}
                />
            )}
            <AddDialog isOpen={isAddDialogOpen} onClose={onAddDialogClose} />
        </div>
    )
}

export default DadosMestreAvaliacoes
