import { getFiltersSelector } from '@/store/quotationPurchaseRequestSlice'
// import FilterToggleButton from '../FilterToggleButton'
import { useBaseStore } from '@/store'
import useDisclosure from '@/hooks/useDisclosure'
import { useToast } from '@/components/ui/use-toast'
import { Button } from '@/components/ui/button'
import SelectAllDialog from '../SelectAllDialog'

const TableHeader = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { toast } = useToast()

    const filters = useBaseStore(getFiltersSelector)

    const onSelectAllOpen = () => {
        if (filters.length === 0) {
            toast({
                title: 'Necessário filtro',
                description:
                    'Por favor, adicione um filtro antes de selecionar todas',
                variant: 'destructive',
            })
        } else {
            onOpen()
        }
    }

    return (
        <>
            <div className="flex items-center gap-4">
                {/* <FilterToggleButton /> */}
                <Button
                    className="px-2 py-1 text-sm bg-transparent border rounded-lg text-neutral-500 border-neutral-200 hover:bg-slate-100"
                    onClick={onSelectAllOpen}
                >
                    Selecionar todas
                </Button>
            </div>
            <SelectAllDialog isOpen={isOpen} onClose={onClose} />
        </>
    )
}

export default TableHeader
