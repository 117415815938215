import { Button } from '@/components/ui/button'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { Download, Table2, Upload, X } from 'lucide-react'
import { ChangeEvent, DragEvent, useState } from 'react'
import { useEditDialogSheet } from './hooks/useEditDialogSheet'
import { useBaseStore } from '@/store'
import useDisclosure from '@/hooks/useDisclosure'
import { Store } from '@/store/type'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'

const stateSelector = (state: Store) => ({
    onBulkUpdateSupplierValue:
        state.quotationMapSlice.actions.onBulkUpdateSupplierValue,
})

const SheetPopover = () => {
    const [isDragging, setIsDragging] = useState(false)
    const [sheetModel, setSheetModel] = useState<File | null>(null)
    const { onDownloadSheet, onUploadSheet } = useEditDialogSheet()
    const { onBulkUpdateSupplierValue } = useBaseStore(stateSelector)
    const { isOpen, onClose, onOpen, onOpenChange } = useDisclosure()

    const onDrag = () => {
        setIsDragging(true)
    }

    const onDragEnd = () => {
        setIsDragging(false)
    }

    const onDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setIsDragging(false)

        if (e.dataTransfer.files) {
            setSheetModel(e.dataTransfer.files[0])
        }
    }

    const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setSheetModel(e.target.files[0])
        }
    }

    const onConfirm = async () => {
        if (sheetModel) {
            const json = await onUploadSheet(sheetModel)

            if (json.length > 0) {
                onBulkUpdateSupplierValue(json)
                onClose()
            }
        }
    }

    return (
        <Popover open={isOpen} onOpenChange={onOpenChange}>
            <PopoverTrigger asChild>
                <Button className="gap-2" onClick={onOpen} variant="outline">
                    <Table2 size={16} className="text-emerald-500" />
                    <TypographyP>Planilha</TypographyP>
                </Button>
            </PopoverTrigger>
            <PopoverContent align="end" className="rounded-lg p-2 w-[300px]">
                <div className="p-2 space-y-2">
                    <TypographyMuted>Planilha modelo</TypographyMuted>
                    <button
                        className="flex items-center justify-center w-full gap-2 p-2 text-sm border rounded-md bg-emerald-100 text-emerald-600 border-emerald-200 hover:bg-emerald-200/70"
                        onClick={onDownloadSheet}
                    >
                        <Download size={14} />
                        Baixar planilha modelo
                    </button>
                    <label
                        className="flex items-center justify-center gap-2 p-2 text-sm border rounded-md cursor-pointer bg-primary-100 text-primary-600 border-primary-200 hover:bg-primary-200/70"
                        htmlFor="file"
                    >
                        <Upload size={14} />
                        Enviar planilha
                    </label>
                    <input
                        type="file"
                        id="file"
                        className="hidden"
                        onChange={onChangeFile}
                        accept=".xlsx,.xls"
                    />
                    <TypographyP className="text-center">ou</TypographyP>
                    <div
                        className={cn(
                            'h-[80px] flex items-center justify-center border border-dashed border-primary-500 rounded-lg',
                            isDragging && 'border-solid bg-primary-50/80'
                        )}
                        onDragOver={onDrag}
                        onDragEnter={onDrag}
                        onDragExit={onDragEnd}
                        onDragLeave={onDragEnd}
                        onDrop={onDrop}
                    >
                        <TypographyP className="text-sm text-primary-600">
                            Arraste o arquivo aqui
                        </TypographyP>
                    </div>
                    {sheetModel && (
                        <div className="flex items-center justify-between px-2 py-2 overflow-hidden hover:bg-slate-50">
                            <TypographyP className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                                {sheetModel.name}
                            </TypographyP>
                            <button
                                className="text-neutral-500"
                                onClick={() => setSheetModel(null)}
                            >
                                <X size={14} />
                            </button>
                        </div>
                    )}
                    <Button
                        disabled={!sheetModel}
                        className="w-full"
                        variant="secondary"
                        onClick={onConfirm}
                    >
                        Confirmar
                    </Button>
                </div>
            </PopoverContent>
        </Popover>
    )
}

export default SheetPopover
