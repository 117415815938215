import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { TypographyH3, TypographyP } from '@/components/ui/typography'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { DisclosureCommonProps } from '@/types/Disclosure'
import { formatCpfOrCnpj } from '@/utils/stringFormatter'
import ValidateTable from './components/ValidateTable'
import { Clock12, TriangleAlert } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { generatePurchaseOrder } from '@/api/business/cotacoes'
import { isAxiosError } from '@/api/business'
import { useToast } from '@/components/ui/use-toast'
import { useParams } from 'react-router-dom'
import { quotationMapKeys } from '@/queries/useQuotatioMapQuery'
import { quotationKeys } from '@/queries/useQuotationQuery'

type ValidateResponseDialogProps = DisclosureCommonProps & {
    onResponseDialogOpen: () => void
    onEndQuotationDialogClose: () => void
}

const stateSelector = (state: Store) => ({
    quotationMap: state.quotationMapSlice.state.quotationMap,
    selectedWinners: state.quotationMapSlice.state.selectedWinners,
    purchaseOrderValidateResponse:
        state.quotationMapSlice.state.purchaseOrderValidateResponse,
    setPurchaseOrderResponse:
        state.quotationMapSlice.actions.setPurchaseOrderResponse,
})

const ValidateResponseDialog = ({
    isOpen,
    onOpenChange,
    onResponseDialogOpen,
    onEndQuotationDialogClose,
}: ValidateResponseDialogProps) => {
    const { id } = useParams()
    const queryClient = useQueryClient()

    const {
        purchaseOrderValidateResponse,
        quotationMap: { VALORES },
        selectedWinners,
        setPurchaseOrderResponse,
    } = useBaseStore(stateSelector)

    const uniqueCompanies = [
        ...new Set(
            purchaseOrderValidateResponse.map((response) => response.SK_EMPRESA)
        ),
    ]

    const hasErrors = purchaseOrderValidateResponse.some(
        (response) => response.ERRO_ITEM || response.ERRO_CABECALHO
    )

    const { toast } = useToast()

    const { mutate, isPending } = useMutation({
        mutationFn: generatePurchaseOrder,
        onSuccess: (data) => {
            if (id) {
                queryClient.invalidateQueries({
                    queryKey: quotationMapKeys.detail(id),
                })
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.detail(id),
                })
            }
            onResponseDialogOpen()
            onEndQuotationDialogClose()
            setPurchaseOrderResponse(data)
            onOpenChange(false)
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível gerar o pedido de compras. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao gerar pedido de compras',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const onSubmit = () => {
        const selectedValues = VALORES.filter(
            (value) =>
                selectedWinners[value.SK_COTACAO_X_PRODUTO] &&
                selectedWinners[value.SK_COTACAO_X_PRODUTO][
                    value.SK_FORNECEDOR_X_RODADA
                ]
        )

        mutate({
            quotations: selectedValues.map((value) => ({
                quotationProductId: value.SK_COTACAO_X_PRODUTO,
                roundSupplierId: value.SK_FORNECEDOR_X_RODADA,
            })),
        })
    }

    if (purchaseOrderValidateResponse.length === 0) return null

    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            <DialogContent className="max-w-[90%] max-h-[90%] flex flex-col">
                <DialogHeader>
                    <DialogTitle>Resposta</DialogTitle>
                </DialogHeader>
                <div className="flex-1 space-y-4 overflow-auto">
                    {uniqueCompanies.map((companyId) => {
                        const responses = purchaseOrderValidateResponse.filter(
                            (response) => response.SK_EMPRESA === companyId
                        )

                        const hasError = responses.some(
                            (response) =>
                                response.ERRO_CABECALHO || response.ERRO_ITEM
                        )

                        const company = responses[0]

                        return (
                            <div key={companyId}>
                                <div className="space-y-4">
                                    <div className="flex items-center gap-2 py-2 mb-2 border-b">
                                        {hasError && (
                                            <TriangleAlert
                                                size={16}
                                                className="text-red-600"
                                            />
                                        )}
                                        <TypographyH3 className="text-md">
                                            {company.ABREVIATURA_EMPRESA} -{' '}
                                            {formatCpfOrCnpj(company.CNPJ)}
                                        </TypographyH3>
                                    </div>
                                    {responses.map((response) => {
                                        const errors = response.ERROS.map(
                                            (error) => error.message
                                        ).join('; ')

                                        return (
                                            <div
                                                key={
                                                    response.SK_RESPOSTA_FORNECEDOR
                                                }
                                                className="space-y-2"
                                            >
                                                <div>
                                                    <TypographyP>
                                                        {
                                                            response.DD_RAZAO_SOCIAL
                                                        }
                                                    </TypographyP>
                                                    {errors && (
                                                        <TypographyP className="text-red-500">
                                                            {errors}
                                                        </TypographyP>
                                                    )}
                                                </div>
                                                <ValidateTable
                                                    items={response.ITENS}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="space-y-2">
                    {hasErrors && (
                        <TypographyP>
                            <TriangleAlert
                                size={14}
                                className="inline-block mr-2 text-amber-600"
                            />
                            Há itens para os quais não será possível gerar
                            pedido de compras. Ao{' '}
                            <span className="font-bold">confirmar</span>, os
                            itens serão{' '}
                            <span className="font-bold">gravados</span>, e será
                            necessário o ajuste pelo Protheus.
                        </TypographyP>
                    )}
                    {isPending && (
                        <div className="flex items-center justify-end gap-2">
                            <Clock12
                                size={14}
                                className="animate-spin text-sky-500"
                            />
                            <TypographyP className="text-end">
                                Gerando pedido. Por favor, não saia da tela
                                enquanto a transação está sendo processada.
                            </TypographyP>
                        </div>
                    )}
                    <DialogFooter>
                        <Button onClick={onSubmit} disabled={isPending}>
                            Confirmar
                        </Button>
                    </DialogFooter>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ValidateResponseDialog
