import { useQuotationMapQuery } from '@/queries/useQuotatioMapQuery'
import { Link, useParams } from 'react-router-dom'
import QuotationTable from './components/QuotationMapTable'
import TopMessage from '@/components/TopMessage'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { TypographyH2 } from '@/components/ui/typography'
import SuppliersSection from './components/SuppliersSection'
import NavigationBar from '@/components/NavigationBar'
import {
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import QuotationMapActionBar from './components/QuotationMapActionBar'

const QuotationMap = () => {
    const { id } = useParams()

    const { data: quotationDetail, isError: isQuotationError } =
        useQuotationByIdQuery({ id: Number(id) })

    const {
        data: quotationMap,
        isFetching: isQuotationMapFetching,
        isError: isQuotationMapError,
    } = useQuotationMapQuery({ id: Number(id) })

    if (isQuotationError || isQuotationMapError)
        return 'Erro ao renderizar o mapa de cotações'

    if (!quotationDetail || !quotationMap) return 'Carregando...'

    return (
        <>
            <NavigationBar
                navigationList={
                    <BreadcrumbList>
                        <BreadcrumbItem>
                            <BreadcrumbLink asChild>
                                <Link to="/cotacoes">Cotações</Link>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbLink asChild>
                                <Link to={`/cotacoes/${id}`}>
                                    {quotationDetail.NUMERO_DA_COTACAO}
                                </Link>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbPage>Mapa</BreadcrumbPage>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                }
            >
                <QuotationMapActionBar />
            </NavigationBar>
            <div>
                {isQuotationMapFetching && (
                    <TopMessage variant="loading" text="Atualizando mapa..." />
                )}
                <SuppliersSection />
                <section className="flex flex-col h-screen p-4 space-y-4 overflow-hidden">
                    <div>
                        <TypographyH2>Mapa</TypographyH2>
                    </div>
                    <div className="flex flex-col flex-1 overflow-hidden">
                        <QuotationTable />
                    </div>
                </section>
            </div>
        </>
    )
}

export default QuotationMap
