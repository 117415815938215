import { isAxiosError } from '@/api/business'
import { removeSupplierFromQuotation } from '@/api/business/cotacoes'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { useToast } from '@/components/ui/use-toast'
import { quotationKeys } from '@/queries/useQuotationQuery'
import { QuotationSupplier } from '@/types/Suppliers'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { RowSelectionState } from '@tanstack/react-table'
import { useParams } from 'react-router-dom'

interface RemoveSupplierDialogProps {
    suppliers: QuotationSupplier[]
    isOpen: boolean
    onSelectRows: (rowSelection: RowSelectionState) => void
    onClose: () => void
}

const RemoveSupplierDialog = ({
    suppliers,
    isOpen,
    onSelectRows,
    onClose,
}: RemoveSupplierDialogProps) => {
    const { id } = useParams()
    const { toast } = useToast()
    const queryClient = useQueryClient()

    const { mutate, isPending } = useMutation({
        mutationFn: removeSupplierFromQuotation,
        onSuccess: () => {
            toast({
                title: 'Fornecedores removidos com sucesso',
            })
            if (id) {
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.detail(id),
                })
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.detailSuppliers(id),
                })
            }
            onSelectRows({})
            onClose()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível remover fornecedores. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao remover fornecedores',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-[680px]">
                <DialogHeader>
                    <DialogTitle>Remover fornecedores</DialogTitle>
                    <DialogDescription>
                        Realmente deseja remover os fornecedores da cotação?
                    </DialogDescription>
                </DialogHeader>
                <div className="space-y-2 divide-y max-h-[400px] overflow-auto">
                    {suppliers.map((supplier) => (
                        <div key={supplier.SK_FORNECEDOR} className="pt-2">
                            {supplier.DD_RAZAO_SOCIAL}
                        </div>
                    ))}
                </div>
                <DialogFooter>
                    <Button
                        className="w-[80px]"
                        variant="destructive"
                        disabled={suppliers.length === 0 || isPending}
                        onClick={() =>
                            mutate({
                                quotationId: Number(id),
                                suppliersIds: suppliers,
                            })
                        }
                    >
                        Remover
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default RemoveSupplierDialog
