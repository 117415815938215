import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'

const ExpiredToken = () => {
    return (
        <div className="px-8 my-4">
            <Alert variant="destructive">
                <ExclamationTriangleIcon className="w-4 h-4" />
                <AlertTitle>Sessão expirada</AlertTitle>
                <AlertDescription>
                    Sua sessão expirou. Por favor, faça login novamente.
                </AlertDescription>
            </Alert>
        </div>
    )
}

export default ExpiredToken
