import Table from '@/components/Table'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { TableData } from '@/components/Table/type'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip'
import { TypographyP } from '@/components/ui/typography'
import { Supplier } from '@/types/Suppliers'
import { createColumnHelper } from '@tanstack/react-table'
import { X } from 'lucide-react'
import { useMemo } from 'react'

type NewSuppliersTable = TableData<Supplier>

const columnHelper = createColumnHelper<NewSuppliersTable>()

interface NewSuppliersSectionProps {
    newSuppliers: Supplier[]
    onRemove: (id: string) => void
}

const NewSuppliersSection = ({
    newSuppliers,
    onRemove,
}: NewSuppliersSectionProps) => {
    const data = useMemo(() => newSuppliers, [newSuppliers])
    const columns = useMemo(
        () => [
            columnHelper.display({
                id: 'Ações',
                header: '',
                cell: ({ row }) => {
                    return (
                        <button
                            className="text-red-500"
                            onClick={() => onRemove(row.original.SK_FORNECEDOR)}
                        >
                            <X size={14} />
                        </button>
                    )
                },
                size: 40,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
                enableColumnFilter: false,
            }),
            columnHelper.display({
                id: 'Status',
                header: '',
                cell: () => {
                    return (
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <span className="px-2 py-1 bg-emerald-50 text-emerald-500">
                                        Novo
                                    </span>
                                </TooltipTrigger>
                                <TooltipContent>
                                    <TypographyP>
                                        Novo fornecedor adicionado
                                    </TypographyP>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    )
                },
                size: 80,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
                enableColumnFilter: false,
            }),

            columnHelper.accessor('DD_RAZAO_SOCIAL', {
                header: 'Razão social',
                size: 300,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
                enableColumnFilter: false,
            }),
            columnHelper.accessor('DD_CNPJ', {
                header: 'CNPJ',
                size: 200,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
                enableColumnFilter: false,
            }),
        ],
        [onRemove]
    )
    return (
        <div className="flex flex-col gap-2">
            <div>
                <TypographyP className="font-semibold">
                    Novos fornecedores adicionados
                </TypographyP>
            </div>
            <Table<NewSuppliersTable>
                data={data}
                columns={columns}
                getRowId={(row) => row.SK_FORNECEDOR}
                meta={{
                    header: {
                        className: 'border-none h-auto',
                    },
                    row: {
                        className: 'border-b',
                    },
                    cell: {
                        className: 'border-none',
                    },
                    layout: 'stretch',
                }}
                defaultColumn={{
                    cell: ({ getValue }) => {
                        return (
                            <DefaultColumn>
                                {getValue() as string}
                            </DefaultColumn>
                        )
                    },
                }}
            />
        </div>
    )
}

export default NewSuppliersSection
