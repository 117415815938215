import { format } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar, CalendarProps } from '@/components/ui/calendar'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover'
import { ptBR } from 'date-fns/locale'
import { TypographyP } from '../ui/typography'

type DatepickerProps = {
    date?: Date
    setDate: (date: Date | undefined) => void
} & CalendarProps

export const DatePicker = ({
    date,
    setDate,
    className,
    ...props
}: DatepickerProps) => {
    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant={'outline'}
                    className={cn(
                        'w-[280px] justify-start text-left font-normal',
                        !date && 'text-muted-foreground',
                        className
                    )}
                >
                    <CalendarIcon className="w-4 h-4 mr-2 text-popover-foreground" />
                    {date ? (
                        <TypographyP className="text-xs truncate">
                            {format(date, 'PP', {
                                locale: props.locale || ptBR,
                            })}
                        </TypographyP>
                    ) : (
                        <TypographyP className="text-xs truncate">
                            Escolha uma data
                        </TypographyP>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="p-0" align="start">
                <Calendar
                    {...props}
                    locale={props.locale || ptBR}
                    captionLayout="dropdown-buttons"
                    mode="single"
                    selected={date}
                    defaultMonth={date}
                    onSelect={setDate}
                    initialFocus
                    fromYear={2019}
                    toYear={2030}
                />
            </PopoverContent>
        </Popover>
    )
}
