export enum Operators {
    equals = 'EQUALS',
    like = 'LIKE',
    between = 'BETWEEN',
    not_null = 'NOT_NULL',
    null = 'IS_NULL',
    different = 'DIFFERENT',
    all = "REGEXP('.*')",
}

export type Joiners = 'OR' | 'AND'

export interface BaseFilter<T> {
    field: keyof T
}

interface BetweenFilter<T> extends BaseFilter<T> {
    operator: Operators.between
    value: string[] | null
}

export interface LikeFilter<T> extends BaseFilter<T> {
    operator: Operators.like
    value: string | number | null
}

interface EqualsFilter<T> extends BaseFilter<T> {
    operator: Operators.equals
    value: string | number | null
}

interface DifferentFilter<T> extends BaseFilter<T> {
    operator: Operators.different
    value: string | number | null
}

interface NotNullFilter<T> extends BaseFilter<T> {
    operator: Operators.not_null
}

interface NullFilter<T> extends BaseFilter<T> {
    operator: Operators.null
}

interface AllFilter<T> extends BaseFilter<T> {
    operator: Operators.all
}

export interface AndFilter<T> {
    AND: FilterTypes<T>[]
}

export interface OrFilter<T> {
    OR: FilterTypes<T>[]
}

export type FilterTypes<T> =
    | BetweenFilter<T>
    | LikeFilter<T>
    | EqualsFilter<T>
    | NotNullFilter<T>
    | NullFilter<T>
    | DifferentFilter<T>
    | AllFilter<T>
    | OrFilter<T>
    | AndFilter<T>

export type Filter<T> = OrFilter<T> | AndFilter<T>
