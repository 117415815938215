import Table from '@/components/Table'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { TableData } from '@/components/Table/type'
import { QuotationMapFlatState } from '@/store/quotationMapSlice/type'
import { currencyFormat, formatCpfOrCnpj } from '@/utils/stringFormatter'
import { createColumnHelper } from '@tanstack/react-table'
import { format, isValid } from 'date-fns'
import { useMemo } from 'react'

type PurchaseOrderTableProps = {
    values: QuotationMapFlatState[]
}

type TableType = TableData<QuotationMapFlatState>

const columnHelper = createColumnHelper<TableType>()

const PurchaseOrderTable = ({ values }: PurchaseOrderTableProps) => {
    const data = useMemo(() => values, [values])

    const columns = useMemo(
        () => [
            columnHelper.accessor('SK_EMPRESA', {
                header: 'Empresa',
                size: 100,
            }),
            columnHelper.accessor('NK_ITEM_SOLICITACAO_DE_COMPRAS', {
                header: 'Item',
                size: 100,
            }),
            columnHelper.accessor('NK_SOLICITACAO_DE_COMPRAS', {
                header: 'SC',
                size: 100,
            }),
            columnHelper.accessor('DS_PRODUTO', {
                header: 'Produto',
                size: 400,
            }),
            columnHelper.accessor('DD_UM', {
                header: 'UM',
                size: 100,
            }),
            columnHelper.accessor('CNPJ', {
                header: 'CNPJ',
                size: 120,
                meta: {
                    cell: {
                        formatterFn: ({ value }) => formatCpfOrCnpj(value),
                    },
                },
            }),
            columnHelper.accessor('DD_RAZAO_SOCIAL', {
                header: 'Fornecedor',
                size: 400,
            }),
            columnHelper.accessor('PRECO', {
                header: 'Preço',
                size: 100,
                meta: {
                    cell: {
                        formatterFn: ({ value }) => currencyFormat(value, 4),
                        className: 'justify-end',
                    },
                },
            }),
            columnHelper.accessor('PRECO_IMPOSTO', {
                header: 'Preço com imposto',
                size: 150,
                meta: {
                    cell: {
                        formatterFn: ({ value }) => currencyFormat(value, 4),
                        className: 'justify-end',
                    },
                },
            }),
            columnHelper.accessor('PRECO_TOTAL', {
                header: 'Preço total',
                size: 150,
                meta: {
                    cell: {
                        formatterFn: ({ value }) => currencyFormat(value, 4),
                        className: 'justify-end',
                    },
                },
            }),
            columnHelper.accessor('PRECO_TOTAL_IMPOSTO', {
                header: 'Preço total com imposto',
                size: 200,
                meta: {
                    cell: {
                        formatterFn: ({ value }) => currencyFormat(value, 4),
                        className: 'justify-end',
                    },
                },
            }),
            columnHelper.accessor('FRETE', {
                header: 'Frete',
                size: 100,
            }),
            columnHelper.accessor('DS_CONDICAO_DE_PAGAMENTO', {
                header: 'Condição de pagamento',
                size: 250,
            }),
            columnHelper.accessor('PREVISAO_DE_ENTREGA', {
                header: 'Previsão de entrega',
                size: 200,
                meta: {
                    cell: {
                        formatterFn: ({ value }) =>
                            value && isValid(new Date(value))
                                ? format(new Date(value), 'dd/MM/yyyy')
                                : '-',
                    },
                },
            }),
        ],
        []
    )

    return (
        <div className="flex flex-col overflow-hidden">
            <Table<TableType>
                data={data}
                columns={columns}
                meta={{ layout: 'stretch' }}
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultColumn>{getValue() as string}</DefaultColumn>
                    ),
                }}
            />
        </div>
    )
}

export default PurchaseOrderTable
