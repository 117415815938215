import { recoverPassword } from '@/api/auth/user'
import { Button } from '@/components/ui/button'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { TypographyH1, TypographyMuted } from '@/components/ui/typography'
import { useToast } from '@/components/ui/use-toast'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

const ResetPassword = () => {
    const schema = z.object({
        email: z.string().email(),
    })

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
    })

    const { toast } = useToast()
    const navigate = useNavigate()

    const { mutate, isPending } = useMutation({
        mutationFn: recoverPassword,
        onSuccess: () => {
            toast({
                title: 'Senha recuperada com sucesso.',
                description: `Nova senha enviada para o email ${form.getValues(
                    'email'
                )}`,
            })
            navigate('/login')
        },
        onError: () => {
            toast({
                title: 'Erro ao recuperar senha.',
                description: `Houve um erro ao recuperar a senha. Verifique os dados e tente novamente`,
                variant: 'destructive',
            })
        },
    })

    const onSubmit = (data: z.infer<typeof schema>) => {
        mutate(data.email)
    }

    return (
        <div className="container flex items-center justify-center h-full mx-auto">
            <Form {...form}>
                <form
                    className="w-1/2 p-10 space-y-4 max-w-[680px] shadow-md rounded-md border"
                    onSubmit={form.handleSubmit(onSubmit)}
                >
                    <div className="space-y-0.5">
                        <TypographyH1>Recuperação de senha</TypographyH1>
                        <TypographyMuted>
                            Digite seu email e confirme.
                        </TypographyMuted>
                    </div>
                    <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Email</FormLabel>
                                <FormControl>
                                    <Input {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <div className="flex">
                        <Button
                            className="w-[100px] ml-auto rounded-md"
                            disabled={isPending}
                        >
                            Confirmar
                        </Button>
                    </div>
                </form>
            </Form>
        </div>
    )
}

export default ResetPassword
