import { fetchParametrosAvaliacao } from '@/api/business/parametrosAvaliacao'
import { useQuery } from '@tanstack/react-query'

export const parametrosAvaliacaoKeys = {
    all: ['parametrosAvaliacao'] as const,
    lists: () => [...parametrosAvaliacaoKeys.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...parametrosAvaliacaoKeys.lists(), page, perPage, filters] as const,
    details: () => [...parametrosAvaliacaoKeys.all, 'detail'] as const,
    detail: (params: string) =>
        [...parametrosAvaliacaoKeys.details(), params] as const,
}

export const useParametrosAvaliacaoQuery = () => {
    return useQuery({
        queryFn: fetchParametrosAvaliacao,
        queryKey: parametrosAvaliacaoKeys.lists(),
    })
}
