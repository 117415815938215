import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { createColumnHelper } from '@tanstack/react-table'
import { ReactNode, useMemo, useState } from 'react'
import TableRowActions from '@/components/Table/components/TableRowActions'
import DeleteDialog from './components/DeleteDialog'
import EditDialog from './components/EditDialog'
import AddDialog from './components/AddDialog'
import { isEqual, isToday } from 'date-fns'
import DadosMestreHeader from '../components/DadosMestreHeader'
import DadosMestreHeaderActions from '../components/DadosMestreHeaderActions'
import { Supplier } from '@/types/Suppliers'
import { usePaginatedSuppliersQuery } from '@/queries/useSuppliersQuery'
import { Filter, Operators } from '@/types/Filters'
import { Mail } from 'lucide-react'
import useDisclosure from '@/hooks/useDisclosure'
import AddEmailDialog from './components/AddEmailDialog'
import { useSuppliersSpreadsheet } from './useSuppliersSpreadsheet'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { formatCpfOrCnpj } from '@/utils/stringFormatter'
import { useTable } from '@/hooks/useTable'

export type FornecedoresTable = TableData<Supplier>

const columnHelper = createColumnHelper<Supplier>()

const DadosMestreFornecedores = () => {
    const {
        table,
        isAddDialogOpen,
        isDeleteDialogOpen,
        isEditDialogOpen,
        selectedRow,
        selectedRows,
        pageIndex,
        pageSize,
        onPaginationChange,
        onColumnFiltersChange,
        onAddDialogClose,
        onClickAdd,
        onClickDelete,
        onClickEdit,
        onClickMultiDelete,
        onDeleteDialogClose,
        onEditDialogClose,
        setSelectedRows,
        setTable,
        setSelectedRow,
    } = useTable<Supplier>()

    const [fieldFilters, setFieldFilters] = useState<Filter<Supplier>[]>([])

    const {
        isOpen: isAddEmailDialogOpen,
        onOpen: onAddEmailDialogOpen,
        onClose: onAddEmailDialogClose,
    } = useDisclosure()

    const { data, isFetching, isPending, refetch } = usePaginatedSuppliersQuery(
        {
            currentPage: pageIndex,
            perPage: pageSize,
            filters: fieldFilters,
            enabled: true,
        }
    )

    const { onDownload } = useSuppliersSpreadsheet(table)

    const memoData = useMemo(() => data?.DADOS || [], [data])

    const memoColumns = useMemo(
        () => [
            columnHelper.accessor(
                ({ DD_BLOQUEADO }) => (DD_BLOQUEADO === 1 ? 'Bloqueado' : ''),
                {
                    id: 'DD_BLOQUEADO',
                    header: 'Status',
                    cell: ({ getValue }) => {
                        return getValue() === 'Bloqueado' ? (
                            <span className="px-2 text-red-500 rounded-md bg-red-50">
                                Bloqueado
                            </span>
                        ) : null
                    },
                    size: 100,
                    meta: {
                        cell: {
                            className: 'justify-center',
                        },
                    },
                }
            ),
            columnHelper.accessor('SK_FORNECEDOR', {
                id: 'SK_FORNECEDOR',
                header: 'Cod Fornecedor',
                size: 80,
            }),
            columnHelper.accessor('DD_NOME_FANTASIA', {
                id: 'DD_NOME_FANTASIA',
                header: 'Nome fantasia',
                size: 250,
            }),
            columnHelper.accessor('DD_RAZAO_SOCIAL', {
                id: 'DD_RAZAO_SOCIAL',
                header: 'Razão social',
                size: 250,
            }),
            columnHelper.accessor('DS_EMAIL', {
                id: 'DS_EMAIL',
                header: 'Email',
                size: 250,
            }),
            columnHelper.accessor('DD_CNPJ', {
                id: 'DD_CNPJ',
                header: 'CNPJ',
                size: 250,
                meta: {
                    cell: {
                        formatterFn: ({ value }) => {
                            return value && formatCpfOrCnpj(value)
                        },
                    },
                },
            }),
            columnHelper.accessor('DD_OBSERVACAO', {
                id: 'DD_OBSERVACAO',
                header: 'Observação',
                size: 200,
            }),
            columnHelper.accessor('DD_ORIGEM_DADO', {
                id: 'DD_ORIGEM_DADO',
                header: 'Origem',
                cell: ({ getValue }) => {
                    const value = getValue()
                    let Badge: ReactNode | null = null

                    switch (value) {
                        case 'MANUAL':
                            Badge = (
                                <span className="px-2 py-1 text-xs text-green-600 rounded-md bg-green-50">
                                    {value}
                                </span>
                            )
                            break
                        case 'PROTHEUS':
                            Badge = (
                                <span className="px-2 py-1 text-xs rounded-md text-cyan-600 bg-blue-50">
                                    {value}
                                </span>
                            )

                            break
                    }

                    return Badge
                },
                meta: {
                    cell: {
                        className: 'flex items-center justify-center',
                    },
                },
                size: 110,
            }),
            columnHelper.display({
                id: 'Ações',
                cell: ({ row }) => {
                    const { DD_ORIGEM_DADO } = row.original
                    return DD_ORIGEM_DADO === 'MANUAL' ? (
                        <TableRowActions
                            onClickDelete={() => onClickDelete(row)}
                            onClickEdit={() => onClickEdit(row)}
                            actions={[
                                {
                                    icon: <Mail size={14} />,
                                    label: 'Adicionar email',
                                    action: () => {
                                        setSelectedRow(row)
                                        onAddEmailDialogOpen()
                                    },
                                },
                            ]}
                        />
                    ) : (
                        <TableRowActions
                            actions={[
                                {
                                    icon: <Mail size={14} />,
                                    label: 'Adicionar email',
                                    action: () => {
                                        setSelectedRow(row)
                                        onAddEmailDialogOpen()
                                    },
                                },
                            ]}
                        />
                    )
                },
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.display({
                id: 'Tipo',
                cell: ({ row }) => {
                    const { CREATED_AT, UPDATED_AT } = row.original
                    let Badge: ReactNode | null = null
                    const createdAt = new Date(CREATED_AT)
                    const updatedAt = new Date(UPDATED_AT)

                    if (isEqual(createdAt, updatedAt)) {
                        if (isToday(createdAt)) {
                            Badge = (
                                <span className="px-2 py-1 text-xs rounded-md text-emerald-500 ">
                                    Novo *
                                </span>
                            )
                        }
                    } else {
                        Badge = (
                            <span className="px-2 py-1 text-xs rounded-md text-zinc-400">
                                Editado
                            </span>
                        )
                    }

                    return Badge
                },
                meta: {
                    cell: {
                        className: 'flex items-center justify-center',
                    },
                },
                size: 110,
                enableColumnFilter: false,
                enableSorting: false,
            }),
        ],
        []
    )

    return (
        <>
            <div className="w-full h-full p-4 overflow-hidden">
                <Table<FornecedoresTable>
                    data={memoData}
                    columns={memoColumns}
                    getRowId={(row) => row.SK_FORNECEDOR.toString()}
                    isLoading={isPending}
                    isFetching={isFetching}
                    getTableInstance={(table) => setTable(table)}
                    onRowSelectionChange={setSelectedRows}
                    tableState={{
                        rowSelection: selectedRows,
                        pagination: {
                            pageIndex,
                            pageSize,
                        },
                    }}
                    enableRowSelection={(row) =>
                        row.original.DD_ORIGEM_DADO === 'MANUAL'
                    }
                    onColumnFiltersChange={(columnFilter) => {
                        if (columnFilter.length > 0) {
                            const filters: Filter<Supplier>[] = [
                                {
                                    AND: columnFilter.map((filter) => {
                                        const field =
                                            filter.id as keyof Supplier

                                        if (field === 'DD_BLOQUEADO') {
                                            return {
                                                field,
                                                operator: Operators.equals,
                                                value:
                                                    filter.value === 'Bloqueado'
                                                        ? 1
                                                        : 0,
                                            }
                                        }

                                        return {
                                            field,
                                            operator: Operators.like,
                                            value: filter.value as string,
                                        }
                                    }),
                                },
                            ]

                            setFieldFilters(filters)
                        } else {
                            setFieldFilters([])
                        }
                        onColumnFiltersChange(columnFilter)
                    }}
                    onPaginationChange={onPaginationChange}
                    pagination={{
                        pageSize,
                        totalPages: data?.TOTAL_DE_PAGINAS || 0,
                        totalItems: data?.TOTAL || 0,
                    }}
                    tableHeader={
                        <DadosMestreHeader
                            title={`Fornecedores`}
                            onDelete={onClickMultiDelete}
                            selectedRows={
                                table
                                    ?.getFilteredRowModel()
                                    .flatRows.filter(
                                        (row) => selectedRows[row.id]
                                    ).length
                            }
                        />
                    }
                    tableActions={
                        <DadosMestreHeaderActions
                            onAdd={onClickAdd}
                            onDownload={() => onDownload()}
                            onRefech={refetch}
                            tableId="DADOS_MESTRE_FORNECEDORES"
                            layout
                        />
                    }
                    defaultColumn={{
                        cell: ({ getValue }) => (
                            <DefaultColumn>
                                {getValue() as string}
                            </DefaultColumn>
                        ),
                    }}
                />
            </div>
            {isDeleteDialogOpen && (
                <DeleteDialog
                    ids={
                        selectedRow
                            ? [selectedRow.original.SK_FORNECEDOR]
                            : table
                            ? table
                                  .getFilteredRowModel()
                                  .flatRows.filter(
                                      (row) => selectedRows[row.id]
                                  )
                                  .map((row) => row.original.SK_FORNECEDOR)
                            : []
                    }
                    isOpen={isDeleteDialogOpen}
                    onClose={onDeleteDialogClose}
                />
            )}
            {selectedRow && isEditDialogOpen && (
                <EditDialog
                    supplier={selectedRow.original}
                    isOpen={isEditDialogOpen}
                    onClose={onEditDialogClose}
                />
            )}
            {isAddDialogOpen && (
                <AddDialog
                    isOpen={isAddDialogOpen}
                    onClose={onAddDialogClose}
                />
            )}
            {selectedRow && (
                <AddEmailDialog
                    supplier={selectedRow.original}
                    isOpen={isAddEmailDialogOpen}
                    onClose={onAddEmailDialogClose}
                />
            )}
        </>
    )
}

export default DadosMestreFornecedores
