import { read, utils, writeFile } from 'xlsx'
import { format, isValid } from 'date-fns'
import { useToast } from '@/components/ui/use-toast'
import { useBaseStore } from '@/store'
import { getFileExtension } from '@/utils/file'
import { QuotationMapFlatState } from '@/store/quotationMapSlice/type'
import { getUpdatedSupplierValuesSelector } from '@/store/quotationMapSlice'
import { useParams } from 'react-router-dom'

type Headers = {
    original: keyof QuotationMapFlatState
    header: string
}

const headers: Headers[] = [
    {
        header: 'Cod supply',
        original: 'SK_RESPOSTA_FORNECEDOR',
    },
    {
        header: 'Cod Produto',
        original: 'SK_PRODUTO',
    },
    {
        header: 'Produto',
        original: 'DS_PRODUTO',
    },
    {
        header: 'Empresa',
        original: 'ABREVIATURA_EMPRESA',
    },
    {
        header: 'Preço',
        original: 'PRECO',
    },
    {
        header: 'Qtd disponível',
        original: 'QTD_DISPONIVEL',
    },
    {
        header: 'Observação',
        original: 'OBSERVACAO',
    },
    {
        header: 'Previsão de entrega',
        original: 'PREVISAO_DE_ENTREGA',
    },
    {
        header: 'ST',
        original: 'ST',
    },
    {
        header: 'PIS',
        original: 'PIS',
    },
    {
        header: 'ICMS',
        original: 'ICMS',
    },
    {
        header: 'IPI',
        original: 'IPI',
    },
    {
        header: 'COFINS',
        original: 'COFINS',
    },
    {
        header: 'FCP',
        original: 'FCP',
    },
]

export const useEditDialogSheet = () => {
    const { supplierId } = useParams()

    const values = useBaseStore(getUpdatedSupplierValuesSelector(supplierId!))
    const { toast } = useToast()

    const onUploadSheet = async (sheet: File) => {
        const extension = getFileExtension(sheet.name)

        if (!extension) {
            toast({
                description: 'Arquivo inválido.',
                variant: 'destructive',
            })
            return []
        }

        if (!['xlsx', 'xls'].includes(extension)) {
            toast({
                description: 'Arquivo deve ser do tipo XLSX ou XLS.',
                variant: 'destructive',
            })
            return []
        }

        const data = await sheet.arrayBuffer()

        const workbook = read(data, {
            type: 'binary',
            cellDates: true,
        })

        const produtosSheet = workbook.Sheets.PRODUTOS
        const produtosSheetJson =
            utils.sheet_to_json<QuotationMapFlatState>(produtosSheet)

        return produtosSheetJson.map((product) => {
            const obj = {} as Record<keyof QuotationMapFlatState, any>

            for (const [key, value] of Object.entries(product)) {
                const header = Object.values(headers).find(
                    (header) => header.header === key
                )?.original

                if (header) {
                    switch (header) {
                        case 'PREVISAO_DE_ENTREGA':
                            const date =
                                value && isValid(new Date(value))
                                    ? format(new Date(value), 'dd/MM/yyyy')
                                    : value

                            if (date) {
                                obj[header] = value
                            }
                            break
                        default:
                            obj[header] = value
                            break
                    }
                }
            }

            return obj
        })
    }

    const onDownloadSheet = () => {
        const sheetData = values.map((produto) => {
            const obj = {} as Record<string, any>

            for (const [key, value] of Object.entries(produto)) {
                const header = headers.find(
                    (header) => header.original === key
                )?.header

                if (header) {
                    switch (key) {
                        case 'PREVISAO_DE_ENTREGA':
                            obj[header] =
                                value && isValid(new Date(value))
                                    ? format(new Date(value), 'dd/MM/yyyy')
                                    : null
                            break
                        case 'SK_PRODUTO':
                            obj[
                                header
                            ] = `${produto.SK_RESPOSTA_FORNECEDOR}${produto.SK_PRODUTO}`
                            break
                        default:
                            obj[header] = value
                            break
                    }
                }
            }

            return obj
        })

        const produtosWorksheet = utils.json_to_sheet(sheetData)
        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, produtosWorksheet, 'PRODUTOS')
        writeFile(workbook, 'Planilha Modelo.xlsx', { compression: true })
    }

    return {
        onDownloadSheet,
        onUploadSheet,
    }
}
