import { useEffect } from 'react'
import EditModeTable from './components/EditModeTable'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import { TypographyH2 } from '@/components/ui/typography'

interface EditModeProps {
    supplierId: string
}

const stateSelector = (state: Store) => ({
    onSelectSupplierToUpdate:
        state.quotationMapSlice.actions.onSelectSupplierToUpdate,
})

const EditMode = ({ supplierId }: EditModeProps) => {
    const { onSelectSupplierToUpdate } = useBaseStore(stateSelector)

    useEffect(() => {
        onSelectSupplierToUpdate(supplierId)
    }, [supplierId])

    return (
        <div className="flex flex-col flex-1 gap-4 overflow-hidden">
            <div>
                <TypographyH2 className="font-semibold">
                    Respondendo pelo fornecedor
                </TypographyH2>
            </div>
            <div className="flex flex-col flex-1 overflow-hidden">
                <EditModeTable supplierId={supplierId} />
            </div>
        </div>
    )
}

export default EditMode
