import { cn } from '@/lib/utils'
import { Attachment } from '@/types/Attachments'
import { fileClassNames, getFileExtension } from '@/utils/file'
import { FileText, X } from 'lucide-react'
import FileMenuDropdown from './components/FileMenuDropdown'

interface FileCommonProps {
    onRemove?: () => void
}

type FileProps =
    | ({
          type: 'file'
          id: string
          file: File
      } & FileCommonProps)
    | ({
          type: 'attachment'
          file: Attachment
          onDownload?: () => void
      } & FileCommonProps)

const File = (props: FileProps) => {
    if (props.type === 'file') {
        return (
            <div
                className={cn(
                    'flex items-center  justify-between rounded-lg gap-2 overflow-hidden px-4 py-2 text-primary-600 bg-primary-50 cursor-pointer hover:bg-primary-100',
                    fileClassNames[getFileExtension(props.file.name) || 'txt']
                )}
            >
                <div className="flex items-center flex-1 gap-2 overflow-hidden">
                    <FileText size={16} />
                    <p className="flex-1 overflow-hidden text-xs whitespace-nowrap text-ellipsis">
                        {props.file.name}
                    </p>
                </div>
                <div className="flex items-center gap-2">
                    {props.onRemove && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation()
                                props.onRemove && props.onRemove()
                            }}
                        >
                            <X size={14} />
                        </button>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div
            className={cn(
                'flex items-center w-full overflow-hidden justify-between rounded-lg gap-2 px-4 py-2 text-primary-600 bg-primary-50 cursor-pointer hover:bg-primary-100',
                fileClassNames[props.file.EXTENSAO_DO_ARQUIVO]
            )}
        >
            <div className="flex items-center flex-1 gap-2 overflow-hidden">
                <FileText size={16} />
                <span className="flex-1 overflow-hidden text-xs whitespace-nowrap text-ellipsis">
                    {props.file.ARQUIVO}
                </span>
            </div>
            <FileMenuDropdown
                onDownload={
                    props.onDownload
                        ? () => props.onDownload && props.onDownload()
                        : undefined
                }
                onRemove={
                    props.onRemove
                        ? () => props.onRemove && props.onRemove()
                        : undefined
                }
            />
        </div>
    )
}

export default File
