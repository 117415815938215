import Select from '@/components/Select'
import { useBaseStore } from '@/store'
import {
    quotationDetailActionsSelector,
    quotationDetailStateSelector,
} from '@/store/quotationDetailSlice'

const RoundSelect = () => {
    const { rounds, selectedRoundId } = useBaseStore(
        quotationDetailStateSelector
    )
    const { onSelectRound } = useBaseStore(quotationDetailActionsSelector)

    return (
        <Select
            options={rounds.map((round) => ({
                label: `${round.round}ª rodada`,
                value: round.roundId.toString(),
            }))}
            onChange={(value) => onSelectRound(Number(value))}
            value={selectedRoundId?.toString() || ''}
            disabled={!selectedRoundId}
            placeholder={!selectedRoundId ? 'Carregando rodadas...' : ''}
        />
    )
}

export default RoundSelect
