import { addDays, format } from 'date-fns'
import { ImmerStateCreator, Store } from '../type'
import {
    FilterKeys,
    Filters,
    QuotationPurchaseRequestSlice,
    QuotationPurchaseRequestState,
} from './type'
import { AndFilter, Operators } from '@/types/Filters'
import { PurchaseRequestFlat } from '@/api/business/solicitacaoDeCompra/type'

export const initialFiltersState: Filters = {
    approvalDate: undefined,
    emissionDate: undefined,
    necessityDate: undefined,
    interval: '',
    priority: '',
    buyer: true,
}

const initialState: QuotationPurchaseRequestState = {
    selectedItems: [],
    rowSelection: {},
    suppliersRowSelection: {},
    selectedItemId: null,
    formState: {
        quotation: '',
        description: '',
        expirationDate: addDays(new Date(), 1).toString(),
        paymentConditions: [],
    },
    selectedSuppliers: [],
    attachments: [],
    columnFilters: [],
    filters: initialFiltersState,
    paymentConditionsSelected: [],
    showAllPaymentCondition: false,
}

export const useQuotationPurchaseRequestSlice: ImmerStateCreator<
    QuotationPurchaseRequestSlice
> = (set) => ({
    state: initialState,
    actions: {
        onSelectItem: (id) => {
            set((state) => {
                state.quotationPurchaseRequestSlice.state.selectedItemId = id
            })
        },
        onSelectItems: (rowSelection, items) => {
            set((state) => {
                const prevState = state.quotationPurchaseRequestSlice.state

                const uniqueIds = [
                    ...new Set(
                        prevState.selectedItems.map(
                            (item) => item.SK_SOLICITACAO_DE_COMPRAS
                        )
                    ),
                ]

                prevState.selectedItems = [
                    // ...items,
                    ...prevState.selectedItems,
                    ...items.filter(
                        (item) =>
                            !uniqueIds.includes(item.SK_SOLICITACAO_DE_COMPRAS)
                    ),
                ]
                prevState.rowSelection = rowSelection
            })
        },
        onDeselectItem: (id) => {
            set((state) => {
                const prevState = state.quotationPurchaseRequestSlice.state

                prevState.selectedItems = prevState.selectedItems.filter(
                    (item) => item.SK_SOLICITACAO_DE_COMPRAS !== id
                )

                prevState.rowSelection[id] = false
            })
        },
        onDeselectAllItems: () => {
            set((state) => {
                state.quotationPurchaseRequestSlice.state.rowSelection = {}
                state.quotationPurchaseRequestSlice.state.selectedItems = []
            })
        },
        onChangeFormState: (name, value) => {
            set((state) => {
                state.quotationPurchaseRequestSlice.state.formState[name] =
                    value
            })
        },
        onSelectSuppliers: (rowSelection, suppliers) => {
            set((state) => {
                const prevState = state.quotationPurchaseRequestSlice.state

                const uniqueIds = [
                    ...new Set(
                        prevState.selectedSuppliers.map(
                            (item) => item.SK_FORNECEDOR
                        )
                    ),
                ]
                prevState.selectedSuppliers = [
                    ...prevState.selectedSuppliers,
                    ...suppliers.filter(
                        (item) => !uniqueIds.includes(item.SK_FORNECEDOR)
                    ),
                ]

                prevState.suppliersRowSelection = rowSelection
            })
        },
        onRemoveSupplier: (id) => {
            set((state) => {
                state.quotationPurchaseRequestSlice.state.selectedSuppliers =
                    state.quotationPurchaseRequestSlice.state.selectedSuppliers.filter(
                        (supplier) => supplier.SK_FORNECEDOR.toString() !== id
                    )
            })
        },
        onRemoveAllSuppliers: () => {
            set((state) => {
                state.quotationPurchaseRequestSlice.state.suppliersRowSelection =
                    {}
                state.quotationPurchaseRequestSlice.state.selectedSuppliers = []
            })
        },
        onAddAttachments: (attachments) => {
            set((state) => {
                state.quotationPurchaseRequestSlice.state.attachments.push(
                    ...attachments
                )
            })
        },
        onRemoveAttachment: (id) => {
            set((state) => {
                state.quotationPurchaseRequestSlice.state.attachments =
                    state.quotationPurchaseRequestSlice.state.attachments.filter(
                        (attachment) => attachment.id !== id
                    )
            })
        },
        onAddColumnFilter: (filters) => {
            set((state) => {
                state.quotationPurchaseRequestSlice.state.columnFilters =
                    filters
            })
        },
        onAddFilter: (field, value) => {
            set((state) => {
                state.quotationPurchaseRequestSlice.state.filters[field] = value
            })
        },
        onResetFilters: () => {
            set((state) => {
                state.quotationPurchaseRequestSlice.state.filters =
                    initialFiltersState
                state.quotationPurchaseRequestSlice.state.columnFilters = []
            })
        },
        setPaymentConditionsSelected: (paymentConditions) => {
            set((state) => {
                state.quotationPurchaseRequestSlice.state.paymentConditionsSelected =
                    paymentConditions
            })
        },
        onChangePaymentCondition: (newP) => {
            set((state) => {
                const prevState = state.quotationPurchaseRequestSlice.state
                prevState.paymentConditionsSelected =
                    prevState.paymentConditionsSelected.some(
                        (item) =>
                            item.SK_CONDICAO_DE_PAGAMENTO ===
                            newP.SK_CONDICAO_DE_PAGAMENTO
                    )
                        ? prevState.paymentConditionsSelected.filter(
                              (paymentCondition) =>
                                  paymentCondition.SK_CONDICAO_DE_PAGAMENTO !==
                                  newP.SK_CONDICAO_DE_PAGAMENTO
                          )
                        : [
                              ...prevState.paymentConditionsSelected,
                              { ...newP, DD_PREFERIDA: 0 },
                          ]
            })
        },
        onChangePreference: (id) => {
            set((state) => {
                state.quotationPurchaseRequestSlice.state.paymentConditionsSelected.forEach(
                    (paymentCondition) => {
                        if (paymentCondition.SK_CONDICAO_DE_PAGAMENTO === id) {
                            paymentCondition.DD_PREFERIDA =
                                paymentCondition.DD_PREFERIDA === 1 ? 0 : 1
                        }
                    }
                )
            })
        },
        onChangeShowAllPaymentCondition: (value) => {
            set((state) => {
                state.quotationPurchaseRequestSlice.state.showAllPaymentCondition =
                    value
            })
        },
        onReset: () =>
            set((state) => {
                state.quotationPurchaseRequestSlice.state = initialState
            }),
    },
})

export const quotationPurchaseRequestStateSelector = (state: Store) =>
    state.quotationPurchaseRequestSlice.state

export const quotationPurchaseRequestActionsSelector = (state: Store) =>
    state.quotationPurchaseRequestSlice.actions

export const getSelectedItemSelector = (state: Store) =>
    state.quotationPurchaseRequestSlice.state.selectedItems.find(
        (item) =>
            item.SK_SOLICITACAO_DE_COMPRAS ===
            state.quotationPurchaseRequestSlice.state.selectedItemId
    )

export const getSelectedItemsSelector = (state: Store) =>
    state.quotationPurchaseRequestSlice.state.selectedItems.filter((item) =>
        Object.keys(
            state.quotationPurchaseRequestSlice.state.rowSelection
        ).includes(item.SK_SOLICITACAO_DE_COMPRAS)
    )

export const getSuppliersSelectedSelector = (state: Store) =>
    state.quotationPurchaseRequestSlice.state.selectedSuppliers.filter((item) =>
        Object.keys(
            state.quotationPurchaseRequestSlice.state.suppliersRowSelection
        ).includes(item.SK_FORNECEDOR.toString())
    )

export const getFiltersSelector = (state: Store) => {
    const filters = Object.entries(
        state.quotationPurchaseRequestSlice.state.filters
    )
        .filter(([key, value]) => key && value)
        .map(([key, v]) => {
            const value = v as string | { from: string; to: string }
            switch (key as FilterKeys) {
                case 'interval':
                    return {
                        field: 'INTERVALO_PRAZO',
                        operator: Operators.like,
                        value: value,
                    }
                case 'priority':
                    return {
                        field: 'DD_URGENCIA',
                        operator: Operators.like,
                        value: value,
                    }
                case 'approvalDate':
                    return {
                        field: 'DT_APROVACAO',
                        operator: Operators.between,
                        value: [
                            format(
                                new Date((value as { from: string }).from),
                                'yyyyMMdd'
                            ),
                            format(
                                new Date((value as { to: string }).to),
                                'yyyyMMdd'
                            ),
                        ],
                    }
                case 'emissionDate':
                    return {
                        field: 'DT_EMISSAO',
                        operator: Operators.between,
                        value: [
                            format(
                                new Date((value as { from: string }).from),
                                'yyyyMMdd'
                            ),
                            format(
                                new Date((value as { to: string }).to),
                                'yyyyMMdd'
                            ),
                        ],
                    }
                case 'necessityDate':
                    return {
                        field: 'DT_NECESSIDADE',
                        operator: Operators.between,
                        value: [
                            format(
                                new Date((value as { from: string }).from),
                                'yyyyMMdd'
                            ),
                            format(
                                new Date((value as { to: string }).to),
                                'yyyyMMdd'
                            ),
                        ],
                    }
                case 'buyer':
                    return {
                        field: 'COMPRADOR',
                        operator: Operators.equals,
                        value,
                    }
                default:
                    return {
                        field: key,
                        operator: Operators.between,
                        value: value,
                    }
            }
        })

    if (filters.length > 0) {
        return [
            ...state.quotationPurchaseRequestSlice.state.columnFilters,
            {
                AND: filters,
            } as AndFilter<PurchaseRequestFlat>,
        ]
    }

    return state.quotationPurchaseRequestSlice.state.columnFilters
}

export const getPaymentConditionByIdSelector = (id: string) => (state: Store) =>
    state.quotationPurchaseRequestSlice.state.paymentConditionsSelected.find(
        (condition) => condition.SK_CONDICAO_DE_PAGAMENTO === id
    )
