import {
    TypographyH1,
    TypographyLead,
    TypographyP,
} from '@/components/ui/typography'
import QuotationEndTable from './components/QuotationEndTable'
import { Link, useParams } from 'react-router-dom'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import EndQuotationDialog from './components/EndQuotationDialog'
import useDisclosure from '@/hooks/useDisclosure'
import ValidateResponseDialog from './components/ValidateResponseDialog'
import EndQuotationResponseDialog from './components/EndQuotationResponseDialog'
import { Button } from '@/components/ui/button'
import { ArrowLeft, BookOpenCheck, CheckCircle } from 'lucide-react'
import ApprovalQuotationDialog from './components/ApprovalQuotationDialog'

const QuotationEnd = () => {
    const { id } = useParams()

    const {
        isOpen: isPurchaseOrderDialogOpen,
        onOpenChange: onPurchaseOrderDialogOpenChange,
    } = useDisclosure()

    const {
        isOpen: isValidateResponseDialogOpen,
        onOpenChange: onValidateResponseDialogOpen,
    } = useDisclosure()

    const {
        isOpen: isEndQuotationDialogOpen,
        onOpenChange: onEndQuotationDialogOpenChange,
    } = useDisclosure()

    const {
        isOpen: isApprovalQuotationDialogOpen,
        onOpenChange: onApprovalQuotationDialogOpenChange,
    } = useDisclosure()

    const { data: quotationDetail } = useQuotationByIdQuery({ id: Number(id) })

    const disabled = quotationDetail?.DD_MAPA_APROVADO !== 1

    const approvalDisabled = [
        quotationDetail?.DD_MAPA_RECUSADO,
        quotationDetail?.DD_MAPA_APROVADO,
        quotationDetail?.DD_EM_APROVACAO,
        quotationDetail?.DD_FINALIZADA,
        quotationDetail?.DD_CANCELADA,
        quotationDetail?.DD_COTACAO_FINALIZADA,
    ].includes(1)

    return (
        <>
            <div className="flex justify-between p-4">
                <div>
                    <Link
                        to={`/cotacoes/${id}/?tab=map`}
                        className="flex items-center gap-2"
                    >
                        <ArrowLeft size={14} />
                        <TypographyP>Voltar para o mapa</TypographyP>
                    </Link>
                </div>
                <div className="space-x-2">
                    <Button
                        variant="outline"
                        className="gap-2"
                        disabled={approvalDisabled}
                        onClick={() =>
                            onApprovalQuotationDialogOpenChange(true)
                        }
                    >
                        <BookOpenCheck size={14} className="text-blue-500" />{' '}
                        Criar aprovação
                    </Button>
                    <Button
                        variant="outline"
                        className="gap-2"
                        disabled={disabled}
                        onClick={() => onEndQuotationDialogOpenChange(true)}
                    >
                        <CheckCircle size={14} className="text-emerald-500" />{' '}
                        Gerar pedido
                    </Button>
                </div>
            </div>
            <div className="px-4 space-y-4">
                <div>
                    <TypographyH1>Finalizar cotação</TypographyH1>
                    {quotationDetail?.DD_EM_APROVACAO === 1 && (
                        <TypographyP className="text-base text-amber-600">
                            Aguardando aprovação
                        </TypographyP>
                    )}
                    {quotationDetail?.DD_MAPA_RECUSADO === 1 && (
                        <TypographyP className="text-base text-destructive">
                            Mapa recusado por aprovador
                        </TypographyP>
                    )}
                    {quotationDetail?.DD_MAPA_APROVADO === 1 && (
                        <TypographyP className="text-base text-green-600">
                            Mapa aprovado
                        </TypographyP>
                    )}
                    <TypographyLead className="text-md">
                        Selecione os valores para a geração do pedido de compras
                    </TypographyLead>
                </div>
                <QuotationEndTable />
            </div>
            <EndQuotationResponseDialog
                isOpen={isPurchaseOrderDialogOpen}
                onOpenChange={onPurchaseOrderDialogOpenChange}
            />
            <ValidateResponseDialog
                isOpen={isValidateResponseDialogOpen}
                onOpenChange={onValidateResponseDialogOpen}
                onResponseDialogOpen={() =>
                    onPurchaseOrderDialogOpenChange(true)
                }
                onEndQuotationDialogClose={() =>
                    onEndQuotationDialogOpenChange(false)
                }
            />
            <ApprovalQuotationDialog
                isOpen={isApprovalQuotationDialogOpen}
                onOpenChange={onApprovalQuotationDialogOpenChange}
            />
            <EndQuotationDialog
                isOpen={isEndQuotationDialogOpen}
                onOpenChange={onEndQuotationDialogOpenChange}
                onValidateResponseDialogOpen={() =>
                    onValidateResponseDialogOpen(true)
                }
            />
        </>
    )
}

export default QuotationEnd
