import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { cn } from '@/lib/utils'
import { ShippingCompany } from '@/types/ShippingCompany'
import { CheckIcon } from 'lucide-react'
import { HTMLAttributes, forwardRef } from 'react'

type ShippingItemProps = HTMLAttributes<HTMLDivElement> & {
    data: ShippingCompany
    selected: boolean
    onClick: () => void
}

const ShippingItem = forwardRef<HTMLDivElement, ShippingItemProps>(
    ({ data, selected, onClick, className, ...props }, ref) => {
        const { NM_TRANSPORTADORA, NM_REDUZIDO, DD_UF } = data

        return (
            <div
                className={cn(
                    'flex items-center gap-4 p-2 cursor-pointer hover:bg-accent',
                    className
                )}
                onClick={onClick}
                ref={ref}
                {...props}
            >
                <div className="w-4 h-4">
                    {selected && <CheckIcon size={14} />}
                </div>
                <div>
                    <TypographyP>{NM_TRANSPORTADORA}</TypographyP>
                    <TypographyMuted>
                        {NM_REDUZIDO} {NM_REDUZIDO && '-'} {DD_UF && `${DD_UF}`}
                    </TypographyMuted>
                </div>
            </div>
        )
    }
)

export default ShippingItem
