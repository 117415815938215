import Select from '@/components/Select'
import { Label } from '@/components/ui/label'
import { useBaseStore } from '@/store'
import { getSelectedFreightSelector } from '@/store/quotationMapSlice'

import { Store } from '@/store/type'

const FREIGHTS = [
    {
        value: 'CIF',
        label: 'CIF',
    },
    {
        value: 'FOB',
        label: 'FOB',
    },
]

const stateSelector = (state: Store) => ({
    onSelectFreight: state.quotationMapSlice.actions.onSelectFreight,
})

const FreightSelect = () => {
    const freight = useBaseStore(getSelectedFreightSelector)
    const { onSelectFreight } = useBaseStore(stateSelector)

    return (
        <div className="space-y-1.5">
            <Label>Selecionar frete</Label>
            <Select
                placeholder="Selecione um frete"
                className="w-[150px]"
                options={FREIGHTS}
                value={freight || ''}
                onChange={(value) => onSelectFreight(value)}
            />
        </div>
    )
}

export default FreightSelect
