import { DatePicker } from '@/components/DatePicker'
import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { cn } from '@/lib/utils'
import { useBaseStore } from '@/store'
import { getLastRoundSelector } from '@/store/quotationDetailSlice'
import { getUpdatedSupplierValuesSelector } from '@/store/quotationMapSlice'
import { QuotationMapFlatState } from '@/store/quotationMapSlice/type'
import { Store } from '@/store/type'
import { numberFormat, percentageFormat } from '@/utils/stringFormatter'
import { createColumnHelper } from '@tanstack/react-table'
import { isBefore, isValid } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import TableHeader from './components/TableHeader'
import { RotateCcw, X } from 'lucide-react'
import TableActions from './components/TableActions'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { TypographyP } from '@/components/ui/typography'
import { SavingTypeMapper } from '@/api/business/cotacoes/type'

const columnHelper = createColumnHelper<QuotationMapFlatState>()

type SupplierTable = TableData<QuotationMapFlatState>

interface EditModeTableProps {
    supplierId: string
}

const stateSelector = (state: Store) => ({
    updatedValues: state.quotationMapSlice.state.supplierDialog.updatedValues,
    selectedCompanyId:
        state.quotationMapSlice.state.supplierDialog.selectedCompanyId,
    companies: state.quotationMapSlice.state.quotationMap.EMPRESAS,
    onUpdateSupplierValue:
        state.quotationMapSlice.actions.onUpdateSupplierValue,
})

const EditModeTable = ({ supplierId }: EditModeTableProps) => {
    const { selectedCompanyId, updatedValues, onUpdateSupplierValue } =
        useBaseStore(stateSelector)

    const values = useBaseStore(getUpdatedSupplierValuesSelector(supplierId))
    const lastRound = useBaseStore(getLastRoundSelector)

    const data = useMemo(() => {
        return lastRound
            ? values.filter(
                  (value) =>
                      value.SK_COTACAO_X_RODADA === lastRound.roundId &&
                      value.SK_EMPRESA === selectedCompanyId
              )
            : []
    }, [values, selectedCompanyId, updatedValues])

    const columns = useMemo(
        () => [
            columnHelper.display({
                id: 'Ações',
                cell: ({ row, column }) => {
                    const { DD_RECUSADO } = row.original

                    return DD_RECUSADO !== 1 ? (
                        <div className="flex items-center justify-center w-full">
                            <button
                                className="px-0 py-0 text-red-500"
                                onClick={() =>
                                    onUpdateSupplierValue(
                                        row.id,
                                        'DD_RECUSADO',
                                        1,
                                        column.columnDef
                                    )
                                }
                            >
                                <X size={14} />
                            </button>
                        </div>
                    ) : (
                        <div className="flex items-center justify-center w-full">
                            <button
                                className="px-0 py-0 text-secondary-500"
                                onClick={() =>
                                    onUpdateSupplierValue(
                                        row.id,
                                        'DD_RECUSADO',
                                        0,
                                        column.columnDef
                                    )
                                }
                            >
                                <RotateCcw size={14} />
                            </button>
                        </div>
                    )
                },
                size: 50,
                enableResizing: false,
                meta: {
                    enableMenu: false,
                    enableColumnOrdering: false,
                    cell: {
                        className: 'p-1',
                    },
                },
            }),
            columnHelper.accessor('SK_EMPRESA', {
                id: 'SK_EMPRESA',
                header: 'Empresa',
                cell: ({ row, getValue }) => {
                    return (
                        <TypographyP
                            className={cn(
                                'text-xs',
                                row.original.DD_RECUSADO !== 0 &&
                                    'text-red-500 line-through'
                            )}
                        >
                            {getValue()}
                        </TypographyP>
                    )
                },
                size: 150,
                meta: {
                    enableColumnOrdering: false,
                    cell: {
                        className:
                            'bg-accent text-foreground cursor-not-allowed truncate',
                    },
                },
            }),
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                id: 'ABREVIATURA_EMPRESA',
                header: 'Empresa',
                cell: ({ row, getValue }) => {
                    return (
                        <TypographyP
                            className={cn(
                                'truncate text-xs',
                                row.original.DD_RECUSADO !== 0 &&
                                    'text-red-500 line-through'
                            )}
                        >
                            {getValue()}
                        </TypographyP>
                    )
                },
                size: 150,
                meta: {
                    enableColumnOrdering: false,
                    cell: {
                        className:
                            'bg-accent text-foreground cursor-not-allowed truncate',
                    },
                },
            }),
            columnHelper.accessor('SK_PRODUTO', {
                id: 'SK_PRODUTO',
                header: 'Código CCI',
                cell: ({ row, getValue }) => {
                    return (
                        <TypographyP
                            className={cn(
                                'truncate text-xs',
                                row.original.DD_RECUSADO !== 0 &&
                                    'text-red-500 line-through'
                            )}
                        >
                            {getValue()}
                        </TypographyP>
                    )
                },
                size: 120,
                meta: {
                    enableColumnOrdering: false,
                    cell: {
                        className:
                            'bg-accent text-foreground cursor-not-allowed truncate',
                    },
                },
            }),
            columnHelper.accessor('DD_NCM', {
                id: 'DD_NCM',
                header: 'NCM',
                cell: ({ row, getValue }) => {
                    return (
                        <TypographyP
                            className={cn(
                                'truncate text-xs',
                                row.original.DD_RECUSADO !== 0 &&
                                    'text-red-500 line-through'
                            )}
                        >
                            {getValue()}
                        </TypographyP>
                    )
                },
                size: 80,
                meta: {
                    enableColumnOrdering: false,
                    cell: {
                        className:
                            'bg-accent text-foreground cursor-not-allowed truncate',
                    },
                },
            }),
            columnHelper.accessor('DS_PRODUTO', {
                id: 'DS_PRODUTO',
                header: 'Produto',
                cell: ({ row, getValue }) => {
                    return (
                        <TypographyP
                            className={cn(
                                'text-xs truncate',
                                row.original.DD_RECUSADO !== 0 &&
                                    'text-red-500 line-through'
                            )}
                        >
                            {getValue()}
                        </TypographyP>
                    )
                },
                size: 300,
                meta: {
                    enableColumnOrdering: false,
                    cell: {
                        className:
                            'bg-accent text-foreground cursor-not-allowed truncate',
                    },
                },
            }),
            columnHelper.accessor('DD_UM', {
                id: 'DD_UM',
                header: 'UM',
                cell: ({ row, getValue }) => {
                    return (
                        <TypographyP
                            className={cn(
                                'text-xs truncate',
                                row.original.DD_RECUSADO !== 0 &&
                                    'text-red-500 line-through'
                            )}
                        >
                            {getValue()}
                        </TypographyP>
                    )
                },
                size: 80,
                meta: {
                    enableColumnOrdering: false,
                    cell: {
                        className:
                            'bg-accent text-foreground cursor-not-allowed truncate',
                    },
                },
            }),
            columnHelper.accessor('QTD_SOLICITADA', {
                id: 'QTD_SOLICITADA',
                header: 'Qtde solicitada',
                cell: ({ row, getValue }) => {
                    return (
                        <TypographyP
                            className={cn(
                                'text-xs truncate',
                                row.original.DD_RECUSADO !== 0 &&
                                    'text-red-500 line-through'
                            )}
                        >
                            {getValue()}
                        </TypographyP>
                    )
                },
                size: 120,
                meta: {
                    enableColumnOrdering: false,
                    cell: {
                        className:
                            'bg-accent text-foreground cursor-not-allowed truncate',
                    },
                },
            }),
            columnHelper.accessor('QTD_DISPONIVEL', {
                id: 'QTD_DISPONIVEL',
                header: 'Qtde disponível',
                size: 120,
                meta: {
                    enableColumnOrdering: false,
                    editableColumnType: 'number',
                    enableColumnEditable: true,
                },
            }),
            columnHelper.accessor('PRECO', {
                id: 'PRECO',
                header: 'Preço',
                size: 80,
                meta: {
                    enableColumnOrdering: false,
                    editableColumnType: 'number',
                    enableColumnEditable: true,
                },
            }),
            columnHelper.accessor('VL_SPENDING', {
                id: 'VL_SPENDING',
                header: 'Valor spending',
                size: 120,
                meta: {
                    enableColumnOrdering: false,
                    cell: { className: 'p-4' },
                },
            }),
            columnHelper.accessor(
                ({ VL_SAVING }) => numberFormat(VL_SAVING || 0),
                {
                    id: 'VL_SAVING',
                    header: 'Valor saving',
                    size: 120,
                    meta: {
                        enableColumnOrdering: false,
                        cell: { className: 'p-4' },
                    },
                }
            ),
            columnHelper.accessor(
                ({ PERCENTUAL_SAVING }) =>
                    percentageFormat(Number(PERCENTUAL_SAVING) || 0),
                {
                    id: 'PERCENTUAL_SAVING',
                    header: 'Saving (%)',
                    size: 120,
                    meta: {
                        editableColumnType: 'number',
                        cell: { className: 'p-4' },
                    },
                }
            ),
            columnHelper.accessor(
                ({ DD_SAVING }) => SavingTypeMapper[DD_SAVING],
                {
                    id: 'DD_SAVING',
                    header: 'Tipo Saving',
                    size: 120,
                    meta: {
                        enableColumnOrdering: false,
                        cell: { className: 'p-4' },
                    },
                }
            ),
            columnHelper.accessor('FCP', {
                id: 'FCP',
                header: 'FCP',
                meta: {
                    editableColumnType: 'number',
                    enableColumnOrdering: false,
                    enableColumnEditable: true,
                    cell: {
                        formatterFn: ({ value }) =>
                            value ? percentageFormat(value) : '-',
                    },
                },
            }),
            columnHelper.accessor('ICMS', {
                id: 'ICMS',
                header: 'ICMS',
                meta: {
                    editableColumnType: 'number',
                    enableColumnOrdering: false,
                    enableColumnEditable: true,
                    cell: {
                        formatterFn: ({ value }) =>
                            value ? percentageFormat(value) : '-',
                    },
                },
            }),
            columnHelper.accessor('IPI', {
                id: 'IPI',
                header: 'IPI',
                meta: {
                    editableColumnType: 'number',
                    enableColumnOrdering: false,
                    enableColumnEditable: true,
                    cell: {
                        formatterFn: ({ value }) =>
                            value ? percentageFormat(value) : '-',
                    },
                },
            }),
            columnHelper.accessor('PIS', {
                id: 'PIS',
                header: 'PIS',
                meta: {
                    editableColumnType: 'number',
                    enableColumnOrdering: false,
                    enableColumnEditable: true,
                    cell: {
                        formatterFn: ({ value }) =>
                            value ? percentageFormat(value) : '-',
                    },
                },
            }),
            columnHelper.accessor('COFINS', {
                id: 'COFINS',
                header: 'COFINS',
                meta: {
                    editableColumnType: 'number',
                    enableColumnOrdering: false,
                    enableColumnEditable: true,
                    cell: {
                        formatterFn: ({ value }) =>
                            value ? percentageFormat(value) : '-',
                    },
                },
            }),
            columnHelper.accessor('ST', {
                id: 'ST',
                header: 'ST',
                meta: {
                    editableColumnType: 'number',
                    enableColumnOrdering: false,
                    enableColumnEditable: true,
                    cell: {
                        formatterFn: ({ value }) =>
                            value ? percentageFormat(value) : '-',
                    },
                },
            }),
            columnHelper.accessor('PREVISAO_DE_ENTREGA', {
                id: 'PREVISAO_DE_ENTREGA',
                header: 'Previsão de entrega',
                cell: ({ table, row, column, getValue }) => {
                    const initialValue = getValue()

                    const onChangeDate = (newDate: Date) => {
                        if (table.options.meta?.updateData) {
                            table.options.meta?.updateData(
                                row.id,
                                // column.id as keyof ProductAPIResponse,
                                column.id as keyof QuotationMapFlatState,
                                newDate,
                                column.columnDef
                            )
                        }
                        setDate(newDate)
                    }

                    const [date, setDate] = useState<Date | undefined>(
                        initialValue && isValid(new Date(initialValue))
                            ? new Date(initialValue)
                            : undefined
                    )

                    useEffect(() => {
                        if (initialValue) {
                            setDate(
                                initialValue && isValid(new Date(initialValue))
                                    ? new Date(initialValue)
                                    : undefined
                            )
                        }
                    }, [initialValue])

                    return (
                        <DatePicker
                            className="w-full px-1 py-0 overflow-hidden text-xs bg-transparent"
                            date={date}
                            setDate={(date) => date && onChangeDate(date)}
                            disabled={(date) => isBefore(date, new Date())}
                        />
                    )
                },
                meta: {
                    enableColumnOrdering: false,
                    cell: {
                        className: 'p-0 w-full',
                    },
                },
            }),
            columnHelper.accessor('OBSERVACAO', {
                id: 'OBSERVACAO',
                header: 'Observação',
                size: 300,
                meta: {
                    enableColumnOrdering: false,
                    enableColumnEditable: true,
                },
            }),
        ],
        []
    )

    return (
        <Table<SupplierTable>
            data={data}
            columns={columns}
            meta={{
                cell: { className: 'p-0' },
                updateData: onUpdateSupplierValue,
            }}
            getRowId={(row) => row.SK_RESPOSTA_FORNECEDOR}
            tableHeader={<TableHeader />}
            tableActions={<TableActions />}
            defaultColumn={{
                cell: ({ getValue }) => (
                    <DefaultColumn>{getValue() as string}</DefaultColumn>
                ),
            }}
        />
    )
}

export default EditModeTable
