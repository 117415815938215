import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { useTable } from '@/hooks/useTable'
import {
    useQuotationByIdQuery,
    useQuotationProductsQuery,
} from '@/queries/useQuotationQuery'
import { QuotationProduct } from '@/types/Products'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import RemoveProductDialog from './components/RemoveProductsDialog'
import useDisclosure from '@/hooks/useDisclosure'
import { isAxiosError } from '@/api/business'
import TopMessage from '@/components/TopMessage'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { CheckCircle, Save, SquareArrowOutUpRight, X } from 'lucide-react'
import ProductsActions from './components/ProductsActions'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog'
import { currencyFormat } from '@/utils/stringFormatter'

type ProductsTable = TableData<QuotationProduct>

const columnHelper = createColumnHelper<QuotationProduct>()

const ProductsSection = () => {
    const { id } = useParams()
    const { selectedRows, setSelectedRows } = useTable()

    const {
        isOpen: isRemoveProductDialogOpen,
        onOpen: onRemoveProductDialogOpen,
        onClose: onRemoveProductDialogClose,
    } = useDisclosure()

    const {
        data: products,
        isPending,
        isFetching,
        isError,
        error,
    } = useQuotationProductsQuery({
        quotationId: Number(id),
    })

    const { data: quotation } = useQuotationByIdQuery({
        id: Number(id),
    })

    const data = useMemo(() => products || [], [products])

    const selectedProducts = useMemo(
        () =>
            data.filter((product) =>
                data.every((product) => product.SK_SOLICITACAO_DE_COMPRAS)
                    ? Object.keys(selectedRows).includes(
                          product.SK_SOLICITACAO_DE_COMPRAS as string
                      )
                    : Object.keys(selectedRows).includes(product.SK_PRODUTO)
            ),
        [data, selectedRows]
    )

    const columns = useMemo(
        () => [
            columnHelper.display({
                id: 'STATUS',
                header: 'Status',
                cell: ({ row }) => {
                    const {
                        DD_PEDIDO_NAO_GERADO,
                        SK_PEDIDO_DE_COMPRAS,
                        DD_PEDIDO_GRAVADO,
                    } = row.original

                    if (DD_PEDIDO_GRAVADO === 1) {
                        return (
                            <span className="flex items-center gap-2 overflow-hidden text-sky-500 whitespace-nowrap text-ellipsis">
                                <Save size={14} />
                                <span className="flex-1 truncate">
                                    Pedido gravado
                                </span>
                            </span>
                        )
                    }

                    if (DD_PEDIDO_NAO_GERADO === 1) {
                        return (
                            <span className="flex items-center gap-2 overflow-hidden text-red-500 whitespace-nowrap text-ellipsis">
                                <X size={14} />
                                <span className="flex-1 truncate">
                                    Erro pedido
                                </span>
                            </span>
                        )
                    }

                    if (SK_PEDIDO_DE_COMPRAS) {
                        return (
                            <span className="flex items-center gap-2 overflow-hidden text-sky-500 whitespace-nowrap text-ellipsis">
                                <CheckCircle size={14} />
                                <span className="flex-1 truncate">
                                    Pedido gerado
                                </span>
                            </span>
                        )
                    }

                    return null
                },
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                size: 140,
                meta: {
                    enableColumnOrdering: false,
                    cell: {
                        className: 'text-foreground border-none',
                    },
                },
            }),
            columnHelper.accessor('NK_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_SOLICITACAO_DE_COMPRAS',
                header: 'SC',
                cell: ({ row, getValue }) => {
                    const { D_E_L_E_T_ } = row.original

                    return D_E_L_E_T_ === null ? (
                        getValue()
                    ) : (
                        <div className="flex items-center gap-3">
                            <span className="px-2 text-red-500 bg-red-100 rounded-md">
                                Removido
                            </span>
                            {getValue() || '-'}
                        </div>
                    )
                },
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                size: 80,
                meta: {
                    enableColumnOrdering: false,
                    cell: {
                        className: 'text-foreground border-none',
                    },
                },
            }),
            columnHelper.accessor('NK_ITEM_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_ITEM_SOLICITACAO_DE_COMPRAS',
                header: 'Item SC',
                enableColumnFilter: false,
                enableHiding: false,
                size: 80,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('NK_PEDIDO_DE_COMPRAS', {
                id: 'NK_PEDIDO_DE_COMPRAS',
                header: 'PC',
                cell: ({ row, getValue }) => {
                    const { D_E_L_E_T_ } = row.original

                    return D_E_L_E_T_ === null ? (
                        getValue()
                    ) : (
                        <div className="flex items-center gap-3">
                            <span className="px-2 text-red-500 bg-red-100 rounded-md">
                                Removido
                            </span>
                            {getValue() || '-'}
                        </div>
                    )
                },
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                size: 80,
                meta: {
                    enableColumnOrdering: false,
                    cell: {
                        className: 'text-foreground border-none',
                    },
                },
            }),
            columnHelper.accessor('NK_ITEM_PEDIDO_DE_COMPRAS', {
                id: 'NK_ITEM_PEDIDO_DE_COMPRAS',
                header: 'Item PC',
                cell: ({ row, getValue }) => {
                    const { D_E_L_E_T_ } = row.original

                    return D_E_L_E_T_ === null ? (
                        getValue()
                    ) : (
                        <div className="flex items-center gap-3">
                            <span className="px-2 text-red-500 bg-red-100 rounded-md">
                                Removido
                            </span>
                            {getValue() || '-'}
                        </div>
                    )
                },
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                size: 80,
                meta: {
                    enableColumnOrdering: false,
                    cell: {
                        className: 'text-foreground border-none',
                    },
                },
            }),
            columnHelper.accessor('NK_PRODUTO', {
                id: 'NK_PRODUTO',
                header: 'Cod produto',
                enableColumnFilter: false,
                enableHiding: false,
                size: 150,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DS_PRODUTO', {
                id: 'ds_produto',
                header: 'Produto',
                size: 300,
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('NK_GRUPO_DE_EMPRESA', {
                id: 'NK_GRUPO_DE_EMPRESA',
                header: 'Grupo empresa',
                size: 80,
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DD_UM', {
                id: 'DD_UM',
                header: 'UM',
                size: 80,
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DS_GRUPO_DE_PRODUTOS', {
                id: 'DS_GRUPO_DE_PRODUTOS',
                header: 'Grupo',
                size: 200,
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DD_NCM', {
                id: 'DD_NCM',
                header: 'NCM',
                size: 150,
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor(
                ({ ULTIMO_PRECO }) => currencyFormat(Number(ULTIMO_PRECO) || 0),
                {
                    id: 'ULTIMO_PRECO',
                    header: 'Último preço',
                    size: 150,
                    enableColumnFilter: false,
                    enableHiding: false,
                    enablePinning: false,
                    meta: {
                        enableColumnOrdering: false,
                    },
                }
            ),
            columnHelper.accessor('DD_QUANTIDADE', {
                id: 'qtde',
                header: 'Qtde',
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
                size: 80,
            }),
            columnHelper.accessor('MOTIVO_NAO_GERACAO', {
                id: 'MOTIVO_NAO_GERACAO',
                header: 'Observação',
                cell: ({ row }) => {
                    const { MOTIVO_NAO_GERACAO, DD_PEDIDO_NAO_GERADO } =
                        row.original

                    return (
                        DD_PEDIDO_NAO_GERADO === 1 && (
                            <Dialog>
                                <DialogTrigger className="flex items-center gap-2 overflow-hidden">
                                    <TypographyP className="flex-1 truncate">
                                        {MOTIVO_NAO_GERACAO}
                                    </TypographyP>
                                    <SquareArrowOutUpRight
                                        size={14}
                                        className="text-sky-500"
                                    />
                                </DialogTrigger>
                                <DialogContent>
                                    <DialogHeader>
                                        <DialogTitle>Motivo</DialogTitle>
                                        <DialogDescription>
                                            Motivo de não geração do pedido
                                        </DialogDescription>
                                    </DialogHeader>
                                    <div>
                                        {MOTIVO_NAO_GERACAO?.split(';').map(
                                            (reason, idx) => {
                                                return (
                                                    <TypographyP key={idx}>
                                                        {reason}
                                                    </TypographyP>
                                                )
                                            }
                                        )}
                                    </div>
                                </DialogContent>
                            </Dialog>
                        )
                    )
                },
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
                size: 300,
            }),
        ],
        []
    )

    return (
        <>
            <div className="py-2">
                <div className="mb-4">
                    <ProductsActions />
                    {selectedProducts.length > 0 && (
                        <div className="flex items-center gap-4">
                            <TypographyMuted>
                                {selectedProducts.length} produtos selecionados
                            </TypographyMuted>
                            <div className="flex items-center gap-4">
                                <button
                                    className="text-sm text-neutral-500 hover:underline"
                                    onClick={onRemoveProductDialogOpen}
                                >
                                    Remover selecionados
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                {isFetching && !isPending && (
                    <TopMessage
                        variant="loading"
                        text="Atualizando produtos..."
                    />
                )}
                {products?.length === 0 && !isPending ? (
                    <div className="flex items-center text-sm justify-center text-neutral-400 h-[100px]">
                        Cotação sem produto
                    </div>
                ) : (
                    <div className="flex flex-col flex-1 overflow-hidden">
                        <Table<ProductsTable>
                            data={data}
                            columns={columns}
                            getRowId={(row) =>
                                data.every(
                                    (row) => row.SK_SOLICITACAO_DE_COMPRAS
                                )
                                    ? (row.SK_SOLICITACAO_DE_COMPRAS as string)
                                    : row.SK_PRODUTO.toString()
                            }
                            isLoading={isPending}
                            isError={isError}
                            errorMessage={
                                isError && isAxiosError(error)
                                    ? error.response?.data.message
                                    : undefined
                            }
                            tableState={{ rowSelection: selectedRows }}
                            onRowSelectionChange={setSelectedRows}
                            enableRowSelection={(row) =>
                                row.original.D_E_L_E_T_ === null &&
                                !!(quotation && quotation?.RODADAS.length === 1)
                            }
                            meta={{
                                layout: 'stretch',
                                cell: {
                                    className: 'border-none py-4 border-b',
                                },
                                header: {
                                    className:
                                        'border-r-0 border-t-0 group-first-of-type:border-l-0 border-b py-1.5',
                                },
                            }}
                            defaultColumn={{
                                cell: ({ getValue }) => (
                                    <DefaultColumn>
                                        {getValue() as string}
                                    </DefaultColumn>
                                ),
                            }}
                        />
                    </div>
                )}
            </div>
            <RemoveProductDialog
                isOpen={isRemoveProductDialogOpen}
                isPurchaseRequest={data.every(
                    (product) => product.SK_SOLICITACAO_DE_COMPRAS
                )}
                onClose={onRemoveProductDialogClose}
                products={selectedProducts}
                onSelectRows={setSelectedRows}
            />
        </>
    )
}

export default ProductsSection
