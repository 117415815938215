import instance from '@/api/business'
import {
    FetchPaymentConditionsPaginatedProps,
    FetchPaymentConditionsPaginatedResponse,
    UpdatePaymentConditionProps,
} from './type'
import { PaymentConditions } from '@/types/PaymentCondition'

const BASE_ROUTE = '/condicao-de-pagamento'

export const fetchPaymentConditions = async () => {
    const response = await instance.post<PaymentConditions>(`${BASE_ROUTE}`)
    return response.data
}

export const fetchPaymentConditionsPaginated = async ({
    currentPage,
    perPage,
    filters,
}: FetchPaymentConditionsPaginatedProps) => {
    const response =
        await instance.post<FetchPaymentConditionsPaginatedResponse>(
            `${BASE_ROUTE}/paginacao`,
            {
                POR_PAGINA: perPage,
                PAGINA_ATUAL: currentPage,
                FILTROS: filters || [],
            }
        )

    return response.data
}

export const updatePaymentCondition = async (
    props: UpdatePaymentConditionProps
) => {
    const response = await instance.patch(`${BASE_ROUTE}`, { ...props })
    return response.data
}
