import { useQuotationHeadersQuery } from '@/queries/useQuotationQuery'
import Header from '../Header'
import HeaderActions from '../HeaderActions'
import { Quotation } from '@/types/Quotation'
import { Filter } from '@/types/Filters'
import { useState } from 'react'
import { PaginationState } from '@tanstack/react-table'
import TopMessage from '@/components/TopMessage'
import { isAxiosError } from '@/api/business'
import Item from './components/Item'
import TablePagination from '@/components/Table/components/TablePagination'
import ItemSkeleton from './components/ItemSkeleton'
import { AnimatePresence } from 'framer-motion'
import Filters from './components/Filters'

interface GridProps {
    buttonFilters: Filter<Pick<Quotation, 'SK_COTACAO' | 'SK_USUARIO'>>[]
    setButtonFilters: (
        filter: Filter<Pick<Quotation, 'SK_COTACAO' | 'SK_USUARIO'>>[]
    ) => void
}

const Grid = ({ buttonFilters, setButtonFilters }: GridProps) => {
    const [fieldFilters, setFieldFilters] = useState<Filter<Quotation>[]>([])

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 9,
    })

    const { data, isError, isPending, isFetching, error, refetch } =
        useQuotationHeadersQuery({
            currentPage: pageIndex,
            filters: [...fieldFilters, ...buttonFilters],
            perPage: pageSize,
        })

    return (
        <>
            <div className="flex items-center justify-between">
                <Header
                    refetch={refetch}
                    setButtonFilters={setButtonFilters}
                    data={data?.DADOS}
                />
                <HeaderActions />
            </div>
            {!isPending && (isFetching || isError) ? (
                <div className="py-2">
                    <TopMessage
                        text={
                            isFetching
                                ? 'Atualizando dados...'
                                : isError && isAxiosError(error)
                                ? error.response?.data.message ||
                                  'Erro ao carregar dados'
                                : ''
                        }
                        variant={
                            isFetching ? 'success' : isError ? 'error' : 'error'
                        }
                    />
                </div>
            ) : null}
            <div className="md:container">
                <Filters setFieldFilters={setFieldFilters} />
                <TablePagination
                    onPageChange={(page) =>
                        setPagination((prev) => ({ ...prev, pageIndex: page }))
                    }
                    selectedPage={pageIndex}
                    totalItems={data?.TOTAL || 0}
                    totalPages={data?.TOTAL_DE_PAGINAS || 0}
                />
                <div className="h-max grid-cols-[repeat(auto-fill,minmax(400px,1fr))] gap-4 grid">
                    {isPending ? (
                        <ItemSkeleton />
                    ) : (
                        <AnimatePresence mode="sync" initial={false}>
                            {data?.DADOS.map((quotation) => {
                                return (
                                    <Item
                                        key={quotation.SK_COTACAO}
                                        {...quotation}
                                    />
                                )
                            })}
                        </AnimatePresence>
                    )}
                </div>
            </div>
        </>
    )
}

export default Grid
