import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { useBaseStore } from '@/store'
import {
    getSelectedFreightSelector,
    getSelectedShippingCompanySelector,
} from '@/store/quotationMapSlice'
import { currencyFormat } from '@/utils/stringFormatter'
import ShippingDialog from '../../../EditMode/components/ShippingDialog'

const ShippingCompanySection = () => {
    const shippingCompany = useBaseStore(getSelectedShippingCompanySelector)
    const freight = useBaseStore(getSelectedFreightSelector)
    return !freight || freight === 'CIF' ? (
        <>
            <div>
                <TypographyMuted>Transportadora</TypographyMuted>
                <div className="px-4 py-2 border rounded-md">
                    <TypographyP>
                        {shippingCompany.NM_TRANSPORTADORA || '-'}
                    </TypographyP>
                </div>
            </div>
            <div>
                <TypographyMuted>Valor do frete</TypographyMuted>
                <div className="px-4 py-2 border rounded-md">
                    <TypographyP>
                        {currencyFormat(
                            Number(shippingCompany.VL_FRETE || 0),
                            4,
                            'REAL'
                        ) || '-'}
                    </TypographyP>
                </div>
            </div>
        </>
    ) : (
        <ShippingDialog />
    )
}

export default ShippingCompanySection
