import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { usePaymentConditionsInfiniteQuery } from '@/queries/usePaymentConditionsQuery'
import { Filter, Operators } from '@/types/Filters'
import { PaymentConditions } from '@/types/PaymentCondition'
import { AlertTriangle, Cog, Search } from 'lucide-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import PaymentConditionItem from './components/PaymentConditionItem'
import PaymentConditionItemSkeleton from './components/PaymentConditionItemSkeleton'
import { useNavigate } from 'react-router-dom'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import TopMessage from '../TopMessage'

interface PaymentConditionsDialogProps {
    isOpen: boolean
    multiSelect?: boolean
    initialSelected?: PaymentConditions[]
    isPending?: boolean
    disabled?: (paymentCondition: PaymentConditions[]) => boolean | boolean
    filterFn?: (paymentCondition: PaymentConditions) => boolean
    onClose: () => void
    onConfirm: (paymentConditions: PaymentConditions[]) => void
}

const PAGE_SIZE = 15

const stateSelector = (state: Store) => ({
    showAllPaymentCondition:
        state.quotationPurchaseRequestSlice.state.showAllPaymentCondition,
    onChangeShowAllPaymentCondition:
        state.quotationPurchaseRequestSlice.actions
            .onChangeShowAllPaymentCondition,
})

const PaymentConditionsDialog = ({
    isOpen,
    initialSelected,
    multiSelect = true,
    isPending: isConfirmPending,
    disabled,
    filterFn,
    onClose,
    onConfirm,
}: PaymentConditionsDialogProps) => {
    const ref = useRef<HTMLDivElement | null>(null)
    const { onChangeShowAllPaymentCondition, showAllPaymentCondition } =
        useBaseStore(stateSelector)

    const [paymentConditionsSelected, setPaymentConditionsSelected] = useState<
        PaymentConditions[]
    >(initialSelected || [])

    const navigate = useNavigate()

    const initialFilter: Filter<PaymentConditions>[] = [
        {
            AND: [
                {
                    field: 'DD_COTACAO',
                    operator: Operators.equals,
                    value: 1,
                },
            ],
        },
    ]

    const [filters, setFilters] =
        useState<Filter<PaymentConditions>[]>(initialFilter)

    const [id, setId] = useState('')
    const [description, setDescription] = useState('')

    const {
        data,
        isPending,
        isError,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
    } = usePaymentConditionsInfiniteQuery({
        perPage: PAGE_SIZE,
        enabled: isOpen,
        filters,
    })

    const paymentConditions = useMemo(
        () =>
            data
                ? filterFn
                    ? data.pages
                          .flatMap((page) => page.DADOS.filter(filterFn))
                          .map((page) => ({
                              ...page,
                              DD_PREFERIDA: 0 as const,
                          }))
                    : data.pages
                          .flatMap((page) => page.DADOS)
                          .map((page) => ({
                              ...page,
                              DD_PREFERIDA: 0 as const,
                          }))
                : [],
        [filterFn, data]
    )

    const onSelect = (paymentCondition: PaymentConditions) => {
        if (
            paymentConditionsSelected.some(
                (pc) =>
                    pc.SK_CONDICAO_DE_PAGAMENTO ===
                    paymentCondition.SK_CONDICAO_DE_PAGAMENTO
            )
        ) {
            setPaymentConditionsSelected((prev) =>
                prev.filter(
                    (pc) =>
                        pc.SK_CONDICAO_DE_PAGAMENTO !==
                        paymentCondition.SK_CONDICAO_DE_PAGAMENTO
                )
            )
        } else {
            setPaymentConditionsSelected((prev) =>
                multiSelect
                    ? [...prev, { ...paymentCondition, DD_PREFERIDA: 0 }]
                    : [{ ...paymentCondition, DD_PREFERIDA: 0 }]
            )
        }
    }

    const onChangePreference = (id: string) => {
        setPaymentConditionsSelected((prev) =>
            prev.map((paymentCondition) =>
                paymentCondition.SK_CONDICAO_DE_PAGAMENTO === id
                    ? {
                          ...paymentCondition,
                          DD_PREFERIDA:
                              paymentCondition.DD_PREFERIDA === 1 ? 0 : 1,
                      }
                    : paymentCondition
            )
        )
    }

    const onSearch = () => {
        if (id || description) {
            const and: Filter<PaymentConditions> = {
                AND: [],
            }

            if (id) {
                and.AND.push({
                    field: 'SK_CONDICAO_DE_PAGAMENTO',
                    value: id,
                    operator: Operators.like,
                })
            }

            if (description) {
                and.AND.push({
                    field: 'DS_CONDICAO_DE_PAGAMENTO',
                    value: description,
                    operator: Operators.like,
                })
            }

            setFilters((prev) => [...prev, and])
        } else {
            setFilters(showAllPaymentCondition ? [] : initialFilter)
        }
    }

    useEffect(() => {
        const cb = (entries: IntersectionObserverEntry[]) => {
            if (entries[0].isIntersecting && hasNextPage) {
                fetchNextPage()
            }
        }

        const options = {
            root: null,
            rootMargin: '-100px',
            threshold: 1.0,
        }

        const observer = new IntersectionObserver(cb, options)

        if (ref.current) {
            observer.observe(ref.current)
        }

        return () => {
            observer.disconnect()
        }
    }, [ref.current])

    useEffect(() => {
        if (initialSelected && initialSelected?.length > 0) {
            setPaymentConditionsSelected(initialSelected)
        }
    }, [initialSelected])

    const onReset = () => {
        setPaymentConditionsSelected([])
        setId('')
        setDescription('')
    }

    return (
        <Dialog
            open={isOpen}
            onOpenChange={(open) => {
                if (!open) {
                    onReset()
                    onClose()
                }
            }}
        >
            <DialogContent className="overflow-hidden max-h-[80%] flex flex-col">
                <DialogHeader>
                    <DialogTitle>Condições de pagamento</DialogTitle>
                    <DialogDescription>
                        Selecione as condições de pagamento que estarão
                        disponíveis nessa cotação.
                    </DialogDescription>
                </DialogHeader>
                <div className="flex flex-col flex-1 space-y-4 overflow-hidden">
                    <div className="flex items-center gap-4 ">
                        <div className="max-w-[100px]">
                            <Label htmlFor="id">Código</Label>
                            <Input
                                id="id"
                                value={id}
                                onChange={(e) => setId(e.target.value)}
                            />
                        </div>
                        <div className="flex-1">
                            <Label htmlFor="description">Descrição</Label>
                            <Input
                                id="description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                        <div className="self-end">
                            <Button size="icon" onClick={onSearch}>
                                <Search size={14} />
                            </Button>
                        </div>
                    </div>
                    {isFetching && !isFetchingNextPage ? (
                        <TopMessage
                            text="Atualizando dados..."
                            variant="success"
                        />
                    ) : null}
                    {isPending ? (
                        <PaymentConditionItemSkeleton quantity={5} />
                    ) : isError ? (
                        <div>Erro ao carregar dados</div>
                    ) : data.pages.every((page) => page.DADOS.length === 0) &&
                      !isFetching ? (
                        <div className="space-y-2">
                            <p className="flex items-center gap-4 p-3 text-sm bg-orange-50 text-amber-600">
                                <AlertTriangle size={14} />
                                Nenhuma condição de pagamento foi configurada
                                para cotação.
                            </p>
                            <div>
                                <Button
                                    variant={'ghost'}
                                    className="w-full gap-2 bg-slate-50 text-secondary-700 hover:bg-slate-100 hover:text-secondary-700"
                                    onClick={() =>
                                        navigate(
                                            '/dados-mestre/condicoes-de-pagamento'
                                        )
                                    }
                                >
                                    <Cog size={14} />
                                    Ir para página de configuração
                                </Button>
                                <Button
                                    variant={'ghost'}
                                    className="w-full text-neutral-500 hover:text-neutral-600"
                                    onClick={() => {
                                        onChangeShowAllPaymentCondition(true)
                                        setFilters([])
                                    }}
                                >
                                    Exibir todas as condições de pagamento
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <ul className="flex-1 py-4 overflow-auto">
                            {paymentConditions.map((paymentCondition) => {
                                const selected = paymentConditionsSelected.find(
                                    (pc) =>
                                        pc.SK_CONDICAO_DE_PAGAMENTO ===
                                        paymentCondition.SK_CONDICAO_DE_PAGAMENTO
                                )
                                return (
                                    <PaymentConditionItem
                                        key={
                                            paymentCondition.SK_CONDICAO_DE_PAGAMENTO
                                        }
                                        checked={!!selected}
                                        paymentCondition={
                                            selected || paymentCondition
                                        }
                                        onSelect={onSelect}
                                        onChangePreference={onChangePreference}
                                    />
                                )
                            })}
                            {isFetching && isFetchingNextPage ? (
                                <PaymentConditionItemSkeleton quantity={5} />
                            ) : null}
                            <div className="h-10" ref={ref}></div>
                        </ul>
                    )}
                </div>
                <DialogFooter>
                    <Button variant="ghost" onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            onConfirm(paymentConditionsSelected)
                        }}
                        disabled={
                            typeof disabled === 'function'
                                ? disabled(paymentConditionsSelected)
                                : disabled || isConfirmPending
                        }
                    >
                        Confirmar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default PaymentConditionsDialog
