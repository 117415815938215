import { isAxiosError } from '@/api/business'
import { finalizeRound } from '@/api/business/cotacoes'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { useToast } from '@/components/ui/use-toast'
import { quotationKeys } from '@/queries/useQuotationQuery'
import { useBaseStore } from '@/store'
import { getLastRoundSelector } from '@/store/quotationDetailSlice'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

interface FinishRoundDialogProps {
    isOpen: boolean
    onClose: () => void
}

const FinishRoundDialog = ({ isOpen, onClose }: FinishRoundDialogProps) => {
    const { id } = useParams()
    const { toast } = useToast()
    const queryClient = useQueryClient()

    const lastRound = useBaseStore(getLastRoundSelector)

    const { mutate, isPending } = useMutation({
        mutationFn: finalizeRound,
        onSuccess: () => {
            if (id)
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.detail(id),
                })

            toast({
                title: 'Rodada finalizada',
                description: 'A rodada foi finalizada com sucesso.',
            })
            onClose()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível finalizar rodada. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao finalizar rodada',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const onSubmit = () => {
        if (id)
            mutate({
                quotationId: Number(id),
            })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Finalizar rodada</DialogTitle>
                </DialogHeader>
                <div>
                    <div className="py-4">
                        <p>
                            Deseja finalizar a{' '}
                            <span className="font-semibold">
                                rodada {lastRound?.round}
                            </span>{' '}
                            antes da data de expiração?
                        </p>
                    </div>
                    <div className="flex justify-end gap-2">
                        <Button variant="ghost" onClick={onClose}>
                            Cancelar
                        </Button>
                        <Button disabled={isPending} onClick={onSubmit}>
                            Confirmar
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default FinishRoundDialog
