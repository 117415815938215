import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { useParams } from 'react-router-dom'
import ApprovalTable from '../ApprovalTable'
import { ReactNode } from 'react'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Paperclip } from 'lucide-react'
import { useApprovalAttachmentsQuery } from '@/queries/useAttachmentsQuery'
import ApprovalAttachmentsTable from '../ApprovalAttachmentsTable'
import { Separator } from '@/components/ui/separator'

const Content = () => {
    const { id } = useParams()

    const { data: quotation } = useQuotationByIdQuery({ id: Number(id) })
    const { data: attachments = [] } = useApprovalAttachmentsQuery({
        quotationId: Number(id),
    })

    const status = [
        quotation?.DD_EM_APROVACAO,
        quotation?.DD_MAPA_APROVADO,
        quotation?.DD_MAPA_RECUSADO,
    ]

    let Approval: ReactNode = null

    if (quotation?.DD_EM_APROVACAO === 1) {
        Approval = (
            <div>
                <TypographyP className="text-base font-semibold text-amber-500">
                    Mapa em aprovação
                </TypographyP>
                <TypographyMuted>
                    Os valores abaixo estão em processo de aprovação
                </TypographyMuted>
            </div>
        )
    } else if (quotation?.DD_MAPA_APROVADO === 1) {
        Approval = (
            <div>
                <TypographyP className="text-base font-semibold text-green-600">
                    Mapa aprovado
                </TypographyP>
                <TypographyMuted>
                    Os valores abaixo foram aprovados
                </TypographyMuted>
            </div>
        )
    } else if (quotation?.DD_MAPA_RECUSADO === 1) {
        Approval = (
            <div>
                <TypographyP className="text-base font-semibold text-red-500">
                    Mapa recusado
                </TypographyP>
                <TypographyMuted>
                    Os valores abaixo foram recusados
                </TypographyMuted>
            </div>
        )
    }

    const showToolbar =
        quotation?.DD_MAPA_APROVADO === 1 ||
        quotation?.DD_MAPA_RECUSADO === 1 ||
        quotation?.DD_EM_APROVACAO

    if (status.includes(1)) {
        return (
            <Tabs
                className="flex flex-col flex-1 w-full overflow-hidden"
                defaultValue="approval"
            >
                {showToolbar ? (
                    <div className="flex items-center gap-4 border-b bg-accent border-neutral-300">
                        <TabsList className="self-end ml-auto rounded-md">
                            <TabsTrigger value="approval">
                                Aprovação
                            </TabsTrigger>
                            <TabsTrigger value="details">Detalhes</TabsTrigger>
                            <TabsTrigger value="attachments" className="gap-2">
                                <span className="px-1 rounded-md bg-neutral-300">
                                    {attachments.length}
                                </span>
                                <Paperclip size={14} />
                            </TabsTrigger>
                        </TabsList>
                    </div>
                ) : null}
                <TabsContent value="details" className="overflow-auto">
                    <div className="flex flex-col gap-4 overflow-auto">
                        <div className="p-4 space-y-4">
                            <div>
                                <TypographyMuted>Comprador</TypographyMuted>
                                <TypographyP>
                                    {quotation?.NOME_USUARIO}
                                </TypographyP>
                            </div>
                            <div>
                                <TypographyMuted>
                                    Email comprador
                                </TypographyMuted>
                                <TypographyP>
                                    {quotation?.EMAIL_USUARIO}
                                </TypographyP>
                            </div>
                            <div>
                                <TypographyMuted>Observação</TypographyMuted>
                                <TypographyP>
                                    {quotation?.DS_OBSERVACAO_APROVACAO_COMPRADOR ||
                                        '-'}
                                </TypographyP>
                            </div>
                        </div>
                        <Separator />
                        <div className="p-4 space-y-4">
                            <div>
                                <TypographyMuted>Aprovado por</TypographyMuted>
                                <TypographyP>
                                    {quotation?.NOME_USUARIO_APROVADOR || '-'}
                                </TypographyP>
                            </div>
                            <div>
                                <TypographyMuted>
                                    Email aprovador
                                </TypographyMuted>
                                <TypographyP>
                                    {quotation?.EMAIL_USUARIO_APROVADOR || '-'}
                                </TypographyP>
                            </div>
                            <div>
                                <TypographyMuted>Observação</TypographyMuted>
                                <TypographyP>
                                    {quotation?.DS_OBSERVACAO_APROVACAO || '-'}
                                </TypographyP>
                            </div>
                        </div>
                    </div>
                </TabsContent>
                <TabsContent value="approval" className="overflow-auto">
                    <div className="flex flex-col gap-4 overflow-hidden">
                        {Approval}
                        <ApprovalTable />
                    </div>
                </TabsContent>
                <TabsContent value="attachments" className="overflow-auto">
                    {attachments.length === 0 ? (
                        <div className="flex items-center justify-center h-[130px]">
                            <TypographyMuted>
                                Não há arquivos enviados
                            </TypographyMuted>
                        </div>
                    ) : (
                        <div className="flex flex-col h-full overflow-hidden">
                            <ApprovalAttachmentsTable />
                        </div>
                    )}
                </TabsContent>
            </Tabs>
        )
    }

    return (
        <div className="flex items-center justify-center p-4">
            <TypographyMuted>Mapa não está em aprovação</TypographyMuted>
        </div>
    )
}

const ApprovalDialog = () => {
    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button variant="outline">Ver aprovação</Button>
            </DialogTrigger>
            <DialogContent className="flex flex-col max-w-[90%] items-start h-[80%]">
                <DialogHeader>
                    <DialogTitle>Aprovação</DialogTitle>
                    <DialogDescription>
                        Status de aprovação dos fornecedores selecionados
                    </DialogDescription>
                </DialogHeader>
                <Content />
            </DialogContent>
        </Dialog>
    )
}

export default ApprovalDialog
