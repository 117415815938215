import { Skeleton } from '@/components/ui/skeleton'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { useParams } from 'react-router-dom'
import {
    TypographyH2,
    TypographyMuted,
    TypographyP,
} from '@/components/ui/typography'
import { formatCpfOrCnpj } from '@/utils/stringFormatter'
import { cn } from '@/lib/utils'
import { QuotationStatusColorScheme } from '@/types/QuotationStatus'
import { getSelectedRoundSelector } from '@/store/quotationDetailSlice'
import { useBaseStore } from '@/store'
import { format } from 'date-fns'

type ItemProps = {
    label: string
    value: string
}

const Item = ({ label, value }: ItemProps) => {
    return (
        <div className="space-y-0.5">
            <TypographyMuted>{label}</TypographyMuted>
            <TypographyP className="truncate">{value}</TypographyP>
        </div>
    )
}

type GroupProps = {
    children: React.ReactNode
    className?: string
}

const Group = ({ children, className }: GroupProps) => {
    return (
        <div className={cn('p-2 space-y-3 rounded-md bg-accent', className)}>
            {children}
        </div>
    )
}

const QuotationDetailsSection = () => {
    const { id } = useParams()
    const selectedRound = useBaseStore(getSelectedRoundSelector)

    const { data, isPending, isError } = useQuotationByIdQuery({
        id: Number(id),
    })

    if (isPending || isError) {
        return (
            <div className="space-y-2">
                <div className="space-y-2">
                    <Skeleton className="w-[150px] h-[16px]" />
                    <Skeleton className="w-[200px] h-[26px]" />
                </div>
                <div className="space-y-2">
                    <Skeleton className="w-[150px] h-[16px]" />
                    <Skeleton className="w-[200px] h-[26px]" />
                </div>
                <div className="space-y-2">
                    <Skeleton className="w-[150px] h-[16px]" />
                    <Skeleton className="w-[200px] h-[26px]" />
                </div>
            </div>
        )
    }

    return (
        <div className="space-y-4">
            <div className="space-y-1">
                <div>
                    <TypographyH2>
                        {data?.NM_COTACAO || ''} {data?.NUMERO_DA_COTACAO || ''}
                    </TypographyH2>
                    <TypographyMuted className="-mt-2 text-base">
                        {data?.DS_COTACAO}
                    </TypographyMuted>
                </div>
                <div>
                    {data.STATUS.map((status) => {
                        return (
                            <span
                                key={status}
                                className={cn(
                                    'text-xs py-1 px-2 rounded-md',
                                    QuotationStatusColorScheme[status]
                                )}
                            >
                                {status}
                            </span>
                        )
                    })}
                </div>
            </div>
            <Group>
                <Item
                    label="Rodada selecionada"
                    value={`${selectedRound?.round}ª rodada`}
                />
                {selectedRound?.expirationDate && (
                    <Item
                        label="Data de expiração"
                        value={format(
                            new Date(selectedRound?.expirationDate),
                            'PPP'
                        )}
                    />
                )}
            </Group>
            <Group>
                <Item
                    label="Número da cotação"
                    value={data?.NUMERO_DA_COTACAO || ''}
                />
                <div className="space-y-0.5">
                    <TypographyMuted>Empresas</TypographyMuted>
                    <div className="space-y-1">
                        {data.EMPRESAS.map((company) => (
                            <div
                                key={company.SK_EMPRESA}
                                className="flex items-center gap-2"
                            >
                                <TypographyP
                                    style={{
                                        color: company.COR_EMPRESA || undefined,
                                    }}
                                >
                                    {company.ABREVIATURA_EMPRESA} -{' '}
                                    {formatCpfOrCnpj(company.CNPJ)}
                                </TypographyP>
                            </div>
                        ))}
                    </div>
                </div>
                <Item label="Comprador" value={data?.NOME_USUARIO || ''} />
            </Group>
            {data.DD_MAPA_APROVADO === 1 || data.DD_MAPA_RECUSADO === 1 ? (
                <Group className="duration-150 ease-in border cursor-pointer bg-primary-50 border-primary hover:bg-primary-100">
                    <Item
                        label={
                            data.DD_MAPA_APROVADO === 1
                                ? 'Aprovado por'
                                : 'Recusado por'
                        }
                        value={data.NOME_USUARIO_APROVADOR || ''}
                    />
                    <Item
                        label="Email aprovador"
                        value={data.EMAIL_USUARIO_APROVADOR}
                    />
                    <Item
                        label="Observação"
                        value={data.DS_OBSERVACAO_APROVACAO || '-'}
                    />
                </Group>
            ) : null}
        </div>
    )
}

export default QuotationDetailsSection
