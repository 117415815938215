import { fetchPaginatedProducts, fetchProducts } from '@/api/business/produtos'
import { FetchPaginatedProductsProps } from '@/api/business/produtos/type'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const productsKeys = {
    all: ['produto'] as const,
    lists: () => [...productsKeys.all, 'list'] as const,
    list: (props: { currentPage: number; perPage: number; filters: any }) =>
        [...productsKeys.lists(), props] as const,
    details: () => [...productsKeys.all, 'detail'] as const,
    detail: (params: string) => [...productsKeys.details(), params] as const,
}

export const useProductsQuery = () => {
    return useQuery({
        queryFn: fetchProducts,
        queryKey: productsKeys.lists(),
    })
}

interface UsePaginatedProductsQueryProps extends FetchPaginatedProductsProps {
    enabled: boolean
}

export const usePaginatedProductsQuery = ({
    currentPage,
    perPage,
    filters,
}: UsePaginatedProductsQueryProps) => {
    return useQuery({
        queryFn: () =>
            fetchPaginatedProducts({ currentPage, perPage, filters }),
        queryKey: productsKeys.list({ currentPage, perPage, filters }),
        placeholderData: keepPreviousData,
    })
}
