import { cn } from '@/lib/utils'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { Bell } from 'lucide-react'
import { useParams } from 'react-router-dom'
import { DropdownMenuItem } from '@/components/ui/dropdown-menu'

type NotifySuppliersButtonProps = {
    onOpen: () => void
}

const NotifySuppliersButton = ({ onOpen }: NotifySuppliersButtonProps) => {
    const { id } = useParams()

    const { data, isPending, isError } = useQuotationByIdQuery({
        id: Number(id),
    })

    const disabled =
        isPending ||
        isError ||
        data.DD_PUBLICADO !== 1 ||
        [
            data.DD_CANCELADA,
            data.DD_EM_APROVACAO,
            data.DD_MAPA_APROVADO,
            data.DD_MAPA_RECUSADO,
        ].includes(1)

    return (
        <DropdownMenuItem
            className="flex items-center gap-2 "
            disabled={disabled}
            onClick={onOpen}
        >
            <Bell
                size={14}
                className={cn(
                    disabled ? 'text-neutral-500' : 'text-orange-500'
                )}
            />
            Notificar
        </DropdownMenuItem>
    )
}

export default NotifySuppliersButton
