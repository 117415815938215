import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { TypographyLarge } from '@/components/ui/typography'
import { ChevronDown } from 'lucide-react'
import useDisclosure from '@/hooks/useDisclosure'
import PaymentConditionsDialog from '@/components/PaymentConditionsDialog'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { PaymentConditions } from '@/types/PaymentCondition'
import { useToast } from '@/components/ui/use-toast'
import { addPaymentCondition } from '@/api/business/cotacoes'
import {
    quotationKeys,
    useQuotationByIdQuery,
} from '@/queries/useQuotationQuery'
import { isAxiosError } from '@/api/business'
import { useParams } from 'react-router-dom'
import AddPaymentConditionButton from '../AddPaymentConditionButton'

const PaymentConditionsActions = () => {
    const { id } = useParams()

    const { data } = useQuotationByIdQuery({
        id: Number(id),
    })

    const { isOpen, onOpen, onClose } = useDisclosure()

    const onConfirm = (paymentConditions: PaymentConditions[]) => {
        mutate({
            quotationId: Number(id),
            paymentConditions,
        })
    }

    const queryClient = useQueryClient()
    const { toast } = useToast()

    const { mutate, isPending: isMutatePending } = useMutation({
        mutationFn: addPaymentCondition,
        onSuccess: () => {
            if (id) {
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.detail(id),
                })
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.detailSuppliers(id),
                })
            }

            toast({
                title: 'Condição de pagamento adicionada',
                description: 'Condição de pagamento adicionada com sucesso',
            })
            onClose()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível adicionar a condição de pagamento. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao adicionar a condição de pagamento',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger className="flex items-center gap-2 rounded-md hover:bg-accent">
                    <TypographyLarge>Condições de pagamento</TypographyLarge>
                    <ChevronDown size={18} />
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                    <AddPaymentConditionButton onClick={onOpen} />
                </DropdownMenuContent>
            </DropdownMenu>
            <PaymentConditionsDialog
                isOpen={isOpen}
                onClose={onClose}
                isPending={isMutatePending}
                multiSelect
                disabled={(paymentConditions) => paymentConditions.length === 0}
                onConfirm={onConfirm}
                filterFn={(pc) => {
                    return !data?.CONDICOES_DE_PAGAMENTO.map(
                        (pc) => pc.SK_CONDICAO_DE_PAGAMENTO
                    ).includes(pc.SK_CONDICAO_DE_PAGAMENTO)
                }}
            />
        </>
    )
}

export default PaymentConditionsActions
