import { isAxiosError } from '@/api/business'
import { Button } from '@/components/ui/button'
import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogDescription,
} from '@/components/ui/dialog'
import { useTable } from '@/hooks/useTable'
import { usePaginatedSuppliersQuery } from '@/queries/useSuppliersQuery'
import { Filter, Operators } from '@/types/Filters'
import { Supplier } from '@/types/Suppliers'
import { RowSelectionState, createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import DefaultColumn from '../Table/components/DefaultColumn'

interface SuppliersDialogProps {
    isOpen: boolean
    dialogState?: 'loading' | 'disabled'
    rowSelection: {
        selectedRows: RowSelectionState
        onSelectSuppliers: (
            selectedRows: RowSelectionState,
            suppliers: Supplier[]
        ) => void
    }
    onClose: () => void
    onCloseWithAction?: () => void
}

type SuppliersTable = TableData<Supplier>

const columnHelper = createColumnHelper<Supplier>()

const SuppliersDialog = ({
    isOpen,
    rowSelection: { selectedRows, onSelectSuppliers },
    dialogState,
    onClose,
    onCloseWithAction,
}: SuppliersDialogProps) => {
    const [fieldFilters, setFieldFilters] = useState<Filter<Supplier>[]>([])

    const {
        columnFilters,
        pageIndex,
        pageSize,
        onColumnFiltersChange,
        onPaginationChange,
    } = useTable()

    const { data, isPending, isFetching, isError, error } =
        usePaginatedSuppliersQuery({
            currentPage: pageIndex,
            perPage: pageSize,
            filters: fieldFilters,
            enabled: isOpen,
        })

    const dataMemo = useMemo(() => data?.DADOS || [], [data])

    const columnsMemo = useMemo(
        () => [
            columnHelper.display({
                header: 'Status',
                cell: ({ row }) =>
                    row.original.DD_BLOQUEADO === 1 ? (
                        <span className="px-2 text-xs text-red-500 rounded-md bg-red-50">
                            Bloqueado
                        </span>
                    ) : null,
                size: 100,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('SK_FORNECEDOR', {
                header: 'Cod fornecedor',
                size: 100,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DD_LOJA', {
                header: 'Loja',
                size: 80,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DD_NOME_FANTASIA', {
                header: 'Nome fantasia',
                size: 150,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DD_RAZAO_SOCIAL', {
                header: 'Razão social',
                size: 400,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DD_UF', {
                header: 'UF',
                size: 100,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DS_EMAIL', {
                header: 'Email',
                size: 150,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DD_CNPJ', {
                header: 'CNPJ',
                size: 150,
                meta: {
                    enableColumnOrdering: false,
                },
            }),

            columnHelper.accessor('DD_ORIGEM_DADO', {
                header: 'Origem',
                meta: {
                    enableColumnOrdering: false,
                },
            }),
        ],
        []
    )

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="flex flex-col max-w-[1200px] h-3/4 max-h-[900px]">
                <DialogHeader>
                    <DialogTitle>Fornecedores</DialogTitle>
                    <DialogDescription>
                        Selecione os fornecedores para serem adicionados na
                        cotação
                    </DialogDescription>
                </DialogHeader>
                <div className="flex-1 overflow-hidden">
                    <Table<SuppliersTable>
                        columns={columnsMemo}
                        data={dataMemo}
                        isLoading={isPending}
                        isError={isError}
                        tableState={{
                            columnFilters,
                            rowSelection: selectedRows,
                            pagination: {
                                pageIndex,
                                pageSize,
                            },
                        }}
                        errorMessage={
                            isError && isAxiosError(error)
                                ? error.response?.data.message
                                : undefined
                        }
                        getRowId={(row) => row.SK_FORNECEDOR.toString()}
                        isFetching={isFetching}
                        meta={{
                            layout: 'stretch',
                        }}
                        enableRowSelection={(row) =>
                            row.original.DD_BLOQUEADO !== 1
                        }
                        pagination={{
                            pageSize,
                            totalItems: data?.TOTAL || 0,
                            totalPages: data?.TOTAL_DE_PAGINAS || 0,
                        }}
                        onColumnFiltersChange={(columnFilterState) => {
                            if (columnFilterState) {
                                setFieldFilters([
                                    {
                                        AND: columnFilterState.map((field) => ({
                                            field: field.id as keyof Supplier,
                                            operator: Operators.like,
                                            value: field.value as string,
                                        })),
                                    },
                                ])
                            } else {
                                setFieldFilters([])
                            }
                            onColumnFiltersChange(columnFilterState)
                        }}
                        onPaginationChange={onPaginationChange}
                        onRowSelectionChange={(rowSelection) =>
                            onSelectSuppliers(
                                rowSelection,
                                dataMemo.filter((supplier) =>
                                    Object.keys(rowSelection).includes(
                                        supplier.SK_FORNECEDOR.toString()
                                    )
                                )
                            )
                        }
                        defaultColumn={{
                            cell: ({ getValue }) => (
                                <DefaultColumn>
                                    {getValue() as string}
                                </DefaultColumn>
                            ),
                        }}
                    />
                </div>
                <DialogFooter>
                    <Button
                        disabled={
                            dialogState === 'disabled' ||
                            dialogState === 'loading'
                        }
                        onClick={
                            onCloseWithAction ? onCloseWithAction : onClose
                        }
                    >
                        Confirmar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default SuppliersDialog
