import {
    fetchPaymentConditions,
    fetchPaymentConditionsPaginated,
} from '@/api/business/condicoesDePagamento'
import { FetchPaymentConditionsPaginatedProps } from '@/api/business/condicoesDePagamento/type'
import {
    keepPreviousData,
    useInfiniteQuery,
    useQuery,
} from '@tanstack/react-query'

export const paymentConditionsKeys = {
    all: ['paymentConditions'] as const,
    lists: () => [...paymentConditionsKeys.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...paymentConditionsKeys.lists(), page, perPage, filters] as const,
    details: () => [...paymentConditionsKeys.all, 'detail'] as const,
    detail: (params: string) =>
        [...paymentConditionsKeys.details(), params] as const,
    infinite: (filters: any) =>
        [...paymentConditionsKeys.lists(), 'infinite', filters] as const,
}

export const usePaymentConditionsQuery = () => {
    return useQuery({
        queryFn: fetchPaymentConditions,
        queryKey: paymentConditionsKeys.lists(),
    })
}

interface UsePaymentConditionsInfiniteQueryProps
    extends Omit<FetchPaymentConditionsPaginatedProps, 'currentPage'> {
    enabled?: boolean
}
interface UsePaymentConditionsPaginatedQueryProps
    extends FetchPaymentConditionsPaginatedProps {
    enabled?: boolean
}

export const usePaymentConditionsPaginatedQuery = ({
    currentPage,
    perPage,
    filters,
    enabled,
}: UsePaymentConditionsPaginatedQueryProps) => {
    return useQuery({
        queryFn: () =>
            fetchPaymentConditionsPaginated({
                currentPage,
                perPage,
                filters,
            }),
        queryKey: paymentConditionsKeys.list(currentPage, perPage, filters),
        enabled,
        placeholderData: keepPreviousData,
    })
}

export const usePaymentConditionsInfiniteQuery = ({
    perPage,
    filters,
    enabled,
}: UsePaymentConditionsInfiniteQueryProps) => {
    return useInfiniteQuery({
        queryFn: ({ pageParam }) =>
            fetchPaymentConditionsPaginated({
                currentPage: pageParam,
                perPage,
                filters,
            }),
        initialPageParam: 0,
        queryKey: paymentConditionsKeys.infinite(filters),
        enabled,
        getNextPageParam: (lastPage) => lastPage.PROXIMA_PAGINA,
        placeholderData: keepPreviousData,
    })
}
