import { Button } from '@/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Crown, MoreVertical } from 'lucide-react'

export type HeaderActionsProps = {
    disabled: boolean
    onSelectAll: () => void
}

const HeaderActions = ({ disabled, onSelectAll }: HeaderActionsProps) => {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon">
                    <MoreVertical size={14} />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
                <DropdownMenuItem
                    className="gap-2"
                    onClick={onSelectAll}
                    disabled={disabled}
                >
                    <Crown size={14} className="text-primary-600" />
                    Selecionar todos
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}

export default HeaderActions
