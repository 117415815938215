import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import CreateLayoutDialog from '@/components/CreateLayoutDialog'
import LayoutDialog from '@/components/LayoutsDialog'
import useDisclosure from '@/hooks/useDisclosure'
import { cn } from '@/lib/utils'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { LayoutGrid, List } from 'lucide-react'
import { Link } from 'react-router-dom'

const stateSelector = (state: Store) => ({
    layout: state.quotationListSlice.state.layout,
    onChangeLayout: state.quotationListSlice.actions.onChangeLayout,
})

const HeaderActions = () => {
    const { layout, onChangeLayout } = useBaseStore(stateSelector)
    const {
        isOpen: isLayoutsDialogOpen,
        onOpenChange: onLayoutsDialogOpenChange,
    } = useDisclosure()
    const {
        isOpen: isCreateLayoutsDialogOpen,
        onOpenChange: onCreateLayoutsDialogOpenChange,
    } = useDisclosure()

    return (
        <div className="flex items-center gap-6">
            <div className="flex items-center gap-2">
                <ButtonWithTooltip
                    className={cn(
                        layout === 'list' &&
                            'bg-neutral-200 hover:bg-neutral-200 dark:bg-zinc-700'
                    )}
                    variant="ghost"
                    tooltipContent={'Visualizar em lista'}
                    onClick={() => onChangeLayout('list')}
                >
                    <List className="text-foreground" size={16} />
                </ButtonWithTooltip>
                <div className="h-4 w-[1px] bg-neutral-300" />
                <ButtonWithTooltip
                    className={cn(
                        layout === 'grid' &&
                            'bg-neutral-200 hover:bg-neutral-200 dark:bg-zinc-700'
                    )}
                    variant="ghost"
                    tooltipContent={'Visualizar agrupado'}
                    onClick={() => onChangeLayout('grid')}
                >
                    <LayoutGrid className="text-foreground" size={16} />
                </ButtonWithTooltip>
            </div>
            {layout === 'list' && (
                <>
                    <LayoutDialog
                        isOpen={isLayoutsDialogOpen}
                        onCreateDialogOpenChange={
                            onCreateLayoutsDialogOpenChange
                        }
                        onOpenChange={onLayoutsDialogOpenChange}
                        tableId={'TABELA_COTACOES'}
                    />
                    <CreateLayoutDialog
                        isOpen={isCreateLayoutsDialogOpen}
                        onOpenChange={onCreateLayoutsDialogOpenChange}
                        tableId={'TABELA_COTACOES'}
                    />
                </>
            )}
            <Link
                className="px-4 py-2 text-sm text-white rounded-md bg-secondary-500 hover:bg-secondary-600"
                to="criar/solicitacao-de-compras"
            >
                Criar cotação
            </Link>
        </div>
    )
}

export default HeaderActions
