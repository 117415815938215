import useDisclosure from '@/hooks/useDisclosure'
import { cn } from '@/lib/utils'
import { PlusCircle } from 'lucide-react'
import NewRoundDialog from './components/NewRoundDialog'
import { useQuotationMapQuery } from '@/queries/useQuotatioMapQuery'
import { useParams } from 'react-router-dom'
import { Button } from '@/components/ui/button'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'

const NewRoundButton = () => {
    const { isOpen, onClose, onOpen } = useDisclosure()
    const { id } = useParams()

    const { data } = useQuotationByIdQuery({ id: Number(id) })
    const { isLoading, isError } = useQuotationMapQuery({ id: Number(id) })

    const disabled =
        isLoading ||
        isError ||
        data?.DD_COTACAO_FINALIZADA === 1 ||
        data?.DD_CANCELADA === 1

    return (
        <>
            <Button onClick={onOpen} disabled={disabled} variant="outline">
                <PlusCircle
                    size={14}
                    className={cn(
                        disabled ? 'text-neutral-500' : 'text-secondary-500'
                    )}
                />
                <span>Nova rodada</span>
            </Button>
            <NewRoundDialog isOpen={isOpen} onClose={onClose} />
        </>
    )
}

export default NewRoundButton
