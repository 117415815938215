import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { UseFormReturn } from 'react-hook-form'
import { CreateQuotationSchema } from '../..'
import { addBusinessDays, format, addDays, isWeekend } from 'date-fns'
import { useState } from 'react'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { cn } from '@/lib/utils'
import { Label } from '@/components/ui/label'
import { Checkbox } from '@/components/ui/checkbox'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import { TypographyP } from '@/components/ui/typography'

interface ExpirationDateFormFieldProps {
    form: UseFormReturn<CreateQuotationSchema>
}

const actionsSelector = (state: Store) => ({
    onChangeFormState:
        state.quotationPurchaseRequestSlice.actions.onChangeFormState,
})

const ExpirationDateFormField = ({ form }: ExpirationDateFormFieldProps) => {
    const [days, setDays] = useState(0)
    const { onChangeFormState } = useBaseStore(actionsSelector)
    const [toggleBusinessDay, setToggleBusinessDay] = useState(false)

    const checkBusinessDay = (date: Date) => {
        return !isWeekend(date)
    }

    return (
        <FormField
            name="expirationDate"
            control={form.control}
            render={({ field }) => {
                const newDate = addDays(new Date(), days)
                const businessDay = addBusinessDays(new Date(), days)
                const isBusinessDay = checkBusinessDay(newDate)

                return (
                    <>
                        <FormItem className="flex-1">
                            <FormLabel>Dias para expirar</FormLabel>
                            <FormControl>
                                <Input
                                    type="number"
                                    value={days}
                                    min={0}
                                    onBlur={() =>
                                        onChangeFormState(
                                            field.name,
                                            toggleBusinessDay
                                                ? businessDay.toString()
                                                : newDate.toString()
                                        )
                                    }
                                    onChange={(e) => {
                                        setDays(Number(e.target.value))
                                    }}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                        <div>
                            <div className="flex items-center gap-2 mb-2">
                                <Checkbox
                                    id="check"
                                    checked={toggleBusinessDay}
                                    onCheckedChange={(checked) => {
                                        setToggleBusinessDay(!!checked)
                                        onChangeFormState(
                                            field.name,
                                            checked
                                                ? businessDay.toString()
                                                : newDate.toString()
                                        )
                                    }}
                                />
                                <Label htmlFor="check">
                                    Cálculo em dias úteis
                                </Label>
                            </div>
                            <TypographyP>
                                Data de expiração:
                                <span
                                    className={cn(
                                        'inline-flex ml-2 font-semibold items-center gap-1',
                                        !isBusinessDay && !toggleBusinessDay
                                            ? 'text-amber-600'
                                            : ''
                                    )}
                                >
                                    {!isBusinessDay && !toggleBusinessDay ? (
                                        <ExclamationTriangleIcon />
                                    ) : null}
                                    {format(
                                        toggleBusinessDay
                                            ? businessDay
                                            : newDate,
                                        'EEEEEEE dd MMM yyyy'
                                    )}
                                </span>
                            </TypographyP>
                        </div>
                    </>
                )
            }}
        />
    )
}

export default ExpirationDateFormField
