import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { useBaseStore } from '@/store'
import { quotationMapFlatSelector } from '@/store/quotationMapSlice'
import { QuotationMapFlatState } from '@/store/quotationMapSlice/type'
import { useMemo } from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import { currencyFormat } from '@/utils/stringFormatter'
import { SavingTypeMapper } from '@/api/business/cotacoes/type'

type ApprovalTableType = TableData<QuotationMapFlatState>

const columnHelper = createColumnHelper<QuotationMapFlatState>()

const ApprovalTable = () => {
    const quotationMap = useBaseStore(quotationMapFlatSelector)

    const selected = useMemo(() => {
        return quotationMap.filter(
            (value) =>
                value.DD_APROVADO_POR_APROVADOR === 1 ||
                value.DD_RECUSADO_POR_APROVADOR === 1 ||
                value.DD_EM_APROVACAO === 1
        )
    }, [quotationMap])

    const columns = useMemo(
        () => [
            columnHelper.accessor('DD_NOME_FANTASIA', {
                id: 'DD_NOME_FANTASIA',
                header: 'Nm fantasia',
                size: 400,
            }),
            columnHelper.accessor('DD_RAZAO_SOCIAL', {
                id: 'DD_RAZAO_SOCIAL',
                header: 'Razão social',
                size: 400,
            }),
            columnHelper.accessor('NK_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_SOLICITACAO_DE_COMPRAS',
                header: 'SC',
                size: 200,
            }),
            columnHelper.accessor('NK_ITEM_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_ITEM_SOLICITACAO_DE_COMPRAS',
                header: 'Item',
                size: 200,
            }),
            columnHelper.accessor('NK_PRODUTO', {
                id: 'NK_PRODUTO',
                header: 'Cod Produto',
                size: 200,
            }),
            columnHelper.accessor('DS_PRODUTO', {
                id: 'DS_PRODUTO',
                header: 'Produto',
                size: 400,
            }),
            columnHelper.accessor(
                ({ QTD_DISPONIVEL }) => Number(QTD_DISPONIVEL),
                {
                    id: 'QTD_DISPONIVEL',
                    header: 'Qtde disponível',
                    size: 150,
                }
            ),
            columnHelper.accessor(
                ({ QTD_SOLICITADA }) => Number(QTD_SOLICITADA),
                {
                    id: 'QTD_SOLICITADA',
                    header: 'Qtde solicitada',
                    size: 150,
                }
            ),
            columnHelper.accessor(
                ({ PRECO }) => currencyFormat(Number(PRECO) || 0),
                {
                    id: 'PRECO',
                    header: 'Preço',
                    size: 200,
                }
            ),
            columnHelper.accessor(
                ({ PRECO_IMPOSTO }) =>
                    currencyFormat(Number(PRECO_IMPOSTO) || 0),
                {
                    id: 'PRECO_IMPOSTO',
                    header: 'Preço c/ imposto',
                    size: 200,
                }
            ),
            columnHelper.accessor(
                ({ PRECO_TOTAL }) => currencyFormat(Number(PRECO_TOTAL) || 0),
                {
                    id: 'PRECO_TOTAL',
                    header: 'Preço total',
                    size: 200,
                }
            ),
            columnHelper.accessor(
                ({ VL_SPENDING }) => currencyFormat(Number(VL_SPENDING) || 0),
                {
                    id: 'VL_SPENDING',
                    header: 'Preço total',
                    size: 200,
                }
            ),
            columnHelper.accessor(
                ({ SAVING }) => currencyFormat(Number(SAVING) || 0),
                {
                    id: 'SAVING',
                    header: 'Saving (%)',
                    size: 200,
                }
            ),
            columnHelper.accessor(
                ({ DD_SAVING }) => SavingTypeMapper[DD_SAVING],
                {
                    id: 'DD_SAVING',
                    header: 'Tipo Saving',
                    size: 200,
                }
            ),
            columnHelper.accessor('FCP', {
                id: 'FCP',
                header: 'FCP',
                size: 200,
            }),
            columnHelper.accessor('ICMS', {
                id: 'ICMS',
                header: 'ICMS',
                size: 200,
            }),
            columnHelper.accessor('IPI', {
                id: 'IPI',
                header: 'IPI',
                size: 200,
            }),
            columnHelper.accessor('PIS', {
                id: 'PIS',
                header: 'PIS',
                size: 200,
            }),
            columnHelper.accessor('COFINS', {
                id: 'COFINS',
                header: 'COFINS',
                size: 200,
            }),
            columnHelper.accessor('ST', {
                id: 'ST',
                header: 'ST',
                size: 200,
            }),
        ],
        []
    )

    return <Table<ApprovalTableType> data={selected} columns={columns} />
}

export default ApprovalTable
