import { Outlet, useParams } from 'react-router-dom'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { useBaseStore } from '@/store'
import { useQuotationMapQuery } from '@/queries/useQuotatioMapQuery'
import { useEffect } from 'react'
import { Store } from '@/store/type'
import QuotationLayoutSkeleton from './components/QuotationLayoutSkeleton'
import QuotationLayoutError from './components/QuotationLayoutError'

const stateSelector = (state: Store) => ({
    onSetData: state.quotationMapSlice.actions.onSetData,
    onSetRounds: state.quotationDetailSlice.actions.onSetRounds,
})

const QuotationDetailsLayout = () => {
    const { id: quotationId } = useParams()

    const {
        data: quotationDetail,
        isPending: isQuotationPending,
        isError: isQuotationError,
    } = useQuotationByIdQuery({
        id: Number(quotationId),
    })

    const {
        data: quotationMap,
        isPending: isQuotationMapPending,
        isError: isQuotationMapError,
    } = useQuotationMapQuery({
        id: Number(quotationId),
    })

    const { onSetData, onSetRounds } = useBaseStore(stateSelector)

    useEffect(() => {
        if (quotationDetail && quotationMap) {
            onSetData(quotationMap, quotationDetail)

            onSetRounds(
                quotationDetail.RODADAS.map((round) => ({
                    roundId: round.SK_COTACAO_X_RODADA,
                    round: round.NUMERO_RODADA,
                    expirationDate: round.DT_EXPIRACAO,
                    finished: round.DD_FINALIZADA === 1 ? true : false,
                    createdAt: round.CREATED_AT,
                    updatedAt: round.UPDATED_AT,
                }))
            )
        }
    }, [quotationDetail, quotationMap])

    if (isQuotationPending || isQuotationMapPending)
        return <QuotationLayoutSkeleton />

    if (isQuotationError || isQuotationMapError) return <QuotationLayoutError />

    return (
        <div className="flex flex-col h-full overflow-auto">
            <Outlet />
        </div>
    )
}

export default QuotationDetailsLayout
