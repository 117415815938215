import {
    fetchTableLayouts,
    // fetchAllTableLayouts,
    fetchUserTableLayouts,
} from '@/api/business/layoutTabela'
import { FetchTableLayoutsProps } from '@/api/business/layoutTabela/type'
import { useQuery } from '@tanstack/react-query'

export const tableLayoutKeys = {
    all: ['tableLayout'] as const,
    lists: () => [...tableLayoutKeys.all, 'list'] as const,
    list: (tableId: string) => [...tableLayoutKeys.lists(), tableId] as const,
    listsUser: (tableId: string) =>
        [...tableLayoutKeys.list(tableId), 'user'] as const,
    details: () => [...tableLayoutKeys.all, 'detail'] as const,
    detail: (params: string) => [...tableLayoutKeys.details(), params] as const,
}

export const useTableLayoutQuery = ({ tableId }: FetchTableLayoutsProps) => {
    return useQuery({
        queryFn: () => fetchTableLayouts({ tableId }),
        queryKey: tableLayoutKeys.detail(tableId),
    })
}

// export const useAllTableLayoutsQuery = ({
//     tableId,
// }: FetchTableLayoutsProps) => {
//     return useQuery({
//         queryFn: () => fetchAllTableLayouts({ tableId }),
//         queryKey: tableLayoutKeys.list(tableId),
//     })
// }

export const useUserTableLayoutQuery = ({
    tableId,
}: FetchTableLayoutsProps) => {
    return useQuery({
        queryFn: () => fetchUserTableLayouts({ tableId }),
        queryKey: tableLayoutKeys.listsUser(tableId),
    })
}
