import { DisclosureCommonProps } from '@/types/Disclosure'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '../ui/dialog'
import { Button } from '@/components/ui/button'

interface UnsavedChangesDialogProps extends DisclosureCommonProps {
    isLoading?: boolean
    onConfirm: () => void
    onDiscard: () => void
}

const UnsavedChangesDialog = ({
    isOpen,
    isLoading,
    onDiscard,
    onConfirm,
    onOpenChange,
}: UnsavedChangesDialogProps) => {
    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Alterações não salvas</DialogTitle>
                </DialogHeader>
                <div>
                    Há alterações não salvas. Você pode salvar, descartar ou
                    cancelar e continuar editando.
                </div>
                <DialogFooter>
                    <Button onClick={() => onOpenChange(false)} variant="ghost">
                        Cancelar
                    </Button>
                    <Button
                        onClick={onDiscard}
                        disabled={isLoading}
                        variant="ghost"
                    >
                        Descartar
                    </Button>
                    <Button onClick={onConfirm} disabled={isLoading}>
                        Confirmar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default UnsavedChangesDialog
