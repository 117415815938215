import instance from '@/api/business'
import {
    ParametrosAvaliacao,
    ParametrosAvaliacaoPaginacaoResponse,
} from '@/types/ParametrosAvaliacao'
import { Filter } from '@/types/Filters'

const BASE_ROUTE = '/parametros-avaliacao'

export const fetchParametrosAvaliacao = async () => {
    const response = await instance.post<ParametrosAvaliacao[]>(BASE_ROUTE)
    return response.data
}

export const fetchParametrosAvaliacaoPaginados = async (
    page = 1,
    perPage = 10,
    filters: Filter<ParametrosAvaliacao>[]
) => {
    const response = await instance.post<ParametrosAvaliacaoPaginacaoResponse>(
        `${BASE_ROUTE}/paginacao`,
        {
            POR_PAGINA: perPage,
            PAGINA_ATUAL: page,
            FILTROS: filters || [],
        }
    )
    return response.data
}

export const addParametroAvaliacao = async (
    data: Partial<ParametrosAvaliacao>
) => {
    const response = await instance.put(BASE_ROUTE, data)

    return response.data
}

export const updateParametroAvaliacao = async (
    data: Partial<ParametrosAvaliacao>
) => {
    const response = await instance.patch(BASE_ROUTE, {
        ...data,
        SK_PARAMETRO_AVALIACAO: data.SK_PARAMETRO_AVALIACAO,
    })

    return response.data
}

export const deleteParametroAvaliacao = async (id: number[]) => {
    const response = await instance.delete(BASE_ROUTE, {
        data: { SK_PARAMETRO_AVALIACAO: id },
    })

    return response.data
}
