import { MenubarItem } from '@/components/ui/menubar'
import { cn } from '@/lib/utils'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { Trash, XCircle } from 'lucide-react'
import { useParams } from 'react-router-dom'

type DeleteItemProps = {
    onClick: () => void
}

const DeleteItem = ({ onClick }: DeleteItemProps) => {
    const { id } = useParams()

    const { data, isPending, isError } = useQuotationByIdQuery({
        id: Number(id),
    })

    const shouldDelete = data?.DD_CANCELADA === 1 || data?.DD_PUBLICADO === 0

    return (
        <>
            <MenubarItem
                className={cn(
                    'gap-2',
                    shouldDelete && 'text-destructive focus:text-destructive'
                )}
                onClick={onClick}
                disabled={isPending || isError}
            >
                {shouldDelete ? (
                    <>
                        <Trash size={14} className="text-red-500" />
                        Excluir cotação
                    </>
                ) : (
                    <>
                        <XCircle size={14} className="text-red-500" />
                        Cancelar cotação
                    </>
                )}
            </MenubarItem>
        </>
    )
}

export default DeleteItem
