import { cn } from '@/lib/utils'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'

import { CheckCircle } from 'lucide-react'
import { useParams } from 'react-router-dom'
import { MenubarItem } from '@/components/ui/menubar'

type ApproveQuotationMapItemProps = {
    onClick: () => void
}

const ApproveQuotationMapItem = ({ onClick }: ApproveQuotationMapItemProps) => {
    const { id } = useParams()

    const {
        data: quotation,
        isPending: isQuotationPending,
        isError: isQuotationError,
    } = useQuotationByIdQuery({
        id: Number(id),
    })

    const disabled =
        isQuotationPending ||
        quotation?.DD_CANCELADA === 1 ||
        isQuotationError ||
        quotation.DD_EM_APROVACAO !== 1

    return (
        <MenubarItem className="gap-2" disabled={disabled} onClick={onClick}>
            <CheckCircle
                size={14}
                className={cn(
                    disabled ? 'text-muted-foreground' : 'text-green-600'
                )}
            />
            Aprovar
        </MenubarItem>
    )
}

export default ApproveQuotationMapItem
