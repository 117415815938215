import { MultiSelect } from '@/components/MultiSelect'
import { Label } from '@/components/ui/label'
import { useQuotationFiltersQuery } from '@/queries/useQuotationQuery'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'

const stateSelector = (state: Store) => ({
    suppliersSelected: state.quotationListSlice.state.suppliersSelected,
    onSelectSupplier: state.quotationListSlice.actions.onSelectSupplier,
})

const SupplierSelect = () => {
    const { data: filters, isPending, isError } = useQuotationFiltersQuery()
    const { suppliersSelected, onSelectSupplier } = useBaseStore(stateSelector)

    return (
        <div className="w-[180px]">
            <Label>Fornecedores</Label>
            <MultiSelect
                options={
                    filters?.FORNECEDORES.map((product) => ({
                        label: product.DD_RAZAO_SOCIAL,
                        value: product.SK_FORNECEDOR,
                    })) || []
                }
                disabled={isPending || isError}
                label={
                    isPending ? 'Carregando...' : isError ? 'Erro' : undefined
                }
                onChange={onSelectSupplier}
                selectedValues={suppliersSelected}
            />
        </div>
    )
}

export default SupplierSelect
