import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { useBaseStore } from '@/store'
import { getSelectedFreightSelector } from '@/store/quotationMapSlice'

const FreightSection = () => {
    const freight = useBaseStore(getSelectedFreightSelector)

    return (
        <div>
            <TypographyMuted>Frete</TypographyMuted>
            <div className="px-4 py-2 border rounded-md">
                <TypographyP>{freight || '-'}</TypographyP>
            </div>
        </div>
    )
}

export default FreightSection
