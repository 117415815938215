import { isAxiosError } from '@/api/business'
import { removeFile } from '@/api/business/arquivos'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogFooter,
    DialogHeader,
} from '@/components/ui/dialog'
import { useToast } from '@/components/ui/use-toast'
import { attachmentsKeys } from '@/queries/useAttachmentsQuery'
import { quotationKeys } from '@/queries/useQuotationQuery'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

interface DeleteAttachmentDialogProps {
    fileId: number
    isOpen: boolean
    onClose: () => void
}

const DeleteAttachmentDialog = ({
    fileId,
    isOpen,
    onClose,
}: DeleteAttachmentDialogProps) => {
    const { id } = useParams()
    const { toast } = useToast()
    const queryClient = useQueryClient()

    const { mutate, isPending } = useMutation({
        mutationFn: removeFile,
        onSuccess: () => {
            if (id)
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.detail(id),
                })

            queryClient.invalidateQueries({
                queryKey: attachmentsKeys.all,
            })

            toast({
                title: 'Anexo excluído',
                description: 'O anexo foi excluído com sucesso',
            })

            onClose()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível excluir anexo. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao excluir anexo',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const onSubmit = () => {
        if (id)
            mutate({
                files: [{ SK_ARQUIVO: fileId }],
                quotationId: Number(id),
            })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Excluir anexo</DialogTitle>
                </DialogHeader>
                <div>
                    <p>Realmente deseja excluir o anexo?</p>
                </div>
                <DialogFooter>
                    <Button variant="ghost" onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button
                        disabled={isPending}
                        variant="destructive"
                        onClick={onSubmit}
                    >
                        Excluir
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default DeleteAttachmentDialog
