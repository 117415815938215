import { BreadcrumbList, BreadcrumbSeparator } from '@/components/ui/breadcrumb'
import { Skeleton } from '@/components/ui/skeleton'

const QuotationLayoutSkeleton = () => {
    return (
        <div>
            <div className="flex items-center h-12 gap-4 p-4 border-b">
                <BreadcrumbList>
                    <Skeleton className="w-[80px] h-5" />
                    <BreadcrumbSeparator />
                    <Skeleton className="w-[80px] h-5" />
                </BreadcrumbList>
            </div>
            <div className="p-4 space-y-4 border-b">
                <div className="flex items-center justify-between">
                    <Skeleton className="w-[250px] h-8" />
                    <Skeleton className="w-[100px] h-6" />
                </div>
                <div className="flex items-center gap-4">
                    <Skeleton className="w-[100px] h-5" />
                    <Skeleton className="w-[100px] h-5" />
                    <Skeleton className="w-[100px] h-5" />
                </div>
                <div className="flex items-center gap-4">
                    <div className="space-y-1.5">
                        <Skeleton className="w-[80px] h-5" />
                        <Skeleton className="w-[120px] h-5" />
                    </div>
                    <div className="space-y-1.5">
                        <Skeleton className="w-[80px] h-5" />
                        <Skeleton className="w-[120px] h-5" />
                    </div>
                    <div className="space-y-1.5">
                        <Skeleton className="w-[80px] h-5" />
                        <Skeleton className="w-[120px] h-5" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuotationLayoutSkeleton
