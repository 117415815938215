import CompanySelect from './components/PurchaseRequestSelect'
import PurchaseRequestSelect from './components/CompanySelect'
import SupplierSelect from './components/SupplierSelect'
import SearchInput from './components/SearchInput'
import { Filter } from '@/types/Filters'
import { Quotation } from '@/types/Quotation'
import SearchButton from './components/SearchButton'
import ClearFiltersButton from './components/ClearFiltersButton'
import StatusSelect from './components/StatusSelect'

interface FiltersProps {
    setFieldFilters: (filters: Filter<Quotation>[]) => void
}

const Filters = ({ setFieldFilters }: FiltersProps) => {
    return (
        <div className="flex-wrap items-end justify-end gap-4 mb-4 space-y-4 lg:flex">
            <div className="flex-1">
                <SearchInput />
            </div>
            <div className="flex gap-2 item-center">
                <CompanySelect />
                <PurchaseRequestSelect />
                <SupplierSelect />
                <StatusSelect />
            </div>
            <div className="flex justify-end gap-2 item-center">
                <SearchButton setFieldFilters={setFieldFilters} />
                <ClearFiltersButton setFieldFilters={setFieldFilters} />
            </div>
        </div>
    )
}

export default Filters
