import Select from '@/components/Select'
import { Label } from '@/components/ui/label'
import { useCurrenciesQuery } from '@/queries/useCurrencyQuery'
import { useBaseStore } from '@/store'
import { getSelectedCurrencySelector } from '@/store/quotationMapSlice'
import { Store } from '@/store/type'

const stateSelector = (state: Store) => ({
    onSelectCurrency: state.quotationMapSlice.actions.onSelectCurrency,
})

const CurrencySelect = () => {
    const { data, isPending, isError } = useCurrenciesQuery()
    const selectedCurrencyId = useBaseStore(getSelectedCurrencySelector)
    const { onSelectCurrency } = useBaseStore(stateSelector)

    return (
        <div className="space-y-1.5">
            <Label>Selecionar moeda</Label>
            <Select
                className="w-[100px]"
                placeholder={
                    isPending
                        ? 'Carregando...'
                        : isError
                        ? 'Erro ao carregar'
                        : 'Selecione uma moeda'
                }
                options={
                    data
                        ? data?.map((currency) => ({
                              label: currency.DS_MOEDA,
                              value: currency.SK_MOEDA.toString(),
                          }))
                        : []
                }
                onChange={(id) => {
                    onSelectCurrency(Number(id))
                }}
                value={selectedCurrencyId?.toString() || ''}
                disabled={isPending || isError}
            />
        </div>
    )
}

export default CurrencySelect
