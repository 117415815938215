import { fetchShippingCompanies } from '@/api/business/transportadoras'
import { useQuery } from '@tanstack/react-query'

export const shippingCompanyKeys = {
    all: ['shippingCompany'] as const,
    lists: () => [...shippingCompanyKeys.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...shippingCompanyKeys.lists(), page, perPage, filters] as const,
    details: () => [...shippingCompanyKeys.all, 'detail'] as const,
    detail: (params: string) =>
        [...shippingCompanyKeys.details(), params] as const,
}

type UseShippingCompanyQueryProps = {
    enabled: boolean
}

export const useShippingCompanyQuery = ({
    enabled,
}: UseShippingCompanyQueryProps) => {
    return useQuery({
        queryFn: fetchShippingCompanies,
        queryKey: shippingCompanyKeys.lists(),
        enabled,
    })
}
