import { Button } from '@/components/ui/button'
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
} from '@/components/ui/command'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import useDisclosure from '@/hooks/useDisclosure'
import { cn } from '@/lib/utils'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { useBaseStore } from '@/store'
import { getSelectedPaymentConditionSelector } from '@/store/quotationMapSlice'
import { Store } from '@/store/type'
import { CheckIcon, ChevronsUpDown, Star } from 'lucide-react'
import { useParams } from 'react-router-dom'

const stateSelector = (state: Store) => ({
    onSelectPaymentCondition:
        state.quotationMapSlice.actions.onSelectPaymentCondition,
})

const PaymentConditionCombobox = () => {
    const { id } = useParams()
    const { isOpen, onOpen, onOpenChange } = useDisclosure()
    const { data } = useQuotationByIdQuery({ id: Number(id) })
    const selectedPaymentConditionId = useBaseStore(
        getSelectedPaymentConditionSelector
    )
    const { onSelectPaymentCondition } = useBaseStore(stateSelector)

    const selectedPaymentCondition = selectedPaymentConditionId?.manual
        ? selectedPaymentConditionId?.id
        : data?.CONDICOES_DE_PAGAMENTO.find(
              (condition) =>
                  condition.SK_CONDICAO_DE_PAGAMENTO ===
                  selectedPaymentConditionId?.id
          )?.DS_CONDICAO_DE_PAGAMENTO

    return (
        <>
            <Popover open={isOpen} onOpenChange={onOpenChange}>
                <PopoverTrigger asChild>
                    <Button
                        className="justify-between w-[220px] flex gap-2 shadow-sm hover:bg-transparent border border-input"
                        onClick={onOpen}
                        variant="outline"
                    >
                        <span className="overflow-hidden text-ellipsis">
                            {selectedPaymentCondition
                                ? selectedPaymentCondition
                                : 'Selecione uma condição de pagamento'}
                        </span>
                        <ChevronsUpDown size={14} />
                    </Button>
                </PopoverTrigger>
                <PopoverContent
                    align="start"
                    className="p-0 w-[500px] overflow-hidden"
                >
                    <Command>
                        <CommandInput placeholder=" Buscar por condição de pagamento" />
                        <CommandEmpty className="flex items-center justify-center p-6 text-sm text-neutral-400">
                            Condição de pagamento não encontrada.
                        </CommandEmpty>
                        <CommandGroup>
                            {data?.CONDICOES_DE_PAGAMENTO.map((condition) => (
                                <CommandItem
                                    key={condition.SK_CONDICAO_DE_PAGAMENTO}
                                    className="items-center gap-2"
                                    value={condition.DS_CONDICAO_DE_PAGAMENTO}
                                    onSelect={() => {
                                        onSelectPaymentCondition(
                                            condition.SK_CONDICAO_DE_PAGAMENTO
                                        )
                                        onOpenChange(false)
                                    }}
                                >
                                    <CheckIcon
                                        size={14}
                                        className={cn(
                                            selectedPaymentConditionId?.id ===
                                                condition.SK_CONDICAO_DE_PAGAMENTO
                                                ? 'opacity-1'
                                                : 'opacity-0'
                                        )}
                                    />
                                    <TypographyP>
                                        {condition.DS_CONDICAO_DE_PAGAMENTO}
                                    </TypographyP>
                                    {condition.DD_PREFERIDA === 1 ? (
                                        <TypographyMuted className="flex items-center gap-1 text-xs">
                                            <Star
                                                size={11}
                                                className="text-primary-600"
                                            />
                                            Preferência do comprador
                                        </TypographyMuted>
                                    ) : null}
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </Command>
                </PopoverContent>
            </Popover>
        </>
    )
}

export default PaymentConditionCombobox
