import { ChevronRight } from 'lucide-react'
import { SIDENAV_ITEMS } from '@/components/AppSidebar/consts'
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from '../ui/collapsible'
import {
    Sidebar,
    SIDEBAR_WIDTH_ICON,
    SidebarContent,
    SidebarGroup,
    SidebarHeader,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
    SidebarMenuSub,
    SidebarRail,
    useSidebar,
} from '../ui/sidebar'
import Logo from '@/assets/icons/icon.png'
import SidebarSubItem from './components/SidebarSubItem'
import SidebarItem from './components/SidebarItem'
import { useNavigate } from 'react-router-dom'

const AppSidebar = () => {
    const { setOpen } = useSidebar()
    const navigate = useNavigate()

    return (
        <Sidebar collapsible="icon">
            <SidebarHeader>
                <SidebarMenuButton
                    size="lg"
                    className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                    tooltip="B/SUPPLY"
                    onClick={() => navigate('/')}
                >
                    <div className="flex items-center justify-center rounded-lg aspect-square size-8">
                        <img
                            src={Logo}
                            style={{
                                width: SIDEBAR_WIDTH_ICON,
                            }}
                        />
                    </div>
                    <div className="grid flex-1 text-sm leading-tight text-left">
                        <span className="font-semibold truncate">B/SUPPLY</span>
                    </div>
                </SidebarMenuButton>
            </SidebarHeader>
            <SidebarContent>
                <SidebarGroup>
                    <SidebarMenu>
                        {SIDENAV_ITEMS.map((item) => {
                            if (item.subItems && item.subItems.length > 0) {
                                return (
                                    <Collapsible
                                        key={item.label}
                                        asChild
                                        className="group/collapsible"
                                    >
                                        <SidebarMenuItem>
                                            <CollapsibleTrigger asChild>
                                                <SidebarMenuButton
                                                    tooltip={item.label}
                                                    onClick={() =>
                                                        setOpen(true)
                                                    }
                                                >
                                                    {item.Icon && <item.Icon />}
                                                    <span>{item.label}</span>
                                                    <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                                                </SidebarMenuButton>
                                            </CollapsibleTrigger>
                                            <CollapsibleContent>
                                                <SidebarMenuSub>
                                                    {item.subItems?.map(
                                                        (subItem) => (
                                                            <SidebarSubItem
                                                                key={
                                                                    subItem.href
                                                                }
                                                                to={
                                                                    subItem.href
                                                                }
                                                                label={
                                                                    subItem.label
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </SidebarMenuSub>
                                            </CollapsibleContent>
                                        </SidebarMenuItem>
                                    </Collapsible>
                                )
                            }

                            return (
                                <SidebarItem
                                    key={item.href}
                                    label={item.label}
                                    Icon={item.Icon}
                                    to={item.href}
                                />
                            )
                        })}
                    </SidebarMenu>
                </SidebarGroup>
            </SidebarContent>
            <SidebarRail />
        </Sidebar>
    )
}

export default AppSidebar
