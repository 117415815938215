export const TableNames: Record<TableIds, string> = {
    DADOS_MESTRE_AVALIACOES: 'Dados mestre avaliações',
    DADOS_MESTRE_FORNECEDORES: 'Dados mestre avaliações',
    DADOS_MESTRE_PARAMETROS_AVALIACAO: 'Dados mestre parâmetros avaliação',
    DADOS_MESTRE_PRODUTOS: 'Dados mestre produtos',
    DADOS_MESTRE_EMPRESAS: 'Dados mestre empresas',
    DADOS_MESTRE_PEDIDOS_DE_COMPRAS: 'Dados mestre pedido de compras',
    DADOS_MESTRE_CONDICOES_DE_PAGAMENTO: 'Dados mestre condições de pagamento',
    TABELA_SOLICITACOES_DE_COMPRAS: 'Solicitações de compras',
    TABELA_COTACOES: 'Cotações',
} as const

export type TableIds =
    | 'DADOS_MESTRE_AVALIACOES'
    | 'DADOS_MESTRE_FORNECEDORES'
    | 'DADOS_MESTRE_PARAMETROS_AVALIACAO'
    | 'DADOS_MESTRE_PRODUTOS'
    | 'DADOS_MESTRE_EMPRESAS'
    | 'DADOS_MESTRE_PEDIDOS_DE_COMPRAS'
    | 'DADOS_MESTRE_CONDICOES_DE_PAGAMENTO'
    | 'DADOS_MESTRE_CONDICOES_DE_PAGAMENTO'
    | 'TABELA_SOLICITACOES_DE_COMPRAS'
    | 'TABELA_COTACOES'
