import ExpiredToken from '@/components/ExpiredToken'
import Header from '@/components/Header'
import ChangePasswordDialog from '@/components/ChangePasswordDialog'
import { useBaseStore } from '@/store'
import { Outlet } from 'react-router-dom'
import { ReactNode } from 'react'
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers'
import {
    DndContext,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    closestCenter,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { Store } from '@/store/type'
import { SidebarProvider } from '@/components/ui/sidebar'
import AppSidebar from '@/components/AppSidebar'
interface MainLayoutProps {
    children?: ReactNode
}

const stateSelector = (state: Store) => ({
    isSidenavOpen: state.appConfigSlice.state.isSidenavOpen,
    tokenExpired: state.authSlice.state.tokenExpired,
})

const MainLayout = ({ children }: MainLayoutProps) => {
    const { tokenExpired } = useBaseStore(stateSelector)

    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    )

    return (
        <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToHorizontalAxis]}
            sensors={sensors}
        >
            <SidebarProvider defaultOpen={false}>
                <AppSidebar />
                <div className="flex flex-col flex-1 h-screen overflow-hidden">
                    <Header />
                    {tokenExpired && <ExpiredToken />}
                    <div className="flex flex-col flex-1 overflow-auto">
                        {children || <Outlet />}
                    </div>
                </div>
            </SidebarProvider>
            <ChangePasswordDialog />
        </DndContext>
    )
}

export default MainLayout
