import { DropdownMenuItem } from '@/components/ui/dropdown-menu'
import { cn } from '@/lib/utils'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { Package } from 'lucide-react'
import { useParams } from 'react-router-dom'

type AddProductsButtonProps = {
    onClick: () => void
}

const AddProductsButton = ({ onClick }: AddProductsButtonProps) => {
    const { id } = useParams()

    const { data, isPending, isError } = useQuotationByIdQuery({
        id: Number(id),
    })

    const disabled =
        isPending ||
        isError ||
        [
            data.DD_CANCELADA,
            data.DD_EM_APROVACAO,
            data.DD_MAPA_APROVADO,
            data.DD_MAPA_RECUSADO,
        ].includes(1)

    return (
        <DropdownMenuItem
            disabled={disabled}
            onClick={onClick}
            className="gap-2"
        >
            <Package
                size={14}
                className={cn(disabled ? 'text-neutral-500' : 'text-sky-500')}
            />
            Adicionar produtos
        </DropdownMenuItem>
    )
}

export default AddProductsButton
