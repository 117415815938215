import instance from '@/api/business'
import { Company } from '@/types/Companies'
import { UpdateCompanyConfigProps } from './type'

export const fetchCompanies = async () => {
    const response = await instance.post<Company[]>('/empresas')

    return response.data
}

export const updateCompanyConfig = async (props: UpdateCompanyConfigProps) => {
    const response = await instance.patch('/empresas', {
        ...props,
    })

    return response.data
}

export const fetchCompanyById = async (id: number) => {
    const response = await instance.post<Company>('/empresas/id', {
        SK_EMPRESA: id,
    })

    return response.data
}
