import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'

import { useToast } from '@/components/ui/use-toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isAxiosError } from '@/api/business'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { createSupplier } from '@/api/business/fornecedores'
import { suppliersKeys } from '@/queries/useSuppliersQuery'
import { validationEmailSchema } from '@/schemas/emailSchema'
interface AddDialogProps {
    isOpen: boolean
    onClose: () => void
}

const AddDialog = ({ isOpen, onClose }: AddDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const schema = z.object({
        supplierName: z.string().min(1, 'Informe o fornecedor'),
        supplier: z.string().min(1, 'Informe o fornecedor'),
        store: z.string().optional(),
        cnpj: z.string().optional(),
        email: validationEmailSchema.optional(),
        observation: z.string().optional(),
    })

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            supplier: '',
            supplierName: '',
            store: '',
            cnpj: '',
            email: '',
            observation: '',
        },
    })

    const { mutate, isPending: mutateLoading } = useMutation({
        mutationFn: createSupplier,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: suppliersKeys.lists(),
            })

            toast({
                title: 'Fornecedor criado com sucesso',
            })
            onClose()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível criar fornecedor. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao criar fornecedor',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const onSubmit = (data: z.infer<typeof schema>) => {
        mutate({
            DD_NOME_FANTASIA: data.supplierName,
            DD_RAZAO_SOCIAL: data.supplier,
            DD_CNPJ: data.cnpj,
            DD_LOJA: data.store,
            DS_EMAIL: [data.email || ''],
            DD_OBSERVACAO: data.observation || '',
        })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Adicionar fornecedor</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-2"
                    >
                        <FormField
                            name="supplier"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Razão social</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="supplierName"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Nome fantasia</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="store"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Loja</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="cnpj"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>CNPJ</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="email"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="observation"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Observação</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <DialogFooter>
                            <Button
                                type="button"
                                onClick={onClose}
                                variant="ghost"
                            >
                                Cancelar
                            </Button>
                            <Button type="submit" disabled={mutateLoading}>
                                Confirmar
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default AddDialog
