import {
    fetchPaginatedSuppliers,
    fetchSuppliers,
} from '@/api/business/fornecedores'
import { FetchPaginatedSuppliersProps } from '@/api/business/fornecedores/type'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const suppliersKeys = {
    all: ['suppliers'] as const,
    lists: () => [...suppliersKeys.all, 'list'] as const,
    list: (props: { currentPage: number; perPage: number; filters: any }) =>
        [...suppliersKeys.lists(), { ...props }] as const,
    details: () => [...suppliersKeys.all, 'detail'] as const,
    detail: (params: string) => [...suppliersKeys.details(), params] as const,
}

export const useSuppliersQuery = () => {
    return useQuery({
        queryFn: fetchSuppliers,
        queryKey: suppliersKeys.lists(),
    })
}

interface UsePaginatedSuppliersQuery extends FetchPaginatedSuppliersProps {
    enabled: boolean
}

export const usePaginatedSuppliersQuery = ({
    currentPage,
    perPage,
    filters,
    enabled,
}: UsePaginatedSuppliersQuery) => {
    return useQuery({
        queryFn: () =>
            fetchPaginatedSuppliers({ currentPage, perPage, filters }),
        queryKey: suppliersKeys.list({ currentPage, perPage, filters }),
        placeholderData: keepPreviousData,
        enabled,
    })
}
