import { ValidatePurchaseOrderItems } from '@/api/business/cotacoes/type'
import Table from '@/components/Table'
import {
    Table as HTMLTable,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { TableData } from '@/components/Table/type'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog'
import { TypographyP } from '@/components/ui/typography'
import { createColumnHelper } from '@tanstack/react-table'
import { SquareArrowOutUpRight } from 'lucide-react'
import { useMemo } from 'react'
import { cn } from '@/lib/utils'

type ValidateTableData = TableData<ValidatePurchaseOrderItems>

type ValidateTableProps = { items: ValidatePurchaseOrderItems[] }

const columnHelper = createColumnHelper<ValidateTableData>()

const ValidateTable = ({ items }: ValidateTableProps) => {
    const columns = useMemo(
        () => [
            columnHelper.display({
                id: 'STATUS',
                header: 'Status',
                size: 200,
                cell: ({ row }) => {
                    const { ERROS, ERROS_RATEIO } = row.original

                    if (ERROS.length > 0 || ERROS_RATEIO.length > 0) {
                        return (
                            <TypographyP className="overflow-hidden text-red-500 whitespace-nowrap text-ellipsis">
                                Erro de validação
                            </TypographyP>
                        )
                    }

                    return (
                        <TypographyP className="text-green-600">OK</TypographyP>
                    )
                },
            }),
            columnHelper.accessor('NK_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_SOLICITACAO_DE_COMPRAS',
                header: 'SC',
                size: 100,
            }),
            columnHelper.accessor('DS_PRODUTO', {
                id: 'DS_PRODUTO',
                header: 'Produto',
                size: 400,
            }),
            columnHelper.display({
                id: 'ERRO',
                header: 'Observação',
                cell: ({ row }) => {
                    const {
                        ERROS,
                        ERROS_RATEIO,
                        NK_SOLICITACAO_DE_COMPRAS,
                        NK_ITEM_SOLICITACAO_DE_COMPRAS,
                    } = row.original

                    const isError = ERROS.length > 0 || ERROS_RATEIO.length > 0

                    const message = ERROS.map((error) => error.message).join(
                        '; '
                    )

                    return isError ? (
                        <Dialog>
                            <DialogTrigger className="flex items-center gap-2">
                                <SquareArrowOutUpRight
                                    size={14}
                                    className="text-sky-500"
                                />
                                <TypographyP className="flex-1 text-red-500 truncate">
                                    {message}
                                </TypographyP>
                            </DialogTrigger>
                            <DialogContent className="max-w-[680px]">
                                <DialogHeader>
                                    <DialogTitle>Erros</DialogTitle>
                                    <DialogDescription>
                                        Erros de validação da solicitação de
                                        compras{' '}
                                        <span className="font-semibold">
                                            {NK_SOLICITACAO_DE_COMPRAS}
                                        </span>{' '}
                                        item{' '}
                                        <span className="font-semibold">
                                            {NK_ITEM_SOLICITACAO_DE_COMPRAS}
                                        </span>
                                    </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col gap-4">
                                    {message && (
                                        <TypographyP className="text-red-500">
                                            {message}
                                        </TypographyP>
                                    )}
                                    {ERROS_RATEIO.length > 0 && (
                                        <HTMLTable>
                                            <TableHeader>
                                                <TableRow>
                                                    <TableHead className="text-start">
                                                        Erro
                                                    </TableHead>
                                                    <TableHead className="text-start">
                                                        Centro de custo
                                                    </TableHead>
                                                    <TableHead className="text-start">
                                                        Conta orçamentária
                                                    </TableHead>
                                                    <TableHead className="text-start">
                                                        Saldo
                                                    </TableHead>
                                                    <TableHead className="text-start">
                                                        Valor
                                                    </TableHead>
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>
                                                {ERROS_RATEIO.map(
                                                    (error, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                <TypographyP
                                                                    className={cn(
                                                                        error.MENSAGEM !==
                                                                            'OK'
                                                                            ? 'text-red-500'
                                                                            : 'text-green-500'
                                                                    )}
                                                                >
                                                                    {
                                                                        error.MENSAGEM
                                                                    }
                                                                </TypographyP>
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    error.NK_CENTRO_DE_CUSTO
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    error.NK_CONTA_ORCAMENTARIA
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {error.SALDO}
                                                            </TableCell>
                                                            <TableCell>
                                                                {error.VALOR}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        </HTMLTable>
                                    )}
                                </div>
                            </DialogContent>
                        </Dialog>
                    ) : null
                },
            }),
        ],
        []
    )

    const data = useMemo(() => items, [items])

    return (
        <Table
            columns={columns}
            data={data}
            meta={{
                layout: 'stretch',
            }}
            defaultColumn={{
                cell: ({ getValue }) => (
                    <DefaultColumn>{getValue() as string}</DefaultColumn>
                ),
            }}
        />
    )
}

export default ValidateTable
