import instance from '@/api/business'
import {
    CreateProductProps,
    DeleteProductProps,
    FetchPaginatedProductsAPIResponse,
    FetchPaginatedProductsProps,
    FetchProductsAPIResponse,
    UpdateProductProps,
} from '@/api/business/produtos/type'

const BASE_ROUTE = '/produtos'

export const fetchProducts = async () => {
    const response = await instance.post<FetchProductsAPIResponse[]>(BASE_ROUTE)
    return response.data
}

export const fetchPaginatedProducts = async ({
    currentPage,
    perPage,
    filters,
}: FetchPaginatedProductsProps) => {
    const response = await instance.post<FetchPaginatedProductsAPIResponse>(
        `${BASE_ROUTE}/paginacao`,
        {
            POR_PAGINA: perPage,
            PAGINA_ATUAL: currentPage,
            FILTROS: filters || [],
        }
    )
    return response.data
}

export const createProduct = async (props: CreateProductProps) => {
    const response = await instance.put(BASE_ROUTE, props)

    return response.data
}

export const updateProduct = async (data: UpdateProductProps) => {
    const response = await instance.patch(BASE_ROUTE, {
        ...data,
        SK_PRODUTO: data.SK_PRODUTO,
    })

    return response.data
}

export const deleteProduct = async (props: DeleteProductProps) => {
    const response = await instance.delete(BASE_ROUTE, {
        data: props,
    })

    return response.data
}
