import Router from './Router'
import { ThemeProvider } from './context/ThemeContextProvider'

function App() {
    return (
        <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
            <Router />
        </ThemeProvider>
    )
}

export default App
