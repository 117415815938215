import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'

import { Row } from '@tanstack/react-table'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/components/ui/use-toast'
import { isAxiosError } from '@/api/business'
import { Button } from '@/components/ui/button'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { useEffect, useState } from 'react'
import { CompanyTable } from '../..'
import { updateCompanyConfig } from '@/api/business/empresas'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { companiesKeys } from '@/queries/useCompaniesQuery'

interface EditDialogProps {
    row: Row<CompanyTable>
    isOpen: boolean
    onClose: () => void
}

const schema = z.object({
    color: z.string(),
})

const EditDialog = ({ row, isOpen, onClose }: EditDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const [color, setColor] = useState('')

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            color: row.original.COR_EMPRESA,
        },
    })

    useEffect(() => {
        form.reset({
            color: row.original.COR_EMPRESA,
        })
        setColor(row.original.COR_EMPRESA)
    }, [row.original])

    const { mutate, isPending: mutateLoading } = useMutation({
        mutationFn: updateCompanyConfig,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: companiesKeys.lists() })
            toast({
                title: 'Configuração atualizada com sucesso',
            })
            onClose()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível editar a empresa. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao editar empresa',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const onSubmit = (data: z.infer<typeof schema>) => {
        mutate({
            SK_EMPRESA: row.original.SK_EMPRESA,
            COR: data.color,
        })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-[400px]">
                <DialogHeader>
                    <DialogTitle>Editar empresa</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-2"
                    >
                        <div className="flex items-center justify-between mb-4 ">
                            <div className="text-sm">
                                <Label>Empresa</Label>
                                <p
                                    style={{
                                        color,
                                    }}
                                >
                                    {row.original.ABREVIATURA_EMPRESA}
                                </p>
                            </div>
                            <FormField
                                control={form.control}
                                name="color"
                                render={({ field }) => {
                                    return (
                                        <FormItem>
                                            <FormLabel htmlFor="color">
                                                Cor
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    className="w-32"
                                                    id="color"
                                                    type="color"
                                                    {...field}
                                                    onChange={(e) => {
                                                        setColor(e.target.value)
                                                        field.onChange(
                                                            e.target.value
                                                        )
                                                    }}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )
                                }}
                            />
                        </div>
                        <DialogFooter>
                            <Button
                                type="button"
                                onClick={onClose}
                                variant="ghost"
                            >
                                Cancelar
                            </Button>
                            <Button type="submit" disabled={mutateLoading}>
                                Confirmar
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default EditDialog
