import { useQuotationMapQuery } from '@/queries/useQuotatioMapQuery'
import { useParams } from 'react-router-dom'
import QuotationTable from './components/QuotationMapTable'
import TopMessage from '@/components/TopMessage'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'

const QuotationMapSection = () => {
    const { id } = useParams()

    const { data: quotationDetail, isError: isQuotationError } =
        useQuotationByIdQuery({ id: Number(id) })

    const {
        data: quotationMap,
        isFetching: isQuotationMapFetching,
        isError: isQuotationMapError,
    } = useQuotationMapQuery({ id: Number(id) })

    if (isQuotationError || isQuotationMapError)
        return 'Erro ao renderizar o mapa de cotações'

    if (!quotationDetail || !quotationMap) return 'Carregando...'

    return (
        <>
            <div>
                {isQuotationMapFetching && (
                    <TopMessage variant="loading" text="Atualizando mapa..." />
                )}
                <section className="flex flex-col h-screen py-2 space-y-4 overflow-hidden">
                    <QuotationTable />
                </section>
            </div>
        </>
    )
}

export default QuotationMapSection
