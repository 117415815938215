import SupplierCard from '@/pages/QuotationMap/components/SupplierCard'
import { suppliersByRoundSelector } from '@/store/quotationMapSlice'
import { useBaseStore } from '@/store'

import { AnimatePresence } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { TypographyH2 } from '@/components/ui/typography'

const SuppliersSection = () => {
    const FORNECEDORES = useBaseStore(suppliersByRoundSelector)

    const navigate = useNavigate()

    return (
        <>
            <section className="max-h-full p-4 space-y-4">
                <div>
                    <TypographyH2>Fornecedores</TypographyH2>
                </div>
                <div className="gap-4 grid grid-cols-[repeat(auto-fill,minmax(400px,1fr))]">
                    <AnimatePresence>
                        {FORNECEDORES.map((supplier) => {
                            return (
                                <SupplierCard
                                    key={supplier.SK_FORNECEDOR}
                                    {...supplier}
                                    onSelect={(id) => {
                                        // setSelectedSupplier(id)
                                        // onOpen()
                                        navigate(`fornecedor/${id}`)
                                    }}
                                />
                            )
                        })}
                    </AnimatePresence>
                </div>
            </section>
        </>
    )
}

export default SuppliersSection
