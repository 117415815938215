import { cn } from '@/lib/utils'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { BookOpenCheck } from 'lucide-react'
import { useParams } from 'react-router-dom'
import { MenubarItem } from '@/components/ui/menubar'

type FinishRoundItemProps = {
    onClick: () => void
}

const FinishRoundItem = ({ onClick }: FinishRoundItemProps) => {
    const { id } = useParams()

    const {
        data: quotation,
        isPending: isQuotationPending,
        isError: isQuotationError,
    } = useQuotationByIdQuery({
        id: Number(id),
    })

    const disabled =
        isQuotationPending ||
        isQuotationError ||
        quotation?.DD_CANCELADA === 1 ||
        quotation.DD_PUBLICADO !== 1 ||
        quotation.DD_MAPA_APROVADO === 1

    return (
        <MenubarItem className="gap-2" disabled={disabled} onClick={onClick}>
            <BookOpenCheck
                size={14}
                className={cn(
                    disabled ? 'text-muted-foreground' : 'text-emerald-500'
                )}
            />
            Finalizar rodada
        </MenubarItem>
    )
}

export default FinishRoundItem
