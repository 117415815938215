import { isAxiosError } from '@/api/business'
import {
    removePaymentCondition,
    updatePaymentConditionPreference,
} from '@/api/business/cotacoes'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useToast } from '@/components/ui/use-toast'
import { quotationKeys } from '@/queries/useQuotationQuery'
import { PaymentConditions } from '@/types/PaymentCondition'
import { QuotationDetails } from '@/types/Quotation'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { MoreHorizontal, Star, StarOff, Trash } from 'lucide-react'
import { useParams } from 'react-router-dom'

type PaymentConditionsDropdownProps = PaymentConditions

const PaymentConditionsDropdown = ({
    SK_CONDICAO_DE_PAGAMENTO,
    DD_PREFERIDA,
}: PaymentConditionsDropdownProps) => {
    const { id } = useParams()
    const queryClient = useQueryClient()

    const { toast } = useToast()
    const { mutate: mutateRemove } = useMutation({
        mutationFn: removePaymentCondition,
        onMutate: ({ paymentConditions, quotationId }) => {
            const queryKey = quotationKeys.detail(quotationId.toString())

            const ids = paymentConditions.map(
                (pc) => pc.SK_CONDICAO_DE_PAGAMENTO
            )

            queryClient.cancelQueries({
                queryKey,
            })

            const previousPaymentConditions =
                queryClient.getQueryData<QuotationDetails>(queryKey)

            queryClient.setQueryData<QuotationDetails>(queryKey, (prev) =>
                prev
                    ? {
                          ...prev,
                          CONDICOES_DE_PAGAMENTO:
                              prev.CONDICOES_DE_PAGAMENTO.filter(
                                  (pc) =>
                                      !ids.includes(pc.SK_CONDICAO_DE_PAGAMENTO)
                              ),
                      }
                    : undefined
            )

            return { previousPaymentConditions, queryKey }
        },
        onError: (err, _, context) => {
            let errorMessage =
                'Não foi possível remover a condição de pagamento. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao remover condição de pagamento',
                description: errorMessage,
                variant: 'destructive',
            })

            if (context) {
                queryClient.setQueryData(
                    context.queryKey,
                    context.previousPaymentConditions
                )
            }
        },
        onSettled: (...[, , , context]) => {
            if (context) {
                queryClient.invalidateQueries({
                    queryKey: context.queryKey,
                })
            }
        },
    })

    const { mutate: mutateUpdate } = useMutation({
        mutationFn: updatePaymentConditionPreference,
        onMutate: ({ paymentConditionId, preference, quotationId }) => {
            const queryKey = quotationKeys.detail(quotationId.toString())

            queryClient.cancelQueries({
                queryKey,
            })

            const previousPaymentConditions =
                queryClient.getQueryData<QuotationDetails>(queryKey)

            queryClient.setQueryData<QuotationDetails>(queryKey, (prev) =>
                prev
                    ? {
                          ...prev,
                          CONDICOES_DE_PAGAMENTO:
                              prev.CONDICOES_DE_PAGAMENTO.map((pc) =>
                                  pc.SK_CONDICAO_DE_PAGAMENTO ===
                                  paymentConditionId
                                      ? {
                                            ...pc,
                                            DD_PREFERIDA: preference,
                                        }
                                      : pc
                              ),
                      }
                    : undefined
            )

            return { previousPaymentConditions, queryKey }
        },
        onError: (err, _, context) => {
            let errorMessage =
                'Não foi possível alterar a condição de pagamento. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao alterar condição de pagamento',
                description: errorMessage,
                variant: 'destructive',
            })

            if (context) {
                queryClient.setQueryData(
                    context.queryKey,
                    context.previousPaymentConditions
                )
            }
        },
        onSettled: (...[, , , context]) => {
            if (context) {
                queryClient.invalidateQueries({
                    queryKey: context.queryKey,
                })
            }
        },
    })

    const onUpdate = () => {
        mutateUpdate({
            preference: DD_PREFERIDA === 1 ? 0 : 1,
            paymentConditionId: SK_CONDICAO_DE_PAGAMENTO,
            quotationId: Number(id),
        })
    }

    const onDelete = () => {
        mutateRemove({
            paymentConditions: [
                {
                    SK_CONDICAO_DE_PAGAMENTO: SK_CONDICAO_DE_PAGAMENTO,
                },
            ],
            quotationId: Number(id),
        })
    }
    return (
        <DropdownMenu>
            <DropdownMenuTrigger>
                <MoreHorizontal size={14} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
                <DropdownMenuItem className="gap-2" onClick={onUpdate}>
                    {DD_PREFERIDA === 1 ? (
                        <>
                            <StarOff size={14} className="text-neutral-500" />
                            Remover preferência
                        </>
                    ) : (
                        <>
                            <Star size={14} className="text-yellow-600" />
                            Marcar como preferência
                        </>
                    )}
                </DropdownMenuItem>
                <DropdownMenuItem className="gap-2" onClick={onDelete}>
                    <Trash size={14} className="text-destructive" />
                    Remover
                </DropdownMenuItem>
            </DropdownMenuContent>{' '}
        </DropdownMenu>
    )
}

export default PaymentConditionsDropdown
