import instance from '@/api/business'
import {
    Avaliacoes,
    AvaliacoesPaginacaoResponse,
    AvaliacoesWithParams,
} from '@/types/Avaliacoes'
import { Filter } from '@/types/Filters'

const BASE_ROUTE = '/avaliacoes'

export const fetchAvaliacoes = async () => {
    const response = await instance.post<AvaliacoesWithParams[]>(BASE_ROUTE)
    return response.data
}

export const fetchAvaliacoesPaginados = async (
    page = 1,
    perPage = 10,
    filters: Filter<Avaliacoes>[]
) => {
    const response = await instance.post<AvaliacoesPaginacaoResponse[]>(
        `${BASE_ROUTE}/paginacao`,
        {
            POR_PAGINA: perPage,
            PAGINA_ATUAL: page,
            FILTROS: filters || [],
        }
    )
    return response.data
}

export const addAvaliacao = async (data: Partial<Avaliacoes>) => {
    const response = await instance.put(BASE_ROUTE, data)

    return response.data
}

export const updateAvaliacao = async (data: Partial<Avaliacoes>) => {
    const response = await instance.patch(BASE_ROUTE, {
        ...data,
        SK_AVALIACAO: data.SK_AVALIACAO,
    })

    return response.data
}

export const deleteAvaliacao = async (ids: number[]) => {
    const response = await instance.delete(BASE_ROUTE, {
        data: { SK_AVALIACAO: ids },
    })

    return response.data
}
