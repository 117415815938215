import {
    SidebarMenuSubButton,
    SidebarMenuSubItem,
} from '@/components/ui/sidebar'
import { NavLink, useLocation } from 'react-router-dom'

type SidebarSubItemProps = {
    to?: string
    label: string
}

const SidebarSubItem = ({ label, to }: SidebarSubItemProps) => {
    const { pathname } = useLocation()
    const isActive = !!to && pathname.includes(to)

    return (
        <SidebarMenuSubItem key={label}>
            <SidebarMenuSubButton asChild isActive={isActive}>
                <NavLink to={to || '#'}>{label}</NavLink>
            </SidebarMenuSubButton>
        </SidebarMenuSubItem>
    )
}

export default SidebarSubItem
