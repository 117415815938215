import {
    GeneratePurchaseOrderError,
    GeneratePurchaseOrderSucess,
} from '@/api/business/cotacoes/type'
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { DisclosureCommonProps } from '@/types/Disclosure'
import ErrorTable from './components/ErrorTable'
import SuccessTable from './components/SucessTable'
import { useMemo } from 'react'

type EndQuotationResponseDialogProps = DisclosureCommonProps

const stateSelector = (state: Store) => ({
    purchaseOrderResponse: state.quotationMapSlice.state.purchaseOrderResponse,
})

const EndQuotationResponseDialog = ({
    isOpen,
    onOpenChange,
}: EndQuotationResponseDialogProps) => {
    const { purchaseOrderResponse } = useBaseStore(stateSelector)

    const success = useMemo(
        () =>
            purchaseOrderResponse.filter(
                (response) => response.created
            ) as GeneratePurchaseOrderSucess[],
        [purchaseOrderResponse]
    )

    const error = useMemo(
        () =>
            purchaseOrderResponse.filter(
                (response) => !response.created
            ) as GeneratePurchaseOrderError[],
        [purchaseOrderResponse]
    )

    if (purchaseOrderResponse.length === 0) return null

    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            <DialogContent className="max-w-[1600px]">
                <DialogHeader>
                    <DialogTitle>Resposta</DialogTitle>
                </DialogHeader>
                <div className="space-y-4 overflow-hidden">
                    {success.length > 0 && <SuccessTable data={success} />}
                    {error.length > 0 && <ErrorTable data={error} />}
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default EndQuotationResponseDialog
