import { MultiSelect } from '@/components/MultiSelect'
import { Label } from '@/components/ui/label'
import { useQuotationFiltersQuery } from '@/queries/useQuotationQuery'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'

const stateSelector = (state: Store) => ({
    purchaseRequestsSelected:
        state.quotationListSlice.state.purchaseRequestsSelected,
    onSelectPurchaseRequest:
        state.quotationListSlice.actions.onSelectPurchaseRequest,
})

const PurchaseRequestSelect = () => {
    const { data: filters, isPending, isError } = useQuotationFiltersQuery()
    const { purchaseRequestsSelected, onSelectPurchaseRequest } =
        useBaseStore(stateSelector)

    return (
        <div className="w-[180px]">
            <Label>SCs</Label>
            <MultiSelect
                options={
                    filters?.PRODUTOS.map((product) => ({
                        label: `${product.NK_SOLICITACAO_DE_COMPRAS} - ${product.DS_PRODUTO}`,
                        value: product.SK_SOLICITACAO_DE_COMPRAS,
                    })) || []
                }
                disabled={isPending || isError}
                label={
                    isPending ? 'Carregando...' : isError ? 'Erro' : undefined
                }
                onChange={onSelectPurchaseRequest}
                selectedValues={purchaseRequestsSelected}
            />
        </div>
    )
}

export default PurchaseRequestSelect
