import instance from '@/api/business'
import {
    CreateSupplierProps,
    DeleteSupplierProps,
    FetchPaginatedSuppliersAPIResponse,
    FetchPaginatedSuppliersProps,
    FetchSuppliersAPIResponse,
    UpdateSupplierEmailProps,
    UpdateSupplierProps,
} from '@/api/business/fornecedores/type'

const BASE_ROUTE = '/fornecedores'

export const fetchSuppliers = async () => {
    const response = await instance.post<FetchSuppliersAPIResponse[]>(
        BASE_ROUTE
    )
    return response.data
}

export const fetchPaginatedSuppliers = async ({
    currentPage,
    perPage,
    filters,
}: FetchPaginatedSuppliersProps) => {
    const response = await instance.post<FetchPaginatedSuppliersAPIResponse>(
        `${BASE_ROUTE}/paginacao`,
        {
            PAGINA_ATUAL: currentPage,
            POR_PAGINA: perPage,
            FILTROS: filters || [],
        }
    )
    return response.data
}

export const createSupplier = async (data: CreateSupplierProps) => {
    const response = await instance.put(BASE_ROUTE, data)

    return response.data
}

export const updateSupplier = async (data: UpdateSupplierProps) => {
    const response = await instance.patch(BASE_ROUTE, {
        ...data,
    })

    return response.data
}

export const updateSupplierEmail = async (data: UpdateSupplierEmailProps) => {
    const response = await instance.patch(`${BASE_ROUTE}/email`, {
        ...data,
    })

    return response.data
}

export const deleteSupplier = async (props: DeleteSupplierProps) => {
    const response = await instance.delete(BASE_ROUTE, {
        data: props,
    })

    return response.data
}
