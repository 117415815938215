import Table from '@/components/Table'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { TableData } from '@/components/Table/type'
import { useCurrenciesQuery } from '@/queries/useCurrencyQuery'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { useBaseStore } from '@/store'
import { getLastRoundSelector } from '@/store/quotationDetailSlice'
import { getUpdatedSupplierValuesSelector } from '@/store/quotationMapSlice'
import { QuotationMapFlatState } from '@/store/quotationMapSlice/type'
import { Store } from '@/store/type'
import { currencyFormat, percentageFormat } from '@/utils/stringFormatter'
import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

type ProductsTable = TableData<QuotationMapFlatState>
const columnHelper = createColumnHelper<QuotationMapFlatState>()

const stateSelector = (state: Store) => ({
    supplierId: state.quotationMapSlice.state.supplierDialog.supplierId,
    updatedValues: state.quotationMapSlice.state.supplierDialog.updatedValues,
    selectedCompanyId:
        state.quotationMapSlice.state.supplierDialog.selectedCompanyId,
    companies: state.quotationMapSlice.state.quotationMap.EMPRESAS,
})

const PublishTable = () => {
    const { id } = useParams()
    const { data: currencies } = useCurrenciesQuery()
    const { data: quotation } = useQuotationByIdQuery({ id: Number(id) })

    const { selectedCompanyId, updatedValues, supplierId } =
        useBaseStore(stateSelector)

    const values = useBaseStore(getUpdatedSupplierValuesSelector(supplierId))

    const lastRound = useBaseStore(getLastRoundSelector)

    const data = useMemo(() => {
        return lastRound
            ? values.filter(
                  (value) =>
                      value.SK_COTACAO_X_RODADA === lastRound.roundId &&
                      value.SK_EMPRESA === selectedCompanyId
              )
            : []
    }, [values, selectedCompanyId, updatedValues])

    const columns = useMemo(
        () => [
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                header: 'Empresa',
                enableColumnFilter: false,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                    header: {
                        className: 'p-4',
                    },
                    cell: {
                        className: 'p-3',
                    },
                },
            }),
            columnHelper.accessor('DD_NCM', {
                header: 'NCM',
                enableColumnFilter: false,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
            }),
            columnHelper.accessor('DS_PRODUTO', {
                header: 'Produto',
                enableColumnFilter: false,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
            }),
            columnHelper.accessor('DD_UM', {
                header: 'UM',
                enableColumnFilter: false,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
            }),
            columnHelper.accessor('QTD_DISPONIVEL', {
                header: 'Qtde disponível',
                enableColumnFilter: false,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
            }),
            columnHelper.accessor('QTD_SOLICITADA', {
                header: 'Qtde solicitada',
                enableColumnFilter: false,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
            }),
            columnHelper.accessor('PRECO', {
                header: 'Preço',
                enableColumnFilter: false,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                    cell: {
                        className: 'text-end',
                        formatterFn: ({ row, value }) =>
                            value
                                ? currencyFormat(
                                      value,
                                      4,
                                      currencies?.find(
                                          (currency) =>
                                              currency.SK_MOEDA ===
                                              row.original.SK_MOEDA
                                      )?.DS_MOEDA
                                  )
                                : '-',
                    },
                },
            }),
            columnHelper.accessor('FCP', {
                header: 'FCP',
                enableColumnFilter: false,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                    cell: {
                        formatterFn: ({ value }) =>
                            value ? percentageFormat(value) : '-',
                    },
                },
            }),
            columnHelper.accessor('ICMS', {
                header: 'ICMS',
                enableColumnFilter: false,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                    cell: {
                        formatterFn: ({ value }) =>
                            value ? percentageFormat(value) : '-',
                    },
                },
            }),
            columnHelper.accessor('IPI', {
                header: 'IPI',
                enableColumnFilter: false,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                    cell: {
                        formatterFn: ({ value }) =>
                            value ? percentageFormat(value) : '-',
                    },
                },
            }),
            columnHelper.accessor('PIS', {
                header: 'PIS',
                enableColumnFilter: false,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                    cell: {
                        formatterFn: ({ value }) =>
                            value ? percentageFormat(value) : '-',
                    },
                },
            }),
            columnHelper.accessor('COFINS', {
                header: 'COFINS',
                enableColumnFilter: false,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                    cell: {
                        formatterFn: ({ value }) =>
                            value ? percentageFormat(value) : '-',
                    },
                },
            }),
            columnHelper.accessor('ST', {
                header: 'ST',
                enableColumnFilter: false,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                    cell: {
                        formatterFn: ({ value }) =>
                            value ? percentageFormat(value) : '-',
                    },
                },
            }),
            columnHelper.accessor('DS_CONDICAO_DE_PAGAMENTO_MANUAL', {
                header: 'Condição de pagamento',
                cell: ({ row, getValue }) => {
                    const { SK_CONDICAO_DE_PAGAMENTO } = row.original

                    const paymentCondition =
                        quotation?.CONDICOES_DE_PAGAMENTO.find(
                            (pc) =>
                                pc.SK_CONDICAO_DE_PAGAMENTO ===
                                SK_CONDICAO_DE_PAGAMENTO
                        )?.DS_CONDICAO_DE_PAGAMENTO

                    return SK_CONDICAO_DE_PAGAMENTO
                        ? paymentCondition
                        : getValue()
                },
                enableColumnFilter: false,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                    cell: {
                        className:
                            'whitespace-nowrap overflow-hidden text-ellipsis',
                    },
                },
            }),
            columnHelper.accessor('FRETE', {
                header: 'Frete',
                enableColumnFilter: false,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
            }),
            columnHelper.accessor('SK_MOEDA', {
                header: 'Moeda',
                cell: ({ row }) => {
                    const { SK_MOEDA } = row.original

                    return currencies?.find(
                        (currency) => currency.SK_MOEDA === SK_MOEDA
                    )?.DS_MOEDA
                },
                enableColumnFilter: false,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
            }),
            columnHelper.accessor('PREVISAO_DE_ENTREGA', {
                header: 'Previsão de entrega',
                enableColumnFilter: false,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                    cell: {
                        formatterFn: ({ value }) =>
                            value ? format(new Date(value), 'dd/MM/yyyy') : '-',
                    },
                },
            }),
            columnHelper.accessor('OBSERVACAO', {
                header: 'Observação',
                enableColumnFilter: false,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                },
            }),
        ],
        [quotation]
    )

    return (
        <Table<ProductsTable>
            data={data}
            columns={columns}
            defaultColumn={{
                cell: ({ getValue }) => (
                    <DefaultColumn>{getValue() as string}</DefaultColumn>
                ),
            }}
        />
    )
}

export default PublishTable
