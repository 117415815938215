import { isAxiosError } from '@/api/business'
import { addFile } from '@/api/business/arquivos'
import File from '@/components/File'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { TypographyMuted } from '@/components/ui/typography'
import { useToast } from '@/components/ui/use-toast'
import { attachmentsKeys } from '@/queries/useAttachmentsQuery'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ChangeEvent, useState } from 'react'
import { useParams } from 'react-router-dom'

type AddFileDialogProps = {
    isOpen: boolean
    onOpenChange: (isOpen: boolean) => void
}

const AddFileDialog = ({ isOpen, onOpenChange }: AddFileDialogProps) => {
    const { id } = useParams()
    const [files, setFiles] = useState<{ file: File; id: string }[]>([])
    const { toast } = useToast()
    const queryClient = useQueryClient()

    const { mutate, isPending } = useMutation({
        mutationFn: addFile,
        onSuccess: () => {
            if (id)
                queryClient.invalidateQueries({
                    queryKey: attachmentsKeys.all,
                })

            toast({
                title: 'Anexos adicionados',
                description: 'Os anexos foram adicionados com sucesso',
            })
            setFiles([])
            onOpenChange(false)
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível adicionar os anexos. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao adicionar anexos',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files

        if (files) {
            setFiles((prev) => [
                ...prev,
                ...Object.values(files).map((file) => ({
                    file: file,
                    id: crypto.randomUUID(),
                })),
            ])
        }
    }

    const onRemoveFile = (id: string) => {
        setFiles((prev) => prev.filter((file) => file.id !== id))
    }

    const onUpload = () => {
        if (id)
            mutate({
                files: files.map((file) => file.file),
                quotationId: Number(id),
                isApproavalFile: true,
            })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Adicionar anexos</DialogTitle>
                    <DialogDescription>
                        Adicionar novos anexos na cotação
                    </DialogDescription>
                </DialogHeader>
                <div className="w-full py-4 space-y-4 overflow-hidden">
                    <label
                        htmlFor="file"
                        className="block py-1 text-sm text-center border rounded-md cursor-pointer hover:bg-neutral-50 text-neutral-500 border-neutral-300"
                    >
                        Anexar arquivo
                    </label>
                    <Input
                        type="file"
                        id="file"
                        className="hidden"
                        onChange={onFileChange}
                    />
                    <div>
                        {files.length === 0 ? (
                            <div className="flex justify-center p-4 border item-center ">
                                <TypographyMuted>
                                    Nenhum arquivo selecionado
                                </TypographyMuted>
                            </div>
                        ) : (
                            <div className="space-y-1.5">
                                {files.map((file) => (
                                    <File
                                        key={file.id}
                                        id={file.id}
                                        file={file.file}
                                        type="file"
                                        onRemove={() => onRemoveFile(file.id)}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <DialogFooter>
                    <Button
                        variant="ghost"
                        onClick={() => {
                            setFiles([])
                            onOpenChange(false)
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={onUpload}
                        disabled={files.length === 0 || isPending}
                    >
                        Upload
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default AddFileDialog
