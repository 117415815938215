import { isAxiosError } from '@/api/business'
import { notifySuppliers } from '@/api/business/cotacoes'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { useToast } from '@/components/ui/use-toast'
import { useMutation } from '@tanstack/react-query'
import { Bell } from 'lucide-react'
import { useParams } from 'react-router-dom'

interface NotifySuppliersDialogProps {
    isOpen: boolean
    onClose: () => void
}

const NotifySuppliersDialog = ({
    isOpen,
    onClose,
}: NotifySuppliersDialogProps) => {
    const { id } = useParams()
    const { toast } = useToast()

    const { mutate, isPending } = useMutation({
        mutationFn: notifySuppliers,
        onSuccess: (data) => {
            toast({
                title: 'Notificação enviada',
                description: data.message,
            })
            onClose()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi notificar os fornecedores. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao notificar',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle className="flex items-center gap-2">
                        Notificar fornecedores
                    </DialogTitle>
                </DialogHeader>
                <div>
                    <p>
                        Deseja notificar todos os fornecedores que ainda não
                        responderam a cotação?
                    </p>
                </div>
                <DialogFooter>
                    <Button variant="ghost" onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button
                        disabled={isPending}
                        className="w-auto gap-2"
                        onClick={() => {
                            mutate({
                                quotationId: Number(id),
                            })
                        }}
                    >
                        <Bell size={18} />
                        Notificar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default NotifySuppliersDialog
