import { getItem, setItem } from '@/utils/storage'
import { ImmerStateCreator } from '../type'
import { Layout, QuotationListSlice, QuotationListState } from './type'

const initialState: QuotationListState = {
    layout: (getItem(localStorage, 'COTACAO_LAYOUT') as Layout) || 'list',
    companiesSelected: [],
    purchaseRequestsSelected: [],
    suppliersSelected: [],
    statusSelected: [],
    searchInput: '',
}

export const useQuotationListSlice: ImmerStateCreator<QuotationListSlice> = (
    set
) => ({
    state: initialState,
    actions: {
        onChangeLayout: (layout) => {
            set((state) => {
                state.quotationListSlice.state.layout = layout
                setItem(localStorage, 'COTACAO_LAYOUT', layout)
            })
        },
        onSelectCompany: (companyId) => {
            set((state) => {
                state.quotationListSlice.state.companiesSelected.find(
                    (id) => id === companyId
                )
                    ? state.quotationListSlice.state.companiesSelected.splice(
                          state.quotationListSlice.state.companiesSelected.indexOf(
                              companyId
                          ),
                          1
                      )
                    : state.quotationListSlice.state.companiesSelected.push(
                          companyId
                      )
            })
        },
        onSelectPurchaseRequest: (purchaseRequestId) => {
            set((state) => {
                state.quotationListSlice.state.purchaseRequestsSelected.find(
                    (id) => id === purchaseRequestId
                )
                    ? state.quotationListSlice.state.purchaseRequestsSelected.splice(
                          state.quotationListSlice.state.purchaseRequestsSelected.indexOf(
                              purchaseRequestId
                          ),
                          1
                      )
                    : state.quotationListSlice.state.purchaseRequestsSelected.push(
                          purchaseRequestId
                      )
            })
        },
        onSelectSupplier: (supplierId) => {
            set((state) => {
                state.quotationListSlice.state.suppliersSelected.find(
                    (id) => id === supplierId
                )
                    ? state.quotationListSlice.state.suppliersSelected.splice(
                          state.quotationListSlice.state.suppliersSelected.indexOf(
                              supplierId
                          ),
                          1
                      )
                    : state.quotationListSlice.state.suppliersSelected.push(
                          supplierId
                      )
            })
        },
        onSelectStatus: (status) => {
            set((state) => {
                state.quotationListSlice.state.statusSelected.find(
                    (id) => id === status
                )
                    ? state.quotationListSlice.state.statusSelected.splice(
                          state.quotationListSlice.state.statusSelected.indexOf(
                              status
                          ),
                          1
                      )
                    : state.quotationListSlice.state.statusSelected.push(status)
            })
        },
        onChangeSearchInput: (value) => {
            set((state) => {
                state.quotationListSlice.state.searchInput = value
            })
        },
        onResetFilters: () => {
            set((state) => {
                state.quotationListSlice.state.companiesSelected = []
                state.quotationListSlice.state.purchaseRequestsSelected = []
                state.quotationListSlice.state.suppliersSelected = []
                state.quotationListSlice.state.statusSelected = []
                state.quotationListSlice.state.searchInput = ''
            })
        },
        onReset: () => {
            set((state) => {
                state.quotationListSlice.state = initialState
            })
        },
    },
})
