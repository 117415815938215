import { ImmerStateCreator, Store } from '../type'
import { QuotationDetailSlice, QuotationDetailState } from './type'

const initialState: QuotationDetailState = {
    rounds: [],
    selectedRoundId: null,
    lastRoundId: null,
}

export const useQuotationDetailSlice: ImmerStateCreator<
    QuotationDetailSlice
> = (set) => ({
    state: initialState,
    actions: {
        onSetRounds: (rounds) => {
            set((state) => {
                state.quotationDetailSlice.state.rounds = rounds

                const lastRoundId = rounds[rounds.length - 1].roundId

                state.quotationDetailSlice.state.selectedRoundId = lastRoundId
                state.quotationDetailSlice.state.lastRoundId = lastRoundId
            })
        },
        onSelectRound: (roundId) => {
            set((state) => {
                state.quotationDetailSlice.state.selectedRoundId = roundId
            })
        },
        onReset: () => {
            set((state) => {
                state.quotationDetailSlice.state = initialState
            })
        },
    },
})

export const quotationDetailActionsSelector = (state: Store) =>
    state.quotationDetailSlice.actions

export const quotationDetailStateSelector = (state: Store) =>
    state.quotationDetailSlice.state

export const getSelectedRoundSelector = (state: Store) =>
    state.quotationDetailSlice.state.rounds.find(
        (round) =>
            round.roundId === state.quotationDetailSlice.state.selectedRoundId
    )
export const getLastRoundSelector = (state: Store) =>
    state.quotationDetailSlice.state.rounds.find(
        (round) =>
            round.roundId === state.quotationDetailSlice.state.lastRoundId
    )

export const getIsLastRoundSelector = (state: Store) =>
    state.quotationDetailSlice.state.selectedRoundId ===
    state.quotationDetailSlice.state.lastRoundId
