import { isAxiosError } from '@/api/business'
import { removeShippingCompany } from '@/api/business/cotacoes'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { TypographyP } from '@/components/ui/typography'
import { useToast } from '@/components/ui/use-toast'
import { quotationMapKeys } from '@/queries/useQuotatioMapQuery'
import { quotationKeys } from '@/queries/useQuotationQuery'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { DisclosureCommonProps } from '@/types/Disclosure'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

type RemoveShippingDialogProps = DisclosureCommonProps & {
    companyId: string
    supplierId: string
}

const stateSelector = (state: Store) => ({
    onRemoveSupplierShippingCompany:
        state.quotationMapSlice.actions.onRemoveSupplierShippingCompany,
})

const RemoveShippingDialog = ({
    isOpen,
    companyId,
    supplierId,
    onOpenChange,
}: RemoveShippingDialogProps) => {
    const { id } = useParams()
    const { toast } = useToast()
    const queryClient = useQueryClient()
    const { onRemoveSupplierShippingCompany } = useBaseStore(stateSelector)

    const { mutate, isPending } = useMutation({
        mutationFn: removeShippingCompany,
        onSuccess: () => {
            if (id) {
                queryClient.invalidateQueries({
                    queryKey: quotationMapKeys.detail(id),
                })
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.detail(id),
                })
                onRemoveSupplierShippingCompany()
            }

            onOpenChange(false)
            toast({
                title: 'Transportadora removida',
                description: 'A transportadora foi removida com sucesso.',
            })
        },
        onError: (error) => {
            let message =
                'Houve um erro ao remover transportadora. Tente novamente mais tarde.'

            if (isAxiosError(error)) {
                message = error.response?.data.message || message
            }

            toast({
                title: 'Erro ao remover transportadora',
                description: message,
                variant: 'destructive',
            })
        },
    })

    const onClick = () => {
        mutate({ companyId, quotationId: Number(id), supplierId })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Remover transportadora</DialogTitle>
                </DialogHeader>
                <div>
                    <TypographyP>
                        Realmente deseja remover a transportadora e o frete?
                    </TypographyP>
                </div>
                <DialogFooter>
                    <Button variant="ghost" onClick={() => onOpenChange(false)}>
                        Cancelar
                    </Button>
                    <Button
                        variant="destructive"
                        onClick={onClick}
                        disabled={isPending}
                    >
                        Remover
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default RemoveShippingDialog
