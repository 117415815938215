import { Table } from '@tanstack/react-table'
import { CompanyTable } from '.'
import { utils, writeFile } from 'xlsx'
import { Company } from '@/types/Companies'

const HEADERS: {
    original: keyof Company
    header: string
}[] = [
    {
        header: 'Cod empresa',
        original: 'SK_EMPRESA',
    },
    {
        header: 'Empresa',
        original: 'ABREVIATURA_EMPRESA',
    },
    {
        header: 'Descrição empresa',
        original: 'DESCRICAO_EMPRESA',
    },
    {
        header: 'CNPJ',
        original: 'CNPJ',
    },
]

export const useCompanySpreadsheet = () => {
    const getTableRows = (table: Table<CompanyTable>): Partial<Company>[] =>
        table.getRowModel().rows.map((row) => {
            const obj = {} as Record<string, any>

            for (const [key, value] of Object.entries(row.original)) {
                const header = HEADERS.find((h) => h.original === key)?.header

                if (header && value) {
                    obj[header] = value.toString()
                }
            }

            return obj
        })

    const onDownload = (tableInstance: Table<CompanyTable>) => {
        const rows = getTableRows(tableInstance)
        const worksheet = utils.json_to_sheet(rows)
        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Empresas')

        writeFile(workbook, 'Empresas.xlsx', {
            compression: true,
        })
    }

    return {
        onDownload,
    }
}
