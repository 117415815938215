import Select from '@/components/Select'
import { Label } from '@/components/ui/label'
import { useBaseStore } from '@/store'

import { Store } from '@/store/type'

const stateSelector = (state: Store) => ({
    selectedCompanyId:
        state.quotationMapSlice.state.supplierDialog.selectedCompanyId,
    companies: state.quotationMapSlice.state.quotationMap.EMPRESAS,
    onSelectCompany: state.quotationMapSlice.actions.onSelectCompany,
})

const CompaniesSelect = () => {
    const { companies, selectedCompanyId, onSelectCompany } =
        useBaseStore(stateSelector)

    return (
        <div className="space-y-1.5">
            <Label>Selecionar empresa</Label>
            <Select
                options={
                    companies
                        ? companies.map((company) => ({
                              label: company.ABREVIATURA_EMPRESA,
                              value: company.SK_EMPRESA,
                          }))
                        : []
                }
                placeholder={
                    companies.length === 0
                        ? 'Erro ao carregar empresas'
                        : 'Selecione uma opção'
                }
                onChange={(value) => onSelectCompany(value)}
                value={selectedCompanyId || ''}
                disabled={companies.length === 0}
            />
        </div>
    )
}

export default CompaniesSelect
