import { fetchCompanies } from '@/api/business/empresas'
import { useQuery } from '@tanstack/react-query'

export const companiesKeys = {
    all: ['companies'] as const,
    lists: () => [...companiesKeys.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...companiesKeys.lists(), page, perPage, filters] as const,
    details: () => [...companiesKeys.all, 'detail'] as const,
    detail: (params: string) => [...companiesKeys.details(), params] as const,
}

export const useCompaniesQuery = () => {
    return useQuery({
        queryFn: fetchCompanies,
        queryKey: companiesKeys.lists(),
    })
}
