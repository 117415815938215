import { Locale, format } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-react'
import { DateRange, Matcher } from 'react-day-picker'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover'
import { HTMLAttributes } from 'react'

type DatepickerWithRangeProps = {
    date: DateRange | undefined
    disabled?: Matcher | Matcher[]
    setDate: (date: DateRange | undefined) => void
    locale: Locale
}

export const DatePickerWithRange = ({
    className,
    date,
    disabled,
    locale,
    setDate,
}: HTMLAttributes<HTMLDivElement> & DatepickerWithRangeProps) => {
    return (
        // <div className={cn(className)}>
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    id="date"
                    variant={'outline'}
                    className={cn(
                        className,
                        'justify-start text-left font-normal',
                        !date && 'text-muted-foreground'
                    )}
                >
                    <CalendarIcon className="w-4 h-4 mr-2" />
                    {date?.from ? (
                        date.to ? (
                            <>
                                {format(date.from, 'LLL dd, y', { locale })} até{' '}
                                {format(date.to, 'LLL dd, y', { locale })}
                            </>
                        ) : (
                            format(date.from, 'LLL dd, y')
                        )
                    ) : (
                        <span>Selecione uma data</span>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                    initialFocus
                    mode="range"
                    defaultMonth={date?.from}
                    selected={date}
                    onSelect={setDate}
                    locale={locale}
                    disabled={disabled}
                    numberOfMonths={2}
                />
            </PopoverContent>
        </Popover>
        // </div>
    )
}
