import { MultiSelect } from '@/components/MultiSelect'
import { Label } from '@/components/ui/label'
import { useQuotationFiltersQuery } from '@/queries/useQuotationQuery'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'

const stateSelector = (state: Store) => ({
    companiesSelected: state.quotationListSlice.state.companiesSelected,
    onSelectCompany: state.quotationListSlice.actions.onSelectCompany,
})

const PurchaseRequestSelect = () => {
    const { data: filters, isPending, isError } = useQuotationFiltersQuery()
    const { companiesSelected, onSelectCompany } = useBaseStore(stateSelector)

    return (
        <div className="w-[180px]">
            <Label>Empresa</Label>
            <MultiSelect
                options={
                    filters?.EMPRESAS.map((company) => ({
                        label: company.ABREVIATURA_EMPRESA,
                        value: company.SK_EMPRESA,
                    })) || []
                }
                disabled={isPending || isError}
                label={
                    isPending ? 'Carregando...' : isError ? 'Erro' : undefined
                }
                onChange={onSelectCompany}
                selectedValues={companiesSelected}
            />
        </div>
    )
}

export default PurchaseRequestSelect
