import { isAxiosError } from '@/api/business'
import { downloadFile } from '@/api/business/arquivos'
import File from '@/components/File'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { Skeleton } from '@/components/ui/skeleton'
import { TypographyP } from '@/components/ui/typography'
import { useToast } from '@/components/ui/use-toast'
import useDisclosure from '@/hooks/useDisclosure'
import DeleteAttachmentDialog from '@/pages/QuotationDetail/components/Dialogs/DeleteAttachmentDialog'
import {
    attachmentsKeys,
    useApprovalAttachmentsQuery,
} from '@/queries/useAttachmentsQuery'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

type ViewFileDialogProps = {
    isOpen: boolean
    onOpenChange: (isOpen: boolean) => void
}

const ViewFileDialog = ({ isOpen, onOpenChange }: ViewFileDialogProps) => {
    const { id } = useParams()
    const queryClient = useQueryClient()

    const [fileId, setFileId] = useState<number | null>(null)

    const {
        isOpen: isDeleteAttachmentDialogOpen,
        onOpen: onDeleteAttachmentDialogOpen,
        onClose: onDeleteAttachmentDialogClose,
    } = useDisclosure()

    const {
        data: files = [],
        isSuccess,
        isPending,
        error,
    } = useApprovalAttachmentsQuery({
        quotationId: Number(id),
    })

    let errorMessage = 'Erro ao listar arquivos'

    if (isAxiosError(error)) {
        errorMessage = error.response?.data.message || errorMessage
    }

    const { toast } = useToast()

    const { mutate: downloadFileMutate } = useMutation({
        mutationFn: downloadFile,
        onError: (err) => {
            let errorMessage =
                'Não foi possível fazer download. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            queryClient.invalidateQueries({
                queryKey: attachmentsKeys.all,
            })

            toast({
                title: 'Erro ao fazer download',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    return (
        <>
            <Dialog open={isOpen} onOpenChange={onOpenChange}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Anexos</DialogTitle>
                        <DialogDescription>
                            Anexos enviados para cotação
                        </DialogDescription>
                    </DialogHeader>
                    <div>
                        {isPending && <Skeleton className="w-full h-[32px]" />}
                        {isSuccess &&
                            (files.length > 0 ? (
                                files.map((file) => (
                                    <File
                                        key={file.SK_COTACAO_X_ARQUIVOS}
                                        type="attachment"
                                        file={file}
                                        onDownload={() =>
                                            downloadFileMutate({
                                                fileKey: file.CHAVE_DO_ARQUIVO,
                                                fileName: file.ARQUIVO,
                                                cb: (e) => console.log(e),
                                            })
                                        }
                                        onRemove={() => {
                                            setFileId(
                                                file.SK_COTACAO_X_ARQUIVOS
                                            )
                                            onOpenChange(false)
                                            onDeleteAttachmentDialogOpen()
                                        }}
                                    />
                                ))
                            ) : (
                                <TypographyP className="text-center">
                                    Cotação sem anexo
                                </TypographyP>
                            ))}
                        {error && (
                            <TypographyP className="text-center text-destructive">
                                {errorMessage}
                            </TypographyP>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
            {fileId && (
                <DeleteAttachmentDialog
                    fileId={fileId}
                    isOpen={isDeleteAttachmentDialogOpen}
                    onClose={onDeleteAttachmentDialogClose}
                />
            )}
        </>
    )
}

export default ViewFileDialog
