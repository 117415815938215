import useDisclosure from '@/hooks/useDisclosure'
import { cn } from '@/lib/utils'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { SendHorizontal } from 'lucide-react'
import PublishDialog from '../PublishDialog'
import { Button } from '@/components/ui/button'
import { supplierByIdSelector } from '@/store/quotationMapSlice'

const stateSelector = (state: Store) => ({
    updatedValues: state.quotationMapSlice.state.supplierDialog.updatedValues,
    supplierId: state.quotationMapSlice.state.supplierDialog.supplierId,
})

const PublishButton = () => {
    const { updatedValues, supplierId } = useBaseStore(stateSelector)

    const supplier = useBaseStore(supplierByIdSelector(supplierId!))

    const {
        isOpen: isPublishDialogOpen,
        onOpen: onPublishDialogOpen,
        onOpenChange: onPublishDialogOpenChange,
    } = useDisclosure()

    const disabled =
        updatedValues.length > 0 ||
        supplier?.FORNECEDOR_DECLINADO === 1 ||
        supplier?.FORNECEDOR_PUBLICADO === 1

    return (
        <>
            <Button
                onClick={onPublishDialogOpen}
                disabled={disabled}
                className="space-x-1.5"
                variant="outline"
            >
                <SendHorizontal
                    size={14}
                    className={cn(
                        disabled ? 'text-neutral-500' : 'text-sky-500'
                    )}
                />
                <span>Publicar</span>
            </Button>
            <PublishDialog
                isOpen={isPublishDialogOpen}
                onOpenChange={onPublishDialogOpenChange}
            />
        </>
    )
}

export default PublishButton
