import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { useCompaniesQuery } from '@/queries/useCompaniesQuery'
import { Company } from '@/types/Companies'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'
import DadosMestreHeader from '../components/DadosMestreHeader'
import EditDialog from './components/EditDialog'
import DadosMestreHeaderActions from '../components/DadosMestreHeaderActions'
import TableRowActions from '@/components/Table/components/TableRowActions'
import { useCompanySpreadsheet } from './useCompanySpreadsheet'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { useTable } from '@/hooks/useTable'

export type CompanyTable = TableData<Company>

const columnHelper = createColumnHelper<Company>()

const DadosMestreEmpresas = () => {
    const {
        table,
        selectedRow,
        isEditDialogOpen,
        onClickEdit,
        onEditDialogClose,
        setTable,
    } = useTable<Company>()

    const { data, isFetching, isPending, refetch } = useCompaniesQuery()

    const { onDownload } = useCompanySpreadsheet()

    const memoData = useMemo(() => data || [], [data])
    const memoColumns = useMemo(
        () => [
            columnHelper.accessor('SK_EMPRESA', {
                id: 'SK_EMPRESA',
                header: 'Cod empresa',
                size: 100,
            }),
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                id: 'ABREVIATURA_EMPRESA',
                header: 'Empresa',
                size: 200,
            }),
            columnHelper.accessor('DESCRICAO_EMPRESA', {
                id: 'DESCRICAO_EMPRESA',
                header: 'Desc. Empresa',
                size: 400,
            }),
            columnHelper.accessor('CNPJ', {
                id: 'CNPJ',
                header: 'CNPJ',
                size: 200,
            }),
            columnHelper.display({
                id: 'COR_EMPRESA',
                header: 'Cor',
                cell: ({ row }) => (
                    <div
                        className="w-5 h-5 rounded-full"
                        style={{
                            backgroundColor: row.original.COR_EMPRESA,
                        }}
                    ></div>
                ),
                meta: {
                    cell: {
                        className: 'justify-center',
                    },
                },
                size: 80,
            }),
            columnHelper.display({
                id: 'Ações',
                cell: ({ row }) => {
                    return (
                        <TableRowActions onClickEdit={() => onClickEdit(row)} />
                    )
                },
                size: 200,
            }),
        ],
        []
    )

    return (
        <div className="w-full h-full p-4 overflow-hidden">
            <Table<CompanyTable>
                data={memoData}
                columns={memoColumns}
                getRowId={(row) => row.SK_EMPRESA.toString()}
                isLoading={isPending}
                isFetching={isFetching}
                getTableInstance={(table) => setTable(table)}
                enableRowSelection={() => false}
                tableHeader={<DadosMestreHeader title="Empresas" />}
                tableActions={
                    <DadosMestreHeaderActions
                        onRefech={refetch}
                        onDownload={() => table && onDownload(table)}
                        tableId="DADOS_MESTRE_EMPRESAS"
                        layout
                    />
                }
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultColumn>{getValue() as string}</DefaultColumn>
                    ),
                }}
            />
            {selectedRow && (
                <EditDialog
                    row={selectedRow}
                    isOpen={isEditDialogOpen}
                    onClose={onEditDialogClose}
                />
            )}
        </div>
    )
}

export default DadosMestreEmpresas
