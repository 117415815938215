import { isAxiosError } from '@/api/business'
import { validatePurchaseOrder } from '@/api/business/cotacoes'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { TypographyH2, TypographyP } from '@/components/ui/typography'
import { useToast } from '@/components/ui/use-toast'
import { useBaseStore } from '@/store'
import { quotationMapFlatSelector } from '@/store/quotationMapSlice'
import { Store } from '@/store/type'
import { useMutation } from '@tanstack/react-query'
import PurchaseOrderTable from './components/PurchaseOrderTable'
import { currencyFormat, formatCpfOrCnpj } from '@/utils/stringFormatter'
import { DisclosureCommonProps } from '@/types/Disclosure'
import _ from 'lodash'

const stateSelector = (state: Store) => ({
    quotationMap: state.quotationMapSlice.state.quotationMap,
    selectedWinners: state.quotationMapSlice.state.selectedWinners,
    updatedValues:
        state.quotationMapSlice.state.purchaseOrderDialog.updatedValues,
    setPurchaseOrderValidateResponse:
        state.quotationMapSlice.actions.setPurchaseOrderValidateResponse,
})

type EndQuotationDialogProps = DisclosureCommonProps & {
    onValidateResponseDialogOpen: () => void
}

const EndQuotationDialog = ({
    isOpen,
    onOpenChange,
    onValidateResponseDialogOpen,
}: EndQuotationDialogProps) => {
    const { toast } = useToast()
    let increment = 0

    const {
        quotationMap: { PRODUTOS, EMPRESAS },
        selectedWinners,
        updatedValues,
        setPurchaseOrderValidateResponse,
    } = useBaseStore(stateSelector)

    const flat = useBaseStore(quotationMapFlatSelector)

    const filt = flat.filter(
        (data) =>
            selectedWinners[data.SK_COTACAO_X_PRODUTO] &&
            selectedWinners[data.SK_COTACAO_X_PRODUTO][
                data.SK_FORNECEDOR_X_RODADA
            ]
    )

    const { mutate, isPending } = useMutation({
        mutationFn: validatePurchaseOrder,
        onSuccess: (data) => {
            onValidateResponseDialogOpen()
            setPurchaseOrderValidateResponse(data)
            // onClose()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível gerar o pedido de compras. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao gerar pedido de compras',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const onSubmit = () => {
        const selectedValues = _.unionBy(
            updatedValues,
            filt,
            'SK_RESPOSTA_FORNECEDOR'
        ).filter(
            (value) =>
                selectedWinners[value.SK_COTACAO_X_PRODUTO] &&
                selectedWinners[value.SK_COTACAO_X_PRODUTO][
                    value.SK_FORNECEDOR_X_RODADA
                ]
        )

        if (selectedValues.length !== Object.values(PRODUTOS.entities).length) {
            toast({ title: 'Selecione todos os valores' })

            return
        }

        mutate({
            quotations: selectedValues.map((value) => ({
                supplierResponseId: value.SK_RESPOSTA_FORNECEDOR,
                quotationProductId: value.SK_COTACAO_X_PRODUTO,
                roundSupplierId: value.SK_FORNECEDOR_X_RODADA,
                savingType: value.DD_SAVING,
                spending: value.VL_SPENDING,
                saving: value.VL_SAVING,
                savingPercentage: value.SAVING_PERCENTUAL,
            })),
        })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            <DialogContent className="flex flex-col h-full max-w-full gap-6">
                <DialogHeader>
                    <DialogTitle>Gerar pedido</DialogTitle>
                    <DialogDescription>
                        A cotação irá gerar os seguintes pedidos de compra:
                    </DialogDescription>
                </DialogHeader>
                <div className="space-y-6 overflow-auto flex-y">
                    {EMPRESAS.map((company) => {
                        const companyPurchaseRequests = filt.filter(
                            (value) => value.SK_EMPRESA === company.SK_EMPRESA
                        )

                        const uniqueSuppliers = [
                            ...new Set(
                                companyPurchaseRequests.map(
                                    (value) => value.SK_FORNECEDOR
                                )
                            ),
                        ].map(
                            (supplierId) =>
                                companyPurchaseRequests.find(
                                    (val) => val.SK_FORNECEDOR === supplierId
                                )!
                        )

                        return (
                            <div key={company.SK_EMPRESA} className="space-y-4">
                                <TypographyH2
                                    className="text-md"
                                    style={{
                                        color: company.COR_EMPRESA,
                                    }}
                                >
                                    {company.ABREVIATURA_EMPRESA} -{' '}
                                    {formatCpfOrCnpj(company.CNPJ)}
                                </TypographyH2>
                                <div className="space-y-4">
                                    {uniqueSuppliers.map((supplier) => {
                                        const values =
                                            companyPurchaseRequests.filter(
                                                (value) =>
                                                    value.SK_FORNECEDOR ===
                                                    supplier.SK_FORNECEDOR
                                            )

                                        const total = values.reduce(
                                            (acc, curr) => {
                                                return (acc +=
                                                    curr.PRECO_TOTAL_IMPOSTO)
                                            },
                                            0
                                        )

                                        increment++
                                        return (
                                            <div
                                                key={supplier.SK_FORNECEDOR}
                                                className="space-y-2"
                                            >
                                                <div>
                                                    <TypographyP>
                                                        {
                                                            supplier.DD_RAZAO_SOCIAL
                                                        }
                                                    </TypographyP>
                                                    <TypographyP className="font-semibold">
                                                        Valor total do pedido:{' '}
                                                        {currencyFormat(
                                                            total,
                                                            4
                                                        )}
                                                    </TypographyP>
                                                </div>
                                                <PurchaseOrderTable
                                                    companyId={
                                                        company.SK_EMPRESA
                                                    }
                                                    supplierId={
                                                        supplier.SK_FORNECEDOR
                                                    }
                                                    values={values}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <DialogFooter>
                    <Button onClick={onSubmit} disabled={isPending}>
                        Confirmar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default EndQuotationDialog
