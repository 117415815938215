import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { z } from 'zod'
import { publishSchema } from '@/schemas/quotationSchema'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useEffect, useMemo } from 'react'
import { format, isValid } from 'date-fns'
import { Button } from '@/components/ui/button'
import { useParams } from 'react-router-dom'
import {
    quotationKeys,
    useQuotationByIdQuery,
    useQuotationProductsQuery,
    useQuotationSuppliersQuery,
} from '@/queries/useQuotationQuery'
import { useBaseStore } from '@/store'
import { getSelectedRoundSelector } from '@/store/quotationDetailSlice'
import PublishDialogTable from './PublishDialogTable'
import { Label } from '@/components/ui/label'
import { validationEmailSchema } from '@/schemas/emailSchema'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/components/ui/use-toast'
import useDisclosure from '@/hooks/useDisclosure'
import { publishQuotation } from '@/api/business/cotacoes'
import { quotationMapKeys } from '@/queries/useQuotatioMapQuery'
import { isAxiosError } from '@/api/business'
import SupplierWarningDialog from '@/components/SupplierWarningDialog'
import { AllExtensions } from '@/types/Attachments'

interface PublishDialogProps {
    isOpen: boolean
    onClose: () => void
}

const PublishDialog = ({ isOpen, onClose }: PublishDialogProps) => {
    const { id } = useParams()
    const { toast } = useToast()
    const queryClient = useQueryClient()
    const {
        isOpen: isSupplierWarningDialogDialogOpen,
        onClose: onSupplierWarningDialogDialogClose,
        onOpen: onSupplierWarningDialogDialogOpen,
    } = useDisclosure()

    const { data: quotation } = useQuotationByIdQuery({
        id: Number(id),
    })

    const { data: products } = useQuotationProductsQuery({
        quotationId: Number(id),
    })

    const { data: suppliers } = useQuotationSuppliersQuery({
        quotationId: Number(id),
    })

    const selectedRound = useBaseStore(getSelectedRoundSelector)

    const form = useForm<z.infer<typeof publishSchema>>({
        resolver: zodResolver(publishSchema),
        defaultValues:
            quotation && suppliers && products
                ? {
                      description: quotation.DS_COTACAO || '',
                      expirationDate: selectedRound?.expirationDate,
                      quotation: quotation.NM_COTACAO,
                      attachments:
                          quotation?.ANEXOS.map((attachment) => ({
                              ...attachment,
                              EXTENSAO_DO_ARQUIVO:
                                  attachment.EXTENSAO_DO_ARQUIVO.toLowerCase() as AllExtensions,
                          })) || [],
                      products: products.filter(
                          (product) => product.D_E_L_E_T_ !== '*'
                      ),
                      suppliers: suppliers.filter(
                          (supplier) => supplier.D_E_L_E_T_ !== '*'
                      ),
                  }
                : {},
    })

    const invalidEmails = useMemo(
        () =>
            suppliers
                ?.map((supplier) => ({
                    ...supplier,
                    success: supplier?.DS_EMAIL.map(
                        (email) =>
                            validationEmailSchema.safeParse(email?.trim())
                                .success
                    ),
                }))
                .filter(
                    (supplier) =>
                        !supplier.success.every((s) => s) &&
                        supplier.D_E_L_E_T_ !== '*'
                ) || [],
        [suppliers]
    )

    const { mutate: mutatePublish, isPending } = useMutation({
        mutationFn: publishQuotation,
        onSuccess: () => {
            if (id) {
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.detail(id),
                })
                queryClient.invalidateQueries({
                    queryKey: quotationMapKeys.detail(id),
                })
            }

            toast({
                title: 'Cotação publicada com sucesso',
            })
            onClose()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível publicar a cotação. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao publicar cotação',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const onSubmit = () => {
        if (invalidEmails?.length > 0) {
            onSupplierWarningDialogDialogOpen()
        } else {
            mutatePublish(Number(id))
        }
    }

    useEffect(() => {
        if (quotation && products && suppliers) {
            form.reset({
                description: quotation.DS_COTACAO || '',
                expirationDate: selectedRound?.expirationDate,
                quotation: quotation.NM_COTACAO,
                attachments:
                    quotation?.ANEXOS.map((attachment) => ({
                        ...attachment,
                        EXTENSAO_DO_ARQUIVO:
                            attachment.EXTENSAO_DO_ARQUIVO.toLowerCase() as AllExtensions,
                    })) || [],
                products: products.filter(
                    (product) => product.D_E_L_E_T_ !== '*'
                ),
                suppliers: suppliers.filter(
                    (supplier) => supplier.D_E_L_E_T_ !== '*'
                ),
            })
        }
    }, [quotation, products, suppliers])

    return (
        <>
            <Dialog open={isOpen} onOpenChange={onClose}>
                <DialogContent className="flex flex-col h-[90%] max-w-[1200px]">
                    <DialogHeader>
                        <DialogTitle>Revisão</DialogTitle>
                    </DialogHeader>
                    <Form {...form}>
                        <form
                            className="flex flex-col flex-1 gap-4 overflow-hidden"
                            onSubmit={form.handleSubmit(onSubmit)}
                        >
                            <div className="flex items-center gap-4">
                                <FormField
                                    name="quotation"
                                    control={form.control}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Cotação</FormLabel>
                                            <FormControl>
                                                <Input
                                                    {...field}
                                                    value={field.value || ''}
                                                    readOnly
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    name="description"
                                    control={form.control}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Descrição</FormLabel>
                                            <FormControl>
                                                <Input
                                                    {...field}
                                                    value={field.value || ''}
                                                    readOnly
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    name="expirationDate"
                                    control={form.control}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>
                                                Data de expiração *
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    {...field}
                                                    value={
                                                        isValid(
                                                            new Date(
                                                                field.value
                                                            )
                                                        )
                                                            ? format(
                                                                  new Date(
                                                                      field.value
                                                                  ),
                                                                  'dd/MM/yyyy'
                                                              )
                                                            : ''
                                                    }
                                                    readOnly
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <div className="space-y-2">
                                    <Label>Comprador</Label>
                                    <Input
                                        className="w-[250px]"
                                        value={quotation?.NOME_USUARIO}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <PublishDialogTable
                                products={products || []}
                                suppliers={suppliers || []}
                            />
                            <DialogFooter>
                                {/* <ButtonWithTooltip
                                type="button"
                                className="w-10 text-white rounded-lg bg-sky-400 hover:bg-sky-500 hover:text-white"
                                tooltipContent="Imprimir"
                            >
                                <Printer size={16} />
                            </ButtonWithTooltip> */}
                                <Button
                                    className="rounded-lg"
                                    type="submit"
                                    disabled={isPending}
                                >
                                    Publicar
                                </Button>
                            </DialogFooter>
                        </form>
                    </Form>
                </DialogContent>
            </Dialog>
            <SupplierWarningDialog
                isOpen={isSupplierWarningDialogDialogOpen}
                onClose={onSupplierWarningDialogDialogClose}
                suppliersIds={invalidEmails.map(
                    (supplier) => supplier.SK_FORNECEDOR
                )}
            />
        </>
    )
}

export default PublishDialog
