import { QuotationMapValues } from '@/api/business/cotacoes/type'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import { Checkbox } from '@/components/ui/checkbox'
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from '@/components/ui/sheet'
import { TypographyP } from '@/components/ui/typography'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { View } from 'lucide-react'

const stateSelector = (state: Store) => ({
    quotationMap: state.quotationMapSlice.state.quotationMap,
    quotationMapTableVisibility:
        state.quotationMapSlice.state.quotationMapTableVisibility,
    onChangeQuotationMapColumnVisibity:
        state.quotationMapSlice.actions.onChangeQuotationMapColumnVisibity,
})

const columnNames: Partial<Record<keyof QuotationMapValues, string>> = {
    PRECO: 'Valor net',
    PRECO_COM_FRETE: 'Valor com frete',
    PRECO_TOTAL: 'Valor total',
    PRECO_IMPOSTO: 'Valor com imposto',
    PRECO_TOTAL_IMPOSTO: 'Valor total com imposto',
    PRECO_TOTAL_COM_FRETE: 'Valor total com frete',
    PREVISAO_DE_ENTREGA: 'Previsão de entrega',
}

const TableColumnsVisibility = () => {
    const {
        quotationMap,
        quotationMapTableVisibility,
        onChangeQuotationMapColumnVisibity,
    } = useBaseStore(stateSelector)

    const columns =
        quotationMap.VALORES.length > 1
            ? Object.keys(quotationMap.VALORES[0]).filter((key) =>
                  Object.keys(quotationMapTableVisibility.fields).includes(key)
              )
            : []

    const onChangeRoundVisibility = (id: number, checked: boolean) => {
        onChangeQuotationMapColumnVisibity('rounds', id, checked)
    }

    const onChangeSupplierVisibility = (id: string, checked: boolean) => {
        onChangeQuotationMapColumnVisibity('suppliers', id, checked)
    }

    const onChangeColumnVisibility = (id: string, checked: boolean) => {
        onChangeQuotationMapColumnVisibity('fields', id, checked)
    }

    return (
        <Sheet modal={false}>
            <SheetTrigger asChild>
                <ButtonWithTooltip
                    variant="outline"
                    size="icon"
                    tooltipContent={'Visibilidade colunas'}
                >
                    <View size={14} />
                </ButtonWithTooltip>
            </SheetTrigger>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle>Visibilidade</SheetTitle>
                </SheetHeader>
                <div className="py-4 space-y-4">
                    <div className="space-y-2">
                        <div>
                            <TypographyP className="font-semibold text-md">
                                Rodadas
                            </TypographyP>
                        </div>
                        <div>
                            {quotationMap.RODADAS.map((round) => {
                                return (
                                    <div
                                        key={round.SK_COTACAO_X_RODADA}
                                        className="flex items-center gap-2 p-2"
                                    >
                                        <Checkbox
                                            id={round.SK_COTACAO_X_RODADA.toString()}
                                            onCheckedChange={(checked) =>
                                                onChangeRoundVisibility(
                                                    round.SK_COTACAO_X_RODADA,
                                                    !!checked
                                                )
                                            }
                                            checked={
                                                quotationMapTableVisibility
                                                    .rounds[
                                                    round.SK_COTACAO_X_RODADA
                                                ]
                                            }
                                        />
                                        <TypographyP asChild>
                                            <label
                                                htmlFor={round.SK_COTACAO_X_RODADA.toString()}
                                            >
                                                Rodada {round.NUMERO_RODADA}
                                            </label>
                                        </TypographyP>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div>
                            <TypographyP className="font-semibold text-md">
                                Fornecedores
                            </TypographyP>
                        </div>
                        <div>
                            {Object.values(
                                quotationMap.FORNECEDORES.entities
                            ).map((supplier) => {
                                return (
                                    <div
                                        key={supplier.SK_FORNECEDOR}
                                        className="flex items-center gap-2 p-2"
                                    >
                                        <Checkbox
                                            id={supplier.SK_FORNECEDOR}
                                            onCheckedChange={(checked) =>
                                                onChangeSupplierVisibility(
                                                    supplier.SK_FORNECEDOR,
                                                    !!checked
                                                )
                                            }
                                            checked={
                                                quotationMapTableVisibility
                                                    .suppliers[
                                                    supplier.SK_FORNECEDOR
                                                ]
                                            }
                                        />
                                        <TypographyP asChild>
                                            <label
                                                htmlFor={supplier.SK_FORNECEDOR}
                                            >
                                                {supplier.DD_RAZAO_SOCIAL}
                                            </label>
                                        </TypographyP>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div>
                            <TypographyP className="font-semibold text-md">
                                Colunas
                            </TypographyP>
                        </div>
                        <div>
                            {columns.map((column) => {
                                return (
                                    <div
                                        key={column}
                                        className="flex items-center gap-2 p-2"
                                    >
                                        <Checkbox
                                            id={column}
                                            onCheckedChange={(checked) =>
                                                onChangeColumnVisibility(
                                                    column,
                                                    !!checked
                                                )
                                            }
                                            checked={
                                                quotationMapTableVisibility
                                                    .fields[column]
                                            }
                                        />
                                        <TypographyP asChild>
                                            <label htmlFor={column}>
                                                {columnNames[column] || column}
                                            </label>
                                        </TypographyP>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </SheetContent>
        </Sheet>
    )
}

export default TableColumnsVisibility
