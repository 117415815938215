import { isAxiosError } from '@/api/business'
import {
    PURCHASE_REQUEST_CLASSNAME,
    PURCHASE_REQUEST_STATUS,
    PurchaseRequestFlat,
} from '@/api/business/solicitacaoDeCompra/type'
import { Button } from '@/components/ui/button'
import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogDescription,
} from '@/components/ui/dialog'
import { useTable } from '@/hooks/useTable'
import { useQuotationPurchaseRequestListQuery } from '@/queries/usePurchaseRequestQuery'
import { Filter, Operators } from '@/types/Filters'
import { RowSelectionState, createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import DefaultColumn from '../Table/components/DefaultColumn'
import { format } from 'date-fns'
import { getDateFromId } from '@/utils/date'
import { cn } from '@/lib/utils'

interface PurchaseRequestItemsDialogProps {
    isOpen: boolean
    dialogState?: 'loading' | 'disabled'
    filters?: Filter<PurchaseRequestFlat>[]
    rowSelection: {
        selectedRows: RowSelectionState
        onSelectPurchaseRequestItems: (
            selectedRows: RowSelectionState,
            purchaseRequestItems: PurchaseRequestFlat[]
        ) => void
    }
    onClose: () => void
    onCloseWithAction?: () => void
}

type PurchaseRequestItemsTable = TableData<PurchaseRequestFlat>

const columnHelper = createColumnHelper<PurchaseRequestFlat>()

const PurchaseRequestItemsDialog = ({
    isOpen,
    rowSelection: { selectedRows, onSelectPurchaseRequestItems },
    dialogState,
    filters = [],
    onClose,
    onCloseWithAction,
}: PurchaseRequestItemsDialogProps) => {
    const [fieldFilters, setFieldFilters] = useState<
        Filter<PurchaseRequestFlat>[]
    >([])

    const {
        pageIndex,
        pageSize,
        columnFilters,
        onPaginationChange,
        onColumnFiltersChange,
    } = useTable()

    const { data, isPending, isFetching, isError, error } =
        useQuotationPurchaseRequestListQuery({
            currentPage: pageIndex,
            perPage: 50,
            filters: [...filters, ...fieldFilters],
            enabled: isOpen,
        })

    const dataMemo = useMemo(() => data?.DADOS || [], [data])

    const columnsMemo = useMemo(
        () => [
            columnHelper.display({
                id: 'Status cotação',
                cell: ({ row }) => {
                    const { STATUS_SC_COTACAO } = row.original
                    return STATUS_SC_COTACAO ? (
                        <span
                            className={cn(
                                'px-2 overflow-hidden text-accent-foreground bg-accent whitespace-nowrap text-ellipsis',
                                PURCHASE_REQUEST_CLASSNAME[STATUS_SC_COTACAO]
                            )}
                        >
                            {PURCHASE_REQUEST_STATUS[STATUS_SC_COTACAO]}
                        </span>
                    ) : null
                },
                meta: {
                    enableColumnOrdering: false,
                },
                size: 100,
            }),
            columnHelper.accessor('NK_SOLICITACAO_DE_COMPRAS', {
                header: 'Número da SC',
                size: 80,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('NUMERO_DA_COTACAO', {
                id: 'NUMERO_DA_COTACAO',
                header: 'Número da cotação',
                size: 100,
            }),
            columnHelper.accessor('SK_EMPRESA', {
                header: 'Cod empresa',
                size: 80,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                header: 'Empresa',
                size: 110,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('NK_ITEM_SOLICITACAO_DE_COMPRAS', {
                header: 'Item SC',
                size: 80,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('NK_PRODUTO', {
                header: 'Código produto',
                size: 110,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DS_PRODUTO', {
                header: 'Produto',
                size: 300,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DD_UM', {
                header: 'UM',
                size: 80,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DD_NCM', {
                header: 'NCM',
                size: 80,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DS_NOME_COMPRADOR', {
                header: 'Comprador',
                size: 200,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('NK_CENTRO_DE_CUSTO', {
                header: 'Código centro de custo',
                size: 80,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('CENTRO_DE_CUSTO', {
                header: 'Centro de custo',
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('NK_CONTA_ORCAMENTARIA', {
                header: 'Código conta orçamentária',
                size: 80,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('CONTA_ORCAMENTARIA', {
                header: 'Conta orçamentária',
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('QTD_SOLICITADA', {
                header: 'Qtde solicitada',
                size: 110,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('QTD_PEDIDA', {
                header: 'Qtde pedida',
                size: 110,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DD_BLOQUEADO', {
                header: 'Bloqueado',
                size: 110,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DD_SOLICITANTE', {
                header: 'Solicitante',
                size: 120,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DD_OBSERVACAO', {
                header: 'Observação',
                size: 200,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DD_APROVADOR', {
                header: 'Aprovador',
                size: 200,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor(
                ({ DT_APROVACAO }) =>
                    DT_APROVACAO
                        ? format(getDateFromId(DT_APROVACAO), 'dd/MM/yyyy')
                        : '',
                {
                    id: 'DT_APROVACAO',
                    header: 'Data aprovação',
                    size: 100,
                }
            ),
            columnHelper.accessor(
                ({ DT_EMISSAO }) =>
                    DT_EMISSAO
                        ? format(getDateFromId(DT_EMISSAO), 'dd/MM/yyyy')
                        : '',
                {
                    id: 'DT_EMISSAO',
                    header: 'Data emissão',
                    size: 100,
                }
            ),
            columnHelper.accessor(
                ({ DT_NECESSIDADE }) =>
                    DT_NECESSIDADE
                        ? format(getDateFromId(DT_NECESSIDADE), 'dd/MM/yyyy')
                        : '',
                {
                    id: 'DT_NECESSIDADE',
                    header: 'Data necessidade',
                    size: 100,
                }
            ),
        ],
        []
    )

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="flex flex-col max-w-[1200px] h-3/4 max-h-[900px]">
                <DialogHeader>
                    <DialogTitle>Solicitações de compras</DialogTitle>
                    <DialogDescription>
                        Selecione os itens das SCs para serem cotados
                    </DialogDescription>
                </DialogHeader>
                <div className="flex-1 overflow-hidden">
                    <Table<PurchaseRequestItemsTable>
                        columns={columnsMemo}
                        data={dataMemo}
                        isLoading={isPending}
                        isFetching={isFetching}
                        isError={isError}
                        tableState={{
                            rowSelection: selectedRows,
                            columnFilters,
                            pagination: {
                                pageIndex,
                                pageSize: pageSize,
                            },
                        }}
                        errorMessage={
                            isError && isAxiosError(error)
                                ? error.response?.data.message
                                : undefined
                        }
                        enableRowSelection={(row) => row.original.GERA_COTACAO}
                        onColumnFiltersChange={(columnFilterState) => {
                            if (columnFilterState) {
                                setFieldFilters([
                                    {
                                        AND: columnFilterState.map((field) => ({
                                            field: field.id as keyof PurchaseRequestFlat,
                                            operator: Operators.like,
                                            value: field.value as string,
                                        })),
                                    },
                                ])
                            } else {
                                setFieldFilters([])
                            }
                            onColumnFiltersChange(columnFilterState)
                        }}
                        onPaginationChange={onPaginationChange}
                        pagination={{
                            pageSize: pageSize,
                            totalItems: data?.TOTAL || 0,
                            totalPages: data?.TOTAL_DE_PAGINAS || 0,
                        }}
                        meta={{
                            layout: 'stretch',
                        }}
                        getRowId={(row) =>
                            row.SK_SOLICITACAO_DE_COMPRAS.toString()
                        }
                        onRowSelectionChange={(rowSelection) =>
                            onSelectPurchaseRequestItems(
                                rowSelection,
                                Object.keys(rowSelection)
                                    .map((id) =>
                                        data?.DADOS.find(
                                            (product) =>
                                                product.SK_SOLICITACAO_DE_COMPRAS.toString() ===
                                                id
                                        )
                                    )
                                    .filter(Boolean) as PurchaseRequestFlat[]
                            )
                        }
                        defaultColumn={{
                            cell: ({ getValue }) => (
                                <DefaultColumn>
                                    {getValue() as string}
                                </DefaultColumn>
                            ),
                        }}
                    />
                </div>
                <DialogFooter>
                    <Button
                        disabled={
                            dialogState === 'disabled' ||
                            dialogState === 'loading'
                        }
                        onClick={
                            onCloseWithAction ? onCloseWithAction : onClose
                        }
                    >
                        Confirmar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default PurchaseRequestItemsDialog
