import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'

import { Row } from '@tanstack/react-table'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/components/ui/use-toast'
import { isAxiosError } from '@/api/business'
import { PaymentConditionTable } from '../..'
import { updatePaymentCondition } from '@/api/business/condicoesDePagamento'
import { paymentConditionsKeys } from '@/queries/usePaymentConditionsQuery'
import { Button } from '@/components/ui/button'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useEffect } from 'react'
import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/ui/label'

interface EditDialogProps {
    row: Row<PaymentConditionTable>
    isOpen: boolean
    onClose: () => void
}

const schema = z.object({
    quotation: z.boolean(),
})

type Schema = z.infer<typeof schema>

const EditDialog = ({ row, isOpen, onClose }: EditDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const form = useForm<Schema>({
        resolver: zodResolver(schema),
        defaultValues: {
            quotation: row.original.DD_COTACAO === 1,
        },
    })

    useEffect(() => {
        form.reset({
            quotation: row.original.DD_COTACAO === 1,
        })
    }, [row.original])

    const { mutate, isPending: mutateLoading } = useMutation({
        mutationFn: updatePaymentCondition,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: paymentConditionsKeys.all,
            })
            toast({
                title: 'Condição de pagamento atualizado com sucesso',
            })
            onClose()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível editar o condição de pagamento. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao editar condição de pagamento',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const onSubmit = (data: Schema) => {
        mutate({
            SK_CONDICAO_DE_PAGAMENTO: row.original.SK_CONDICAO_DE_PAGAMENTO,
            DD_COTACAO: data.quotation ? 1 : 0,
        })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Editar condição de pagamento</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-4"
                    >
                        <div>
                            <Label htmlFor="id">Código</Label>
                            <Input
                                id="id"
                                value={row.original.SK_CONDICAO_DE_PAGAMENTO}
                                disabled
                            />
                        </div>
                        <div>
                            <Label htmlFor="id">Condição de pagamento</Label>
                            <Input
                                id="id"
                                value={row.original.DS_CONDICAO_DE_PAGAMENTO}
                                disabled
                            />
                        </div>
                        <FormField
                            name="quotation"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <div className="flex items-center gap-2">
                                            <Checkbox
                                                id="quotation"
                                                checked={field.value}
                                                onCheckedChange={(value) =>
                                                    field.onChange(!!value)
                                                }
                                            />
                                            <FormLabel htmlFor="quotation">
                                                Utilizar em cotação
                                            </FormLabel>
                                        </div>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <DialogFooter>
                            <Button
                                type="button"
                                onClick={onClose}
                                variant="ghost"
                            >
                                Cancelar
                            </Button>
                            <Button type="submit" disabled={mutateLoading}>
                                Confirmar
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default EditDialog
