import List from '@/pages/QuotationList/components/List'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import Grid from './components/Grid'
import { Filter, Operators } from '@/types/Filters'
import { useState } from 'react'
import { Quotation } from '@/types/Quotation'
import { ScrollArea } from '@/components/ui/scroll-area'

const stateSelector = (state: Store) => ({
    layout: state.quotationListSlice.state.layout,
    userId: state.authSlice.state.userId,
})

const QuotationList = () => {
    const { layout, userId } = useBaseStore(stateSelector)

    const [buttonFilters, setButtonFilters] = useState<
        Filter<Pick<Quotation, 'SK_USUARIO' | 'SK_COTACAO'>>[]
    >([
        {
            AND: [
                {
                    field: 'SK_USUARIO',
                    operator: Operators.equals,
                    value: userId,
                },
            ],
        },
    ])

    return (
        <div className="flex flex-col flex-1 overflow-hidden">
            {layout === 'list' ? (
                <div className="flex-1 p-6 overflow-hidden">
                    <List
                        buttonFilters={buttonFilters}
                        setButtonFilters={setButtonFilters}
                    />
                </div>
            ) : (
                <ScrollArea className="p-6">
                    <Grid
                        buttonFilters={buttonFilters}
                        setButtonFilters={setButtonFilters}
                    />
                </ScrollArea>
            )}
        </div>
    )
}

export default QuotationList
