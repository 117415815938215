import { isAxiosError } from '@/api/business'
import {
    PurchaseRequestFlat,
    INTERVAL_CLASSNAME,
} from '@/api/business/solicitacaoDeCompra/type'
import { Button } from '@/components/ui/button'
import Table from '@/components/Table'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { TableData } from '@/components/Table/type'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { cn } from '@/lib/utils'
import { usePurchaseRequestToSelectQuery } from '@/queries/usePurchaseRequestQuery'
import { useBaseStore } from '@/store'
import { getFiltersSelector } from '@/store/quotationPurchaseRequestSlice'
import { Store } from '@/store/type'
import { Intervals, Priority } from '@/types/PurchaseOrder'
import { getDateFromId } from '@/utils/date'
import { RowSelectionState, createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import { AlertTriangle } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'

type SelectAllDialogProps = {
    isOpen: boolean
    onClose: () => void
}

export type PurchaseRequestTable = TableData<PurchaseRequestFlat>

const columnHelper = createColumnHelper<PurchaseRequestFlat>()

const stateSelector = (state: Store) => ({
    rowSelection: state.quotationPurchaseRequestSlice.state.rowSelection,
    onSelectItems: state.quotationPurchaseRequestSlice.actions.onSelectItems,
})

const SelectAllDialog = ({ isOpen, onClose }: SelectAllDialogProps) => {
    const filters = useBaseStore(getFiltersSelector)
    const [rowSelectionState, setRowSelection] = useState({})

    const { onSelectItems } = useBaseStore(stateSelector)

    const {
        data = [],
        isPending,
        isFetching,
        isError,
        error,
        isSuccess,
    } = usePurchaseRequestToSelectQuery({
        filters,
        enabled: isOpen,
    })

    const onConfirm = () => {
        onSelectItems(
            rowSelectionState,
            Object.keys(rowSelectionState)
                .map(
                    (id) =>
                        data.find(
                            (toSelect) =>
                                toSelect.SK_SOLICITACAO_DE_COMPRAS === id
                        )!
                )
                .filter(Boolean)
        )
        onClose()
    }

    const dataMemo = useMemo(() => data, [data])

    const columnsMemo = useMemo(
        () => [
            columnHelper.display({
                id: 'Status cotação',
                cell: ({ row }) => {
                    const { NUMERO_DA_COTACAO } = row.original
                    return NUMERO_DA_COTACAO ? (
                        <span className="px-2 bg-amber-50 text-amber-600">
                            Em cotação
                        </span>
                    ) : null
                },
                meta: {
                    enableColumnOrdering: false,
                },
                size: 100,
            }),
            columnHelper.accessor('NK_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_SOLICITACAO_DE_COMPRAS',
                header: 'Número da SC',
                size: 100,
            }),
            columnHelper.accessor('NUMERO_DA_COTACAO', {
                id: 'NUMERO_DA_COTACAO',
                header: 'Número da cotação',
                size: 100,
            }),
            columnHelper.accessor('SK_EMPRESA', {
                id: 'SK_EMPRESA',
                header: 'Código empresa',
                cell: ({ getValue, row }) => {
                    const { COR_EMPRESA } = row.original
                    return (
                        <div
                            className="overflow-hidden whitespace-nowrap text-ellipsis"
                            style={{
                                color: COR_EMPRESA || undefined,
                            }}
                        >
                            {getValue()}
                        </div>
                    )
                },
                size: 100,
            }),
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                id: 'ABREVIATURA_EMPRESA',
                header: 'Empresa',
                cell: ({ getValue, row }) => {
                    const { COR_EMPRESA } = row.original
                    return (
                        <div
                            className="overflow-hidden whitespace-nowrap text-ellipsis"
                            style={{
                                color: COR_EMPRESA || undefined,
                            }}
                        >
                            {getValue()}
                        </div>
                    )
                },
                size: 120,
            }),
            columnHelper.accessor('NK_ITEM_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_ITEM_SOLICITACAO_DE_COMPRAS',
                header: 'Item SC',
                size: 100,
            }),
            columnHelper.accessor('NK_PRODUTO', {
                id: 'NK_PRODUTO',
                header: 'Código produto',
                size: 120,
            }),
            columnHelper.accessor('DS_PRODUTO', {
                id: 'DS_PRODUTO',
                header: 'Produto',
                size: 400,
            }),
            columnHelper.accessor('DD_UM', {
                id: 'DD_UM',
                header: 'UM',
                size: 100,
            }),
            columnHelper.accessor('DD_NCM', {
                id: 'DD_NCM',
                header: 'NCM',
                size: 100,
            }),
            columnHelper.accessor('DS_GRUPO_DE_PRODUTOS', {
                id: 'DS_GRUPO_DE_PRODUTOS',
                header: 'Grupo de produto',
                size: 200,
            }),
            columnHelper.accessor('DD_URGENCIA', {
                id: 'DD_URGENCIA',
                header: 'Prioridade',
                cell: ({ getValue }) => {
                    const priority = getValue() as Priority
                    return priority === 'Urgente' ? (
                        <span className="flex items-center gap-1.5">
                            <AlertTriangle
                                size={12}
                                className="text-orange-500"
                            />
                            {priority}
                        </span>
                    ) : (
                        priority
                    )
                },
                size: 110,
            }),
            columnHelper.accessor('DD_STATUS', {
                id: 'DD_STATUS',
                header: 'Status',
                size: 100,
            }),
            columnHelper.accessor('DS_NOME_COMPRADOR', {
                id: 'DS_NOME_COMPRADOR',
                header: 'Comprador',
                size: 200,
            }),
            columnHelper.accessor('NK_CENTRO_DE_CUSTO', {
                id: 'NK_CENTRO_DE_CUSTO',
                header: 'Código centro de custo',
                size: 100,
            }),
            columnHelper.accessor('CENTRO_DE_CUSTO', {
                id: 'CENTRO_DE_CUSTO',
                header: 'Centro de custo',
                size: 200,
            }),
            columnHelper.accessor('NK_CONTA_ORCAMENTARIA', {
                id: 'NK_CONTA_ORCAMENTARIA',
                header: 'Código conta orçamentária',
                size: 100,
            }),
            columnHelper.accessor('CONTA_ORCAMENTARIA', {
                id: 'CONTA_ORCAMENTARIA',
                header: 'Conta orçamentária',
                size: 300,
            }),
            columnHelper.accessor('QTD_SOLICITADA', {
                id: 'QTD_SOLICITADA',
                header: 'Qtde solicitada',
                size: 100,
            }),
            columnHelper.accessor('QTD_PEDIDA', {
                id: 'QTD_PEDIDA',
                header: 'Qtde pedida',
                size: 100,
            }),
            columnHelper.accessor(
                ({ DD_BLOQUEADO }) => (DD_BLOQUEADO ? 'Sim' : 'Não'),
                {
                    id: 'DD_BLOQUEADO',
                    header: 'Bloqueado',
                    cell: ({ getValue }) => {
                        return (
                            <span
                                className={cn(
                                    'px-2',
                                    getValue() === 'Sim'
                                        ? 'bg-red-50 text-red-500'
                                        : 'bg-emerald-50 text-emerald-500'
                                )}
                            >
                                {getValue()}
                            </span>
                        )
                    },
                    meta: {
                        cell: {
                            className: 'justify-center',
                        },
                    },
                    size: 120,
                }
            ),
            columnHelper.accessor('DD_SOLICITANTE', {
                id: 'DD_SOLICITANTE',
                header: 'Solicitante',
                size: 130,
            }),
            columnHelper.accessor('DD_OBSERVACAO', {
                id: 'DD_OBSERVACAO',
                header: 'Observação',
                size: 300,
            }),
            columnHelper.accessor('DD_APROVADOR', {
                id: 'DD_APROVADOR',
                header: 'Aprovador',
                size: 300,
            }),
            columnHelper.accessor(
                ({ DT_PRAZO }) =>
                    DT_PRAZO
                        ? format(getDateFromId(Number(DT_PRAZO)), 'dd/MM/yyyy')
                        : '',
                {
                    id: 'DT_PRAZO',
                    header: 'Prazo',
                    size: 100,
                }
            ),
            columnHelper.accessor('INTERVALO_PRAZO', {
                id: 'INTERVALO_PRAZO',
                header: 'Intervalo',
                cell: ({ getValue }) => {
                    const interval = getValue() as Intervals
                    return (
                        <span
                            className={cn(
                                'px-2 py-0.5 overflow-hidden whitespace-nowrap text-ellipsis',
                                INTERVAL_CLASSNAME[interval]
                            )}
                        >
                            {interval}
                        </span>
                    )
                },
                size: 200,
                meta: {
                    cell: {
                        className: 'justify-center',
                    },
                },
            }),
            columnHelper.accessor('ATRASO', {
                id: 'ATRASO',
                header: 'Atraso',
                size: 100,
            }),
            columnHelper.accessor(
                ({ DT_APROVACAO }) =>
                    DT_APROVACAO
                        ? format(getDateFromId(DT_APROVACAO), 'dd/MM/yyyy')
                        : '',
                {
                    id: 'DT_APROVACAO',
                    header: 'Data aprovação',
                    size: 100,
                }
            ),
            columnHelper.accessor(
                ({ DT_EMISSAO }) =>
                    DT_EMISSAO
                        ? format(getDateFromId(DT_EMISSAO), 'dd/MM/yyyy')
                        : '',
                {
                    id: 'DT_EMISSAO',
                    header: 'Data emissão',
                    size: 100,
                }
            ),
            columnHelper.accessor(
                ({ DT_NECESSIDADE }) =>
                    DT_NECESSIDADE
                        ? format(getDateFromId(DT_NECESSIDADE), 'dd/MM/yyyy')
                        : '',
                {
                    id: 'DT_NECESSIDADE',
                    header: 'Data necessidade',
                    size: 100,
                }
            ),
        ],
        []
    )

    useEffect(() => {
        if (isSuccess && data) {
            const initialRowSelection = data.reduce((acc, curr) => {
                acc[curr.SK_SOLICITACAO_DE_COMPRAS] = true
                return acc
            }, {} as RowSelectionState)

            setRowSelection(initialRowSelection)
        }
    }, [isSuccess])

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-[80%] h-[90%] flex flex-col">
                <DialogHeader>
                    <DialogTitle>Selecionar SCs</DialogTitle>
                </DialogHeader>
                <p>
                    <strong className="text-bold">{data?.length}</strong> SCs
                    encontradas para serem selecionadas
                </p>
                <div className="flex-1 overflow-hidden">
                    <Table<PurchaseRequestTable>
                        data={dataMemo}
                        isLoading={isPending}
                        isFetching={isFetching}
                        isError={isError}
                        errorMessage={
                            isError && isAxiosError(error)
                                ? error?.response?.data.message
                                : 'Erro ao carregar dados'
                        }
                        tableState={{
                            rowSelection: rowSelectionState,
                        }}
                        columns={columnsMemo}
                        onRowSelectionChange={(rowSelection) => {
                            onSelectItems(
                                rowSelection,
                                dataMemo.filter((item) =>
                                    Object.keys(rowSelection).includes(
                                        item.SK_SOLICITACAO_DE_COMPRAS
                                    )
                                )
                            )
                        }}
                        enableRowSelection={(row) => row.original.GERA_COTACAO}
                        getRowId={(row) => row.SK_SOLICITACAO_DE_COMPRAS}
                        defaultColumn={{
                            cell: ({ getValue }) => {
                                return (
                                    <DefaultColumn>
                                        {getValue() as string}
                                    </DefaultColumn>
                                )
                            },
                        }}
                    />
                </div>
                <DialogFooter>
                    <Button variant="ghost" onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button onClick={onConfirm}>Confirmar</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default SelectAllDialog
