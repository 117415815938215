import {
    fetchQuotations,
    fetchQuotationById,
    fetchQuotationProducts,
    fetchQuotationSuppliers,
    fetchPaginatedQuotations,
    fetchSuppliersToQuotation,
    fetchQuotationHeaders,
    fetchQuotationFilters,
} from '@/api/business/cotacoes'
import {
    FetchPaginatedQuotationsProps,
    FetchQuotationHeadersProps,
    FetchQuotationProductsProps,
    FetchSuppliersToQuotationProps,
} from '@/api/business/cotacoes/type'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

interface UseQuotationByIdQueryProps {
    id: number
}

interface UsePaginatedQuotationQuery extends FetchPaginatedQuotationsProps {}

export const quotationKeys = {
    all: ['quotation'] as const,
    lists: () => [...quotationKeys.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...quotationKeys.lists(), page, perPage, filters] as const,
    listHeaders: (page: number, perPage: number, filters: any) =>
        [...quotationKeys.lists(), 'header', page, perPage, filters] as const,
    details: () => [...quotationKeys.all, 'detail'] as const,
    detail: (params: string) => [...quotationKeys.details(), params] as const,
    detailProducts: (params: string) =>
        [...quotationKeys.details(), 'products', params] as const,
    detailSuppliers: (params: string) =>
        [...quotationKeys.details(), 'suppliers', params] as const,
    suppliersList: (
        params: string,
        page: number,
        perPage: number,
        filters: any
    ) =>
        [
            ...quotationKeys.details(),
            'suppliers',
            'quotation',
            page,
            perPage,
            filters,
            params,
        ] as const,
    filtersList: () => [...quotationKeys.lists(), 'filters'] as const,
}

export const useCotacoesQuery = () => {
    return useQuery({
        queryFn: fetchQuotations,
        queryKey: quotationKeys.lists(),
    })
}

export const usePaginatedQuotationQuery = ({
    page,
    perPage,
    filters,
}: UsePaginatedQuotationQuery) => {
    return useQuery({
        queryFn: () => fetchPaginatedQuotations({ page, perPage, filters }),
        queryKey: quotationKeys.list(page, perPage, filters),
        placeholderData: keepPreviousData,
    })
}

export const useQuotationFiltersQuery = () => {
    return useQuery({
        queryFn: () => fetchQuotationFilters(),
        queryKey: quotationKeys.filtersList(),
    })
}

interface UseQuotationHeadersQuery extends FetchQuotationHeadersProps {}

export const useQuotationHeadersQuery = ({
    currentPage,
    perPage,
    filters,
}: UseQuotationHeadersQuery) => {
    return useQuery({
        queryFn: () =>
            fetchQuotationHeaders({
                currentPage,
                perPage,
                filters,
            }),
        queryKey: quotationKeys.listHeaders(currentPage, perPage, filters),
        placeholderData: keepPreviousData,
    })
}

export const useQuotationByIdQuery = ({ id }: UseQuotationByIdQueryProps) => {
    return useQuery({
        queryFn: () => fetchQuotationById(id),
        queryKey: quotationKeys.detail(id.toString()),
    })
}

export const useQuotationProductsQuery = ({
    quotationId,
}: FetchQuotationProductsProps) => {
    return useQuery({
        queryFn: () => fetchQuotationProducts({ quotationId }),
        queryKey: quotationKeys.detailProducts(quotationId.toString()),
    })
}

interface UseQuotationSuppliersQueryProps extends FetchQuotationProductsProps {}

export const useQuotationSuppliersQuery = ({
    quotationId,
}: UseQuotationSuppliersQueryProps) => {
    return useQuery({
        queryFn: () => fetchQuotationSuppliers({ quotationId }),
        queryKey: quotationKeys.detailSuppliers(quotationId.toString()),
    })
}

interface UseSuppliersToQuotationQuery extends FetchSuppliersToQuotationProps {
    enabled?: boolean
}

export const useSuppliersToQuotationQuery = ({
    products,
    currentPage,
    perPage,
    filters,
}: UseSuppliersToQuotationQuery) => {
    return useQuery({
        queryFn: () =>
            fetchSuppliersToQuotation({
                products,
                perPage,
                currentPage,
                filters,
            }),
        queryKey: quotationKeys.suppliersList(
            products?.map((product) => product.productNk).join(', ') || '',
            currentPage,
            perPage,
            filters
        ),
        placeholderData: keepPreviousData,
    })
}
