import { isAxiosError } from '@/api/business'
import { updateSupplierValues } from '@/api/business/cotacoes'
import { useToast } from '@/components/ui/use-toast'
import { quotationMapKeys } from '@/queries/useQuotatioMapQuery'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Loader, Save } from 'lucide-react'
import { useParams } from 'react-router-dom'
import PublishButton from '../../../PublishButton'
import { Button } from '@/components/ui/button'
import SheetPopover from '../../../SheetPopover'

const stateSelector = (state: Store) => ({
    updatedValues: state.quotationMapSlice.state.supplierDialog.updatedValues,
    supplierId: state.quotationMapSlice.state.supplierDialog.supplierId,
    onResetUpdatedValues: state.quotationMapSlice.actions.onResetUpdatedValues,
})

const TableActions = () => {
    const { id } = useParams()
    const { updatedValues, supplierId, onResetUpdatedValues } =
        useBaseStore(stateSelector)

    const { toast } = useToast()
    const queryClient = useQueryClient()

    const { mutate, isPending } = useMutation({
        mutationFn: updateSupplierValues,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: quotationMapKeys.all })
            toast({
                title: 'Resposta salva com sucesso',
            })
            onResetUpdatedValues()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível salvar respostas. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao salvar',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const onClick = () => {
        mutate({
            products: updatedValues,
            quotationId: Number(id),
            supplierId,
        })
    }

    const disabled = updatedValues.length === 0

    return (
        <div className="flex items-center self-end gap-4">
            <SheetPopover />
            <Button
                disabled={disabled}
                onClick={onClick}
                variant="outline"
                className="space-x-1.5"
            >
                {isPending ? (
                    <Loader size={14} className="animate-spin" />
                ) : (
                    <Save size={14} />
                )}
                <span>Salvar</span>
            </Button>
            <PublishButton />
        </div>
    )
}

export default TableActions
