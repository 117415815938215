import { SidebarMenuButton, SidebarMenuItem } from '@/components/ui/sidebar'
import { LucideIcon } from 'lucide-react'
import { NavLink, useLocation } from 'react-router-dom'

type SidebarItemProps = {
    label: string
    to?: string
    Icon?: LucideIcon
}

const SidebarItem = ({ label, to, Icon }: SidebarItemProps) => {
    const { pathname } = useLocation()
    const isActive = !!to && pathname.includes(to)

    return (
        <SidebarMenuItem key={label}>
            <SidebarMenuButton tooltip={label} asChild isActive={isActive}>
                <NavLink to={to || '#'}>
                    {Icon && <Icon />}
                    <span>{label}</span>
                </NavLink>
            </SidebarMenuButton>
        </SidebarMenuItem>
    )
}

export default SidebarItem
