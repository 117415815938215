import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteQuotation } from '@/api/business/cotacoes'
import {
    quotationKeys,
    useQuotationByIdQuery,
} from '@/queries/useQuotationQuery'
import { useNavigate, useParams } from 'react-router-dom'
import { isAxiosError } from '@/api/business'
import { useToast } from '@/components/ui/use-toast'
import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/ui/label'
import { useEffect, useState } from 'react'
import { TypographyP } from '@/components/ui/typography'

interface DeleteQuotationDialogProps {
    isOpen: boolean
    onClose: () => void
}

const DeleteQuotationDialog = ({
    isOpen,
    onClose,
}: DeleteQuotationDialogProps) => {
    const { id } = useParams()

    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { toast } = useToast()

    const [forceDelete, setForceDelete] = useState(false)

    const { data } = useQuotationByIdQuery({ id: Number(id) })

    const { mutate: deleteMutate, isPending: deleteIsPending } = useMutation({
        mutationFn: deleteQuotation,
        onSuccess: () => {
            if (id) {
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.lists(),
                })
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.detail(id.toString()),
                })
            }

            if (forceDelete) {
                navigate('..')
            }

            toast({
                title: 'Cotação Cancelada',
                description: `A cotação ${data?.NUMERO_DA_COTACAO} foi cancelada com sucesso.`,
            })
            onClose()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível deletar a cotação. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao deletar cotação',
                description: errorMessage,
                variant: 'destructive',
            })
        },
        onSettled: () => {},
    })

    useEffect(() => {
        if (data) {
            setForceDelete(data.DD_PUBLICADO === 0 || data.DD_CANCELADA === 1)
        }
    }, [data])
    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        {data?.DD_CANCELADA === 1 || data?.DD_PUBLICADO === 0
                            ? 'Excluir cotação'
                            : 'Cancelar cotação'}
                    </DialogTitle>
                </DialogHeader>
                <div>
                    {data?.DD_CANCELADA === 1 || data?.DD_PUBLICADO === 0 ? (
                        <TypographyP>
                            Realmente deseja excluir a cotação{' '}
                            <span className="font-semibold">
                                {data?.NUMERO_DA_COTACAO}
                            </span>{' '}
                            e removê-la do histórico?
                        </TypographyP>
                    ) : (
                        <>
                            <TypographyP>
                                Realmente deseja cancelar a cotação{' '}
                                <span className="font-semibold">
                                    {data?.NUMERO_DA_COTACAO}
                                </span>{' '}
                                ? Essa ação irá notificar os fornecedores.
                            </TypographyP>
                            <div className="flex items-center gap-2 mt-4">
                                <Checkbox
                                    onCheckedChange={(value) =>
                                        setForceDelete(!!value)
                                    }
                                    checked={forceDelete}
                                />
                                <Label>Excluir permanentemente</Label>
                            </div>
                        </>
                    )}
                </div>
                <DialogFooter>
                    <Button variant="ghost" onClick={() => onClose()}>
                        Cancelar
                    </Button>
                    <Button
                        variant="destructive"
                        onClick={() => {
                            deleteMutate({ id: Number(id), forceDelete })
                        }}
                        disabled={deleteIsPending}
                    >
                        Confirmar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default DeleteQuotationDialog
